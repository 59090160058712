import axios from "axios";
import {
  LOAD_CALLER_ACTIONS_ASSIGNED,
  LOAD_CALLER_ACTIONS_PERFORMED,
  LOAD_CALLER_ACTION_DETAIL,
  LOAD_CALLER_ACTION_DETAIL_RANDOM,
  LOAD_PHONE_NUMBERS,
} from "./types";
// import { logOut } from '.';
import { reset } from "redux-form";

const apiURL = require("../config.json").apiURL;

export const LoadAllAssignedCallees = (
  pagesize,
  pagenum,
  havePerformed = false,
  searchParameters
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "action/getassignedcalls",
        params: {
          pagesize,
          pagenum,
          havePerformed,
          parameters: JSON.stringify(searchParameters),
        },
      })
        .then(({ data }) => {
          //console.log(data);
          let filteredData = [...data.actions];
          filteredData = filteredData.map((item) => {
            let object = {};
            object.id = item.id;
            object.defaulterId = item.defaulterId;
            object.priority = item.priority;
            object.date = item.date;
            let { defaulter, assignedByUser, formId, userId, user } = item;
            object.formId = formId;
            object.userId = userId;
            if (user && typeof user === "object" && user !== null) {
              let { firstname, lastname } = user;
              object.username = `${firstname} ${lastname}`;
            }
            if (
              defaulter &&
              typeof defaulter === "object" &&
              defaulter !== null
            ) {
              let {
                company,
                companyId,
                phoneNumbers,
                name,
                companyTrackingId,
                balance,
                defaulterAddresses,
              } = defaulter;
              object.defaulterName = name;
              object.companyTrackingId = companyTrackingId;
              object.companyId = companyId;
              object.balance = balance;

              object.cycleDay = defaulterAddresses.find(
                (x) => x.isPrimary === true
              )?.type;
              object.area = defaulterAddresses.find(
                (x) => x.isPrimary === true
              )?.region.name;

              if (company && typeof company === "object" && company !== null) {
                let { name } = company;
                object.companyName = name;
              }

              if (
                phoneNumbers &&
                Array.isArray(phoneNumbers) &&
                phoneNumbers.length !== 0
              ) {
                object.contact = phoneNumbers.map((contact) => {
                  return contact.number;
                });
              }
            }

            if (
              assignedByUser &&
              typeof assignedByUser === "object" &&
              assignedByUser !== null
            ) {
              let { firstname, lastname } = assignedByUser;
              object.assignedByUserName = `${firstname} ${lastname}`;
            }
            return object;
          });
          if (havePerformed) {
            dispatch({
              type: LOAD_CALLER_ACTIONS_PERFORMED,
              payload: {
                data: filteredData,
                total: data.count,
              },
            });
          } else {
            dispatch({
              type: LOAD_CALLER_ACTIONS_ASSIGNED,
              payload: {
                data: filteredData,
                total: data.count,
              },
            });
          }

          resolve("done");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              reject("failed");
            }
          } else {
            reject("failed");
            // dispatch(logOut())
          }
        });
    });
  };
};

export const LoadCalleeDetails = (calleeId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "action/getdetailsforcaller/" + calleeId,
      })
        .then(({ data }) => {
          console.log(data);
          let { action } = data;
          let details = {};
          let {
            id,
            assignedByUser,
            assignedByUserId,
            defaulter,
            defaulterId,
            form,
            formId,
            userId,
            user,
          } = action;
          details.id = id;
          details.assignedByUserId = assignedByUserId;
          details.defaulterId = defaulterId;
          details.formId = formId;
          details.form = form;
          details.userId = userId;
          if (user && typeof user === "object" && user !== null) {
            let { firstname, lastname } = user;
            details.username = `${firstname} ${lastname}`;
          }
          if (
            defaulter &&
            typeof defaulter === "object" &&
            defaulter !== null
          ) {
            let {
              company,
              companyId,
              phoneNumbers,
              name,
              companyTrackingId,
              balance,
              actions,
              otherDetails,
              defaulterAddresses,
              histories
            } = defaulter;
            details.defaulterName = name;
            details.companyTrackingId = companyTrackingId;
            details.companyId = companyId;
            details.balance = balance;
            details.actions = actions;
            details.otherDetails = otherDetails;
            details.phoneNumbers = phoneNumbers;
            details.defaulterHistories = histories;
            // details.NTBC = { name, companyTrackingId, balance };
            details.defaulterAddresses = defaulterAddresses;
            if (company && typeof company === "object" && company !== null) {
              let { name } = company;
              details.companyName = name;
            }

            // if (phoneNumbers && Array.isArray(phoneNumbers) && phoneNumbers.length !== 0) {
            //     details.contact = phoneNumbers.map(contact => {
            //         return contact.number;
            //     })
            // }
          }

          if (
            assignedByUser &&
            typeof assignedByUser === "object" &&
            assignedByUser !== null
          ) {
            let { firstname, lastname } = assignedByUser;
            details.assignedByUserName = `${firstname} ${lastname}`;
          }

          dispatch({
            type: LOAD_CALLER_ACTION_DETAIL,
            payload: details,
          });
          resolve("done");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              reject("failed");
            }
          } else {
            reject("failed");
            // dispatch(logOut())
          }
        });
    });
  };
};

export const getRandomCalleeDetails = () => {
  console.log("GET RANDOM CALLEE");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "action/getrandom",
      })
        .then(({ data }) => {
          console.log(data);
          console.log(typeof data);
          if (data === "") {
            dispatch({
              type: LOAD_CALLER_ACTION_DETAIL_RANDOM,
              payload: {},
            });
            return;
          }
          let { action } = data;
          let details = {};
          let {
            id,
            assignedByUser,
            assignedByUserId,
            defaulter,
            defaulterId,
            form,
            formId,
            userId,
            user,
          } = action;
          details.id = id;
          details.assignedByUserId = assignedByUserId;
          details.defaulterId = defaulterId;
          details.formId = formId;
          details.form = form;
          details.userId = userId;
          if (user && typeof user === "object" && user !== null) {
            let { firstname, lastname } = user;
            details.username = `${firstname} ${lastname}`;
          }
          if (
            defaulter &&
            typeof defaulter === "object" &&
            defaulter !== null
          ) {
            let {
              company,
              companyId,
              phoneNumbers,
              name,
              companyTrackingId,
              balance,
              actions,
              otherDetails,
              defaulterAddresses,
            } = defaulter;
            details.defaulterName = name;
            details.companyTrackingId = companyTrackingId;
            details.companyId = companyId;
            details.balance = balance;
            details.actions = actions;
            details.otherDetails = otherDetails;
            details.phoneNumbers = phoneNumbers;
            // details.NTBC = { name, companyTrackingId, balance };
            details.defaulterAddresses = defaulterAddresses;
            if (company && typeof company === "object" && company !== null) {
              let { name } = company;
              details.companyName = name;
            }

            // if (phoneNumbers && Array.isArray(phoneNumbers) && phoneNumbers.length !== 0) {
            //     details.contact = phoneNumbers.map(contact => {
            //         return contact.number;
            //     })
            // }
          }

          if (
            assignedByUser &&
            typeof assignedByUser === "object" &&
            assignedByUser !== null
          ) {
            let { firstname, lastname } = assignedByUser;
            details.assignedByUserName = `${firstname} ${lastname}`;
          }

          dispatch({
            type: LOAD_CALLER_ACTION_DETAIL_RANDOM,
            payload: details,
          });
          resolve("done");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              reject("failed");
            }
          } else {
            reject("failed");
            // dispatch(logOut())
          }
        });
    });
  };
};

export const toggleContactValidity = (id, isInvalid) => {
  let form = new FormData();
  form.append("Id", id);
  form.append("isInvalid", isInvalid);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: apiURL + "phonenumber/ToggleValidityState",
        data: form,
      })
        .then(() => {
          resolve("done");
        })
        .catch((error) => {
          alert("Action Failed");
          reject("failed");
        });
    });
  };
};

export const LoadPhoneNumbers = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "phonenumber/GetByDefaulterId/" + id,
      })
        .then(({ data }) => {
          console.log(data);
          let { phoneNumbers } = data;
          phoneNumbers = phoneNumbers.map((phone) => {
            return { ...phone, text: phone.number, value: phone.number };
          });
          dispatch({
            type: LOAD_PHONE_NUMBERS,
            payload: phoneNumbers,
          });
          resolve("done");
        })
        .catch((error) => {
          alert("Action Failed");
          reject("failed");
        });
    });
  };
};

export const addPhoneNumber = (id, number, type, isPrimary) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("Defaulterid", id);
    form.append("Number", number);
    if (isPrimary) form.append("Isprimary", isPrimary);
    if (type) form.append("Type", type);
    axios({
      method: "post",
      url: apiURL + "phonenumber/post",
      data: form,
    })
      .then((respone) => {
        console.log(respone);
        dispatch(reset("phone"));
        resolve("done");
      })
      .catch((error) => {
        alert("Action Failed");
        reject("failed");
      });
  });
};

export const changeValidPhone = (id, status) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("id", id);
    form.append("isInvalid", status);
    axios({
      method: "put",
      url: apiURL + "phonenumber/ToggleValidityState",
      data: form,
    })
      .then((respone) => {
        console.log(respone);
        resolve("done");
      })
      .catch((error) => {
        alert("Action Failed");
        reject("failed");
      });
  });
};

export const deletePhoneNumber = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append("id", id);
    axios({
      method: "delete",
      url: apiURL + "phonenumber/delete",
      data: form,
    })
      .then((respone) => {
        console.log(respone);
        resolve("done");
      })
      .catch((error) => {
        alert("Action Failed");
        reject("failed");
      });
  });
};
