import React, { Component } from "react";
import Sidebar from "react-sidebar";
import { Icon, MenuItem } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

let check = true;

const SideBarMenuItems = [
  check ? {
    label: "Dashboard",
    iconName: "dashboard",
    link: "/dashboard"
  } : {},
  {
    label: "Customers",
    iconName: "address card",
    link: "/customers"
  },
  {
    label: "Users",
    iconName: "user",
    link: "/users"
  },
  {
    label: "Roles",
    iconName: "setting",
    link: "/roles"
  },
  {
    label: "Companies",
    iconName: "building",
    link: "/companies"
  },
  {
    label: "Forms",
    iconName: "wpforms",
    link: "/forms"
  },
  {
    label: "Actions",
    iconName: "flag",
    link: "/actions"
  },
  {
      label: 'My Cases',
      iconName: 'tasks',
      link: '/perform'
  },
  {
    label: "Permissions",
    iconName: "check square",
    link: "/permissions"
  },
  {
    label: "Settings",
    iconName: "settings",
    link: "/settings"
  },
  {
    label: "Logs",
    iconName: "file text",
    link: "/logs"
  }
];

class SideBar extends Component {
  state = {
    activeItem: window.location.pathname
  };

  onClickHandle = item => {
    this.setState({
      activeItem: item
    });
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  renderSideButton = ({ iconName, label, link }) => {
    return (
      <MenuItem
        key={label}
        name={link}
        as={Link}
        to={link}
        // style={{color: 'red'}}
        // style={this.state.activeItem === iconName ? activeStyle : {}}
        onClick={this.handleItemClick}
      >
        <div
          style={
            this.state.activeItem === link
              ? sideBarActiveButton
              : sideBarButton
          }
        >
          <div style={{ paddingBottom: "1px" }}>
            <Icon name={iconName} />
          </div>
          <div>{label}</div>
        </div>
      </MenuItem>
    );
  };

  renderSideMenu = list => {
    let menuItems = [];
    list.forEach(x => {
      if(x.label !== undefined && x.label !== null)
        menuItems.push(this.renderSideButton(x));
    });
    return menuItems;
  };

  render() {
    return (
      <Sidebar
        sidebar={
          <div style={{ marginTop: "50px" }}>
            {this.renderSideMenu(SideBarMenuItems)}
          </div>
        }
        shadow={false}
        docked={this.props.visible}
        styles={sideStyles}
        onSetOpen={this.onSetSidebarOpen}
      >
        {this.props.children}
      </Sidebar>
    );
  }
}

const sideBarActiveButton = {
  color: "black",
  // backgroundColor: 'black',
  textAlign: "center",
  borderBottom: "solid white 1px",
  fontSize: "14px",
  margin: "5px",
  paddingTop: "8px",
  paddingBottom: "8px"
};

const sideBarButton = {
  color: "white",
  textAlign: "center",
  borderBottom: "solid white 1px",
  fontSize: "14px",
  margin: "5px",
  paddingTop: "8px",
  paddingBottom: "8px"
};

const sideStyles = {
  root: {
    background: "white"
  },
  sidebar: {
    background: "#2185D0",
    // background: 'red',
    color: "white",
    transition: "none",
    WebkitTransition: "none"
  },
  content: {
    transition: "none",
    WebkitTransition: "none"
  },
  active: {
    backgroundColor: "gold",
    color: "Black",
    fontWeight: "bold"
  }
};

const mstp = state => {
  return {
    visible: state.nav.visible
  };
};

export default connect(mstp, null)(SideBar);
