import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input, Button } from 'semantic-ui-react'
import { CommonInput } from './commonInput';
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
import { reset } from 'redux-form';

let Credentials = (props) => {
    return (
        <>
            <>
                <span>{`username: ${props.username}`}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
            <>
                <span>{`New Password: ${props.password}`}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
        </>
    )
}

let PassInputForm = (props) => {
    return (
        <div>
            <Modal
                size="tiny"
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header
                    icon="file"
                    content={props.header}
                />
                <Modal.Content>
                    <Form
                        id="passInputForm"
                        onSubmit={props.handleSubmit}
                        size="small"
                    >
                        {props.credentials === undefined || null ?
                            <><>
                                <span>{'Enter your own password for verification'}</span>
                                <div style={{ marginBottom: '5px' }} />
                            </>
                                <Field
                                    // disabled={props.loading}
                                    fluid
                                    name="pass"
                                    // component='input'
                                    component={CommonInput}
                                    iconLeft
                                    icon="lock"
                                    placeholder="Password"
                                    type="password"
                                />
                            </> : Credentials(props.credentials)
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {/* <Button primary form='passwordChange' type='submit'>Submit Form</Button> */}
                    {props.credentials === undefined || null ? props.actionsComponent : null}
                </Modal.Actions>
            </Modal>
        </div>
    )
}

PassInputForm = reduxForm({
    form: 'passIn',
    enableReinitialize: true
})(PassInputForm)

export default PassInputForm;