import React, { Component } from "react";
import {
  Button,
  Grid,
  Icon,
  Form,
  Header,
  Segment,
  Checkbox,
  Comment,
  Label,
  Modal,
  Input,
  Popup,
  Tab,
} from "semantic-ui-react";
import DataTable from "../components/dataTable";
import DataTableCheckBox from "../components/dataTableCutomers";
import SelectableTable from "../components/selectableTable";
import { connect } from "react-redux";
import * as actions from "../actions";
import ConfirmBox from "../components/confirmBox";
import LoaderView from "../components/loaderView";
import UnauthorizedView from "../components/unauthorizedView";
//import ViewActionFilterForm from '../components/forms/viewActionFilterForm';
import ViewActionFilterForm from "../components/forms/actionFilters";
//import FormFill from '../components/forms/fillForm';
import FillForm from "../components/forms/fillForm";
import RedoForm from "../components/forms/actionRedo";
import OutcomeEditForm from "../components/forms/outcomeTagEditForm";
// import OutcomeEditForm from '../components/forms/tagEditForm'
import ActionForm from "../components/forms/actionForm";
import FormValueEditForm from "../components/forms/formValueEditForm";
import ActionOutcomeForm from "../components/forms/actionoutcomeForm";
import PhoneForm from "../components/forms/phoneForm";
class ViewAll extends Component {
  state = {
    filterShow: false,
    loading: false,
    access: false,
    loaded: false,
    searchParameter: {},
    triggerUpdate: 0,
    loadTable: false,
    selectedAction: null,
    displayAction: false,
    displayFillAction: false,
    deleteLoading: false,
    activityLoading: [],
    showDeleteConfirmation: false,
    actionOnDelete: null,
    actionOutcome: null,
    changeNumberLoading: false,
    image: null,
    selectedOutcome: null,
    displayRedo: false,
    messageShow: false,
    message: null,
    supportingImageLoading: false,
    paymentImageLoading: false,
    sortby: "id",
    isdescending: true,
    displayEditAction: false,
    selectedIds: [],
    checked: false,
    selectAll: false,
    isCompleted: false,
    isRedone: false,
    isNotCompleted: false,
    showRejectConfirmation: false,
    rejectLoading: false,
    askReason: false,
    reasonLoading: false,
    reason: "",
    hasNotViewed: false,
    currentPage: 1,
    showEditMessage: false,
    submitLoading: false,
    editLoading: false,
    formValueToEdit: null,
    outcomeEditShow: false,
    uploadPaymentImage: false,
    uploadSupportingImage: false,
    selectedSupportingImages: null,
    selectedPaymentImages: null,
    deleteSupportingImageConfirmationShow: false,
    imageOnDelete: null,
    deletePaymentImageConfirmationShow: false,
    tagEditData: {},
    commentsList: [],
    commentsLoading: false,
    outcomeLoading: false,
    newComment: "",
    addCommentLoading: false,
    selectedPaymentImagesPaths: [],
    checkedPayment: false,
    selectAllPayment: false,
    selectedSupportingImagesPaths: [],
    checkedSupporting: false,
    selectAllSupporting: false,
    loadingPaymentImagesZip: false,
    loadingSupportingImagesZip: false,
    commentOnEdit: null,
    commentOnEditText: "",
    commentEditLoading: false,
    displayOutcome: false,
    performedActions: [],
    displayOutcome: false,
    addPhoneLoading: false,
    addPhoneMessage: null,
    openAddPhoneModa: false,
    showDeleteNumberConfirmation: false,
    deleteNumberId: null,
    deleteNumberLoading: false,
    numberForStatusChange: null,
    selectedTab: localStorage.getItem("selectedActionTab") ?? "",
    selectedTabIndex: localStorage.getItem("selectedActionTabIndex") ?? 0,
  };

  componentDidMount() {
    console.log(this.state.selectedTab);
    this.props.LoadActiveTags();
    this.props.LoadRoles();
    this.props.LoadAllUsersWithID();
    this.props.LoadAllFormsWithID();
    this.props.LoadCompaniesWithIds();
  }

  tablePaging = (pageSize, currentPage) => {
    this.setState({ loadTable: true, currentPage: currentPage });
    this.props
      .LoadAllViews(
        pageSize,
        currentPage,
        this.state.searchParameter,
        this.state.sortby,
        this.state.isdescending,
        this.state.selectedTab
      )
      .then((x) =>
        this.setState({
          loaded: true,
          access: true,
          loadTable: false,
          displayAction: false,
        })
      )
      .catch((x) => this.setState({ loaded: true, access: false }));
  };

  componentWillUnmount() {
    this.setState({ loaded: false, access: true });
  }

  getActionOutcome = (id) => {
    this.setState({
      outcomeLoading: true,
      commentsLoading: true,
    });
    // this.props.LoadPhoneNumbers(id);
    this.props
      .GetActionOutcome(id)
      .then((x) => {
        this.setState({
          displayAction: true,
          selectedOutcome: this.props.outcome,
          outcomeLoading: false,
        });
      })
      .catch((x) => {
        this.setState({ loaded: true });
      });
    this.props
      .GetComments(id)
      .then((x) => {
        this.setState({
          commentsLoading: false,
          commentsList: this.props.commentsList,
        });
      })
      .catch((x) => {
        this.setState({ loaded: true });
      });
  };

  /*   
       getActionOutcomeNow = (id) => {
           this.props.GetActionOutcome(id)
               .then(x => {
                   this.setState({ displayAction: true, selectedOutcome: this.props.outcome })
               }).catch((x => {
                   this.setState({ loaded: true })
               }
               ))
   
       }
       */

  constructForm = (id) => {
    //    console.log("construct Form")
    //  return new Promise((resolve, reject) => {
    this.props
      .LoadFormByID(id)
      .then((x) => {
        /*console.log(this.props.loadedForm)*/
      }, this.setState({ triggerUpdate: this.state.triggerUpdate }))
      .catch((x) => console.log("error"));
    //   })
  };

  actionButton = (value) => {
    // console.log(value)
    const activityProcessing = this.state.activityLoading.includes(value.id);
    return (
      <>
        {/* <Button
                    size='mini'
                    color='facebook'
                    icon='redo'
                    disabled={value.completedOn !== null && value.isRedone}
                    onClick={() => {
                        this.setState({ selectedAction: value, displayRedo: true })
                    }}
                /> */}
        {/* <Button
                    size='mini'
                    color={value.hasViewed ? 'green' : 'grey'}
                    icon='mail'
                    // inverted={value.hasViewed}
                    disabled={!value.hasViewed}
                    onClick={() => {
                        this.props.MarkUnread(value.id)
                            .then(() => {
                                this.setState({
                                    triggerUpdate: this.state.triggerUpdate + 1
                                })
                            })
                            .catch(() => { })
                        // this.setState({ selectedAction: value, displayRedo: true })
                    }}
                /> */}
        <Popup
          content={`View Details of action performed for ${value.defaulter}`}
          position="top right"
          on="hover"
          trigger={
            <Button
              size="mini"
              color="blue"
              icon="eye"
              disabled={false}
              onClick={() => {
                this.setState({ selectedAction: value, displayAction: true });
                this.getActionOutcome(value.id);
                this.props.LoadPhoneNumbers(value.defaulterId);
                this.getActionHistory(value.defaulterId);
                // console.log(value)
                this.constructForm(value.formId);
              }}
            />
          }
        />
        <Popup
          content={`Fill Action manually.`}
          position="top right"
          on="hover"
          trigger={
            <Button
              size="mini"
              color="grey"
              icon="pencil alternate"
              disabled={value.completedOn !== null}
              onClick={() => {
                this.setState({
                  selectedAction: value,
                  displayFillAction: true,
                });
                this.constructForm(value.formId);
              }}
            />
          }
        />

        <Popup
          content={`Edit Action to be perform on ${value.defaulter}.`}
          position="top right"
          on="hover"
          trigger={
            <Button
              size="mini"
              color="teal"
              icon="edit"
              disabled={value.completedOn !== null}
              onClick={() => {
                //console.log(value)
                this.props.LoadSortingParams(value.defaulterId).then((x) => {
                  // value.requiresApproval = value.approved
                  //console.log(value)
                  let newData = this.copy(value);
                  let date = "";
                  date = newData.date;
                  let parts = date.split("/");
                  date = parts[1] + "/" + parts[0] + "/" + parts[2];
                  date = new Date(date);
                  // date = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
                  // let newDate = new Date(date)
                  // console.log(newDate)
                  console.log(date);
                  newData.date = date;
                  // console.log(value)
                  this.setState({
                    selectedAction: newData,
                    displayEditAction: true,
                    sortingParams: this.props.sortingParams,
                  });
                });
                // this.constructForm(value.formId)
              }}
            />
          }
        />

        {/* <Button size="mini" color="red" icon
                    disabled={activityProcessing}
                    onClick={() => this.setState({ showDeleteConfirmation: true, actionOnDelete: value })}
                >
                    <Icon name="x" />
                </Button> */}
      </>
    );
  };

  copy = (mainObj) => {
    let objCopy = {}; // objCopy will store a copy of the mainObj
    let key;

    for (key in mainObj) {
      objCopy[key] = mainObj[key]; // copies each property to the objCopy object
    }
    return objCopy;
  };

  renderRejectConfirmationBox = () => {
    return this.state.selectedAction !== null ? (
      <ConfirmBox
        loading={this.state.rejectLoading}
        open={this.state.showRejectConfirmation}
        header="Confirm Action deletion"
        content={`You are rejecting an Action with id: ${this.state.selectedAction.id} and name: ${this.state.selectedAction.tag}. Rejection cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() => this.setState({ showRejectConfirmation: false })}
        onConfirm={() =>
          this.setState({ askReason: true, showRejectConfirmation: false })
        }
      />
    ) : null;
  };

  renderDeleteConfirmationBox = () => {
    return (
      <ConfirmBox
        loading={this.state.deleteLoading}
        open={this.state.showDeleteConfirmation}
        header="Confirm Customer deletion"
        content={`You are deleting Action which cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() => this.setState({ showDeleteConfirmation: false })}
        onConfirm={() => this.handleActionDeletion()}
      />
    );
  };

  renderDeleteNumberConfirmationBox = () => {
    return (
      <ConfirmBox
        loading={this.state.deleteNumberLoading}
        open={this.state.showDeleteNumberConfirmation}
        header="Confirm Customer deletion"
        content={`You are deleting Action which cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() => this.setState({ showDeleteNumberConfirmation: false })}
        onConfirm={() => this.handleNumberDeletion()}
      />
    );
  };

  handleActionDeletion = () => {
    this.setState({ deleteLoading: true });
    let action = {};
    if (this.state.selectedIds.length > 1) {
      action.ids = JSON.stringify(this.state.selectedIds);
    } else {
      action.id = this.state.selectedIds[0];
    }
    this.props
      .DeleteAction(action)
      .then((x) => {
        // alert('Done')
        this.setState({
          actionOnDelete: null,
          deleteLoading: false,
          showDeleteConfirmation: false,
          selectedIds: [],
          triggerUpdate: this.state.triggerUpdate + 1,
        });
      })
      .catch((x) => {
        // alert('not done')
        this.setState({ deleteLoading: false, showDeleteConfirmation: false });
      });
  };

  handleNumberDeletion = () => {
    this.setState({ deleteNumberLoading: true });
    this.props
      .deletePhoneNumber(this.state.deleteNumberId)
      .then(() => {
        this.props
          .LoadPhoneNumbers(this.state.selectedAction.defaulterId)
          .then(() => {
            this.setState({
              deleteNumberLoading: false,
              showDeleteNumberConfirmation: false,
            });
          });
      })
      .catch(() => {
        this.setState({
          deleteNumberLoading: false,
          showDeleteNumberConfirmation: false,
        });
      });
  };

  renderTable = () => {
    const data = this.displayAction ? [] : this.props.data;

    for (let l in data) {
      let list = Object.assign({}, data[l]);
      for (var x in list) {
        if (x === "source") {
          if (list[x] === false) {
            list[x] = "Inbound";
            data[l] = list;
          } else if (list[x] === true) {
            list[x] = "Outbound";
            data[l] = list;
          }
        }
      }
    }
    let header = [
      {
        name: "Id",
        accessor: "id",
      },
      {
        name: "Company Tracking ID",
        accessor: "companyTrackingId",
      },
      {
        name: "Tag",
        accessor: "tag",
      },
      {
        name: "Result Tag",
        accessor: "resultTag",
      },
      {
        name: "Type",
        accessor: "type",
      },
      {
        name: "Source",
        accessor: "source",
      },
      {
        name: "Date",
        accessor: "date",
      },
      {
        name: "Completed On",
        accessor: "completedOn",
      },
      {
        name: "Approved",
        accessor: "approved",
        component: (x, parentKey) => {
          return (
            <>
              <Icon
                name={
                  x === "a" ? "checkmark" : x === "p" ? "question" : "close"
                }
                color={x === "a" ? "green" : x === "p" ? "yellow" : "red"}
                size="big"
              />
              {x}
            </>
          );
        },
      },
      {
        name: "User Name",
        accessor: "user",
      },
      {
        name: "Defaulter Name",
        accessor: "defaulter",
      },
      {
        name: "Cycle Day",
        accessor: "addressType",
      },
      {
        name: "Form",
        accessor: "form",
      },
      {
        name: "Priority",
        accessor: "priority",
      },
    ];
    if (this.state.selectedTab === "") {
      header.push({
        name: "Meduim",
        accessor: "medium",
      });
    } else if (this.state.selectedTab === "Call") {
      header.push({
        name: "Random",
        accessor: "isRandom",
        /*component: (x, parentKey) => {
                    console.log(x);
                    return (
                        <>
                            <Icon
                                name={x === 'Yes' ? 'random' : 'user'}
                                color={'blue'}
                            />
                            {x}
                        </>
                    )
                }*/
      });
    }

    return (
      <DataTableCheckBox
        header={header}
        checkbox={(x) => this.renderCheckbox(x)}
        selectAll={() => this.selectAllCB()}
        allselected={this.state.checked}
        actions={(x) => this.actionButton(x)}
        loading={this.state.loadTable}
        show={
          this.state.access && this.state.loaded && !this.state.displayAction
        }
        rowCount={this.props.total}
        data={data}
        tableSize={"small"}
        compact
        pageSizes={[10, 20, 50, 100, 250]}
        trigger={this.state.triggerUpdate}
        onPageChange={(x, y) => this.tablePaging(x, y)}
        sorted
        sortby={this.state.sortby}
        isdescending={this.state.isdescending}
        handleSortby={this.handleSortbyChange}
        recent
        // actionPage
        currentPage={this.state.currentPage}
        localstorage={"actionPage"}
        overflowX
      />
    );
  };

  renderCheckbox = (value) => {
    var check = this.state.selectedIds.includes(value.id);
    return (
      <Checkbox checked={check} onClick={() => this.handleCheckbox(value)} />
    );
  };

  renderPaymentCheckbox = (value) => {
    var check = this.state.selectedPaymentImagesPaths.includes(value.path);
    return (
      <Checkbox
        checked={check}
        onClick={() => this.handlePaymentImagesCheckBox(value)}
      />
    );
  };

  renderSupportingCheckbox = (value) => {
    var check = this.state.selectedSupportingImagesPaths.includes(value.path);
    return (
      <Checkbox
        checked={check}
        onClick={() => this.handleSupportingImagesCheckBox(value)}
      />
    );
  };

  selectAllCB = () => {
    let data;
    let arr = this.state.selectedIds;
    data = this.props.data.map((x) => x.id);
    if (!this.state.selectAll) {
      arr = arr.concat(data);
      let set = new Set(arr);
      arr = Array.from(set);
      this.setState({
        selectedIds: arr,
        selectAll: true,
        checked: true,
      });
    } else if (this.state.selectAll) {
      // data = this.props.data.map(x => x.id);

      arr = arr.filter((item) => !data.includes(item));
      this.setState({
        selectedIds: arr,
        selectAll: false,
        checked: false,
      });
    }
  };

  selectAllPaymentCB = () => {
    let global = this.state.selectedOutcome.payment.paymentImages;
    let arr = this.state.selectedPaymentImagesPaths;
    let data = global.map((x) => x.path);
    if (!this.state.selectAllPayment) {
      arr = arr.concat(data);
      let set = new Set(arr);
      arr = Array.from(set);
      console.log(arr);
      this.setState({
        selectedPaymentImagesPaths: arr,
        selectAllPayment: true,
        checkedPayment: true,
      });
    } else if (this.state.selectAllPayment) {
      arr = arr.filter((item) => !data.includes(item));
      console.log(arr);
      this.setState({
        selectedPaymentImagesPaths: arr,
        selectAllPayment: false,
        checkedPayment: false,
      });
    }
    // console.log(global)
  };

  selectAllSupportingCB = () => {
    let global = this.state.selectedOutcome.supportingImages;
    let arr = this.state.selectedSupportingImagesPaths;
    let data = global.map((x) => x.path);
    if (!this.state.selectAllSupporting) {
      arr = arr.concat(data);
      let set = new Set(arr);
      arr = Array.from(set);
      console.log(arr);
      this.setState({
        selectedSupportingImagesPaths: arr,
        selectAllSupporting: true,
        checkedSupporting: true,
      });
    } else if (this.state.selectAllSupporting) {
      arr = arr.filter((item) => !data.includes(item));
      console.log(arr);
      this.setState({
        selectedSupportingImagesPaths: arr,
        selectAllSupporting: false,
        checkedSupporting: false,
      });
    }
    // console.log(global)
  };

  checkIsNotCompleted = () => {
    let array = this.state.selectedIds;
    let check = false;
    for (let x of array) {
      for (let y of this.props.data) {
        if (y.id === x) {
          if (y.completedOn === null) {
            check = true;
            this.setState({
              isNotCompleted: true,
            });
            break;
          }
          if (y.isRedone) {
            check = true;
            this.setState({
              isNotCompleted: true,
            });
            break;
          }
        }
      }
      if (check) {
        break;
      }
      if (!check) {
        this.setState({
          isNotCompleted: false,
        });
      }
    }
  };
  checkHasNotViewed = () => {
    let array = this.state.selectedIds;
    let check = false;
    for (let x of array) {
      for (let y of this.props.data) {
        if (y.id === x) {
          if (!y.hasViewed) {
            check = true;
            this.setState({
              hasNotViewed: true,
            });
            break;
            // } else if (y.isRedone) {
            //     check = true
            //     this.setState({
            //         isNotCompleted: true
            //     })
            //     break
          }
        }
      }
      if (check) {
        break;
      }
      if (!check) {
        this.setState({
          hasNotViewed: false,
        });
      }
    }
  };

  handlePaymentImagesCheckBox = (value) => {
    let array = this.state.selectedPaymentImagesPaths;
    let index = array.indexOf(value.path);
    if (index !== -1) {
      //for removing any element form the array at posiditon 'index'
      array.splice(index, 1);
      this.setState({
        selectedPaymentImagesPaths: array,
      });
    } else {
      // adding to the list if not already in it
      array.push(value.path);
      this.setState({
        selectedPaymentImagesPaths: array,
      });
    }
    console.log(array);
    this.handleAllPaymentImagesChecked();
  };

  handleAllPaymentImagesChecked = () => {
    let data = this.state.selectedOutcome.payment.paymentImages.map(
      (x) => x.path
    );
    let array = this.state.selectedPaymentImagesPaths;
    let check = data.every((element) => array.includes(element));
    this.setState({
      checkedPayment: check,
      selectAllPayment: check,
    });
  };

  handleSupportingImagesCheckBox = (value) => {
    let array = this.state.selectedSupportingImagesPaths;
    let index = array.indexOf(value.path);
    if (index !== -1) {
      //for removing any element form the array at posiditon 'index'
      array.splice(index, 1);
      this.setState({
        selectedSupportingImagesPaths: array,
      });
    } else {
      // adding to the list if not already in it
      array.push(value.path);
      this.setState({
        selectedSupportingImagesPaths: array,
      });
    }
    this.handleAllSupportingImagesChecked();
  };

  handleAllSupportingImagesChecked = () => {
    let data = this.state.selectedOutcome.supportingImages.map((x) => x.path);
    let array = this.state.selectedSupportingImagesPaths;
    let check = data.every((element) => array.includes(element));
    this.setState({
      checkedSupporting: check,
      selectAllSupporting: check,
    });
  };

  handleCheckbox = (value) => {
    let arr = this.state.selectedIds;
    // let ch = this.state.selectedIds.includes(value.id);
    let ind = arr.indexOf(value.id);
    if (ind !== -1) {
      arr.splice(ind, 1);
      this.setState({
        selectedIds: arr,
      });
      //this.checkIsNotCompleted()
      this.checkHasNotViewed();
    } else {
      arr.push(value.id);
      this.setState({
        selectedIds: arr,
      });
      //this.checkIsNotCompleted()
      this.checkHasNotViewed();
    }
    this.handlechecked();
  };

  handlechecked = () => {
    let data = this.props.data.map((x) => x.id);
    let global = this.state.selectedIds;
    console.log(global);
    let check = data.every((element) => global.includes(element));
    this.setState({
      checked: check,
      selectAll: check,
    });
  };

  handleSortbyChange = (value) => {
    this.setState({
      sortby: value,
      isdescending: !this.state.isdescending,
      triggerUpdate: this.state.triggerUpdate + 1,
    });
  };

  handleAddComment = () => {
    this.setState({
      addCommentLoading: true,
    });
    let obj = {};
    obj.id = this.state.selectedOutcome.id;
    obj.comment = this.state.newComment;
    this.props
      .AddComment(obj)
      .then((x) => {
        this.setState({ addCommentLoading: false, newComment: "" });
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch((x) => {
        this.setState({ addCommentLoading: false });
      });
  };

  handleEditComment = () => {
    this.setState({
      addCommentLoading: true,
    });
    let obj = {};
    obj.id = this.state.commentOnEdit.id;
    obj.comment = this.state.commentOnEditText;
    this.props
      .EditComment(obj)
      .then((x) => {
        this.setState({
          addCommentLoading: false,
          commentOnEdit: null,
          commentOnEditText: "",
        });
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch((x) => {
        this.setState({ addCommentLoading: false });
      });
  };

  deleteComment = (id) => {
    this.props
      .DeleteComment(id)
      .then(() => {
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch(() => {});
  };

  renderComments = () => {
    return (
      <>
        <Comment.Group size="large">
          <Header as="h2" dividing>
            {"Comments"}
          </Header>
          {this.state.commentsList.map((obj) => {
            return (
              <Comment>
                <Comment.Avatar src="https://react.semantic-ui.com/images/avatar/small/matt.jpg" />
                <Comment.Content>
                  <Comment.Author>{obj.username}</Comment.Author>
                  {/* <Comment.Metadata>
                                        <div>Today at 5:42PM</div>
                                </Comment.Metadata> */}
                  <Comment.Text>{obj.comment}</Comment.Text>
                  <Comment.Actions>
                    <Comment.Action
                      onClick={() => {
                        this.setState({
                          commentOnEdit: obj,
                          commentOnEditText: obj.comment,
                        });
                      }}
                    >
                      <Button
                        icon="edit"
                        size="mini"
                        color="blue"
                        inverted
                        content="Edit"
                      />
                    </Comment.Action>
                    <Comment.Action onClick={() => this.deleteComment(obj.id)}>
                      <Button
                        icon="close"
                        size="mini"
                        color="red"
                        inverted
                        content="Delete"
                      />
                    </Comment.Action>
                  </Comment.Actions>
                </Comment.Content>
              </Comment>
            );
          })}
          <Form reply>
            <Form.TextArea
              placeholder="Enter Your Comment"
              value={
                this.state.commentOnEdit !== null
                  ? this.state.commentOnEditText
                  : this.state.newComment
              }
              onChange={(e) => {
                this.state.commentOnEdit !== null
                  ? this.setState({ commentOnEditText: e.target.value })
                  : this.setState({ newComment: e.target.value });
              }}
            />
            <Button
              loading={this.state.addCommentLoading}
              disabled={
                this.state.commentOnEdit === null
                  ? this.state.newComment.length === 0
                  : this.state.commentOnEdit.length === 0
              }
              content={
                this.state.commentOnEdit === null
                  ? "Add Comment"
                  : "Edit Comment"
              }
              labelPosition="left"
              floated="left"
              primary
              icon={this.state.commentOnEdit === null ? "add" : "edit"}
              onClick={
                this.state.commentOnEdit === null
                  ? this.handleAddComment
                  : this.handleEditComment
              }
            />
            <Button
              icon="close"
              color="red"
              disabled={
                this.state.commentOnEdit === null
                  ? this.state.newComment.length === 0
                  : this.state.commentOnEditText.length === 0
              }
              onClick={() => {
                this.setState({
                  commentOnEdit: null,
                  newComment: "",
                  commentOnEditText: "",
                });
              }}
              content={"Cancel"}
            />
          </Form>
        </Comment.Group>
      </>
    );

    // return (this.state.commentsLoading ? <LoaderView inverted text="Loading Comments!" />
    //     :
    //     <>
    //         <Comment.Group>
    //             <Header as='h3' dividing>
    //                 {"Comments"}
    //             </Header>

    //             <Comment>
    //                 <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
    //                 <Comment.Content>
    //                     <Comment.Author as='a'>Matt</Comment.Author>
    //                     <Comment.Metadata>
    //                         <div>Today at 5:42PM</div>
    //                     </Comment.Metadata>
    //                     <Comment.Text>How artistic!</Comment.Text>
    //                     <Comment.Actions>
    //                         <Comment.Action>Reply</Comment.Action>
    //                     </Comment.Actions>
    //                 </Comment.Content>
    //             </Comment>
    //         </Comment.Group>

    //     </>
    // )
  };

  renderDetails = () => {
    return this.state.displayAction ? (
      <>
        {this.renderActionDisplay()}
        {this.renderRejectConfirmationBox()}
        {this.renderDeleteNumberConfirmationBox()}
        {this.addPhoneModal()}
        {this.onActionRejection()}
        <div style={{ height: 10 }} />
        {this.renderActionOutcomeDisplay()}
        {this.renderModalForFormEdit()}
        {this.renderEditTagForm()}
        {this.renderModalForPaymentImages()}
        {this.renderModalForSupportingImages()}
        {this.renderDeleteSupporingImageConfirmationBox()}
        {this.renderDeletePaymentImageConfirmationBox()}
      </>
    ) : null;
  };

  renderActionOutcomeDisplay = () => {
    if (
      this.state.selectedOutcome === null &&
      this.state.displayAction === false
    ) {
      return null;
    }
    if (this.state.selectedOutcome === null) {
      //   this.getActionOutcomeNow(this.state.selectedAction.id)
    }
    console.log(this.state.selectedOutcome);
    if (this.state.outcomeLoading)
      return <LoaderView text="Action Outcome Loading!" />;

    return this.state.selectedOutcome === null ||
      this.state.selectedOutcome === "" ||
      this.state.selectedOutcome === undefined ? (
      <div style={styles.footer}>
        <Segment style={{ textAlign: "center", fontSize: "1.5rem" }}>
          <Icon name="exclamation triangle" />
          {"No Action Outcome!"}
        </Segment>
      </div>
    ) : (
      <div style={styles.mainDiv}>
        <Segment attached="top">
          <Header as="h2" style={{ textAlign: "center" }}>
            <span>Action Outcome</span>
          </Header>
        </Segment>

        <Segment attached="bottom">
          <Grid>
            {this.renderOutcomeTable(this.state.selectedOutcome)}
            {this.renderPromiseTable(this.state.selectedOutcome)}
            {this.renderPaymentTable(this.state.selectedOutcome)}
            {this.renderReminderTable(this.state.selectedOutcome)}
            {this.renderSupportingImages(this.state.selectedOutcome)}
            {this.renderFormValuesTable(this.state.selectedOutcome)}
            {this.renderPhoneNumbers(this.props.phoneNumbers, this.state.selectedOutcome)}
            {this.renderActionHistoryTable()}
            {this.renderOutcomeBox()}
            <Grid.Column width={16}>
              <Grid centered>
                <Grid.Column width={8}>{this.renderComments()}</Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
        <div style={styles.footer} />
      </div>
    );
  };

  renderFlagButton = (value) => {
    let color, iconName, text, flag;

    if (!value.isInvalid) {
      color = "green";
      iconName = "circle";
      text = "Valid";
      flag = true;
    } else {
      color = "grey";
      iconName = "circle outline";
      text = "In valid";
      flag = false;
    }
    return (
      <Popup
        content={`${value.number} is ${text} Number. Click to change it.`}
        position="top right"
        on="hover"
        trigger={
          <Button
            loading={
              this.state.changeNumberLoading &&
              this.state.numberForStatusChange === value.id
            }
            // disabled={activityProcessing}
            size="mini"
            color={color}
            icon={iconName}
            onClick={() => {
              this.setState({
                changeNumberLoading: true,
                numberForStatusChange: value.id,
              });
              this.changeValid(value.id, flag);
            }}
          />
        }
      />
    );
  };

  changeValid = (id, status) => {
    console.log(this.state.selectedOutcome);
    this.props
      .changeValidPhone(id, status)
      .then(() => {
        this.props
          .LoadPhoneNumbers(this.state.selectedAction.defaulterId)
          .then(() => {
            this.setState({ changeNumberLoading: false });
          })
          .catch(() => {
            this.setState({ changeNumberLoading: false });
          });
      })
      .catch(() => {
        this.setState({ changeNumberLoading: false });
      });
  };
  addPhoneModal = () => {
    return (
      <PhoneForm
        size="mini"
        label
        // type
        // isPrimary
        open={this.state.openAddPhoneModal}
        onSubmit={this.submitAddPhone}
        loading={this.state.addPhoneLoading}
        onCloseHandle={() => {
          this.setState({
            openAddPhoneModal: false,
            addPhoneMessage: null,
          });
        }}
        header={{ icon: "add", text: "Add Phone" }}
        actionsComponent={this.addPhoneActions()}
        message={this.state.addPhoneMessage}
      />
    );
  };

  addPhoneActions = () => {
    return (
      <>
        <Button
          floated="left"
          onClick={() => {
            this.setState({ openAddPhoneModal: false, addPhoneMessage: null });
          }}
        >
          Close
        </Button>
        <Button
          loading={this.state.addPhoneLoading}
          color="green"
          type="submit"
          form="phoneForm"
        >
          Submit
        </Button>
      </>
    );
  };

  submitAddPhone = (value) => {
    console.log(value);
    this.setState({ addPhoneLoading: true });
    let { defaulterId } = this.state.selectedAction;
    let { number, type, isPrimary } = value;
    this.props
      .addPhoneNumber(defaulterId, number, type, isPrimary)
      .then(() => {
        this.setState({
          addPhoneLoading: false,
          addPhoneMessage: {
            type: "success",
            header: "Success",
            content: "Phone Number Added Successfully",
          },
        });
        this.props
          .LoadPhoneNumbers(this.state.selectedAction.defaulterId)
          .then(() => {
            // this.setState({ deleteNumberLoading: false });
          });
      })
      .catch(() => {
        this.setState({
          addPhoneLoading: false,
          addPhoneMessage: {
            type: "error",
            header: "Failed",
            content: "Phone Number could not be added.",
          },
        });
      });
  };
  renderPhoneNumbers = (phoneNumbers, outcome) => {
    //console.log("nmbers", phoneNumbers);
    console.log(outcome);
    // if (phoneNumbers === null || phoneNumbers.length === 0) return;
    return (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">
            Phone Numbers
            <Button
              icon="add"
              content="Add"
              onClick={() => {
                this.setState({
                  openAddPhoneModal: true,
                });
              }}
              size="tiny"
              floated="right"
            />
          </Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Type",
              accessor: "type",
            },
            {
              name: "Number",
              accessor: "number",
              component: (x, parentKey) => {
                return (
                  <>
                    {x.number}
                    {x.number ===
                    this.state.selectedOutcome.calledPhoneNumber ? (
                      <Icon name={"phone"} color={"green"} size="big" />
                    ) : null}
                  </>
                );
              },
            },
            // {
            //   name: 'Status',
            //   accesssor
            // }
            // {
            //     name: 'Type',
            //     accessor: 'type'
            // },
            // {
            //   name: "Value",
            //   accessor: "value",
            // },
          ]}
          actions={(x) => this.phoneActions(x)}
          loading={false}
          data={phoneNumbers}
          show={true}
          noPagination
          // trigger={() => { }}
        />
      </Grid.Column>
    );
  };

  phoneActions = (value) => {
    return (
      <>
        {this.renderFlagButton(value)}
        <Popup
          content={`Delete Data of ${value.name}.`}
          position="top right"
          // on='hover'
          // inverted
          trigger={
            <Button
              size="mini"
              color="red"
              icon="remove"
              //   disabled={activityProcessing}
              loading={this.state.deleteNumberLoading}
              onClick={() => {
                this.setState({
                  showDeleteNumberConfirmation: true,
                  deleteNumberId: value.id,
                });
              }}
            />
          }
        />
      </>
    );
  };
  renderLocationButton = (outcome) => {
    var array = outcome.formValues.filter(
      (element) =>
        element.caption === "Longitude" || element.caption === "Latitude"
    );

    if (array.length === 0) {
      console.log("It is null");
      return null;
    }

    array = array.map((x) => {
      return x.caption === "Longitude"
        ? { Longitude: x.value }
        : x.caption === "Latitude"
        ? { Latitude: x.value }
        : null;
    });
    array = [{ ...array[0], ...array[1] }];
    return (
      <>
        <Grid.Column width={16}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="medium">
              Address Details{" "}
              {/*<Button floated="right" size="tiny" >Add</Button> */}
            </Header>
          </Grid.Column>
          <DataTable
            header={[
              {
                name: "Latitude",
                accessor: "Latitude",
              },
              {
                name: "Longitute",
                accessor: "Longitude",
              },
            ]}
            actions={(x) => this.LoadAddressActions(x)}
            loading={this.state.loadTable}
            show={this.state.access && this.state.loaded}
            data={array}
            noPagination
            overflowX
          />
        </Grid.Column>
      </>
    );
  };

  LoadAddressActions = (address) => {
    return (
      <>
        <Button
          data-tooltip="Open Location in Google Maps"
          data-position="top right"
          circular
          color="blue"
          size="tiny"
          disabled={false}
          icon="paper plane"
          onClick={(x) => this.renderLocation(address)}
        />
      </>
    );
  };

  renderLocation = (Address) => {
    let { longitude, latitude, address } = Address;
    let link;
    if (longitude === null || latitude === null) {
      link = `http://maps.google.com?q=${address}`;
    } else {
      link = `http://maps.google.com?q=${latitude},${longitude}`;
    }
    window.open(link);
  };

  renderReminderTable = (ref) => {
    return ref.reminder === null ? null : (
      <Grid.Column width={8}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Reminders</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Date",
              accessor: "date",
            },
          ]}
          loading={this.state.loadTable}
          data={new Array(ref.reminder)}
          show={this.state.access && this.state.loaded}
          noPagination
          trigger={this.state.triggerUpdate}
        />
      </Grid.Column>
    );
  };

  renderFormValueActionButtons = (value) => {
    return (
      <>
        <Button
          data-tooltip="Edit Value"
          data-position="top right"
          color="teal"
          size="tiny"
          disabled={false}
          icon="edit"
          onClick={() => {
            value.val = value.value;
            console.log(value);
            this.setState({
              formValueToEdit: value,
            });
          }}
        />
      </>
    );
  };

  renderModalForFormEdit = () => {
    return (
      <FormValueEditForm
        open={this.state.formValueToEdit !== null ? true : false}
        size="tiny"
        onCloseHandle={() => {
          this.setState({
            formValueToEdit: null,
          });
        }}
        actionsComponent={this.renderActionButtonsFormValueEdit()}
        onSubmit={this.handleSubmitFormValueEdit}
        loading={this.state.loading}
        initialValues={this.state.formValueToEdit}
        data={
          this.state.formValueToEdit !== null ? this.state.formValueToEdit : {}
        }
      />
    );
  };

  handleSubmitFormValueEdit = (x) => {
    console.log("enter");
    console.log(x);
    let array = [];
    let obj = {};
    obj.id = this.state.formValueToEdit.id;
    // obj.value = x[this.state.formValueToEdit.caption]
    obj.value = x.val;
    array.push(obj);
    this.setState({ editLoading: true });
    this.props
      .UpdateFormValue(array)
      .then(() => {
        this.setState({ formValueToEdit: null, editLoading: false });
        alert("Successfully Edited!");
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch(() => {
        this.setState({ formValueToEdit: null, editLoading: false });
        alert("Failed to Edit!");
      });
  };

  renderActionButtonsFormValueEdit = () => {
    return (
      <>
        <Button
          name="close"
          icon="x"
          color="red"
          disabled={false}
          loading={this.state.editLoading}
          onClick={() => {
            this.setState({ formValueToEdit: null });
          }}
          content="Close"
        />
        <Button
          //disabled={this.state.loading}
          loading={this.state.editLoading}
          form="formValueEdit"
          color="green"
          type="submit"
          icon="checkmark"
          content="Confirm"
        />
      </>
    );
  };

  renderFormValuesTable = (ref) => {
    return ref.formValues !== undefined &&
      ref.formValues.length === 0 ? null : (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Form Values</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Caption",
              accessor: "caption",
            },
            {
              name: "Value",
              accessor: "value",
            },
          ]}
          actions={(x) => this.renderFormValueActionButtons(x)}
          loading={this.state.loadTable}
          data={ref.formValues}
          show={this.state.access && this.state.loaded}
          noPagination
          // trigger={this.state.triggerUpdate}
        />
      </Grid.Column>
    );
  };

  renderModalForPaymentImages = () => {
    return (
      <Modal open={this.state.uploadPaymentImage} size="tiny">
        <Header icon="upload" content="Upload Images for Payment" />
        <Modal.Content>
          <div className="ui container">
            <div className="ui row">
              <div className="offset-md-3 col-md-6">
                <div className="form-group files">
                  <Input
                    type="file"
                    multiple
                    onChange={this.onChangePaymentImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>{this.paymentImageUploadActions()}</Modal.Actions>
      </Modal>
    );
  };

  onChangePaymentImage = (e) => {
    this.setState({
      selectedPaymentImages: e.target.files,
    });
  };

  paymentImageUploadActions = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          floated="left"
          inverted
          size="tiny"
          color="red"
          onClick={() => {
            this.setState({ uploadPaymentImage: false });
          }}
          icon="remove"
          content="Close"
        />
        <Button
          disabled={this.state.loading}
          loading={this.state.loading}
          onClick={this.onPaymentImageSubmit}
          size="tiny"
          icon="upload"
          content="Upload"
        />
      </>
    );
  };

  onPaymentImageSubmit = () => {
    console.log(this.state.selectedPaymentImages);
    let obj = {};
    obj.id = this.state.selectedOutcome.payment.id;
    obj.images = this.state.selectedPaymentImages;
    console.log(typeof obj.images);

    this.props
      .AddPaymentImage(obj)
      .then(() => {
        alert("Image(s) Uploaded!");
        this.setState({ submitLoading: false, uploadPaymentImage: false });
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch(() => {
        alert("Failed!");
        this.setState({ submitLoading: false, uploadPaymentImage: false });
      });
  };

  // supportingActions = (value) => {
  //     // var array = this.state.supportingImageLoading;
  //     // array.push(false);
  //     // console.log(value);
  //     // this.setState({ supportingImageLoading: array })
  //     console.log("this is the bool")
  //     return (
  //         <>
  //             <Button
  //                 key={value.id}
  //                 size='mini'
  //                 color='green'
  //                 icon='image'
  //                 loading={this.state.supportingImageLoading}
  //                 //  disabled={value.completedOn === null}
  //                 onClick={() => {
  //                     this.setState({ paymentImageLoading: false })
  //                     this.props.LoadPaymentImage(value.path)
  //                         .then(() => {
  //                             // var array1 = this.state.supportingImageLoading;
  //                             // array1[value.id - 1] = false
  //                             // // console.log(value);
  //                             this.setState({ paymentImageLoading: false })
  //                         })
  //                         .catch(() => console.error('Error'))
  //                 }}
  //             />
  //             <Button
  //                 size='mini'
  //                 color='red'
  //                 icon='x'
  //                 onClick={() => {
  //                     console.log(value)
  //                     this.setState({ deleteSupportingImageConfirmationShow: true, imageOnDelete: value })
  //                 }}
  //             />

  //         </>
  //     )
  // }

  paymentActions = (element) => {
    return (
      <>
        <Button
          size="mini"
          disabled={false}
          icon="image"
          color="green"
          loading={this.state.paymentImageLoading}
          onClick={() => {
            this.setState({ paymentImageLoading: false });
            this.props
              .LoadImage(element.path)
              .then((x) => {
                this.setState({ paymentImageLoading: false });
              })
              .catch((x) => {
                this.setState({ paymentImageLoading: false });
              });
          }}
        />
        <Button
          size="mini"
          color="red"
          icon="x"
          onClick={() => {
            this.setState({
              deletePaymentImageConfirmationShow: true,
              imageOnDelete: element,
            });
          }}
        />
      </>
    );
  };

  renderDeletePaymentImageConfirmationBox = () => {
    return (
      <ConfirmBox
        loading={this.state.deleteLoading}
        open={this.state.deletePaymentImageConfirmationShow}
        header="Confirm Payment Image deletion"
        content={`You are deleting an image. Deletion cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() =>
          this.setState({
            deletePaymentImageConfirmationShow: false,
            imageOnDelete: null,
          })
        }
        onConfirm={() => {
          this.setState({ deleteLoading: true });
          console.log(this.state.imageOnDelete);
          this.props
            .DeletePaymentImage(this.state.imageOnDelete.imageId)
            .then(() => {
              console.log("DONELSKJDFLKJDS");
              this.setState({
                deleteLoading: false,
                deletePaymentImageConfirmationShow: false,
                imageOnDelete: null,
              });
              this.getActionOutcome(this.state.selectedAction.id);
            })
            .catch(() => {
              this.setState({
                deleteLoading: false,
                deletePaymentImageConfirmationShow: false,
                imageOnDelete: null,
              });
            });
        }}
      />
    );
  };

  renderPaymentImages = (ref) => {
    let row = 0;
    let arr = ref.paymentImages.map((x) => {
      row++;
      return { id: row, imageId: x.id, path: x.path };
    });
    return (
      <>
        <Grid.Column width={8}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="mini">
              {"Payment Images"}
              <span position="right">
                <Popup
                  content="Add more Images"
                  trigger={
                    <Button
                      size="mini"
                      icon="add"
                      color="blue"
                      floated="right"
                      disabled={false}
                      onClick={() =>
                        this.setState({ uploadPaymentImage: true })
                      }
                    />
                  }
                />
                <Popup
                  content="Download images in zip file."
                  trigger={
                    <Button
                      size="mini"
                      icon="download"
                      floated="right"
                      loading={this.state.loadingPaymentImagesZip}
                      disabled={
                        this.state.selectedPaymentImagesPaths.length === 0
                      }
                      onClick={() => {
                        this.setState({ loadingPaymentImagesZip: true });
                        this.props
                          .downloadPaymentImagesZip(
                            this.state.selectedPaymentImagesPaths
                          )
                          .then(() => {
                            this.setState({
                              selectedPaymentImagesPaths: [],
                              checkedPayment: false,
                              selectAllPayment: false,
                              loadingPaymentImagesZip: false,
                            });
                          })
                          .catch(() => {});
                      }}
                    />
                  }
                />
              </span>
            </Header>
          </Grid.Column>
          <SelectableTable
            header={[
              {
                name: "Sr. No.",
                accessor: "id",
              },
            ]}
            checkbox={(x) => this.renderPaymentCheckbox(x)}
            selectAll={() => this.selectAllPaymentCB()}
            allselected={this.state.checkedPayment}
            actions={(x) => this.paymentActions(x)}
            loading={this.state.loadTable}
            data={arr}
            show={this.state.access && this.state.loaded}
            noPagination
            compact
            trigger={this.state.triggerUpdate}
          />
        </Grid.Column>
      </>
    );
  };

  renderPaymentTable = (ref) => {
    return ref.result.tag === "Payment" ? (
      // ref.payment === null ?
      <>
        <Grid.Column width={8}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="medium">
              Payments
              {/* <Button floated="right" size="tiny" icon='add' content='Add' onClick={() => { this.setState({ uploadPaymentImage: true }); console.log(this.state.selectedOutcome) }} /> */}
            </Header>
          </Grid.Column>
          <DataTable
            header={[
              {
                name: "Id",
                accessor: "id",
              },
              {
                name: "Amount",
                accessor: "amount",
              },
              {
                name: "Date",
                accessor: "date",
              },
            ]}
            // ActionHeading='View Image'
            // actions={(x) => this.showImage(x.paymentImages)}
            loading={this.state.loadTable}
            data={ref.payment !== null ? new Array(ref.payment) : []}
            show={this.state.access && this.state.loaded}
            noPagination
            trigger={this.state.triggerUpdate}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          {this.renderPaymentImages(ref.payment)}
        </Grid.Column>
      </>
    ) : null;
  };

  showImage = (imageRef) => {
    var arr = [];
    imageRef.forEach((element) => {
      arr.push(
        <Button
          size="tiny"
          disabled={false}
          icon
          compact
          color="green"
          loading={this.state.paymentImageLoading}
          onClick={(x) => {
            this.setState({ paymentImageLoading: false });
            this.props
              .LoadImage(element.path)
              .then((x) => {
                this.setState({ paymentImageLoading: false });
              })
              .catch((x) => {
                this.setState({ paymentImageLoading: false });
              });
          }}
        >
          <Icon name="image" />
        </Button>
      );
      // this.props.LoadImage(element.path)
      //     .then(
      //         image = this.props.image,
      //         arr.push(<a href={"data:image/png;base64," + image + ""} target="_blank" >Image <br /></a>)
      //     )
    });
    return <> {arr} </>;
  };

  renderOutcomeTable = (ref) => {
    if (ref === null) {
      return null;
    } else {
      if (ref.approval !== null) {
        ref.reason = ref.approval.reason;
      }
      if (ref.result !== null) {
        ref.resultTag = ref.result.tag;
      }
    }

    let dat = new Array(ref);
    // console.log(dat);

    return (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Details</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Tag",
              accessor: "resultTag",
            },
            {
              name: "Time Stamp",
              accessor: "timestamp",
            },
            {
              name: "Latitude",
              accessor: "latitude",
            },
            {
              name: "Longitute",
              accessor: "longitude",
            },
            {
              name: "Approved Status",
              accessor: "approved",
            },
            {
              name: "Reason",
              accessor: "reason",
            },
          ]}
          actions={(x) => this.renderEditLocationButton(x)}
          loading={this.state.loadTable}
          data={dat}
          show={this.state.access && this.state.loaded}
          noPagination
          trigger={this.state.triggerUpdate}
          compact
        />
      </Grid.Column>
    );
  };

  renderEditLocationButton = (value) => {
    return (
      <>
        <Button
          icon="edit"
          color="teal"
          size="tiny"
          disabled={false}
          onClick={() => {
            this.tagEditData(value);
            this.setState({ actionOutcome: value, outcomeEditShow: true });
          }}
          data-tooltip="Edit Tag"
          data-position="top right"
        />
        <Button
          data-tooltip="Open Location in Google Maps"
          data-position="top right"
          circular
          color="blue"
          size="tiny"
          disabled={false}
          icon="paper plane"
          onClick={() => this.renderLocation(value)}
        />
        {/* {this.LoadAddressActions(x)} */}
      </>
    );
  };

  renderOutcomeEditActions = () => {
    return (
      <>
        <Button
          floated="left"
          inverted
          color="red"
          onClick={() => {
            this.setState({ outcomeEditShow: false });
          }}
          content="Close"
        />
        <Button
          inverted
          color="green"
          form="outcomeEditTag"
          type="submit"
          content="Upload"
        />
      </>
    );
  };

  handleOutcomeEditSubmit = (value) => {
    const resultTagObject = this.props.tagsList.find((obj) => {
      if (obj.value === value.resultId) {
        return obj.text;
      }
    });
    const resultTag = resultTagObject.text;
    if (value.oldResultId === value.resultId) {
      // when values of these 3 => Promise, Reminder, Payment
      // Updating calls for three of these using switch case based of resultTag, API: Update_______
      let obj = {};
      obj.outcomeid = value.id;

      switch (resultTag) {
        case "Payment": {
          obj.id = value.payment.id;
          if (typeof value.payment.date === "string")
            obj.date = value.payment.date;
          else obj.date = value.payment.date.toLocaleDateString();
          obj.amount = value.payment.amount;
          this.props
            .UpdatePayment(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        case "Reminder": {
          if (typeof value.payment.date === "string")
            obj.date = value.reminder.date;
          else obj.date = value.reminder.date.toLocaleDateString();
          obj.id = value.reminder.id;
          this.props
            .UpdateReminder(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        case "Promise": {
          obj.amount = value.promise.amount;
          if (typeof value.promise.date === "string")
            obj.date = value.promise.date;
          else obj.date = value.promise.date.toLocaleDateString();
          obj.id = value.promise.id;
          this.props
            .UpdatePromise(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        default: {
          this.props
            .UpdateOutcomeTag(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
        }
      }
    } else {
      // when changing the Result Tag
      // Update the resultTag using the API : UpdateOutcomeTag
      let obj = {};
      obj.id = value.id;
      obj.resultId = value.resultId;
      switch (resultTag) {
        case "Payment": {
          let payment = {};
          payment.amount = value.payment.amount;
          payment.date = value.payment.date.toLocaleDateString();
          obj.payment = payment;
          this.props
            .UpdateOutcomeTag(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        case "Reminder": {
          let reminder = {};
          reminder.date = value.reminder.date.toLocaleDateString();
          obj.reminder = reminder;
          this.props
            .UpdateOutcomeTag(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        case "Promise": {
          let promise = {};
          promise.amount = value.promise.amount;
          promise.date = value.promise.date.toLocaleDateString();
          obj.promise = promise;
          this.props
            .UpdateOutcomeTag(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
          break;
        }
        default: {
          this.props
            .UpdateOutcomeTag(obj)
            .then(() => {
              this.getActionOutcome(this.state.selectedAction.id);
              this.setState({
                outcomeEditShow: false,
              });
            })
            .catch(() => {});
        }
      }
    }
  };

  tagEditData = (val) => {
    const value = this.copy(val);
    const oldData = {
      id: value.id,
      actionId: value.actionId,
      result: value.result,
      resultTag: value.resultTag,
      resultId: value.resultId,
      oldResultId: value.resultId,
      payment: value.payment,
      promise: value.promise,
      reminder: value.reminder,
    };
    let data = this.copy(oldData);
    Object.keys(data).forEach((key) => {
      if (
        (key === "payment" || key === "promise" || key === "reminder") &&
        data[key] !== null &&
        typeof data[key].date === "string"
      ) {
        let date = data[key].date;
        let parts = date.split("/");
        date = parts[1] + "/" + parts[0] + "/" + parts[2];
        date = new Date(date);
        data[key].date = date;
      }
    });

    this.setState({
      tagEditData: data,
    });
  };

  renderEditTagForm = () => {
    return (
      <OutcomeEditForm
        open={this.state.outcomeEditShow}
        size="small"
        onCloseHandle={() => {
          this.setState({ outcomeEditShow: false });
        }}
        // onSubmit={this.tagSubmit}
        onSubmit={this.handleOutcomeEditSubmit}
        actionsComponent={this.renderOutcomeEditActions()}
        data={this.state.tagEditData}
        tagOptions={this.props.tagsList}
        initialValues={this.state.tagEditData}
      />
    );
  };

  renderPromiseTable = (ref) => {
    return ref.promise === null ? null : (
      <Grid.Column width={8}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Promises</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Amount",
              accessor: "amount",
            },
            {
              name: "Date",
              accessor: "date",
            },
          ]}
          loading={this.state.loadTable}
          data={new Array(ref.promise)}
          show={this.state.access && this.state.loaded}
          noPagination
          trigger={this.state.triggerUpdate}
        />
      </Grid.Column>
    );
  };

  supportingActions = (value) => {
    return (
      <>
        <Button
          key={value.id}
          size="mini"
          color="green"
          icon="image"
          loading={this.state.supportingImageLoading}
          //  disabled={value.completedOn === null}
          onClick={() => {
            this.setState({ supportingImageLoading: false });
            this.props
              .LoadSupportingImage(value.path)
              .then(() => {
                this.setState({ supportingImageLoading: false });
              })
              .catch(() => console.error("Error"));
          }}
        />
        <Button
          size="mini"
          color="red"
          icon="x"
          onClick={() => {
            console.log(value);
            this.setState({
              deleteSupportingImageConfirmationShow: true,
              imageOnDelete: value,
            });
          }}
        />
      </>
    );
  };

  renderDeleteSupporingImageConfirmationBox = () => {
    return (
      <ConfirmBox
        loading={this.state.deleteLoading}
        open={this.state.deleteSupportingImageConfirmationShow}
        header="Confirm Supporing Image deletion"
        content={`You are deleting an image. Deletion cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() =>
          this.setState({
            deleteSupportingImageConfirmationShow: false,
            imageOnDelete: null,
          })
        }
        onConfirm={() => {
          this.setState({ deleteLoading: true });
          console.log(this.state.imageOnDelete);
          this.props
            .DeleteSupportingImage(this.state.imageOnDelete.imageId)
            .then(() => {
              console.log("DONELSKJDFLKJDS");
              this.setState({
                deleteLoading: false,
                deleteSupportingImageConfirmationShow: false,
                imageOnDelete: null,
              });
              this.getActionOutcome(this.state.selectedAction.id);
            })
            .catch(() => {
              this.setState({
                deleteLoading: false,
                deleteSupportingImageConfirmationShow: false,
                imageOnDelete: null,
              });
            });
        }}
      />
    );
  };

  renderModalForSupportingImages = () => {
    return (
      <Modal open={this.state.uploadSupportingImage} size="tiny">
        <Header icon="upload" content="Upload Supporting Images" />
        <Modal.Content>
          <div className="ui container">
            <div className="ui row">
              <div className="offset-md-3 col-md-6">
                <div className="form-group files">
                  <Input
                    multiple
                    type="file"
                    onChange={this.onChangeSupportingImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>{this.supportingImageUploadActions()}</Modal.Actions>
      </Modal>
    );
  };

  onChangeSupportingImage = (e) => {
    this.setState({
      selectedSupportingImages: e.target.files,
    });
  };

  supportingImageUploadActions = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          floated="left"
          size="tiny"
          inverted
          color="red"
          onClick={() => {
            this.setState({
              uploadSupportingImage: false,
              selectedSupportingImages: null,
            });
          }}
          icon="remove"
          content="Close"
        />
        <Button
          disabled={this.state.loading}
          loading={this.state.loading}
          onClick={this.onSupportingImageSubmit}
          size="tiny"
          icon="upload"
          content="Upload"
        />
      </>
    );
  };

  onSupportingImageSubmit = () => {
    let obj = {};
    obj.id = this.state.selectedOutcome.id;
    obj.images = this.state.selectedSupportingImages;
    this.setState({ submitLoading: true });
    this.props
      .AddSupportingImages(obj)
      .then(() => {
        alert("Image(s) Uploaded!");
        this.setState({
          submitLoading: false,
          uploadSupportingImage: false,
          selectedSupportingImages: null,
        });
        this.getActionOutcome(this.state.selectedAction.id);
      })
      .catch(() => {
        alert("Failed!");
        this.setState({
          submitLoading: false,
          uploadSupportingImage: false,
          selectedSupportingImages: null,
        });
      });
  };

  renderSupportingImages = (ref) => {
    //  console.log(ref)
    var row = 0;
    var arr = ref.supportingImages.map((x) => {
      row++;
      return { id: row, imageId: x.id, path: x.path };
    });
    // console.log(arr)
    return (
      <Grid.Column width={4}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">
            {"Supporting Images"}
            <span position="right">
              <Popup
                content="Add more Images."
                position="top right"
                trigger={
                  <Button
                    floated="right"
                    size="mini"
                    icon="add"
                    color="blue"
                    onClick={() =>
                      this.setState({ uploadSupportingImage: true })
                    }
                  />
                }
              />
              <Popup
                content="Download images in zip file."
                position="top right"
                trigger={
                  <Button
                    size="mini"
                    icon="download"
                    floated="right"
                    loading={this.state.loadingSupportingImagesZip}
                    disabled={
                      this.state.selectedSupportingImagesPaths.length === 0
                    }
                    onClick={() => {
                      this.setState({ loadingSupportingImagesZip: true });
                      this.props
                        .downloadSupportingImagesZip(
                          this.state.selectedSupportingImagesPaths
                        )
                        .then(() => {
                          this.setState({
                            selectedSupportingImagesPathsImagesPaths: [],
                            checkedSupporting: false,
                            selectAllSupporting: false,
                            loadingSupportingImagesZip: false,
                          });
                        })
                        .catch(() => {});
                    }}
                  />
                }
              />
            </span>
          </Header>
        </Grid.Column>
        <SelectableTable
          header={[
            {
              name: "Sr. No.",
              accessor: "id",
            },
          ]}
          checkbox={(x) => this.renderSupportingCheckbox(x)}
          selectAll={() => this.selectAllSupportingCB()}
          allselected={this.state.checkedSupporting}
          actions={(x) => this.supportingActions(x)}
          loading={this.state.loadTable}
          data={arr}
          show={this.state.access && this.state.loaded}
          noPagination
          compact
          trigger={this.state.triggerUpdate}
        />
      </Grid.Column>
    );
  };

  onActionRejection = () => {
    return (
      <Modal size="tiny" open={this.state.askReason} onClose={() => {}}>
        <Header>
          <Icon name="question" />
          {"Please Enter the reason for Rejection"}
        </Header>
        <Modal.Content>
          <Input
            disabled={this.state.reasonLoading}
            placeholder="Provide reason here"
            fluid
            // error={this.state.reasonError !== null}
            value={this.state.reason}
            onChange={(e) => this.setState({ reason: e.target.value })}
          >
            <input />
          </Input>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                askReason: false,
              });
            }}
            disabled={this.state.reasonLoading}
            loading={this.state.reasonLoading}
            content="Cancel"
            // primary
          />
          <Button
            onClick={() => {
              var action = {};
              action.id = this.state.selectedAction.id;
              action.decision = "r";
              action.reason = this.state.reason;
              console.log(action.reason);
              this.props
                .ApproveAction(action)
                .then(() => {
                  this.setState({
                    askReason: false,
                  });
                })
                .catch(() => {});

              // this.setState({ displayAction: false, selectedAction: null, selectedOutcome: null, loaded: false })
            }}
            disabled={
              this.state.reasonLoading || this.state.reason.length === 0
            }
            loading={this.state.reasonLoading}
            content="Submit"
            primary
          />
        </Modal.Actions>
      </Modal>
    );
  };

  renderActionDisplay = () => {
    const customer = this.state.selectedAction;
    //  console.log(this.state.selectedAction)
    console.log(customer);
    if (
      this.state.selectedAction === null &&
      this.state.displayAction === false
    ) {
      return null;
    }
    return (
      <div style={styles.mainDiv}>
        <Segment attached="top">
          <Header as="h2">
            <span>
              <Icon name="user" />
            </span>
            <span>Defaulter Action Details</span>
            <span>
              <Button
                color="blue"
                floated="right"
                onClick={() => {
                  this.setState({
                    displayAction: false,
                    selectedAction: null,
                    selectedOutcome: null,
                    loaded: false,
                  });
                }}
                icon="x"
                content="Close"
              />
            </span>
            {customer.approved === "p" ||
            (this.props.override && customer.approved !== null) ? (
              <span>
                <Button
                  color="red"
                  floated="right"
                  disabled={customer.approved === "r"}
                  onClick={() => {
                    this.setState({
                      showRejectConfirmation: true,
                    });
                  }}
                  icon="x"
                  content="Reject"
                />
                <Button
                  color="green"
                  floated="right"
                  disabled={customer.approved === "a"}
                  onClick={() => {
                    var action = {};
                    action.id = this.state.selectedAction.id;
                    action.decision = "a";
                    this.props
                      .ApproveAction(action)
                      .then(() => {})
                      .catch(() => {});
                    // this.setState({ displayAction: false, selectedAction: null, selectedOutcome: null, loaded: false }) }
                  }}
                  icon="checkmark"
                  content="Approve"
                />
              </span>
            ) : null}
          </Header>
        </Segment>

        <Segment attached="bottom">
          <Grid>
            {this.renderActionDisplayCell(4, "Action ID", customer.id)}
            {this.renderActionDisplayCell(4, "User ID", customer.userId)}
            {this.renderActionDisplayCell(4, "User Name", customer.user)}
            {this.renderActionDisplayCell(
              4,
              "Company Tracking Id",
              customer.companyTrackingId
            )}
            {this.renderActionDisplayCell(
              4,
              "Defaulter ID",
              customer.defaulterId
            )}
            {this.renderActionDisplayCell(
              4,
              "Defaulter Name",
              customer.defaulter
            )}
            {this.renderActionDisplayCell(4, "Address", customer.address)}
            {this.renderActionDisplayCell(
              4,
              "Status (Approved) ",
              customer.approved
            )}
            {this.renderActionDisplayCell(4, "Assign Tag", customer.tag)}
            {this.renderActionDisplayCell(4, "Type", customer.type)}
            {this.renderActionDisplayCell(4, "Form Name", customer.form)}
            {this.renderActionDisplayCell(4, "Date", customer.date)}
            {this.renderActionDisplayCell(4, "Balance", customer.balance)}
            {this.renderActionDisplayCell(4, "Source", customer.source)}
            {this.renderActionDisplayCell(4, "Company", customer.company)}
            {this.renderActionDisplayCell(
              5,
              "Completed On",
              customer.completedOn
            )}
            {this.renderActionDisplayCell(3, "Medium", customer.medium)}
            {this.renderOtherDetails(customer.otherDetails)}
          </Grid>
        </Segment>
      </div>
    );
  };

  renderOtherDetails = (details) => {
    return (
      <Grid.Column width={8}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">
            Other Details{" "}
            {/* <Button floated="right" size="tiny" >Add</Button> */}
          </Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Key",
              accessor: "key",
            },
            {
              name: "Value",
              accessor: "value",
            },
          ]}
          loading={this.state.loadTable}
          show={this.state.access && this.state.loaded}
          data={details}
          noPagination
        />
      </Grid.Column>
    );
  };

  renderActionDisplayCell = (size, label, data) => {
    return (
      <Grid.Column width={size}>
        <Segment>
          <span
            style={{
              border: "solid #d3d3d3 0px",
              borderRadius: 0,
              padding: 0,
              marginRight: 3,
              marginLeft: 1,
            }}
          >
            {label.concat(" : ")}
          </span>
          <span>{data}</span>
        </Segment>
      </Grid.Column>
    );
  };
  /*
    renderTest = () => {
                console.log(this.state.actionIds)
        var obj = {"name": "Afzal" }
            var ar = this.state.actionIds
            var arr1 = []
        ar.forEach(x => {
                arr1.push({
                    ...obj,
                    id: x
                })
            }
            )
            this.removeFromActionIds()
        this.setState({selectAll: false })
     
            console.log(arr1)
     
        }
     
    renderAddButton = () => {
        return (
            <div>
                <Button
                    onClick={() => { this.renderTest() }}
                    color="green"
                    floated="right"
                >{"Add"}
                </Button>
            </div>
            )
        }*/

  renderImageButton = () => {
    var path = "Images/Payment/50/46268";
    return (
      <div>
        <Button
          primary={true}
          onClick={() => {
            this.props.LoadImage(path).then(console.log("Image Imported"));
          }}
        >
          {!this.state.filterShow ? "Search" : "Hide"}
        </Button>
      </div>
    );
  };

  redoSubmit = (value) => {
    this.setState({ loading: true });
    console.log(value);
    let date =
      value.date === undefined || value.date === null
        ? null
        : value.date.toLocaleDateString();
    let user =
      value.userid === undefined || value.userid === null ? null : value.userid;
    console.log(user);
    let obj = {};
    if (this.state.selectedIds.length > 1) {
      obj.ids = JSON.stringify(this.state.selectedIds);
    } else {
      obj.id = this.state.selectedIds;
    }
    if (date !== null) obj.date = date;
    if (user !== null) obj.userid = user;

    this.props
      .ActionRedoMultiple(obj)
      .then(() => {
        var dat = {
          type: "success",
          header: "Success",
          content: "Action is Created Successfully!",
        };
        this.setState({
          selectedIds: [],
          message: dat,
          loading: false,
          triggerUpdate: this.state.triggerUpdate + 1,
        });
      })
      .catch(() => {
        var dat = {
          type: "error",
          header: "Attention",
          content:
            "Action Conldn't be Created due to some error check console!",
        };
        this.setState({ message: dat, loading: false });
      });
  };

  redoActions = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          loading={this.state.loading}
          basic
          color="red"
          onClick={() => {
            this.setState({ displayRedo: false, message: null });
          }}
          icon="x"
          content="Close"
        />
        <Button
          disabled={this.state.loading}
          loading={this.state.loading}
          form="redoForm"
          color="green"
          type="submit"
          icon="checkmark"
          content="Confirm"
        />
      </>
    );
  };

  renderRedo = () => {
    return (
      <RedoForm
        open={this.state.displayRedo}
        size="tiny"
        onCloseHandle={() => {
          this.setState({ displayRedo: false });
        }}
        header={{ icon: "calendar", text: "Select Redo Date" }}
        actionsComponent={this.redoActions()}
        assignTo={this.props.userbyID}
        onSubmit={this.redoSubmit}
        Message={this.state.message}
      />
    );
  };

  renderPageActions = () => {
    var imageData = "";

    return (
      <div style={{ marginBottom: "10px" }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>{this.renderFilterButton()}</Grid.Column>
            <Grid.Column width={2}>
              {/* {this.props.total === 0 ? null : <span>{`${this.props.total} Total`}</span>} */}
              {this.props.total === 0 ? null : (
                <Label basic>{`${this.props.total} Total`}</Label>
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              {this.state.selectAllDefaulters ? (
                <Label basic>{`${this.props.total} Selected`}</Label>
              ) : this.state.selectedIds.length === 0 ? null : (
                <Label
                  basic
                >{`${this.state.selectedIds.length} Selected`}</Label>
              )}
              {/* {this.state.selectedIds.length !== 0 && !this.state.selectedAllDefaulters ? <Label basic >{`${this.state.selectedIds.length} Selected`}</Label> : this.state.selectAllDefaulters ? <Label basic >{`${this.props.total} Selected`}</Label> : null} */}
            </Grid.Column>
            <Grid.Column width={2} position="left">
              <span position="left">
                <Popup
                  content={`Redo Selected Actions.`}
                  position="top right"
                  on="hover"
                  trigger={
                    <Button
                      size="mini"
                      color="facebook"
                      icon="redo"
                      disabled={
                        this.state.selectedIds.length === 0
                          ? true
                          : this.state.isNotCompleted
                      }
                      onClick={() => {
                        this.setState({ displayRedo: true });
                      }}
                    />
                  }
                />
                <Popup
                  content={`Mark selected Actions as Unread`}
                  position="top right"
                  on="hover"
                  trigger={
                    <Button
                      size="mini"
                      color={
                        this.state.selectedIds.length === 0
                          ? "grey"
                          : this.state.hasNotViewed
                          ? "grey"
                          : "green"
                      }
                      icon="mail"
                      // inverted={value.hasViewed}
                      disabled={
                        this.state.selectedIds.length === 0
                          ? true
                          : this.state.hasNotViewed
                      }
                      onClick={() => {
                        let action = {};
                        if (this.state.selectedIds.length > 1) {
                          action.actionIds = JSON.stringify(
                            this.state.selectedIds
                          );
                        } else {
                          action.actionId = this.state.selectedIds[0];
                        }
                        this.props
                          .MarkUnread(action)
                          .then(() => {
                            alert("DOne");
                            this.setState({
                              selectedIds: [],
                              triggerUpdate: this.state.triggerUpdate + 1,
                            });
                          })
                          .catch(() => {});
                        // this.setState({ selectedAction: value, displayRedo: true })
                      }}
                    />
                  }
                />

                {/* <Button size="mini" color="teal" icon='edit'
                                // disabled={value.completedOn !== null}
                                onClick={() => {
                                    // console.log(value)
                                    this.props.LoadSortingParams(this.getDefaulterId(this.state.selectedIds[0])).then((x) => {
                                        console.log('DONENENEN')
                                        // value.requiresApproval = value.approved
                                        // console.log(value)
                                        // let newData = this.copy(value)
                                        // let date = ''
                                        // date = newData.date
                                        // var parts = date.split('/')
                                        // // console.log(parts)
                                        // date = parts[1] + '/' + parts[0] + '/' + parts[2]
                                        // date = new Date(date)
                                        // // date = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
                                        // // let newDate = new Date(date)
                                        // // console.log(newDate)
                                        // console.log(date)
                                        // newData.date = date
                                        // // console.log(value)
                                        // this.setState({
                                        //     selectedAction: newData,
                                        //     displayEditAction: true,
                                        //     sortingParams: this.props.sortingParams
                                        // })
                                    })
                                    // this.constructForm(value.formId)
                                }}
                            /> */}
                <Popup
                  content={`Delete selected Actions.`}
                  position="top right"
                  on="hover"
                  trigger={
                    <Button
                      size="mini"
                      color="red"
                      icon="delete"
                      disabled={this.state.selectedIds.length === 0}
                      onClick={() => {
                        this.setState({ showDeleteConfirmation: true });
                      }}
                    />
                  }
                />
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  getDefaulterId = (id) => {
    let dID = null;
    this.props.data.forEach((x) => {
      if (x.id === id) dID = x.defaulterId;
    });
    return dID;
  };
  /*
    selectAll = () => {
            //this.anyMethod()
            this.addTOActionIds()
    console.log(!this.state.selectAll)
    if (!this.state.selectAll) {this.addTOActionIds()}
        else {this.removeFromActionIds()}
        this.setState({
            selectAll: !this.state.selectAll
    })
    console.log(this.state.selectAll)
    //this.renderTable()
    }
     
    */
  renderSortButton = () => {
    return (
      <div>
        {/* <Button
                    onClick={() => { }}
                    color="green"
                    floated="right"
                >{"Sort"}
                </Button> */}
      </div>
    );
  };

  renderFilterButton = () => {
    return (
      <div>
        <Button
          size="tiny"
          primary={!this.state.filterShow}
          onClick={() => {
            this.setState({ filterShow: !this.state.filterShow });
          }}
        >
          {!this.state.filterShow ? "Search Actions" : "Hide Filter Box"}
        </Button>
      </div>
    );
  };

  renderFilterBox = () => {
    return this.state.filterShow ? (
      <div style={{ marginBottom: "10px" }}>
        <Header attached="top" size="large">
          Filter View All
        </Header>
        <Segment attached>
          <ViewActionFilterForm
            onSubmit={(x) => {
              console.log(x);
              if (
                x.datefrom !== undefined &&
                x.datefrom !== null &&
                typeof x.datefrom === "object"
              ) {
                x.datefrom = x.datefrom.toLocaleDateString();
              }
              if (
                x.dateto !== undefined &&
                x.dateto !== null &&
                typeof x.dateto === "object"
              ) {
                x.dateto = x.dateto.toLocaleDateString();
              }
              if (
                x.completedonfrom !== undefined &&
                x.completedonfrom !== null &&
                typeof x.completedonfrom === "object"
              ) {
                x.completedonfrom = x.completedonfrom.toLocaleDateString();
              }
              if (
                x.completedonto !== undefined &&
                x.completedonto !== null &&
                typeof x.completedonto === "object"
              ) {
                x.completedonto = x.completedonto.toLocaleDateString();
              }
              if (
                x.minlastPaymentDate !== undefined &&
                x.minlastPaymentDate !== null &&
                typeof x.minlastPaymentDate === "object"
              ) {
                x.minlastPaymentDate = x.minlastPaymentDate.toLocaleDateString();
              } else {
                delete x.minlastPaymentDate;
              }
              if (
                x.maxlastPaymentDate !== undefined &&
                x.maxlastPaymentDate !== null &&
                typeof x.maxlastPaymentDate === "object"
              ) {
                x.maxlastPaymentDate = x.maxlastPaymentDate.toLocaleDateString();
              } else {
                delete x.maxlastPaymentDate;
              }
              if ("company" in x) {
                x.companyname = this.props.companybyID.find(function (y) {
                  if (y.value === x.company) return y;
                });
                x.companyname = x.companyname.text;
                var arr = Object.entries(x).map(([key, value]) => {
                  if (key.substring(0, 13) === "otherDetails_") {
                    return { key: key.substring(13), value: value };
                  }
                });
                x.otherdetails = arr.filter(function (el) {
                  return el;
                });
              } else {
                delete x.company;
                delete x.companyname;
                delete x.otherdetails;
              }
              //console.log(x);
              console.log(this.state.selectedTab);
              Object.keys(x).forEach((key) => {
                console.log(key);
                if (Array.isArray(x[key])) {
                  if (x[key].length === 0) {
                    delete x[key];
                  }
                } else if (
                  x[key] === "" ||
                  x[key] === null ||
                  x[key] === undefined
                ) {
                  delete x[key];
                } else if (typeof x[key] === "object") {
                  if (x[key] === null) {
                    delete x[key];
                  } else if (Object.keys(x[key]).length === 0) {
                    delete x[key];
                  }
                }
              });
              console.log(x);
              this.setState({ searchParameter: x, currentPage: 1 }, () =>
                this.setState({ triggerUpdate: this.state.triggerUpdate + 1 })
              );
            }}
            initialValues={this.state.searchParameter}
            call={this.state.selectedTab === "Call" ? true : false}
            medium={this.state.selectedTab}
          />
        </Segment>
      </div>
    ) : null;
  };

  displayFillAction = () => {
    return (
      <>
        <div style={styles.mainDiv}>
          <Segment attached="top">
            <Header as="h2">
              <span>
                <Icon name="file" />
              </span>
              <span>Fill Form</span>
              <span>
                <Button
                  color="red"
                  size="small"
                  floated="right"
                  onClick={() => {
                    this.setState({
                      displayFillAction: false,
                      selectedAction: null,
                      selectedOutcome: null,
                      loaded: false,
                    });
                  }}
                >
                  <Icon name="x" />
                  Close
                </Button>
              </span>
            </Header>
          </Segment>
          <Segment attached>
            {this.props.loadedForm === undefined ||
            this.props.loadedForm === null ? (
              <FillForm
                onSubmit={this.formSubmit}
                submitLoading={this.state.submitLoading}
                tagOptions={this.props.tagsList}
                onClose={() => this.props.UnloadForm()}
              />
            ) : (
              <FillForm
                tagOptions={this.props.tagsList}
                onSubmit={this.formSubmit}
                submitLoading={this.state.submitLoading}
                formInfo={
                  this.props.loadedForm === null ? [] : this.props.loadedForm
                }
                onClose={() => this.props.UnloadForm()}
              />
            )}
          </Segment>
        </div>
      </>
    );
  };

  formSubmit = (values) => {
    console.log(values);
    this.setState({ submitLoading: true });
    var data = {};
    data.actionid = this.state.selectedAction.id;
    var arr = [];
    var obj = {};
    Object.keys(values).forEach((key) => {
      var newData = {};
      if (key.includes("FORM")) {
        var k = key.substring(5);
        newData.caption = k;
        newData.value = values[key];
        arr.push(newData);
      }
      if (key.includes(values.resultId + "_")) {
        let rId = values.resultId + "";
        var k = key.substring(rId.length + 1);
        if (typeof values[key] === "object")
          obj[k] = values[key].toLocaleDateString();
        else obj[k] = values[key];
        console.log(k);
      }
    });
    data.formValues = arr;
    let tag = "";
    this.props.tagsList.forEach((x) => {
      if (x.value === values.resultId) {
        console.log("entered inside");
        tag = x.text;
      }
    });
    data[tag] = obj;
    data.resultId = values.resultId;
    console.log(data);
    var dataSend = {};
    dataSend.outcome = data;
    if (values.paymentimages !== undefined && values.paymentimages !== null) {
      dataSend.paymentimages = Object.values(values.paymentimages);
    }
    if (
      values.supportingImages !== undefined &&
      values.supportingImages !== null
    ) {
      dataSend.supportingImages = Object.values(values.supportingImages);
    }
    console.log(dataSend);
    this.props
      .ActionFill(dataSend)
      .then((x) => {
        console.log("DONE");
        this.setState({ displayFillAction: false, submitLoading: false });
        alert("Form Submitted");
      })
      .catch((x) => alert("Failed"));
  };

  handleSubmit = (value) => {
    console.log(value);
    let action = {};

    if (value !== undefined && value !== null) {
      action.id = value.id;
      if (
        value.date !== undefined &&
        value.date !== null &&
        typeof value.date === "object"
      ) {
        action.date = value.date.toLocaleDateString();
      }
      if (
        value.userId !== undefined &&
        value.userId !== null &&
        value.userId === 0
      ) {
        delete value.userid;
      } else {
        action.userId = value.userId;
      }
      if (value.formId !== undefined && value.formId !== null) {
        action.formId = value.formId;
      }
      action.tag = value.tag;
      action.type = value.type;
      action.medium = value.medium;
      action.requiresApproval = value.requiresApproval;
      action.isRedone = value.isRedone;
      action.isAscending = value.isAscending;
      action.sortingParameter = value.sortingParameter;
      action.defaulterId = value.defaulterId;
    }
    console.log(action);
    this.setState({ loading: true });
    this.props
      .EditAction(action)
      .then((x) => {
        alert("Action Updated");
        this.setState({
          triggerUpdate: this.state.triggerUpdate + 1,
          loading: false,
          displayEditAction: false,
        });
      })
      .catch((error) => {
        alert("Not Updated");
        this.setState({
          loading: false,
          displayEditAction: false,
        });
      });
  };

  returnEditBoxActionsComponent = () => {
    return (
      <>
        <Button
          size="tiny"
          disabled={this.state.loading}
          basic
          color="red"
          onClick={() => {
            this.setState({ displayEditAction: false });
          }}
        >
          <Icon name="remove" />
          Close
        </Button>
        <Button
          size="tiny"
          disabled={this.state.loading}
          loading={this.state.loading}
          form="actionForm"
          color="green"
          type="submit"
        >
          <Icon name="checkmark" />
          Update Action
        </Button>
      </>
    );
  };

  renderEditAction = () => {
    return (
      <ActionForm
        open={this.state.displayEditAction}
        onCloseHandle={() => {
          this.setState({ displayEditAction: false });
        }}
        header={{ icon: "edit", text: "Edit Action" }}
        actionsComponent={this.returnEditBoxActionsComponent()}
        onSubmit={this.handleSubmit}
        loading={this.state.loading}
        assignTo={this.props.userbyID}
        myForms={this.props.formsWithIds}
        sortingParams={this.props.sortingParams}
        showMessage={this.state.showEditMessage}
        Message={null}
        initialValues={this.state.selectedAction}
      />
    );
  };

  renderViewAll = () => {
    return (
      <div>
        {this.state.access && this.state.loaded
          ? this.renderPageActions()
          : null}
        {this.state.access && this.state.loaded ? this.renderFilterBox() : null}
        {this.renderDeleteConfirmationBox()}
        {/* <div style={{ height: 10 }} /> */}
        {this.renderTable()}
        {this.renderRedo()}
        {this.renderEditAction()}
      </div>
    );
  };
  renderActions() {
    return (
      <>
        {this.state.displayAction
          ? this.renderDetails()
          : this.state.displayFillAction
          ? this.displayFillAction()
          : this.renderViewAll()}
      </>
    );
  }

  renderTabs() {
    let panes = [
      {
        menuItem: "All",
        render: () => (
          <Tab.Pane attached={false}>{this.renderActions()}</Tab.Pane>
        ),
      },
      {
        menuItem: "Visit",
        render: () => (
          <Tab.Pane attached={false}>{this.renderActions()}</Tab.Pane>
        ),
      },
      {
        menuItem: "Call",
        render: () => (
          <Tab.Pane attached={false}>{this.renderActions()}</Tab.Pane>
        ),
      },
      {
        menuItem: "SMS",
        render: () => (
          <Tab.Pane attached={false}>{this.renderActions()}</Tab.Pane>
        ),
      },
    ];
    return (
      <div style={styles.mainDiv}>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={this.state.selectedTabIndex}
          onTabChange={(event, data) => this.onTabChange(event, data)}
        />
      </div>
    );
  }

  onTabChange(event, data) {
    let index = data.activeIndex;
    let tabName = data.panes[data.activeIndex].menuItem;
    localStorage.setItem("selectedActionTabIndex", index);
    this.props.LoadTags(tabName, true);
    if (index !== 0) {
      localStorage.setItem("selectedActionTab", tabName);
      this.setState({
        selectedTab: tabName,
        selectedTabIndex: index,
        triggerUpdate: this.state.triggerUpdate + 1,
      });
    } else {
      localStorage.setItem("selectedActionTab", "");
      this.setState({
        selectedTab: "",
        selectedTabIndex: index,
        triggerUpdate: this.state.triggerUpdate + 1,
      });
    }
  }

  renderActionHistoryTable = () => {
    let actions = this.state.performedActions;
    console.log(actions);
    if (actions.length > 0) {
      actions = actions.filter(
        (x) => x.id != this.state.selectedOutcome.actionId
      );
      actions.map((obj) => {
        let name = this.props.userbyID.find((x) => x.value === obj.userId);
        obj.user = name !== null && name !== undefined ? name.text : "Null";
        let form = this.props.formsWithIds.find((x) => x.value === obj.formId);
        obj.form = form !== null && form !== undefined ? form.text : "Null";
        obj.source = obj.source === true ? "Outbound" : "Inbound";
        obj.approved =
          obj.outcome === null
            ? ""
            : obj.outcome.approved === "a"
            ? "Approved"
            : obj.outcome.approved === "p"
            ? "Pending"
            : "Rejected";
        obj.resultTag = obj.outcome.result.tag;
      });
    }
    return actions.length > 0 ? (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">
            Action History
            <Label>Total: {actions.length}</Label>
          </Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Action Id",
              accessor: "id",
            },
            {
              name: "Tag",
              accessor: "tag",
            },
            {
              name: "Type",
              accessor: "type",
            },
            /*{
              name: "Source",
              accessor: "source",
            },*/
            {
              name: "Requires Approval",
              accessor: "requiresApproval",
            },
            {
              name: "Date",
              accessor: "date",
            },
            {
              name: "Meduim",
              accessor: "medium",
            },
            {
              name: "User ID",
              accessor: "userId",
            },
            {
              name: "User Name",
              accessor: "user",
            },
            {
              name: "Form",
              accessor: "form",
            },
            {
              name: "Result Tag",
              accessor: "resultTag"
            },
            {
              name: "Approved",
              accessor: "approved",
            },
          ]}
          ActionHeading="View Outcome"
          actions={(x) => this.viewHistoryOutcome(x)}
          loading={this.state.loadTable}
          show={this.state.access && this.state.loaded}
          data={actions}
          noPagination
          overflowX
        />
      </Grid.Column>
    ) : (
      <Header>No Previous Actions</Header>
    );
  };

  viewHistoryOutcome = (x) => {
    return (
      <Button
        size="tiny"
        color="black"
        icon
        onClick={() => this.getHistoryOutcome(x.id)}
      >
        <Icon name="search" />
      </Button>
    );
  };

  renderHistoryOutcomeBoxActions = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          basic
          color="red"
          onClick={() => this.setState({ displayOutcome: false })}
        >
          <Icon name="remove" />
          Close
        </Button>
      </>
    );
  };

  renderOutcomeBox = () => {
    return (
      <>
        <ActionOutcomeForm
          size="large"
          open={this.state.displayOutcome}
          onCloseHandle={() => {
            this.setState({ displayOutcome: false });
          }}
          header={{ icon: "user", text: "Outcome" }}
          actionsComponent={this.renderHistoryOutcomeBoxActions()}
          loading={this.props.loading}
          OutCome={this.props.outcome}
          phoneNumbers={this.props.phoneNumbers}
        />
      </>
    );
  };

  getHistoryOutcome = (value) => {
    this.props
      .GetActionOutcome(value)
      .then((x) => {
        this.setState({ displayOutcome: true });
      })
      .catch((x) => {
        this.setState({ loaded: true });
      });
  };

  getActionHistory = (defaulterId) => {
    this.props
      .GetActionHistory(defaulterId)
      .then((x) => {
        this.setState({ performedActions: this.props.performedActions });
      })
      .catch((x) => {
        this.setState({ loaded: true });
      });
  };

  render() {
    return (
      <>
        {!this.state.loaded ? (
          <LoaderView text="View All page is Loading!" />
        ) : this.state.access ? null : (
          <UnauthorizedView pageName="View All Page" />
        )}
        {this.renderTabs()}
        {/* {this.state.displayAction ? this.renderDetails() : this.renderViewAll()} */}
      </>
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "2vw",
    marginRight: "2vw",
  },
  topBox: {
    marginBottom: "10px",
  },
  footer: {
    marginLeft: "3vw",
    marginRight: "3vw",
    marginBottom: "3vw",
  },
};

const mstp = (state) => {
  return {
    data: state.actions.data === undefined ? [] : state.actions.data,
    total: state.actions.total,
    override: state.actions.override,
    outcome: state.actions.outcome,
    image: state.actions.image,
    imageFile: state.actions.imageFile,
    companybyID: state.company.companyWithIds,
    loadedForm: state.myForms.loadedForm,
    userbyID: state.user.userbyID,
    formsWithIds: state.myForms.formsWithIds,
    sortingParams: state.actions.sortingParams,
    tagsList: state.tags.activeTags,
    commentsList: state.actions.commentsList,
    performedActions: state.customer.performedActions,
    phoneNumbers: state.teleCaller.phoneNumbers,
  };
};

export default connect(mstp, actions)(ViewAll);
