import {
    LOAD_ACTION_REPORTS,
    LOAD_ATTENDANCE_SUMMARY,
    LOAD_REGION_REPORTS
} from '../actions/types';

const INITIAL_STATE = {
    userSummary: [],
    regionWiseSummary: {}
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case LOAD_ACTION_REPORTS:
            return {
                ...state,
                countActions: actions.payload.countActions,
                countCompleted: actions.payload.countCompleted
            }
        case LOAD_ATTENDANCE_SUMMARY:
            return {
                ...state,
                userSummary: actions.payload.userSummary
            }
        case LOAD_REGION_REPORTS:
            return {
                ...state,
                regionWiseSummary: actions.payload.regionWiseSummary
            }
        default:
            return state;
    }
}