import {
    LOAD_CUSTOMERS,
    LOAD_TAG_KEYS,
    LOAD_DEFAULTER,
    LOAD_DROPDOWNS,
    LOAD_USER_DEFAULTER_CODE,
    LOAD_ALL_CODES,
    LOAD_SELECTED_DEFAULTERS,
    LOAD_ACTION_HISTORY
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    total: 0,
    addLoading: false,
    deleteLoading: [],
    selectedDefaulter: null,
    tagKeys: null,
    name: [],
    selectedCustomers: [],
    invalidTrackingIds: [],
    performedActions: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total
            }
        case LOAD_DEFAULTER:
            return {
                ...state,
                selectedDefaulter: action.payload
            }
        case LOAD_TAG_KEYS:
            return {
                ...state,
                tagKeys: action.payload
            }
        case LOAD_DROPDOWNS:
            return {
                ...state,
                Dropdowns: action.payload
            }
        case LOAD_USER_DEFAULTER_CODE:
            return {
                ...state,
                userDefaulterCodes: action.payload
            }
        case LOAD_ALL_CODES:
            return {
                ...state,
                allCodes: action.payload
            }
        case LOAD_SELECTED_DEFAULTERS:
            return {
                ...state,
                selectedCustomers: action.payload.ids,
                invalidTrackingIds: action.payload.invalidTrackingIds
            }
        case LOAD_ACTION_HISTORY:
            return {
                ...state,
                performedActions: action.payload
            }
        default:
            return state;
    }
}