import React from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form'

import { Button, Form, Segment, Message, Grid } from 'semantic-ui-react'

import { CommonInput } from './commonInput';

import { noSpace, normalizeCNIC, normalizeContact } from './normalizers';



let RegisterForm = (props) => {
    return (
        <div>
           <Form error={props.registerMessage !== null? props.registerMessage.type === 'error': false} success={props.registerMessage !== null? props.registerMessage.type === 'success': false} onSubmit={props.handleSubmit} size='large'>
                <Segment>
                    <Grid>
                        <Grid.Row>
                            {/* firstName */}
                            <Grid.Column width={8}>
                                <Field
                                disabled={props.loading}
                                fluid
                                placeholder="First Name"
                                name="firstName"
                                component={CommonInput}
                                normalize={noSpace}         
                                />      
                            </Grid.Column>
                            {/* lastName */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Last Name"
                                    name="lastName"
                                    component={CommonInput}
                                    normalize={noSpace}         
                                    />  
                            </Grid.Column>
                            {/* contactnum */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Contact Number"
                                    name="contactnum"
                                    component={CommonInput}
                                    normalize={normalizeContact}     
                                    />  
                            </Grid.Column>
                            {/* email */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Email Address"
                                    name="email"
                                    component={CommonInput}
                                    type="email"    
                                    normalize={noSpace}   
                                    />  
                            </Grid.Column>
                            {/* cnic */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="CNIC"
                                    name="cnic"
                                    component={CommonInput}    
                                    normalize={normalizeCNIC}   
                                    />
                            </Grid.Column>
                            {/* username */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Username"
                                    name="username"
                                    component={CommonInput}    
                                    normalize={noSpace}   
                                    />
                            </Grid.Column>
                            {/* password */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Password"
                                    name="password"
                                    component={CommonInput}    
                                    normalize={noSpace}  
                                    type="password" 
                                    />
                            </Grid.Column>
                            {/* confirm password */}
                            <Grid.Column width={8}>
                                <Field
                                    disabled={props.loading}
                                    fluid
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    component={CommonInput}    
                                    normalize={noSpace}  
                                    type="password" 
                                    />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                         
                    {props.registerMessage !== null?
                        <Message
                            error={props.registerMessage !== null? props.registerMessage.type === 'error': false}
                            success={props.registerMessage !== null? props.registerMessage.type === 'success': false}
                            header = {props.registerMessage.header}
                            content = {props.registerMessage.content}
                        />
                        :
                        null
                    }
                    <Button type="submit" disabled={props.loading} loading={props.loading} color='blue' fluid size='large'>
                        Register
                    </Button>
                </Segment>
            </Form>
            <br/>
            <Button onClick={props.toggle} disabled={props.loading} inverted color='blue' size='small'>
                Login to an existing account
            </Button>
        </div>
    )
}

const valueCheck = (value,name,minLength = null) => {
    if (!value) {
        return `You must enter  ${name}`
    } else {
        if (minLength) {
            if (value.length < minLength) {
                return `${name} must be at least ${minLength} characters`
            }
        }
    }
}

const passwordMatch = (p1,p2) => {
    if (p1 !== p2) {
        return  'Password must match'
    }
}

const validate = (formValues) => {
    const errors = {};
    errors.firstName = valueCheck(formValues.firstName,'first name',2);
    errors.lastName = valueCheck(formValues.lastName,'last name',2);
    errors.contactNum = valueCheck(formValues.contactNum,'contact number',11);
    errors.email = valueCheck(formValues.email,'email address');
    errors.cnic = valueCheck(formValues.cnic,'CNIC',15)
    errors.username = valueCheck(formValues.username,'username')
    errors.password = valueCheck(formValues.password,'password',5)
    errors.confirmPassword = passwordMatch(formValues.confirmPassword,formValues.password)
    return errors;
}


RegisterForm = reduxForm({
    form: 'register',
    validate
})(RegisterForm)

const mtsp = (state) => {
    return {
        loading:  state.auth.loading,
        registerMessage: state.auth.registerMessage
    }
}

export default connect(mtsp,null)(RegisterForm);