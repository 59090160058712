import axios from "axios";
import { 
    LOAD_PROFILE, CHANGE_USERNAME, USERNAME_CHANGE_STATUS,PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE,PROFILE_PASSWORD_CHANGE_MESSAGE
} from './types';
const apiURL = require('../config.json').apiURL;

export const LoadProfile = () =>
    dispatch => {
        axios({
            method: 'get',
            url: apiURL+'userlogin/getmyprofile'
        }).then(({data}) => {
            dispatch({
                type: LOAD_PROFILE,
                payload: data.user
            })
        }).catch((error) => {
            console.log(error)
        })
    }

export const ChangeUsername = (newUsername) => {
    const form = new FormData();
    form.append('newUsername', newUsername);
    return dispatch => {
        dispatch({
            type: USERNAME_CHANGE_STATUS,
            payload: 'loading'
        })
        axios({
            method: 'post',
            url: apiURL+'userlogin/updateusername',
            data: form
        }).then((data) => {
            dispatch({
                type: CHANGE_USERNAME,
                payload: newUsername
            })
            dispatch({
                type: USERNAME_CHANGE_STATUS,
                payload: 'display'
            })
        }).catch((error) => {
            console.log(error)
            dispatch({
                type: USERNAME_CHANGE_STATUS,
                payload: 'display'
            })
        })
    }
}

export const ChangingUsernameStatus = (status) => {
    return ({
        type: USERNAME_CHANGE_STATUS,
        payload: status
    })
}

export const RemoveAllProfileMessages = () => {
    return dispatch => {
        dispatch({
            type: PROFILE_PASSWORD_CHANGE_MESSAGE,
            payload: null
        })
    }
}

export const ChangeProfilePassword = (oldPassword, newPassword) => {
    const form = new FormData();
    form.append('oldPassword', oldPassword);
    form.append('newPassword', newPassword);
    return dispatch => {
        console.log(...form);
        dispatch({
            type: PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE
        })
        dispatch({
            type: PROFILE_PASSWORD_CHANGE_MESSAGE,
            payload: null
        })
        axios({
            method: 'post',
            url: apiURL+'userlogin/updatepassword',
            data: form
            
        }).then((data) => {
            dispatch({
                type: PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE
            })
            dispatch({
                type: PROFILE_PASSWORD_CHANGE_MESSAGE,
                payload: {
                    type: 'success',
                    header: 'SUCCESS',
                    content: 'password was changed successfully!'
                }
            })
        }).catch((error) => {
            console.log(error.response)
            dispatch({
                type: PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE
            })
            dispatch({
                type: PROFILE_PASSWORD_CHANGE_MESSAGE,
                payload: {
                    type: 'error',
                    header: 'ERROR',
                    content: 'password was not able to be changed! try again later'
                }
            })
        })
    }
}