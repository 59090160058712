import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { Form, Message, Modal, Header, Icon, Grid, Button, Segment, GridColumn, Table } from 'semantic-ui-react'
import * as actions from '../../actions'
import { CommonInput, CommonSelect, CommonMultiSelect } from './commonInput';
import NewFormFieldForm from './newFormFieldForm'
import Datatable from '../../components/dataTable';


class FormCreationForm extends Component {
    // caption, minlength, maxlength, datatype, type, fieldvalues
    
    state = {
        formID:null,
        formName: '',
        formNameError: null,
        fieldList: [],
        fieldIdCounter: 0,
        loading: false,
        noFieldsError: false
    }

    loadValuesForEdit = (data) => {
        const newFieldList = data.formFields.map(x=> {
            const {caption, datatype, minLength,maxLength,type,fieldValues} = x
            return(
                {
                    caption,
                    datatype,
                    type,
                    minlength: minLength,
                    maxlength: maxLength,
                    fieldValues: fieldValues.map(y=>y.value).join(',')


                }
            )
        })
        this.setState({
            formID: data.id,
            formName: data.name,
            fieldList: newFieldList
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== undefined && nextProps.data !== null){
            this.loadValuesForEdit(nextProps.data);
        }
    }


    myField = (name, label, disabled, type="text") => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{marginBottom:'3px'}}/>
                </>
                <Field
                    disabled={disabled}
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder={label}
                    type={type}
                />
            </>
        )
    }

    renderActionsButton = (values) => {
        return (
            <Button size="mini" color="red" icon
                onClick={() => this.setState({fieldList: this.state.fieldList.filter(x => x.id !== values.id)})}
            >
                <Icon name="x"/>
            </Button>
        )
    }

    formatFormData = () => {
        const formattedForm = {...this.state.fieldList};
        var result = Object.keys(formattedForm).map(function(key) {
            const newObj = {...formattedForm[key]}
            delete newObj.id
            if (newObj.fieldvalues !== undefined){
                const fieldvaluesArray = newObj.fieldvalues.split(',');
                const fieldvaluesObject = []
                fieldvaluesArray.map(x => {
                    fieldvaluesObject.push({"value":x})
                    return null;
                })
                newObj.fieldvalues = fieldvaluesObject
            }
            
            return newObj;
        });
        this.finalizeFormCreation(this.state.formName,result)
    }

    finalizeFormCreation = (formName,fields) => {
        if (!/\S/.test(formName) || fields.length === 0){
            if (!/\S/.test(formName)){
                this.setState({formNameError:'You must enter a name for the form', formName:''})
            }
            if (fields.length === 0){
                this.setState({noFieldsError: true})
            }
        } else {
            formName = formName.trim()
            if (fields.length !== 0) {
                this.setState({loading: true})
                this.props.createForm(formName,fields)
                .then(x => {
                    this.props.LoadForms();
                    this.setState({loading: false,formName: '',fieldList:[]})
                })
                .catch(x => this.setState({loading: false}))
            }
        }

    }

    renderFormActions = () => {
        return (
            <>
                <Button
                    onClick={() => {this.setState({formNameError:null, noFieldsError: false, formName:'',fieldList:[]});if(!this.state.loading)this.props.onClose()}}
                    disabled={this.state.loading}
                >
                    Close
                </Button>
                <Button
                    primary
                    onClick={() => this.formatFormData()}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                >
                    Add Form
                </Button>
            </>
        )
        
    }

    renderNewFieldTable = () => {
        return (
            this.state.fieldList.length !== 0?
            <Segment
                style={{height:'100%'}}
            >
                <Datatable
                    tableSize="small"
                    header={[
                        {
                            name: 'Caption',
                            accessor: 'caption'
                        },
                        {
                            name: 'Max Length',
                            accessor: 'maxlength'
                        },
                        {
                            name: 'Min Length',
                            accessor: 'minlength'
                        },
                        {
                            name: 'Data Type',
                            accessor: 'datatype'
                        },
                        {
                            name: 'Input Type',
                            accessor: 'type'
                        },
                        {
                            name: 'Dropdown Options',
                            accessor: 'fieldvalues'
                        }
                    ]}
                    actionsFirst
                    noActionHeading
                    actions = {(x) => this.renderActionsButton(x)}
                    data = {this.state.fieldList}
                    noPagination
                    overflowX
                />
            </Segment>
            :
            <Segment
                    style={
                        {
                            height: '100%',
                            // textAlign: 'center',
                            // verticalAlign: 'middle'
                        }
                    }
            >
                <div
                    style={
                        {
                            fontSize: '2rem',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-50%)'
                        }
                    }
                >
                    {this.state.noFieldsError?
                        <span style={{color: 'red'}}>
                            No fields provided
                        </span>
                        :
                        "No fields added yet"
                    }
                </div>
            </Segment>
        )
    }

    addNewFieldToList = (values) => {
        const newValues = {...values}
        if (newValues.fieldvalues !== undefined){
            newValues.fieldvalues = newValues.fieldvalues.split(',').filter(x => /\S/.test(x)).join(',')
        }
        this.setState({fieldIdCounter: this.state.fieldIdCounter+1, fieldList: [...this.state.fieldList,{id: this.state.fieldIdCounter,...newValues}]})
    }

    renderNewFieldAdditionForm = () => {
        return (
            <>
                <Header as='h5' attached='top'>
                    Add Field to Form
                </Header>
                <Segment attached>
                    <NewFormFieldForm
                        Message={null}
                        onSubmit={(values) => this.addNewFieldToList(values)}
                        loading={this.state.loading}
                    />
                </Segment>
            </>
        )
    }

    renderFormMessage = () => {
        return (
            this.props.Message !== null?
            <Message
                error={this.props.Message !== null? this.props.Message.type === 'error': false}
                success={this.props.Message !== null? this.props.Message.type === 'success': false}
                header = {this.props.Message.header}
                content = {this.props.Message.content}
            />
            :
            null
        )
    }

    renderFormNameInput = () => {
        return (
            <>
                <CommonInput
                    disabled={this.state.loading}
                    size="massive"
                    placeholder="Form Name"
                    fluid
                    value = {this.state.formName}
                    onChange = {(e) => {
                        if (e.target.value !== '' && this.state.formNameError !== null){
                            this.setState({formNameError:null})
                        }
                        if (e.target.value === ''){
                            this.setState({formNameError:'You must enter a name for the form'})
                        }
                        this.setState({formName: e.target.value})
                    }}
                    onBlur = {(e) => {
                        if(this.state.formName === '') {
                            this.setState({formNameError:'You must enter a name for the form'})
                        } else {
                            this.setState({formNameError:null})
                        }
                    }}
                    errorMessage={this.state.formNameError}
                />
            </>
        )
    }



    renderCreationModal = () => {
        return (
            <Modal
                size={this.props.size}
                open={this.props.open}
                onClose={() => {this.setState({formNameError:null, noFieldsError: false, formName:'', fieldList:[]});if(!this.state.loading)this.props.onClose()}}
                style={{width: '90%'}}
            >
                <Header>
                    <Icon name='plus'/>
                    Create Form 
                </Header>
                    <Modal.Content>
                        <>
                            <Grid>
                                <Grid.Column width={7}>
                                    {this.renderFormNameInput()}
                                    {this.renderNewFieldAdditionForm()}
                                    {this.renderFormMessage()}                            
                                </Grid.Column>
                                <Grid.Column width={9}>
                                    {this.renderNewFieldTable()}
                                </Grid.Column>                                
                            </Grid>
                        </>
                    </Modal.Content>
                    <Modal.Actions>
                        {this.renderFormActions()}
                    </Modal.Actions>
            </Modal>
        )
    }
    
    render() {
        return (
           this.renderCreationModal()
        )
    }
}

export default connect(null, actions)(FormCreationForm);