import {
    PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE,
    PROFILE_PASSWORD_CHANGE_MESSAGE,
    LOAD_PROFILE,
    CHANGE_USERNAME,
    USERNAME_CHANGE_STATUS
} from '../actions/types';

const INITIAL_STATE = {
    passwordChangeLoading :false,
    passwordChangeMessage: null,
    profileData: [],
    usernameChangeStatus: 'display'
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE:
            return {...state, passwordChangeLoading: !state.passwordChangeLoading};
        case PROFILE_PASSWORD_CHANGE_MESSAGE:
            return {...state, passwordChangeMessage: action.payload}
        case LOAD_PROFILE: 
            return {...state, profileData: action.payload}
        case CHANGE_USERNAME: 
            return {...state, profileData: {...state.profileData, username: action.payload}}
        case USERNAME_CHANGE_STATUS: 
            return {...state, usernameChangeStatus: action.payload}
        default:
            return state;
    }
}