import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { reset, Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input, Checkbox, Button } from 'semantic-ui-react'
import { CommonSelect, CommonSearchableDropdown } from './commonInput';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)


class ActionFormVisit extends React.Component {

    state = {
        checkedStatus: null
    }

    renderCheckbox = ({ label }) => {
        console.log(label)
        return (
            <Checkbox
                label={label}
                onChange={toggle}
            />
        )
    }
    toggle = () => {
        checkedStatus = !checkedStatus
        console.log(checkedStatus)
    }

    renderDropDown = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ [field.input.name]: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={field.options}
                />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Modal
                    size={this.props.size}
                    open={this.props.open}
                    onClose={this.props.onCloseHandle}
                >
                    <Header >
                        <Icon name={this.props.header.icon} />
                        {this.props.header.text}
                    </Header>
                    <Modal.Content>
                        <Form
                            id="actionsForm"
                            error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                            success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                            onSubmit={this.props.handleSubmit}
                            size='large'>
                            {this.props.total ? <><span>Selected : {this.props.total}</span><div style={{ marginBottom: '3px' }} /></> : null}
                            {this.props.label ? <><span>Tag</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="tag"
                                options={[
                                    { value: 'Promise', text: 'Promise' },
                                    { value: 'Reminder', text: 'Reminder' },
                                    { value: 'Not Paying', text: 'Not Paying' },
                                    { value: 'First Contact', text: 'First Contact' },
                                    { value: 'BD Activity', text: 'BD Activity' }
                                ]}
                                component={CommonSelect}
                                placeholder="Tag"
                                clearable
                            />

                            {this.props.label ? <><span>Type</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="type"
                                options={[
                                    { value: 'Survey', text: 'Survey' },
                                    { value: 'Follow Up', text: 'Follow Up' }
                                ]}
                                component={CommonSelect}
                                placeholder="Type"
                                clearable
                            />

                            {/*<div style={{ marginBottom: '15x' }} > {}</div>*/}
                            {/*this.props.label ? <><span>Medium</span><div style={{ marginBottom: '3px' }} /></> : null*/}
                            {/*<Field
                                disabled={this.props.loading}
                                fluid
                                name="medium"
                                options={[]}
                                // searchable
                                component={CommonSelect}
                                options={[
                                    { value: 'Sms', text: 'Sms' },
                                    { value: 'Email', text: 'Email' },
                                    { value: 'Call', text: 'Call' },
                                    { value: 'Visit', text: 'Visit' }
                                ]}
                                placeholder="Medium"
                                clearable
                            />
                            */}

                            {this.props.label ? <><span>Requires Approval</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="requiresApproval"
                                options={[
                                    { value: true, text: 'Yes' },
                                    { value: false, text: 'No' }
                                ]}
                                component={CommonSelect}
                                placeholder="Requires Approval"
                                clearable
                            />
                            {this.props.label ? <><span>Assign To</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                searchable
                                name="userid"
                                options={this.props.assignTo}
                                component={this.renderDropDown}
                                placeholder="Assign To (Optional)"
                            // clearable
                            />
                            {this.props.label ? <><span>Form</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="formid"
                                searchable
                                component={this.renderDropDown}
                                options={this.props.myForms}
                                clearable
                                placeholder="Form (Optional)"
                            />

                            {this.props.label ? <><span>Date</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                name="date"
                                showTime={false}
                                placeholder="Date (Optional)"
                                component={renderDateTimePicker}
                                disabled={this.props.loading}
                            />

                            {this.props.label ? <><span>Sorting</span><div style={{ marginBottom: '3px' }} /></> : null}
                            {/* <div style={{ marginBottom: '10px' }} /> */}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="SortingParameter"
                                component={this.renderDropDown}
                                options={this.props.sortingParams}
                                clearable
                                placeholder="Sorting Parameters"
                            />
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="isAscending"
                                component={CommonSelect}
                                options={[
                                    { value: true, text: 'Yes' },
                                    { value: false, text: 'No' }
                                ]}
                                clearable
                                placeholder="Ascending Order"
                            />
                            {this.props.showMessage !== false ?
                                <Message
                                    error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                                    success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                                    header={this.props.Message.header}
                                    content={this.props.Message.text}
                                />
                                :
                                null
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <button form="action" type="button" onClick={reset}>
                            Clear Values
                        </button> */}
                        {this.props.actionsComponent}
                    </Modal.Actions>
                </Modal>


            </div>
        )
    }

}

var checkedStatus = false;

const renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) => {
    return (
        <div style={{ marginBottom: '15px' }}>
            <DateTimePicker
                dropUp
                onChange={onChange}
                format="DD-MM-YYYY"
                placeholder={placeholder || "Date"}
                time={showTime}
                value={!value ? null : new Date(value)}
            />
        </div>
    )
}

const renderCheckbox = ({ label }) => {
    console.log(label)
    return (
        <Checkbox
            label={label}
            onChange={toggle}
        />
    )
}
const toggle = () => {
    checkedStatus = !checkedStatus
    console.log(checkedStatus)
}

/*

let ActionForm = (this.props) => {

    return (
        <div>
            <Modal
                size={this.props.size}
                open={this.props.open}
                onClose={this.props.onCloseHandle}
            >
                <Header >
                    <Icon name={this.props.header.icon} />
                    {this.props.header.text}
                </Header>
                <Modal.Content>
                    <Form
                        id="actionsForm"
                        error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                        success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                        onSubmit={this.props.handleSubmit}
                        size='large'>
                        {this.props.label ? <><span>Tag</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="tag"
                            options={[
                                { value: 'Promise', text: 'Promise' },
                                { value: 'Reminder', text: 'Reminder' },
                                { value: 'Not Paying', text: 'Not Paying' },
                                { value: 'First Contact', text: 'First Contact' },
                                { value: 'BD Activity', text: 'BD Activity' }
                            ]}
                            component={CommonSelect}
                            placeholder="Tag"
                            clearable
                        />

                        {this.props.label ? <><span>Type</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="type"
                            options={[
                                // { value: 'Survey', text: 'Survey' },
                                { value: 'Follow Up', text: 'Follow Up' }
                            ]}
                            component={CommonSelect}
                            placeholder="Type"
                            clearable
                        />

                        <div style={{ marginBottom: '15x' }} > {}</div>
                        {this.props.label ? <><span>Medium</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="medium"
                            options={[]}
                            component={CommonSelect}
                            options={[
                                { value: 'Sms', text: 'Sms' },
                                { value: 'Email', text: 'Email' },
                                { value: 'Call', text: 'Call' },
                                { value: 'Visit', text: 'Visit' }
                            ]}
                            placeholder="Medium"
                            clearable
                        />

                        {this.props.label ? <><span>Requires Approval</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="requiresApproval"
                            options={[
                                //  { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                            ]}
                            component={CommonSelect}
                            placeholder="Requires Approval"
                            clearable
                        />
                        {this.props.label ? <><span>Assign To</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="userid"
                            options={this.props.assignTo}
                            component={CommonSelect}
                            placeholder="Assign To"
                            clearable
                        />
                        {this.props.label ? <><span>Form</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="formid"
                            component={CommonSelect}
                            options={this.props.myForms}
                            clearable
                            placeholder="Form"
                        />

                        {this.props.label ? <><span>Date</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            name="date"
                            showTime={false}
                            component={renderDateTimePicker}
                            disabled={this.props.loading}
                        />

                        {this.props.label ? <><span>Sorting</span><div style={{ marginBottom: '3px' }} /></> : null}
                        {/* <div style={{ marginBottom: '10px' }} /> }
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="SortingParameter"
                            component={CommonSelect}
                            options={this.props.sortingParams}
                            clearable
                            placeholder="Sorting Parameters"
                        />
                        <Field
                            disabled={this.props.loading}
                            fluid
                            name="isAscending"
                            component={CommonSelect}
                            options={[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                            ]}
                            clearable
                            placeholder="Ascending Order"
                        />
                        {this.props.Message !== null ?
                            <Message
                                error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                                success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                                header={this.props.Message.header}
                                content={this.props.Message.content}
                            />
                            :
                            null
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <button form="action" type="button" onChange={() => { reset('actionsForm') }} onClick={reset('actionsForm')}>
                        Clear Values
                    </button>
                    {this.props.actionsComponent}
                </Modal.Actions>
            </Modal>


        </div>
    )
}
*/

const valueCheck = (value, name, minLength = null) => {
    if (name === 'Require Approval' || name === 'Is Ascending?') {
        if (value === undefined)
            return `You must select ${name}`;
        else
            return
    }
    if (!value) {
        return `You must select ${name}`;
    }
    if (value == null) {
        return 'Please Enter/Select Value';
    }

}
const validate = (formValues) => {
    const errors = {};
    errors.tag = valueCheck(formValues.tag, 'Tag')
    errors.type = valueCheck(formValues.type, 'Type')
    //errors.medium = valueCheck(formValues.medium, 'Medium')
    errors.requiresApproval = valueCheck(formValues.requiresApproval, 'Require Approval')
    errors.SortingParameter = valueCheck(formValues.SortingParameter, 'Sorting Parameter')
    errors.isAscending = valueCheck(formValues.isAscending, 'Is Ascending?')
    return errors;
}


const warn = values => {
    const warnings = {}
    // warnings.medium = valueCheck(values.medium, 'medium');
    return warnings;
}




ActionFormVisit = reduxForm({
    form: 'action',
    validate,
    enableReinitialize: true
})(ActionFormVisit)

export default ActionFormVisit;