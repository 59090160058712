import React, { Component } from 'react';
import { Modal, Select, Button, Grid, Form, Dropdown, Checkbox as CBUI } from 'semantic-ui-react';
import { CommonInput, CommonSelect, CommonMultiSelect } from '../forms/commonInput'
import { Field, reduxForm, FieldArray, unregisterField } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
momentLocaliser(moment)


class CustomerFilterForm extends Component {
    state = {
        tags: null,
        company: null,
        name: [],
        region: [],
        city: [],
        Companytrackingid: [],
        AccountTag: [],
        Phonenumber: [],
        Cycleday: [],
        DefaulterCodes: [],
        submittedPayment: [
            { key: 'z', value: true, text: 'Yes' },
            { key: 'x', value: false, text: 'No' }
        ]
    }

    componentDidMount() {
        this.props.LoadCompaniesWithIds();
        //this.props.LoadAllCodes().then(() => { this.setState({ DefaulterCodes: this.props.allCodes }) })
        this.props.DropdownCustomerFilter('name')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('Cycleday')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('region')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('city')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('Addresstype')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('Phonenumber')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('AccountTag')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('Companytrackingid')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('username')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
        this.props.DropdownCustomerFilter('resulttag')
            .then(() => {
                this.getDropDownFields()
            })
            .catch(() => { })
    }

    renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder, label }) => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </>
                <DateTimePicker
                    onChange={onChange}
                    format="DD-MM-YYYY"
                    placeholder={placeholder}
                    time={showTime}
                    value={!value ? null : new Date(value)}
                />
            </>
        )
    }

    myField = (name, label, disabled, type = "text") => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </>
                <Field
                    disabled={disabled}
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder={label}
                    type={type}
                />
            </>
        )
    }

    myFieldFloat = (name, label, disabled, type = "number") => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </>
                <Field
                    disabled={disabled}
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder={label}
                    type={type}
                    step={0.01}
                />
            </>
        )
    }

    renderDropDown = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ company: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={this.props.companybyID}
                />
            </div>
        )
    }

    renderDropDowns = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ company: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={this.state.name}
                />
            </div>
        )
    }



    renderCompanyDropdown = () => {
        return (
            <>
                <Grid.Column width={3}>
                    {this.showLabel('Company')}
                    <Field
                        name='company'
                        onChange={this.onCompanyDropdownChange}
                        placeholder='Company'
                        component={this.renderDropDown}
                    />
                </Grid.Column>
            </>
        )
    }

    showLabel = (label) => {
        return (
            <>
                <span>{label}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
        )
    }

    renderFields = ({ fields }) => {
        // console.log(this.state)
        fields = this.props.tagKeys === null || this.props.tagKeys === undefined ? null : this.props.tagKeys
        // console.log(fields)
        return (
            fields === null || fields === undefined ? null :
                fields.map((member) => {
                    // console.log(member)
                    return (
                        <Grid.Column width={3}>
                            {this.showLabel(member.text)}
                            <Field
                                name={`${member}.text`}
                                placeholder={member.text}
                                type="text"
                                component={CommonInput}
                            //label="First Name"
                            />
                        </Grid.Column>
                    )
                })
        )

    }


    renderOtherDetailsFields = () => {
        // console.log(this.props.tagKeys)
        return (

            this.props.tagKeys === null || this.props.tagKeys === undefined ?
                null : this.props.tagKeys.map(x => {
                    return (
                        // <Grid.Column key={x.value} width={3}>
                        <Grid.Column width={2}>
                            {/* {this.showLabel(x.text)} */}
                            {this.rendeOtherCommonSelect(`${this.state.company}_${x.text}`, x.text, 
                                x.text.replace(/\s+/g, ''))}
                            {/* <Field
                                name={`${this.state.company}_${x.text}`}
                                placeholder={"Type in the " + x.text}
                                component='input'
                            /> */}
                        </Grid.Column>
                    )
                })
        )
    }

    renderCommonSelect = (name, label) => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </>
                <Field
                    fluid
                    name={name}
                    component={CommonMultiSelect}
                    clearable
                    selection
                    // onChange={(x) => console.log(x)}
                    placeholder={label}
                    options={this.state[name]}
                />
            </>
        )
    }

    rendeOtherCommonSelect = (name, label, access) => {
        //console.log(access)
        //console.log(this.state[access])
        // console.log(this.state.name)
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </>
                <Field
                    //fluid
                    name={name}
                    type="text"
                    component={CommonInput}
                    //clearable
                    //selection
                    // onChange={(x) => console.log(x)}
                    placeholder={label}
                    //options={this.state[access]}
                />
            </>
        )
    }

    getDropDownFields = () => {
        // console.log(this.props.dropdowns)
        if (this.props.dropdowns !== undefined && this.props.dropdowns !== null) {
            // console.log(this.props.dropdowns.field)
            this.setState({
                [this.props.dropdowns.field]: this.props.dropdowns.data
            })
        }
        // if(===)
    }

    /*loadOtherDetails = (keyVal) => {
        console.log(keyVal)
        keyVal.map(x => {
            this.props.DropdownCustomerFilter(x.text.replace(/\s+/g, ''))
                .then(() => {
                    this.getDropDownFields()
                })
                .catch(() => { })
        })
    }*/

    onCompanyDropdownChange = (value) => {
        //value === "" ? console.log('its null beta g') :
        // console.log(value)
        if (value === "") {
            console.log('its null')
        }

        this.props.LoadTagKeys(value)
            .then(x => {
                //this.loadOtherDetails(this.props.tagKeys)
                this.setState({
                    tags: this.props.tagKeys
                })
            })
    }

    renderCheckbox = ({ input, label }) => {
        return (
            <>
                <Form.Field>
                    <CBUI
                        label={label}
                        checked={input.value ? true : false}
                        onChange={(e, { checked }) => input.onChange(checked)}
                    />
                </Form.Field>
            </>
        )
    }

    renderCheckBoxUI = () => {
        return (
            <Field
                name="isRevoked"
                label="Revoked"
                fluid
                component={this.renderCheckbox}
            />
        )
    }

    renderAffirmDropdown = (name, label) => {
        return (
            <>
                {this.showLabel(label)}
                <Field
                    name={name}
                    placeholder={label}
                    fluid
                    component={CommonSelect}
                    options={[
                        { key: 'yes', value: true, text: 'Yes' },
                        { key: 'no', value: false, text: 'No' },
                    ]}
                    clearable
                />
            </>
        )
    }

    renderFlagDD = () => {
        return (
            <>
                {this.showLabel('Flag')}
                <Field
                    name='Flag'
                    placeholder='Flag'
                    fluid
                    component={CommonSelect}
                    options={[
                        { key: 'n', value: 'n', text: 'Normal' },
                        { key: 'f', value: 'f', text: 'Marked' },
                    ]}
                    clearable
                />
            </>
        )
    }

    renderFieldArray = () => <FieldArray name='otherDetails' component={this.renderFields} />

    renderForm = () => {
        // console.log(this.state)
        let z = 6;
        let l = 2;
        let m = 3;
        return (
            <Form onSubmit={this.props.handleSubmit} size='small'>
                <Grid>
                    <Grid.Column width={m}>
                        {/* {this.renderCommonSelect('name', 'Name')} */}
                        {this.myField('name', 'Name', false)}
                    </Grid.Column>
                    <Grid.Column width={z}>
                        {this.renderCommonSelect('region', 'Region')}
                        {/* {this.myField('region', 'Region', false)} */}
                    </Grid.Column>
                    <Grid.Column width={m}>
                        {this.renderCommonSelect('city', 'City')}
                        {/* {this.myField('city', 'City', false)} */}
                    </Grid.Column>
                    {/* <Grid.Column width={5}>
                        {this.renderCommonSelect('username', 'Username')}
                        //{{this.myField('city', 'City', false)} }
                    </Grid.Column> }
                     <Grid.Column width={m}>
                        {this.myField('companyName', 'Company', false)}
                    </Grid.Column> }
        */}
                    <Grid.Column width={4}>
                        {this.renderCommonSelect('resulttag', 'Result Tag')}
                        {/* {this.myField('city', 'City', false)}  */}
                    </Grid.Column>
                    <Grid.Column width={m - 1}>
                        {this.myField('balancelowerlimit', 'Min Balance', false)}
                    </Grid.Column>
                    <Grid.Column width={m - 1}>
                        {this.myField('balanceupperlimit', 'Max Balance', false)}
                    </Grid.Column>
                    <Grid.Column width={m}>
                        {/* {this.renderCommonSelect('Companytrackingid', 'Company Tracking Id')} */}
                        {this.myField('Companytrackingid', 'Company Tracking Id', false)}
                    </Grid.Column>
                    <Grid.Column width={m + 1}>
                        {this.renderCommonSelect('Addresstype', 'Cycle Day')}
                        {/* {this.myField('Addresstype', 'Cycle Day', false)} */}
                    </Grid.Column>
                    <Grid.Column width={m}>
                        {this.myField('Address', 'Address', false)}
                    </Grid.Column>
                    <Grid.Column width={m - 1}>
                        {/* {this.renderCommonSelect('Phonenumber', 'Phone Number')} */}
                        {this.myField('Phonenumber', 'Phone Number', false)}
                    </Grid.Column>
                    <Grid.Column width={z - 1}>
                        {this.renderCommonSelect('AccountTag', 'Account Tag')}
                        {/* {this.myField('AccountTag', 'Account Tag', false)} */}
                    </Grid.Column>
                    <Grid.Column width={m}>
                        {this.renderCompanyDropdown()}
                    </Grid.Column>
                    {this.renderOtherDetailsFields()}
                    {/* {this.renderFieldArray()} */}
                    <Grid.Column width={m - 1}>
                        {this.myFieldFloat('MinLastPaymentAmount', 'Min. Last Payment', false)}
                    </Grid.Column>
                    <Grid.Column width={m - 1}>
                        {this.myFieldFloat('MaxLastPaymentAmount', 'Max. Last Payment', false)}
                    </Grid.Column>
                    <Grid.Column width={m} >
                        {/* {myField('completedonfrom', 'Completed on From Date', false)} */}
                        <Field
                            name='minlastPaymentDate'
                            showTime={false}
                            component={this.renderDateTimePicker}
                            placeholder="Min. Last Payment Date"
                            label="Min. Last Payment Date"
                        />
                    </Grid.Column>
                    <Grid.Column width={m} >
                        {/* {myField('completedonto', 'Completed on Date To', false)} */}
                        <Field
                            name='maxlastPaymentDate'
                            showTime={false}
                            component={this.renderDateTimePicker}
                            placeholder="Max. Last Payment Date"
                            label="Max. Last Payment Date"
                        />
                    </Grid.Column>
                    <Grid.Column width={m-1}>
                        {this.renderAffirmDropdown("HasPhoneNumber", "Has Phone Number")}
                    </Grid.Column>
                    <Grid.Column width={m - 1}>
                        {this.renderFlagDD()}
                    </Grid.Column>
                    <Grid.Column width={m-1}>
                        {this.renderAffirmDropdown("ActionAssigned", "Action Assigned")}
                    </Grid.Column>
                    <Grid.Column width={m}>
                        {this.renderAffirmDropdown("ActionCompletedThisMonth", "Action Completed This Month")}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {this.renderAffirmDropdown('submittedPayment', 'Submitted Payment')}
                    </Grid.Column>
                    <Grid.Column width={m} >
                        <Field
                            name='submittedMinPaymentDate'
                            showTime={false}
                            component={this.renderDateTimePicker}
                            placeholder="Submitted Min Payment Date"
                            label="Submitted Min Payment Date"
                        />
                    </Grid.Column>
                    <Grid.Column width={m} >
                        <Field
                            name='submittedMaxPaymentDate'
                            showTime={false}
                            component={this.renderDateTimePicker}
                            placeholder="Submitted Max Payment Date"
                            label="Submitted Max Payment Date"
                        />
                    </Grid.Column>
                    <Grid.Column width={m-2}>
                        {this.showLabel("is Revoked?")}
                        {this.renderCheckBoxUI()}
                    </Grid.Column>
                </Grid>
                <div style={{ height: '15px' }} />
                {this.props.actionsComponent()}
                {/* <Button primary type="submit">Search with Filters</Button> */}
            </Form>
        )
    }

    render() {
        return (
            <>
                {this.renderForm()}
            </>
        )
    }
}
const validate = (formValues) => {
    console.log(formValues)
}


const mstp = (state) => {
    // console.log(state.customer)
    return ({
        companybyID: state.company.companyWithIds,
        tagKeys: state.customer.tagKeys,
        dropdowns: state.customer.Dropdowns,
        allCodes: state.customer.allCodes
    })
}

CustomerFilterForm = reduxForm({
    form: 'customerFilter',
    enableReinitialize: true,
    validate
})(CustomerFilterForm)

export default connect(mstp, actions)(CustomerFilterForm)

//export default CustomerFilterForm;

