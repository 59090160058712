import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { Form, Message, Modal, Header, Icon, Grid, Button, Segment, Dropdown, GridColumn, Table } from 'semantic-ui-react'
import * as actions from '../../actions'
import { CommonInput, CommonSelect, CommonMultiSelect } from './commonInput';
import NewFormFieldForm from './newFormFieldForm'
import Datatable from '../../components/dataTable';
import { fdatasync } from 'fs';
import { ConfirmPermissionChange } from '../../actions';


class FormEditForm extends Component {
    // caption, minlength, maxlength, datatype, type, fieldvalues

    state = {
        formID: null,
        formName: '',
        formNameError: null,
        fieldList: [],
        fieldIdCounter: 0,
        loading: false,
        selectedField: null,
        newFieldFormMessage: null,
        noFieldsError: false,
        oldForm: null,
        sequenceOptions: []
    }

    loadValuesForEdit = (data) => {
        let innerCount = this.state.fieldIdCounter;
        const newFieldList = data.formFields.map(x => {
            const { caption, datatype, minLength, maxLength, type, fieldValues, sequenceNumber } = x
            return (
                {
                    caption,
                    datatype,
                    type,
                    minlength: minLength,
                    maxlength: maxLength,
                    fieldvalues: fieldValues.map(y => y.value).join(','),
                    sequenceNumber: sequenceNumber,
                    id: ++innerCount
                }
            )

        })
        this.setState({
            fieldIdCounter: innerCount,
            formID: data.id,
            formName: data.name,
            fieldList: newFieldList
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== undefined && nextProps.data !== null) {
            this.loadValuesForEdit(nextProps.data);
            let array = nextProps.data.formFields.map(x => {
                return { value: x.sequenceNumber, text: x.sequenceNumber }
            })
            this.setState({ oldForm: nextProps.data, sequenceOptions: array });
        }
    }


    myField = (name, label, disabled, type = "text") => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '3px' }} />
                </>
                <Field
                    disabled={disabled}
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder={label}
                    type={type}
                />
            </>
        )
    }

    renderActionsButton = (values) => {
        return (
            <>
                <Button size="mini" color="green" icon
                    onClick={() => this.setState({ selectedField: values })}
                >
                    <Icon name="edit" />
                </Button>
                <Button size="mini" color="red" icon
                    onClick={() => {
                        let array = this.state.fieldList;
                        let num = values.sequenceNumber
                        console.log(values)
                        array.map(obj => {
                            if (obj.sequenceNumber > num) {
                                obj.sequenceNumber--
                            }
                        })
                        array = array.filter(x => x.id !== values.id)
                        let seqOpt = array.map(Obj => {
                            return { value: Obj.sequenceNumber, text: Obj.sequenceNumber }
                        })
                        this.setState({ fieldList: array, sequenceOptions: seqOpt })
                    }}
                >
                    <Icon name="x" />
                </Button>
            </>
        )
    }

    // formatFormData = () => {
    //     const formattedForm = {...this.state.fieldList};
    //     var result = Object.keys(formattedForm).map(function(key) {
    //         const newObj = {...formattedForm[key]}
    //         delete newObj.id
    //         if (newObj.fieldvalues !== undefined){
    //             const fieldvaluesArray = newObj.fieldvalues.split(',');
    //             const fieldvaluesObject = []
    //             fieldvaluesArray.map(x => {
    //                 fieldvaluesObject.push({"value":x})
    //                 return null;
    //             })
    //             newObj.fieldvalues = fieldvaluesObject
    //         }

    //         return newObj;
    //     });
    //     this.finalizeFormCreation(this.state.formName,result)
    // }

    returnListOfEditedFields = ({ formFields }, newList) => {
        const oldList = formFields
        const sharedFieldNames = oldList.filter(x => newList.map(y => y.caption).includes(x.caption)).map(z => z.caption)
        const EditedFields = []
        sharedFieldNames.forEach(x => {
            let oldField = oldList.filter(y => y.caption === x)[0]
            let newField = newList.filter(y => y.caption === x)[0]
            for (let x of Object.keys(newField)) {
                if (x === 'maxlength') {
                    if (oldField['maxLength'] !== newField['maxlength']) {
                        EditedFields.push({ id: oldField.id, caption: newField.caption })
                        break;
                    }
                } else if (x === 'minlength') {
                    if (oldField['minLength'] !== newField['minlength']) {
                        EditedFields.push({ id: oldField.id, caption: newField.caption })
                        break;
                    }
                } else if (x === 'fieldvalues') {
                    const oldFieldString = oldField['fieldValues'].map(x => x.value);
                    if (newField['fieldvalues'] !== oldFieldString.join()) {
                        EditedFields.push({ id: oldField.id, caption: newField.caption })
                        break;
                    }
                } else {
                    if ((oldField[x] !== newField[x]) && x !== 'id') {
                        EditedFields.push({ id: oldField.id, caption: newField.caption })
                        break;
                    }
                }
            }
        })
        return EditedFields
    }

    evaluateFormChanges = () => {

        let changes = {
            formName: this.props.data.name !== this.state.formName ? this.state.formName : false,
            fieldsToDelete: this.props.data.formFields.filter(x => !this.state.fieldList.map(y => y.caption).includes(x.caption)),
            fieldsToAdd: this.state.fieldList.filter(x => !this.props.data.formFields.map(y => y.caption).includes(x.caption)),
            fieldsToEdit: this.returnListOfEditedFields(this.state.oldForm, this.state.fieldList)
        }
        console.log(changes.fieldsToEdit)
        changes.fieldsToEdit.filter(x => !changes.fieldsToAdd )
        console.log(changes)
        this.setState({ loading: true })
        this.props.editForm(this.state.oldForm, changes, this.state.fieldList)
            .then(x => {
                this.props.updateForm()
                    .then(x => {
                        this.setState({ loading: false })
                    })
                    .catch(x => {
                        this.setState({ loading: false })
                    })
            })
            .catch(x => {
                this.setState({ loading: false })
                //do nothing
            })
    }

    // finalizeFormCreation = (formName,fields) => {
    //     if (!/\S/.test(formName)){
    //         this.setState({formNameError:'You must enter a name for the form', formName:''})
    //     } else {
    //         formName = formName.trim()
    //         if (fields.length !== 0) {
    //             this.setState({loading: true})
    //             this.props.createForm(formName,fields)
    //             .then(x => {
    //                 this.props.LoadForms();
    //                 this.setState({loading: false,formName: '',fieldList:[]})
    //             })
    //             .catch(x => this.setState({loading: false}))
    //         }
    //     }

    // }

    renderFormActions = () => {
        return (
            <>
                <Button
                    onClick={() => { this.setState({ formNameError: null, noFieldsError: false, formName: '', fieldList: [] }); if (!this.state.loading) this.props.onClose() }}
                    disabled={this.state.loading}
                >
                    Close
                </Button>
                <Button
                    primary
                    onClick={() => this.evaluateFormChanges()}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                >
                    Confirm Form Edit
                </Button>
            </>
        )

    }

    changeSeqNum = (object) => {
        console.log(object)
        let old;
        let newVal = object.value
        let array = this.state.fieldList


        array.map(obj => {
            if (obj.caption === object.text) {
                old = obj.sequenceNumber;
            }
        })
        console.log(old)
        let newArray = []
        if (old > newVal) {
            newArray = array.map(obje => {
                if (obje.sequenceNumber >= newVal && obje.sequenceNumber < old) {
                    obje.sequenceNumber++
                }
                return obje
            })
            newArray.map(item => {
                if (item.caption === object.text) {
                    item.sequenceNumber = newVal
                }
            })
        } else {
            newArray = array.map(item => {
                if (item.sequenceNumber > old && item.sequenceNumber <= newVal) {
                    item.sequenceNumber--;
                }
                return item
            })
            newArray.map(item => {
                if (item.caption === object.text) {
                    item.sequenceNumber = newVal
                }
            })
        }
        console.log(newArray)

        this.setState({
            fieldList: newArray
        })
    }

    renderNewFieldTable = () => {
        // console.log(this.state.fieldList)
        return (
            this.state.fieldList.length !== 0 ?
                <Segment
                    style={{ height: '100%' }}
                >
                    <Datatable
                        tableSize="small"
                        header={[
                            {
                                name: 'Caption',
                                accessor: 'caption'
                            },
                            {
                                name: 'Max Length',
                                accessor: 'maxlength'
                            },
                            {
                                name: 'Min Length',
                                accessor: 'minlength'
                            },
                            {
                                name: 'Data Type',
                                accessor: 'datatype'
                            },
                            {
                                name: 'Input Type',
                                accessor: 'type'
                            },
                            {
                                name: 'Dropdown Options',
                                accessor: 'fieldvalues'
                            },
                            {
                                name: 'Sequece',
                                accessor: 'sequenceNumber',
                                component: (x, key) => {
                                    return (
                                        <Dropdown
                                            key={key}
                                            fluid
                                            selection
                                            value={x.sequenceNumber}
                                            name={'seqNum'}
                                            onChange={(e, { value }) => this.changeSeqNum({ text: x.caption, value })}
                                            options={this.state.sequenceOptions}
                                        />
                                    )
                                }
                            }
                        ]}
                        actionsFirst
                        actions={(x) => this.renderActionsButton(x)}
                        data={this.state.fieldList}
                        noPagination
                        overflowX
                    />
                </Segment>
                :
                <Segment
                    style={
                        {
                            height: '100%',
                            // textAlign: 'center',
                            // verticalAlign: 'middle'
                        }
                    }
                >
                    <div
                        style={
                            {
                                fontSize: '2rem',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translateX(-50%) translateY(-50%)'
                            }
                        }
                    >
                        {this.state.noFieldsError ?
                            <span style={{ color: 'red' }}>
                                No fields provided
                        </span>
                            :
                            "No fields added yet"
                        }
                    </div>
                </Segment>
        )
    }

    addNewFieldToList = (values) => {
        const newValues = { ...values }
        if (newValues.fieldvalues !== undefined) {
            newValues.fieldvalues = newValues.fieldvalues.split(',').filter(x => /\S/.test(x)).join(',')
        }
        let seqOpt = this.state.sequenceOptions
        let last = seqOpt.length + 1
        seqOpt.push({ text: last, value: last })
        this.setState({
            fieldIdCounter: this.state.fieldIdCounter + 1,
            fieldList: [...this.state.fieldList,
            { id: this.state.fieldIdCounter + 1, ...newValues, sequenceNumber: last }],
            sequenceOptions: seqOpt
        })
    }

    fieldNameExistenceCheck = (values) => {
        return this.state.fieldList.filter(x => x.caption === values.caption).length === 0 ? false : true
    }

    newFormFieldHandler = (values) => {
        console.log(values)
        this.setState({ newFieldFormMessage: null })
        if (this.state.selectedField !== null) {
            const newFieldList = this.state.fieldList.map(x => {
                if (x.caption !== values.caption) {
                    return x
                } else {
                    if (values.type === 'dropdown') {
                        delete values['datatype']
                        delete values['maxlength']
                        delete values['minlength']
                    }
                    if (values.type === 'textField') {
                        delete values['fieldvalues']
                    }
                    return values
                }
            })
            this.setState({ fieldList: newFieldList, selectedField: null })
        } else {
            if (!this.fieldNameExistenceCheck(values)) {
                this.addNewFieldToList(values)
            } else {
                this.setState({ newFieldFormMessage: `Field by the name '${values.caption}' already exists!` })
            }
        }
    }

    renderNewFieldAdditionForm = () => {
        return (
            <>
                <Header as='h5' attached='top'>
                    {this.state.selectedField === null ? 'Add New Field to Form' : `Editing The Field: '${this.state.selectedField.caption}'`}
                </Header>
                <Segment attached="bottom">
                    <NewFormFieldForm
                        Message={{
                            type: 'error',
                            header: this.state.newFieldFormMessage
                        }}
                        checkExistence={(x) => this.fieldNameExistenceCheck(x)}
                        clearMessage={() => this.setState({ newFieldFormMessage: null })}
                        onSubmit={(values) => this.newFormFieldHandler(values)}
                        loading={this.state.loading}
                        initialValues={this.state.selectedField}
                        cancelEditHandler={() => this.setState({ selectedField: null })}
                    />
                </Segment>
            </>
        )
    }

    renderFormMessage = () => {
        return (
            this.props.Message !== null ?
                <Message
                    error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                    success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                    header={this.props.Message.header}
                    content={this.props.Message.content}
                />
                :
                null
        )
    }

    renderFormNameInput = () => {
        return (
            <>
                Form Name
                <CommonInput
                    disabled={this.state.loading}
                    size="massive"
                    placeholder="Form Name"
                    fluid
                    value={this.state.formName}
                    onChange={(e) => {
                        if (e.target.value !== '' && this.state.formNameError !== null) {
                            this.setState({ formNameError: null })
                        }
                        if (e.target.value === '') {
                            this.setState({ formNameError: 'You must enter a name for the form' })
                        }
                        this.setState({ formName: e.target.value })
                    }}
                    onBlur={(e) => {
                        if (this.state.formName === '') {
                            this.setState({ formNameError: 'You must enter a name for the form' })
                        } else {
                            this.setState({ formNameError: null })
                        }
                    }}
                    errorMessage={this.state.formNameError}
                />
            </>
        )
    }



    renderEditModal = () => {
        return (
            <Modal
                size={this.props.size}
                open={this.props.open}
                onClose={() => { this.setState({ formNameError: null, noFieldsError: false, formName: '', fieldList: [] }); if (!this.state.loading) this.props.onClose() }}
                style={{ width: '90%' }}
            >
                <Header>
                    <Icon name='edit' />
                    Edit Form [Form ID: {this.props.data !== null ? this.props.data.id : 'Loading...'}]
                </Header>
                <Modal.Content>
                    <>
                        <Grid>
                            <Grid.Column width={7}>
                                {this.renderFormNameInput()}
                                {this.renderNewFieldAdditionForm()}
                                {this.renderFormMessage()}
                            </Grid.Column>
                            <Grid.Column width={9}>
                                {this.renderNewFieldTable()}
                            </Grid.Column>
                        </Grid>
                    </>
                </Modal.Content>
                <Modal.Actions>
                    {this.renderFormActions()}
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        return (
            this.renderEditModal()
        )
    }
}

export default connect(null, actions)(FormEditForm);