import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import App from './components/App';
import reducers from './reducers';

import 'semantic-ui-css/semantic.min.css'

const composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

ReactDOM.render(
    <Provider store = {createStore(reducers,composeEnchancers(applyMiddleware(reduxThunk)))}>
            <App />
    </Provider>
, document.getElementById('root'));


// "apiURL": "http://izak10-testing.eastus.cloudapp.azure.com/api/"