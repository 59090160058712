import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Label,
  Popup,
  Header,
  Segment,
  Modal,
  Tab,
} from "semantic-ui-react";
import DataTable from "../components/dataTable";
import DataTableCustomer from "../components/dataTableCutomers";
import { connect } from "react-redux";
import { reset } from "redux-form";
import * as actions from "../actions";
import UserForm from "../components/forms/userForm";
import LoaderView from "../components/loaderView";
import ConfirmBox from "../components/confirmBox";
import UnauthorizedView from "../components/unauthorizedView";
import DateInputForm from "../components/forms/datesInputForm";
import DateMonthYearForm from "../components/forms/monthYearDateForm";
import UserFilterForm from "../components/forms/userFilterForm";
import Attendance from "../pages/attendance";
import PasswordPrompt from "../components/forms/passwordPrompt";
import PassInputForm from "../components/forms/passPrompt";
import "react-widgets/dist/css/react-widgets.css";

//import axios from 'axios'

import moment from "moment";
import momentLocaliser from "react-widgets-moment";

momentLocaliser(moment);

const apiURL = require("../config.json").apiURL;

class Users extends Component {
  state = {
    loading: false, //
    showDeleteConfirmation: false,
    deleteLoading: false,
    userOnDelete: null,
    activityLoading: [], // this is an array, in which we append user ID. For each user, row. If we press activity toggle. The button loads until verifaction from server
    selectedUser: null, // this is the variable for the selected user, it is either null or contains user object
    displayUser: false, // this is the boolean for displaying a user, if true edit box will not open
    access: false, // this is the boolean for the access check
    loaded: false, // this is the boolean for the loading check, checking is data has arrived
    loadTable: false, // this is the boolean for the loading in the dataTable
    searchParameter: {}, // this is the object for the search parameters
    triggerUpdate: 0, // this a int for triggering update in the datatables
    filterShow: false, // this is the boolean for showing the filter box
    downloadShow: false,
    performanceReport: false,
    attendanceReport: false,
    selectedIds: [],
    selectAll: false,
    checked: false,
    getPasswordPrompt: false,
    downloadAttendenceShow: false,
    currentPage: 1,
    selectedTab: localStorage.getItem("selectedUserTab") ?? "Active",
    selectedTabIndex: localStorage.getItem("selectedUserTabIndex") ?? 0,
  };

  componentDidMount() {
    this.props.LoadProfile();
    this.props.LoadAllUsersWithID();
    this.props.LoadCompaniesWithIds();
    this.props.LoadRegionsWithIds();
    this.props.LoadRoles(); // This is called from the actions/roleaction files.
    // this will load roles in the redux store, it has no paging
    this.props.LoadUserDefaulterCodes();
  }

  // Below function is called by the datatable component
  // It is called when the table is loaded or when the pages is changed
  // it is also called when the trigger props in the datatable is updated
  tablePaging = (pageSize, currentPage) => {
    console.log(this.state.selectedTab);
    console.log(this.state.selectedTabIndex);
    // as soon as data is requested, the loadTable is set true. This is passed into the datatable as a prop
    this.setState({ loadTable: true, currentPage: currentPage });
    // this is called from actions/useraction. Paging is enabled. The below function is a promise.
    // When the user data is loaded the promise is processed
    // 3 parameters are passed in the below function
    this.props
      .LoadUsers(
        pageSize,
        currentPage,
        this.state.searchParameter,
        this.state.selectedTab
      )
      // in case of success, meaning data has been loaded, the code .then() is run
      .then((x) =>
        this.setState({ loaded: true, access: true, loadTable: false })
      )
      // in case of failure, access is set false, meaning user doesn't have access to the data
      .catch((x) => this.setState({ loaded: true, access: false }));
  };

  // runs before the component is unloaded
  componentWillUnmount() {
    // once components is going to be unloaded, the access is set true and the loaded is set false
    this.setState({ loaded: false, access: true });
  }

  selectAllCB = () => {
    var data;
    var arr = this.state.selectedIds;

    if (!this.state.selectAll) {
      data = this.props.data.map((x) => x.id);
      arr = arr.concat(data);
      var set = new Set(arr);
      arr = Array.from(set);
      this.setState({
        selectedIds: arr,
        selectAll: true,
        checked: true,
      });
    } else if (this.state.selectAll) {
      data = this.props.data.map((x) => x.id);

      arr = arr.filter((item) => !data.includes(item));
      this.setState({
        selectedIds: arr,
        selectAll: false,
        checked: false,
      });
    }
  };

  handleCheckbox = (value) => {
    let arr = this.state.selectedIds;
    let ch = this.state.selectedIds.includes(value.id);
    if (ch) {
      var arr1 = arr.filter((n) => n !== value.id);
      this.setState({
        selectedIds: arr1,
        // resetCB: false
      });
    } else {
      arr.push(value.id);
      this.setState({
        selectedIds: arr,
        // resetCB: false
      });
    }
    this.handlechecked();
  };

  renderCheckbox = (value) => {
    var check = this.state.selectedIds.includes(value.id);
    return (
      <Checkbox checked={check} onClick={() => this.handleCheckbox(value)} />
    );
  };

  handlechecked = () => {
    var data = this.props.data.map((x) => x.id);
    var global = this.state.selectedIds;
    var check = data.every((element) => global.includes(element));
    this.setState({
      checked: check,
      selectAll: check,
    });
  };

  rolesActionArray = (newRoles) => {
    const previousRoles = this.state.selectedUser.roles;
    const additions = newRoles
      .map((x) => {
        if (!previousRoles.includes(x)) return x;
        return null;
      })
      .filter(Number);
    const deletions = previousRoles
      .map((x) => {
        if (!newRoles.includes(x)) return x;
        return null;
      })
      .filter(Number);
    // console.log(additions)
    // console.log(deletions)
    return {
      additions,
      deletions,
    };
  };

  companiesActionArray = (newCompanies) => {
    // console.log(this.state.selectedUser);
    const previousCompanies = this.state.selectedUser.companies;
    const additions = newCompanies
      .map((x) => {
        if (!previousCompanies.includes(x)) return x;
        return null;
      })
      .filter(Number);
    const deletions = previousCompanies
      .map((x) => {
        if (!newCompanies.includes(x)) return x;
        return null;
      })
      .filter(Number);
    return {
      additions,
      deletions,
    };
  };

  regionsActionArray = (newRegions) => {
    const previousRegions = this.state.selectedUser.regions;
    const additions = newRegions
      .map((x) => {
        if (!previousRegions.includes(x)) return x;
        return null;
      })
      .filter(Number);
    const deletions = previousRegions
      .map((x) => {
        if (!newRegions.includes(x)) return x;
        return null;
      })
      .filter(Number);
    return {
      additions,
      deletions,
    };
  };

  codesActionArray = (newCodes) => {
    // console.log(newCodes)
    const prevCodes = this.state.selectedUser.defaulterCodesIds;
    const additions = newCodes
      .map((x) => {
        if (!prevCodes.includes(x)) return x;
        return null;
      })
      .filter(Number);
    // console.log(additions)
    const deletions = prevCodes
      .map((x) => {
        if (!newCodes.includes(x)) return x;
        return null;
      })
      .filter(Number);
    // console.log(deletions)
    // console.log(prevCodes)
    return { additions, deletions };
  };

  superiorsActionArray = (newSuperiors) => {
    const previousSuperiors = this.state.selectedUser.superiorsIds;
    const additions = newSuperiors
      .map((x) => {
        if (!previousSuperiors.includes(x)) return x;
        return null;
      })
      .filter(Number);
    const deletions = previousSuperiors
      .map((x) => {
        if (!newSuperiors.includes(x)) return x;
        return null;
      })
      .filter(Number);
    return {
      additions,
      deletions,
    };
  };

  handleSubmit = (values) => {
    // console.log(values)
    const rolesAction = this.rolesActionArray(values.roles);
    const companiesAction = this.companiesActionArray(values.companies);
    const regionsAction = this.regionsActionArray(values.regions);
    const superiorsAction = this.superiorsActionArray(values.superiorsIds);
    const codesAction = this.codesActionArray(values.defaulterCodesIds);
    var dataChanges = false;
    for (var k in values) {
      if (
        k !== "roles" ||
        k !== "companies" ||
        k !== "regions" ||
        k !== "superiorsIds"
      )
        if (values[k] !== this.state.selectedUser[k]) {
          dataChanges = true;
          break;
        }
    }

    this.setState({ loading: true });
    this.props
      .EditUser(
        values,
        dataChanges,
        rolesAction,
        companiesAction,
        regionsAction,
        superiorsAction,
        codesAction,
        this.props.pageSize,
        this.props.pageNumber
      )
      .then((x) => {
        this.setState({
          loading: false,
          triggerUpdate: this.state.triggerUpdate + 1,
          selectedUser: null,
        });
        // console.log(values)
        // this.editUserInit(values)
        // this.props.data.forEach(x => {
        //   if (x.id = values.Id) {
        //     console.log(x)
        //     this.editUserInit(x)
        //   }
        // })
        this.props.LoadUserDefaulterCodes();
      })
      .catch((x) => this.setState({ loading: false }));
  };

  renderActivityButton = (id, username, activeStatus, activityProcessing) => {
    let color = "grey";
    let iconName = "circle outline";
    let text = "inactive";
    if (Boolean(activeStatus)) {
      color = "green";
      iconName = "circle";
      text = "active";
    }
    return (
      <Popup
        content={`${username} is ${text}. Click to toggle activity status`}
        position="top right"
        on="hover"
        trigger={
          <Button
            loading={activityProcessing}
            disabled={activityProcessing}
            size="mini"
            color={color}
            icon={iconName}
            onClick={() => this.activityToggle(id, activeStatus)}
          />
        }
      />
    );
  };

  renderDeleteConfirmationBox = () => {
    return this.state.userOnDelete !== null ? (
      <ConfirmBox
        loading={this.state.deleteLoading}
        open={this.state.showDeleteConfirmation}
        header="Confirm user deletion"
        content={`You are deleting user with id: ${this.state.userOnDelete.id} and name: ${this.state.userOnDelete.firstname} ${this.state.userOnDelete.lastname}. Deletion cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() => this.setState({ showDeleteConfirmation: false })}
        onConfirm={() => this.handleUserDeletion()}
      />
    ) : null;
  };

  handleUserDeletion = () => {
    this.setState({ deleteLoading: true });
    this.props
      .DeleteUser(this.state.userOnDelete.id)
      .then((x) =>
        this.setState({
          deleteLoading: false,
          showDeleteConfirmation: false,
          triggerUpdate: this.state.triggerUpdate + 1,
        })
      )
      .catch((x) => this.setState({ deleteLoading: false }));
  };

  activityToggle = (id, activeStatus) => {
    this.setState({ activityLoading: [...this.state.activityLoading, id] });
    this.props
      .ToggleUserActivity(id, activeStatus)
      .then((x) => {
        this.setState({
          activityLoading: this.state.activityLoading.filter(
            (x) => x.id === id
          ),
        });
      })
      .catch((x) => {
        this.setState({
          activityLoading: this.state.activityLoading.filter(
            (x) => x.id === id
          ),
        });
      });
  };

  // this is the delete handler, for deleting users
  deleteUserHandler = (id) => {
    this.setState({});
  };

  editUserInit = (value) => {
    console.log(value);
    const userForEdit = {
      Id: value.id,
      Firstname: value.firstname,
      Lastname: value.lastname,
      Cnic: value.cnic,
      Contactnum: value.contactNum,
      Email: value.email,
      isactive: value.isActive,
      userid: value.reportToId,
      roles: value.roles.map((x) => x.id),
      regions: value.regions.map((x) => x.id),
      companies: value.companies.map((x) => x.id),
      superiorsIds: value.superiorsIds,
      defaulterCodesIds: value.defaulterCodesIds,
      defaulterCodes: value.defaulterCodes,
    };
    this.setState({
      selectedUser: userForEdit,
    });
  };

  returnEditBoxActionsComponent = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          basic
          color="red"
          onClick={() => this.setState({ selectedUser: null })}
        >
          <Icon name="remove" />
          Cancel Edit
        </Button>
        <Button
          disabled={this.state.loading}
          loading={this.state.loading}
          form="userForm"
          color="green"
          type="submit"
        >
          <Icon name="checkmark" />
          Confirm Edit
        </Button>
      </>
    );
  };

  copyArray = (mainArray) => {
    const newArray = [];
    mainArray.forEach((element) => {
      newArray.push({ value: element.value, text: element.text });
    });

    return newArray;
  };

  renderEditBox = () => {
    if (this.state.displayUser === false) {
      const uid =
        this.state.selectedUser !== null ? this.state.selectedUser.Id : null;
      const roleOptions = this.props.roles.map((x) => {
        return { value: x.id, text: x.type };
      });
      let defaulterCodes =
        this.state.selectedUser !== null
          ? this.state.selectedUser.defaulterCodes
          : [];
      let codeOptions = this.copyArray(
        this.props.codes !== undefined ? this.props.codes : []
      );
      if (defaulterCodes.length !== 0) {
        // console.log('EEnter')
        // console.log(defaulterCodes)
        let codeOptionsIds = codeOptions.map((x) => x.value);
        defaulterCodes.forEach((element) => {
          if (!codeOptionsIds.includes(element.id)) {
            console.log("Entere insdide if");
            codeOptions.push({ value: element.id, text: element.code });
            console.log(codeOptions);
          }
        });
      }

      // codeOptions = Array.from(new Set(codeOptions))
      // console.log(codeOptions)
      return (
        <UserForm
          uid={uid}
          size="small"
          open={
            this.state.selectedUser !== null &&
            this.state.downloadShow === false
          }
          onCloseHandle={() => {
            this.setState({ selectedUser: null });
          }}
          header={{ icon: "edit", text: "Edit User with ID: " + uid }}
          actionsComponent={this.returnEditBoxActionsComponent()}
          label={true}
          onSubmit={this.handleSubmit}
          loading={this.state.loading}
          Message={null}
          initialValues={this.state.selectedUser}
          reportToOptions={this.props.userbyID}
          roleOptions={roleOptions}
          companyOptions={this.props.companybyID}
          regionOptions={this.props.regionbyID}
          codeOptions={codeOptions}
        />
      );
    } else return null;
  };

  actionsButton = (value) => {
    // console.log(value)
    // console.log(window.localStorage.getItem('user'))
    // window.localStorage.setItem('user', 1)
    // console.log(window.localStorage.getItem('user'))
    const activityProcessing = this.state.activityLoading.includes(value.id);
    return (
      <>
        {this.renderActivityButton(
          value.id,
          value.username,
          value.isActive,
          activityProcessing
        )}
        {/* <Button
          size="mini"
          color="teal"
          icon="download"
          onClick={() => {
            this.setState({ downloadShow: true, selectedUser: value });
          }}
        /> */}
        <Button
          size="mini"
          color="black"
          icon
          disabled={activityProcessing}
          onClick={() => {
            this.setState({ displayUser: true, selectedUser: value });
          }}
        >
          <Icon name="search" />
        </Button>
        <Button
          size="mini"
          color="blue"
          icon
          disabled={activityProcessing}
          onClick={() => {
            // console.log(this.state.selectedUser)
            this.editUserInit(value);
          }}
        >
          <Icon name="edit" />
        </Button>
        <Button
          size="mini"
          color="red"
          icon
          disabled={activityProcessing}
          onClick={() =>
            this.setState({ showDeleteConfirmation: true, userOnDelete: value })
          }
        >
          <Icon name="x" />
        </Button>
      </>
    );
  };

  renderTable = () => {
    return (
      <DataTableCustomer
        header={[
          {
            name: "ID",
            accessor: "id",
          },
          {
            name: "Username",
            accessor: "username",
          },
          {
            name: "Full Name",
            accessor: "firstname lastname",
          },
          {
            name: "CNIC",
            accessor: "cnic",
          },
          {
            name: "Contact Number",
            accessor: "contactNum",
          },
          {
            name: "Email Address",
            accessor: "email",
          },
          {
            name: `Company`,
            accessor: "companies",
            component: (x, parentKey) => (
              <Label style={{ marginBottom: "3px" }} key={parentKey + x.id}>
                {x.name}
              </Label>
            ),
          },
          {
            name: `Codes`,
            accessor: "defaulterCodes",
            component: (x, parentKey) => (
              <Label style={{ marginBottom: "3px" }} key={parentKey + x.id}>
                {x.code}
              </Label>
            ),
          },
          {
            name: `Region`,
            accessor: "regions",
            component: (x, parentKey) => (
              <Label style={{ marginBottom: "3px" }} key={parentKey + x.id}>
                {x.name}
              </Label>
            ),
          },
          {
            name: "Roles",
            accessor: "roles",
            component: (x, parentKey) => (
              <Label style={{ marginBottom: "3px" }} key={parentKey + x.id}>
                {x.type}
              </Label>
            ),
          },
          {
            name: `Superiors`,
            accessor: "superiors",
            component: (x, parentKey) => (
              <Label
                style={{ marginBottom: "3px" }}
                key={parentKey + x.superiorId}
              >
                {x.superiorName}
              </Label>
            ),
          },
        ]}
        checkbox={(x) => this.renderCheckbox(x)}
        selectAll={() => this.selectAllCB()}
        allselected={this.state.checked}
        actions={(x) => this.actionsButton(x)}
        loading={this.state.loadTable}
        show={this.state.access && this.state.loaded}
        rowCount={this.props.total}
        data={this.props.data}
        pageSizes={[10, 20, 50, 100]}
        trigger={this.state.triggerUpdate}
        onPageChange={(x, y) => this.tablePaging(x, y)}
        tableSize={"small"}
        compact
        // collapsing
        overflowX
        currentPage={this.state.currentPage}
        localstorage={"userPage"}
      />
    );
  };

  renderFilterButton = () => {
    return (
      <div>
        <Button
          size="mini"
          primary={!this.state.filterShow}
          onClick={() => {
            this.setState({ filterShow: !this.state.filterShow });
            if (
              !this.state.filterShow === false &&
              JSON.stringify(this.state.searchParameter) !== "{}"
            ) {
              this.setState({ searchParameter: {} }, () =>
                this.setState({ triggerUpdate: this.state.triggerUpdate + 1 })
              );
            }
          }}
        >
          {!this.state.filterShow ? "Search Users" : "Hide Filter Box"}
        </Button>
      </div>
    );
  };

  renderPerformanceButton = () => {
    return (
      <div>
        <Button
          //   primary={false}
          disabled={this.state.selectedIds.length === 0}
          size="mini"
          icon="download"
          floated="right"
          color="teal"
          fluid
          onClick={() => {
            this.setState({
              downloadShow: true,
              performanceReport: true,
            });
          }}
          content="Performance"
        />
      </div>
    );
  };

  renderAttendanceButton = () => {
    return (
      <div>
        <Button
          //   primary={false}
          disabled={this.state.selectedIds.length === 0}
          size="mini"
          icon="download"
          floated="right"
          color="teal"
          fluid
          onClick={() => {
            this.setState({
              downloadAttendenceShow: true,
              attendanceReport: true,
            });
          }}
          content="Attendance"
        />
      </div>
    );
  };

  renderPageActions = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <Grid>
          <Grid.Column width={10}>{this.renderFilterButton()}</Grid.Column>
          <Grid.Column width={3}>{this.renderPerformanceButton()}</Grid.Column>
          <Grid.Column width={3}>{this.renderAttendanceButton()}</Grid.Column>
        </Grid>
      </div>
    );
  };

  renderFilterBox = () => {
    return (
      <>
        {/* <Header as='h3' attached='top'>
                    Filter Users */}
        {/* <Button
                        primary
                        floated="right" size="mini"
                        onClick={() => {
                            this.setState({ filterShow: !this.state.filterShow })
                            if (!this.state.filterShow === false && JSON.stringify(this.state.searchParameter) !== "{}") {
                                this.setState({ searchParameter: {} }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))
                            }
                        }}
                    >
                        {this.state.filterShow ? 'Hide' : 'Show'}
                    </Button> */}

        {this.state.filterShow ? (
          <>
            <Header as="h3" attached="top">
              Filter Users
            </Header>
            <Segment attached>
              <UserFilterForm
                onSubmit={(x) =>
                  this.setState({ searchParameter: x }, () =>
                    this.setState({
                      triggerUpdate: this.state.triggerUpdate + 1,
                    })
                  )
                }
              />
            </Segment>
          </>
        ) : null}
      </>
    );
  };

  modalForAttendenceDownload = () => {
    return (
      <DateMonthYearForm
        size="tiny"
        header={"Download"}
        open={this.state.downloadAttendenceShow}
        onCloseHandle={() => {
          this.setState({
            downloadAttendenceShow: false,
            selectedUser: null,
          });
        }}
        actionsComponent={this.renderAttendenceDownloadActionsComponent()}
        onSubmit={this.handleAttendanceDownload}
        loading={this.state.loading}
        Message={null}
      />
    );
  };

  modalForDownload = () => {
    return (
      <DateInputForm
        size="tiny"
        header={"Download"}
        open={this.state.downloadShow}
        onCloseHandle={() => {
          this.setState({
            downloadShow: false,
            selectedUser: null,
          });
        }}
        actionsComponent={this.renderDownloadActionsComponent()}
        onSubmit={this.handleDownloadSubmit}
        loading={this.state.loading}
      />
    );
  };

  handleDownloadSubmit = (value, dispatch) => {
    console.log(value);
    if (
      value.From !== undefined &&
      value.From !== null &&
      typeof value.From === "object"
    ) {
      value.From =
        value.From.getFullYear() +
        "-" +
        (value.From.getUTCMonth() + 1) +
        "-" +
        value.From.getDate();
    }
    if (
      value.To !== undefined &&
      value.To !== null &&
      typeof value.To === "object"
    ) {
      value.To =
        value.To.getFullYear() +
        "-" +
        (value.To.getUTCMonth() + 1) +
        "-" +
        value.To.getDate();
    }
    value.userids = this.state.selectedIds;
    window.open(
      apiURL +
        `reports/downloaduseractionreport/search?parameters=${JSON.stringify(
          value
        )}`
    );
    this.setState({
      downloadShow: false,
      performanceReport: false,
      attendanceReport: false,
    });
    return dispatch(reset("dates"));
  };

  handleAttendanceDownload = (value, dispatch) => {
    if (
      value.Date !== undefined &&
      value.Date !== null &&
      typeof value.Date === "object"
    ) {
      value.Date =
        value.Date.getFullYear() +
        "-" +
        (value.Date.getUTCMonth() + 1) +
        "-" +
        value.Date.getDate();
      value.Month = 0;
      value.Year = 0;
    }

    value.ids = this.state.selectedIds;

    if (value.Date === undefined || value.Date === null) {
      window.open(
        apiURL +
          `reports/downloaduserattendancereport/search?userids=${JSON.stringify(
            value.ids
          )}&month=${JSON.stringify(value.Month)}&year=${JSON.stringify(
            value.Year
          )}`
      );
    } else {
      window.open(
        apiURL +
          `reports/downloaduserattendancereport/search?userids=${JSON.stringify(
            value.ids
          )}&month=${JSON.stringify(value.Month)}&year=${JSON.stringify(
            value.Year
          )}&date=${value.Date}`
      );
    }
    this.setState({
      downloadAttendenceShow: false,
      performanceReport: false,
      attendanceReport: false,
    });
    return dispatch(reset("dmyForm"));
  };

  renderAttendenceDownloadActionsComponent = () => {
    return (
      <>
        <Button
          basic
          color="red"
          onClick={() => {
            this.setState({
              downloadAttendenceShow: false,
              selectedUser: null,
            });
          }}
          icon="x"
          content="Cancel"
        />
        <Button
          loading={this.state.loading}
          form="dateMonthYearForm"
          color="green"
          type="submit"
          icon="download"
          content="Download"
        />
      </>
    );
  };

  renderDownloadActionsComponent = () => {
    return (
      <>
        <Button
          basic
          color="red"
          onClick={() => {
            this.setState({
              downloadShow: false,
              selectedUser: null,
            });
          }}
          icon="x"
          content="Cancel"
        />
        <Button
          loading={this.state.loading}
          form="dateInputForm"
          color="green"
          type="submit"
          icon="download"
          content="Download"
        />
      </>
    );
  };

  renderUsersBox = () => {
    return (
      <div style={styles.mainDiv}>
        {this.state.access && this.state.loaded
          ? this.renderPageActions()
          : null}
        {this.renderEditBox()}
        {this.renderDeleteConfirmationBox()}
        {this.modalForDownload()}
        {this.modalForAttendenceDownload()}
        {this.state.access && this.state.loaded ? this.renderFilterBox() : null}
        <div style={{ height: 10 }} />
        {this.renderTable()}
      </div>
    );
  };

  renderUserDisplay = () => {
    const user = this.state.selectedUser;
    console.log(user);
    if (this.state.selectedUser === null && this.state.displayUser === false) {
      return null;
    }
    return (
      <div style={styles.mainDiv}>
        <Segment attached="top">
          <Header as="h2">
            <span>
              <Icon name="user" />
            </span>
            <span>User Display</span>
            <span>
              <Button
                color="red"
                floated="right"
                icon="x"
                content="Close"
                onClick={() =>
                  this.setState({ displayUser: false, selectedUser: null })
                }
              />
              <span />
              {this.renderChangeUsernamePassword(user.id)}
            </span>
          </Header>
        </Segment>

        <Segment attached="bottom">
          <Grid>
            {this.renderUserDisplayCell(8, "User ID", user.id)}
            {this.renderUserDisplayCell(8, "Username", user.username)}
            {this.renderUserDisplayCell(8, "First Name", user.firstname)}
            {this.renderUserDisplayCell(8, "Last Name", user.lastname)}
            {this.renderUserDisplayCell(8, "Email Address", user.email)}
            {this.renderUserDisplayCell(8, "Contact Number", user.contactNum)}
            {this.renderUserDisplayCell(8, "CNIC", user.cnic)}

            {this.renderUserDisplayCell(
              8,
              "Active Status",
              <Label color={user.isActive ? "green" : "red"}>
                {user.isActive ? "Active" : "Inactive"}
              </Label>
            )}
            {this.renderUserDisplayCell(
              8,
              "Report To",
              <>
                {user.reportToName}
                <Label size="mini">ID: {user.reportToId}</Label>
              </>
            )}
            {this.renderUserDisplayCell(
              8,
              "roles",
              user.roles.map((x) => (
                <Label key={`userdisplayroles${x.id}`}>{x.type}</Label>
              ))
            )}
          </Grid>
        </Segment>
        <Attendance userid={user.id} />
      </div>
    );
  };

  renderChangeUsernamePassword = () => {
    return (
      <>
        {/* <Grid.Column width={8} textAlign="center">
          <Segment> */}
        <Button
          // compact
          loading={false}
          disabled={false}
          // size="mini"
          color="blue"
          icon="setting"
          floated="right"
          content="Reset Password"
          onClick={() => {
            // console.log(id);
            this.setState({
              getPasswordPrompt: true,
            });
          }}
        />
        {/* </Segment>
        </Grid.Column> */}
      </>
    );
  };

  renderPasswordPrompt = () => {
    return (
      <PassInputForm
        header={"Password Reset Form"}
        open={this.state.getPasswordPrompt}
        onCloseHandle={() => {
          this.setState({
            getPasswordPrompt: false,
          });
        }}
        onSubmit={this.handlePasswordSubmit}
        actionsComponent={this.passwordActions()}
        credentials={this.props.credentials}
        initialValues={this.props.credentials}
      />
    );
  };

  handlePasswordSubmit = (value) => {
    // console.log(value)
    // console.log(this.state.selectedUser.id)
    var data = {};
    data.password = value.pass;
    data.id = this.state.selectedUser.id;

    this.props
      .ResetUserPassword(data)
      .then((x) => console.log("DONE Reset"))
      .catch((x) => console.log("error Occured"));
  };

  passwordActions = () => {
    return (
      <>
        <Button
          basic
          color="red"
          onClick={() => {
            this.setState({
              getPasswordPrompt: false,
            });
          }}
          icon="x"
          content="Cancel"
        />
        <Button
          // loading={this.state.loading}
          key="pass_submit_key"
          form="passInputForm"
          color="green"
          type="submit"
          icon="checkmark"
          content="Submit"
        />
      </>
    );
  };

  renderUserDisplayCell = (size, label, data) => {
    return (
      <Grid.Column width={size}>
        <Segment>
          <span
            style={{
              border: "solid #d3d3d3 1px",
              borderRadius: 5,
              padding: 4,
              marginRight: 7,
            }}
          >
            {label}
          </span>
          {data}
        </Segment>
      </Grid.Column>
    );
  };

  renderTabs() {
    let panes = [
      {
        menuItem: "Active",
        render: () => <Tab.Pane attached>{this.renderUsers()}</Tab.Pane>,
      },
      {
        menuItem: "Inactive",
        render: () => <Tab.Pane attached>{this.renderUsers()}</Tab.Pane>,
      },
    ];
    return (
      <div style={styles.mainDiv}>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={this.state.selectedTabIndex}
          onTabChange={(event, data) => this.onTabChange(event, data)}
        />
      </div>
    );
  }

  onTabChange(event, data) {
    let index = data.activeIndex;
    localStorage.setItem("selectedUserTabIndex", index);
    localStorage.setItem(
      "selectedActionTab",
      data.panes[data.activeIndex].menuItem
    );
    this.setState({
      selectedTab: data.panes[data.activeIndex].menuItem,
      selectedTabIndex: index,
      triggerUpdate: this.state.triggerUpdate + 1,
    });
  }

  renderUsers() {
    return (
      <>
        {this.renderPasswordPrompt()}
        {this.state.displayUser
          ? this.renderUserDisplay()
          : this.renderUsersBox()}
      </>
    );
  }

  render() {
    return (
      <>
        {!this.state.loaded ? (
          <LoaderView text="Users Page is loading" />
        ) : this.state.access ? null : (
          <UnauthorizedView pageName="Users Page" />
        )}
        {this.renderTabs()}
      </>
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

const mstp = (state) => {
  // console.log(state.customer.userDefaulterCodes)
  return {
    profileData: state.profile.profileData,
    data: state.user.data === undefined ? [] : state.user.data,
    selectedUser: state.user.selectedUser,
    roles: state.role.data,
    total: state.user.total,
    selfInfo: state.profile.profileData,
    userbyID: state.user.userbyID,
    companybyID: state.company.companyWithIds,
    regionbyID: state.region.regionWithIds,
    credentials: state.user.credentials,
    codes: state.customer.userDefaulterCodes,
  };
};

export default connect(mstp, actions)(Users);
