import axios from 'axios';
import {
    LOAD_REGIONS_WITH_IDS
} from './types'

const apiURL = require('../config.json').apiURL;

export const LoadRegionsWithIds = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'region/getall'
            }).then(({ data }) => {
                const newData = data.map(x => {
                    return {
                        value: x.id,
                        text: x.name
                    }
                })
                // newData.unshift({ value: 0, text: '[No Region]' })
                dispatch({
                    type: LOAD_REGIONS_WITH_IDS,
                    payload: newData
                })
                resolve('done');
            }).catch(x => {
                reject('failed');
            })
        })
    }
}