import React, { Component } from "react";
import {
  Table,
  Grid,
  Button,
  Dropdown,
  Input,
  Label,
  Loader,
  Dimmer,
  Segment,
  Icon
} from "semantic-ui-react";

class DataTable extends Component {
  state = { selectedPage: 1, pageSize: 1, pageCount: 1, tempPageNum: null };

  componentDidMount = () => {
    if (this.props.noPagination === undefined) {
      this.setState(
        {
          pageSize: this.props.selectedPageSize ? JSON.parse(this.props.selectedPageSize) : 
            this.props.pageSizes[0],
          pageCount: Math.ceil(this.props.rowCount / this.props.pageSizes[0])
        },
        () =>
          this.props.onPageChange(this.state.pageSize, this.state.selectedPage)
      );
    }
  };

  componentDidUpdate = () => { };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.noPagination === undefined) {
      if (prevProps.rowCount !== this.props.rowCount) {
        this.setState({
          pageCount: Math.ceil(this.props.rowCount / this.props.pageSizes[0])
        });
      }
    }
    if (prevProps.trigger !== this.props.trigger) {
      this.setState({ selectedPage: 1 }, () => {
          try {
            this.props.onPageChange(this.state.pageSize, 1);
          } catch (error) {
            
          }
          
        }
      );
    }
    return null;
  }

  changePageNumber = value => {
    const { pageSize, pageCount, selectedPage } = this.state;
    if (value === 0) {
      this.props.onPageChange(pageSize, 1);
      this.setState({ selectedPage: 1 });
    } else if (value === "max") {
      this.props.onPageChange(pageSize, pageCount);
      this.setState({ selectedPage: pageCount });
    } else {
      this.props.onPageChange(pageSize, selectedPage + value);
      this.setState({ selectedPage: selectedPage + value });
    }
  };

  renderTableHeader = () => {
    return (
      <Table.Header>
        {this.props.sorted ?
          <Table.Row>
            {this.props.checkbox !== undefined ? (
              <Table.HeaderCell>
                {this.props.noCheckboxHeading ? null : "[]"}
              </Table.HeaderCell>
            ) : null}
            {this.props.actions !== undefined && this.props.actionsFirst ? (
              <Table.HeaderCell>
                {this.props.noActionHeading ? null : "Actions"}
              </Table.HeaderCell>
            ) : null}
            {this.props.header.map(head => {
              var up = '';
              var down = ''
              if (head.accessor === this.props.sortby) {
                up = 'caret square up'
                down = 'caret square down'
              } else {
                up = 'caret up'
                down = 'caret down'
              }
              return (
                <Table.HeaderCell width={head.width} key={head.name}>
                  {head.name}
                  {this.props.sortby === head.accessor ? this.props.isdescending ?
                    <Icon name={down} onClick={() => this.props.handleSortby(head.accessor)} />
                    :
                    <Icon name={up} onClick={() => this.props.handleSortby(head.accessor)} />
                    :
                    <Icon name={down} onClick={() => this.props.handleSortby(head.accessor)} />
                  }
                </Table.HeaderCell>
              );
            })}
            {this.props.actions !== undefined && !this.props.actionsFirst ? (
              <Table.HeaderCell>
                {this.props.noActionHeading ? null : "Actions"}
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
          :
          <Table.Row>
            {this.props.checkbox !== undefined ? (
              <Table.HeaderCell>
                {this.props.noCheckboxHeading ? null : "[]"}
              </Table.HeaderCell>
            ) : null}
            {this.props.actions !== undefined && this.props.actionsFirst ? (
              <Table.HeaderCell>
                {this.props.noActionHeading ? null : "Actions"}
              </Table.HeaderCell>
            ) : null}
            {this.props.header.map(head => {
              return (
                <Table.HeaderCell width={head.width} key={head.name}>
                  {head.name}
                </Table.HeaderCell>
              );
            })}
            {this.props.actions !== undefined && !this.props.actionsFirst ? (
              <Table.HeaderCell>
                {this.props.noActionHeading ? null : "Actions"}
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        }
      </Table.Header>
    );
  };

  renderData = () => {
    console.log(this.props.data)
    
    return this.props.data !== undefined && this.props.data.length !== 0 ? (
      <Table.Body>
        {this.props.data.map((x, ukeys) => {
          // console.log(x.hasViewed)
          return (
            <Table.Row
              key={"u" + ukeys}
              active={x.hasViewed}
            >
              {this.props.checkbox !== undefined ? (
                <Table.Cell style={{ width: "2%", whiteSpace: "nowrap" }}>
                  {this.props.checkbox(x)}
                </Table.Cell>
              ) : null}
              {this.props.actionsFirst && this.props.actions !== undefined ? (
                <Table.Cell style={{ width: "1%", whiteSpace: "nowrap" }}>
                  {this.props.actions(x)}
                </Table.Cell>
              ) : null}
              {this.props.header.map(
                ({ accessor, separator, component }, lkeys) => {
                  var joinIt = true;
                  if (accessor !== undefined) {
                    const data = accessor.split(" ").map(z => {
                      if (Array.isArray(x[z])) {
                        joinIt = false;
                        if (component !== undefined) {
                          if (x[z].length !== 0) {
                            return x[z].map(y =>
                              component(y, "u" + ukeys + "l" + lkeys)
                            );
                          } else return "null";
                        } else {
                          joinIt = true;
                          return x[z].map(item => item);
                        }
                      } else if (x[z] === null) {
                        return "null";
                      } else if (component !== undefined) {
                        joinIt = false;
                        return component(x, "u", ukeys + "l" + lkeys)
                      }
                      return x[z];
                    });

                    return (
                      <Table.Cell key={"u" + ukeys + "l" + lkeys}>
                        {joinIt
                          ? data.join(separator !== undefined ? separator : " ")
                          : data}
                      </Table.Cell>
                    );
                  }
                  return null;
                }
              )}
              {this.props.actionsFirst ? null : this.props.actions !==
                undefined ? (
                  <Table.Cell style={{ width: "1%", whiteSpace: "nowrap" }}>
                    {this.props.actions(x)}
                  </Table.Cell>
                ) : null}
            </Table.Row>
          );
        })}
      </Table.Body>
    ) : null;
  };

  returnPageSizeOptions = () => {
    return this.props.pageSizes.map(x => {
      return { value: x, text: `${x} rows per page` };
    });
  };

  handleRowCountChange = value => {
    this.props.onPageChange(value, 1);
    this.setState({
      pageSize: value,
      pageCount: Math.ceil(this.props.rowCount / value),
      selectedPage: 1
    });
  };

  handlePageChange = pageNumber => {
    // eslint-disable-next-line no-mixed-operators
    if (0 < pageNumber && pageNumber <= this.state.pageCount) {
      this.props.onPageChange(this.state.pageSize, pageNumber);
      this.setState({ selectedPage: Number(pageNumber) });
    }
  };

  renderPaginationPart = () => {
    return this.props.noPagination === undefined && this.props.data !== undefined &&
      this.props.data.length !== 0 ? (
        <div>
          <Grid columns={4}>
            <Grid.Column>{/* data to come*/}</Grid.Column>
            <Grid.Column>
              <Dropdown
                fluid
                selection
                value={this.state.pageSize}
                onChange={(e, { value }) => this.handleRowCountChange(value)}
                options={this.returnPageSizeOptions()}
                disabled={this.props.loading}
              />
            </Grid.Column>
            <Grid.Column>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.pageInputChangeHandler();
                }}
              >
                <Input fluid labelPosition="right" type="text">
                  <Label>Page</Label>
                  <input
                    value={this.state.selectedPage}
                    onChange={e => {
                      if (/^[0-9]*$/.test(e.target.value)) {
                        this.setState({ selectedPage: e.target.value });
                      }
                    }}
                    onFocus={e => this.setState({ tempPageNum: e.target.value })}
                    onBlur={this.pageInputChangeHandler}
                    disabled={this.props.loading}
                  />
                  <Label>of {this.state.pageCount}</Label>
                </Input>
              </form>
            </Grid.Column>
            <Grid.Column>
              <Button.Group fluid>
                <Button
                  icon="double left angle"
                  disabled={
                    this.state.selectedPage === 1
                      ? true
                      : false || this.props.loading
                  }
                  onClick={() => this.changePageNumber(0)}
                ></Button>
                <Button
                  icon="left angle"
                  disabled={
                    this.state.selectedPage === 1
                      ? true
                      : false || this.props.loading
                  }
                  onClick={() => this.changePageNumber(-1)}
                ></Button>
                <Button
                  icon="right angle"
                  disabled={
                    this.state.selectedPage === this.state.pageCount
                      ? true
                      : false || this.props.loading
                  }
                  onClick={() => this.changePageNumber(1)}
                ></Button>
                <Button
                  icon="double right angle"
                  disabled={
                    this.state.selectedPage === this.state.pageCount
                      ? true
                      : false || this.props.loading
                  }
                  onClick={() => this.changePageNumber("max")}
                ></Button>
              </Button.Group>
            </Grid.Column>
          </Grid>
        </div>
      ) : null;
  };

  pageInputChangeHandler = () => {
    if (this.state.selectedPage > this.state.pageCount) {
      this.setState({ selectedPage: this.state.pageCount });
      if (this.state.tempPageNum != this.state.pageCount) {
        this.handlePageChange(this.state.pageCount);
      }
    } else if (this.state.selectedPage < 1) {
      this.setState({ selectedPage: 1 });
      if (this.state.tempPageNum != 1) {
        this.handlePageChange(1);
      }
    } else {
      if (this.state.selectedPage != this.state.tempPageNum) {
        this.handlePageChange(this.state.selectedPage);
      }
    }
    this.setState({ tempPageNum: null });
  };

  renderFinalOut() {
    return (
      <>
        <div
          style={{
            paddingBottom: "10px",
            position: "relative"
          }}
        >
          <Dimmer active={this.props.loading} inverted>
            <Loader size="massive" active={this.props.loading}>
              Loading Data
            </Loader>
          </Dimmer>
          <div style={{ overflowX: this.props.overflowX ? "auto" : null }}>
            <Table
              selectable
              celled
              unstackable
              style={{ width: "100%" }}
              size={this.props.tableSize}
              compact={this.props.compact}
              collapsing={this.props.collapsing}
            >
              {this.renderTableHeader()}
              {this.renderData()}
            </Table>
            {this.props.data !== undefined && this.props.data.length === 0 ? (
              <Segment style={{ textAlign: "center", fontSize: "1.3rem" }}>
                <Icon name="exclamation triangle" />
                {this.props.noDataInfo === undefined
                  ? "No data found"
                  : this.props.noDataInfo}
              </Segment>
            ) : null}
          </div>
        </div>
        {this.renderPaginationPart()}
      </>
    );
  }

  render() {
    return this.props.show !== false ? this.renderFinalOut() : null;
  }
}

export default DataTable;
