import axios from "axios";
// import {reset} from 'redux-form';
import {
  LOAD_USERS,
  TOGGLE_USER_ACTIVITY,
  LOAD_ALL_USER_WITH_ID,
  USERNAME_PASSWORD_RESET,
} from "./types";
import { logOut } from "../actions";

const apiURL = require("../config.json").apiURL;

export const DeleteUser = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("id", id);
      axios({
        method: "delete",
        url: apiURL + "user/delete",
        data: data,
      })
        .then((x) => {
          resolve("done");
        })
        .catch((x) => {
          reject("failed");
        });
    });
  };
};

export const LoadAllUsersWithID = (activeOnly = false) => {
  console.log(activeOnly);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "user/getdropdown?activeOnly=" + activeOnly,
      })
        .then(({ data }) => {
          const newData = data.map((x) => {
            return {
              value: x.id,
              text: x.firstname + " " + x.lastname,
            };
          });
          newData.unshift({ value: 0, text: "[NO ONE]" });
          dispatch({
            type: LOAD_ALL_USER_WITH_ID,
            payload: newData,
          });
          resolve("done");
        })
        .catch((x) => {
          reject("failed");
        });
    });
  };
};

export const ResetUserPassword = (values) => {
  var form = new FormData();
  form.append("Id", values.id);
  form.append("Password", values.password);
  console.log(values);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: apiURL + "userlogin/resetusernamepassword",
        data: form,
      })
        .then(({ data }) => {
          var object;
          // object.credentials = data;
          // console.log(object);
          dispatch({
            type: USERNAME_PASSWORD_RESET,
            payload: data,
          });
          console.log(data);
          resolve("done");
        })
        .catch((x) => {
          console.log("neroor");
          reject("failed");
        });
    });
  };
};

export const LoadUsers = (pagesize, pagenum, searchParameters, active) => {
  console.log(active);
  searchParameters.active = active === "Active" ? true : false;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "user/getfiltered/search",
        params: {
          pagesize,
          pagenum,
          parameters: JSON.stringify(searchParameters),
        },
      })
        .then(({ data }) => {
          const array = data.users.map((x) => {
            x.defaulterCodesIds = x.defaulterCodes.map((y) => y.id);
          });
          dispatch({
            type: LOAD_USERS,
            payload: {
              data: data.users,
              total: data.total,
            },
          });
          resolve("done");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            reject("failed");
          } else {
            dispatch(logOut());
          }
        });
    });
  };
};

export const ToggleUserActivity = (id, status) => {
  const form = new FormData();
  form.append("id", id);
  form.append("isActive", Boolean(!status));
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "user/toggleactive",
        data: form,
      })
        .then(({ data }) => {
          dispatch({
            type: TOGGLE_USER_ACTIVITY,
            payload: id,
          });
          resolve("done");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            reject("failed");
          } else {
            dispatch(logOut());
          }
        });
    });
  };
};

const EditUserValues = (values, changeit) => {
  return new Promise((resolve, reject) => {
    if (changeit) {
      const newValues = { ...values };
      delete newValues.roles;
      const form = new FormData();
      Object.keys(newValues).map((key, index) => {
        form.append(key.toLowerCase(), newValues[key]);
        return null;
      });
      axios({
        method: "put",
        url: apiURL + "user/put",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            reject("failed");
          }
        });
    } else {
      resolve("done");
    }
  });
};

// const AddRolesToUser = (id, roles) => {
//     return new Promise((resolve, reject) => {
//         if (roles.length > 0){
//             roles.map(x => {
//                 var form = new FormData();
//                 form.append('userid',id)
//                 form.append('Roleid',x)
//                 axios({
//                     method: 'post',
//                     url: apiURL+'userrole/post',
//                     data: form
//                 }).then(({data}) => {
//                     resolve('done')
//                 }).catch(error => {
//                     if (error.response.status === 403) {
//                         reject('failed')
//                     }
//                 })
//             })
//         } else {
//             resolve('done')
//         }
//     })
// }

// const DeleteRolesToUser = (id, roles) => {
//     return new Promise((resolve, reject) => {
//         if (roles.length > 0){
//             roles.map(x => {
//                 var form = new FormData();
//                 form.append('userid',id)
//                 form.append('Roleid',x)
//                 axios({
//                     method: 'delete',
//                     url: apiURL+'userrole/delete',
//                     data: form
//                 }).then(({data}) => {
//                     resolve('done')
//                 }).catch(error => {
//                     reject('failed')
//                 })
//             })
//         } else {
//             resolve('done')
//         }
//     })
// }

export const UpdateUserRoles = (userid, rawAdditions, rawDeletions) => {
  return new Promise((resolve, reject) => {
    if (rawAdditions.length === 0 && rawDeletions.length === 0) {
      resolve("no need");
    } else {
      const additions = rawAdditions.map((x) => {
        return {
          userid,
          Roleid: x,
        };
      });
      const deletions = rawDeletions.map((x) => {
        return {
          userid,
          Roleid: x,
        };
      });
      const form = new FormData();
      form.append("additions", JSON.stringify(additions));
      form.append("deletions", JSON.stringify(deletions));
      axios({
        method: "post",
        url: apiURL + "userrole/update",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    }
  });
};

export const UpdateUserRegions = (userid, rawAdditions, rawDeletions) => {
  return new Promise((resolve, reject) => {
    if (rawAdditions.length === 0 && rawDeletions.length === 0) {
      resolve("no need");
    } else {
      const additions = rawAdditions.map((x) => {
        return {
          userid,
          Regionid: x,
        };
      });
      const deletions = rawDeletions.map((x) => {
        return {
          userid,
          Regionid: x,
        };
      });
      const form = new FormData();
      form.append("additions", JSON.stringify(additions));
      form.append("deletions", JSON.stringify(deletions));
      axios({
        method: "post",
        url: apiURL + "userregion/update",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    }
  });
};

export const UpdateUserCompanies = (userid, rawAdditions, rawDeletions) => {
  return new Promise((resolve, reject) => {
    if (rawAdditions.length === 0 && rawDeletions.length === 0) {
      resolve("no need");
    } else {
      const additions = rawAdditions.map((x) => {
        return {
          userid,
          companyid: x,
        };
      });
      const deletions = rawDeletions.map((x) => {
        return {
          userid,
          companyid: x,
        };
      });
      const form = new FormData();
      form.append("additions", JSON.stringify(additions));
      form.append("deletions", JSON.stringify(deletions));
      axios({
        method: "post",
        url: apiURL + "usercompany/update",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    }
  });
};

export const UpdateUserDefaulterCodes = (
  userid,
  rawAdditions,
  rawDeletions
) => {
  return new Promise((resolve, reject) => {
    if (rawAdditions.length === 0 && rawDeletions.length === 0) {
      resolve("no need");
    } else {
      const additions = rawAdditions.map((x) => {
        return {
          userid,
          defaulterCodeId: x,
        };
      });
      const deletions = rawDeletions.map((x) => {
        return {
          userid,
          defaulterCodeId: x,
        };
      });
      const form = new FormData();
      form.append("additions", JSON.stringify(additions));
      form.append("deletions", JSON.stringify(deletions));
      axios({
        method: "post",
        url: apiURL + "userdefaultercode/update",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    }
  });
};

export const UpdateUserSuperiors = (userid, rawAdditions, rawDeletions) => {
  console.log(userid);
  console.log(rawAdditions);
  console.log(rawDeletions);
  return new Promise((resolve, reject) => {
    if (rawAdditions.length === 0 && rawDeletions.length === 0) {
      resolve("no need");
    } else {
      const additions = rawAdditions.map((x) => {
        return {
          subordinateUID: userid,
          superiorUId: x,
        };
      });
      const deletions = rawDeletions.map((x) => {
        return {
          subordinateUID: userid,
          superiorUId: x,
        };
      });
      console.log(additions);
      console.log(deletions);
      const form = new FormData();
      form.append("additions", JSON.stringify(additions));
      form.append("deletions", JSON.stringify(deletions));
      axios({
        method: "post",
        url: apiURL + "userhierarchy/update",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    }
  });
};

export const EditUser = (
  values,
  changeit,
  roleChanges,
  companyChanges,
  regionChanges,
  superiorsAction,
  codeChanges,
  pageSize,
  pageNumber
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log("ths is being called");
      EditUserValues(values, changeit)
        .then((x) => {
          UpdateUserRoles(
            values.Id,
            roleChanges.additions,
            roleChanges.deletions
          )
            .then((x) => resolve("done"))
            .catch((x) => reject("failed"));
          UpdateUserCompanies(
            values.Id,
            companyChanges.additions,
            companyChanges.deletions
          )
            .then((x) => resolve("done"))
            .catch((x) => resolve("failed"));
          UpdateUserRegions(
            values.Id,
            regionChanges.additions,
            regionChanges.deletions
          )
            .then((x) => resolve("done"))
            .catch((x) => resolve("failed"));
          UpdateUserSuperiors(
            values.Id,
            superiorsAction.additions,
            superiorsAction.deletions
          )
            .then((x) => resolve("done"))
            .catch((x) => resolve("failed"));
          UpdateUserDefaulterCodes(
            values.Id,
            codeChanges.additions,
            codeChanges.deletions
          )
            .then((x) => resolve("done"))
            .catch((x) => resolve("failed"));
        })
        .catch((x) => reject("failed"));
    });
  };
};
