import React from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form'

import { Button, Form, Message } from 'semantic-ui-react'

import { CommonInput } from './commonInput';



let PasswordChangeForm = (props) => {
    return (
        <div>
           <Form error={props.passwordChangeMessage !== null? props.passwordChangeMessage.type === 'error': false} success={props.passwordChangeMessage !== null? props.passwordChangeMessage.type === 'success': false} onSubmit={props.handleSubmit} size='large'>
                <Field
                    disabled={props.loading}
                    fluid
                    name="oldPassword"
                    component={CommonInput}
                    placeholder="Old Password"
                    type="password"
                />
                <Field
                    disabled={props.loading}
                    fluid
                    name="newPassword"
                    component={CommonInput}
                    placeholder="New Password"
                    type="password"
                />
                <Field
                    disabled={props.loading}
                    fluid
                    name="confirmNewPassword"
                    component={CommonInput}
                    placeholder="Confirm New Password"
                    type="password"
                />
                
                {props.passwordChangeMessage !== null?
                        <Message
                            error={props.passwordChangeMessage !== null? props.passwordChangeMessage.type === 'error': false}
                            success={props.passwordChangeMessage !== null? props.passwordChangeMessage.type === 'success': false}
                            header = {props.passwordChangeMessage.header}
                            content = {props.passwordChangeMessage.content}
                        />
                        :
                        null
                    }
                <Button type="submit" disabled={props.loading} loading={props.loading} color='blue' fluid size='large'>
                    Change Password
                </Button>
            </Form>
        </div>
    )
}


const valueCheck = (value,name,minLength = null) => {
    if (!value) {
        return `You must enter  ${name}`
    } else {
        if (minLength) {
            if (value.length < minLength) {
                return `${name} must be at least ${minLength} characters`
            }
        }
    }
}


const passwordMatch = (p1,p2) => {
    if (p1 !== p2) {
        return  'Password must match'
    }
}


const validate = (formValues) => {
    const errors = {};
    errors.oldPassword = valueCheck(formValues.oldPassword,'password')
    errors.newPassword = valueCheck(formValues.newPassword,'new password',5)
    errors.confirmNewPassword = passwordMatch(formValues.confirmNewPassword,formValues.newPassword)
    return errors;
}


PasswordChangeForm = reduxForm({
    form: 'passwordChange',
    validate
})(PasswordChangeForm)

const mtsp = (state) => {
    return {
        loading: state.profile.passwordChangeLoading,
        passwordChangeMessage: state.profile.passwordChangeMessage
    }
}

export default connect(mtsp,null)(PasswordChangeForm);