import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import * as actions from '../../actions'

class Header extends Component {
    state = {
        name: null
    }

    componentDidMount = () => {
        // if (this.props.username !== undefined && this.props.username !== null) {
        //     this.setState({
        //         name: this.props.username
        //     })
        // }
    }

    render() {
        return (
            <>
                <Menu borderless style={styles.menu}>
                    <Menu.Item style={styles.menuItem} onClick={this.props.sidebarSwitch}>
                        <Icon name='bars' />
                    </Menu.Item>
                    <Menu.Item style={styles.menuItem}>
                        <Link to='/'>
                            <h2 style={{ color: 'white' }}>iZAK 10 </h2>
                        </Link>
                        {/* <h2>IZAK 10</h2> */}
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item position='right' style={styles.menuItem} >
                            {this.props.username ? window.localStorage.getItem('userHeader') : null}
                            {/* <h2>IZAK 10</h2> */}
                        </Menu.Item>
                        {/* <Label>{this.props.username ? window.localStorage.getItem('userHeader') : null}</Label> */}
                        <Dropdown style={styles.dropdown} item icon='chevron circle down' simple>
                            <Dropdown.Menu>
                                <Link to="/profile">
                                    <Dropdown.Item>
                                        My Profile
                                </Dropdown.Item>
                                </Link>
                                <Link to="/attendance">
                                    <Dropdown.Item>
                                        Attendance
                                </Dropdown.Item>
                                </Link>
                                <Dropdown.Item
                                    onClick={this.props.logOut}
                                >
                                    Log Out
                            </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>
            </>
        )
    }

}

const styles = {
    menu: {
        backgroundColor: '#2185D0',
        borderStyle: 'none',
        borderRadius: '0',
        color: 'white'
    },
    menuItem: {
        color: 'white'
    },
    dropdown: {
        color: 'white',
        fontSize: '17px',
    }
}

export default connect(null, actions)(Header);