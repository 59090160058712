import React, { Component, createContext } from 'react';
import { Modal, Input, Button, Grid, Form, Dropdown, Header, Icon } from 'semantic-ui-react';
import { CommonInput, CommonSelect } from '../forms/commonInput'
import { Field, reduxForm } from 'redux-form';
import LoaderView from '../loaderView';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
momentLocaliser(moment)

class OutcomeEditForm extends Component {
    state = {
        actionTag: null,
        showFields: false,
        tag: this.props.data.resultId,
        selectedFields: null,
        elevationImageFile: null,
        resultTag: this.props.resultTag
    }

    componentDidmount = () => {
        // console.log(this.props.resultId)
        const { resultId } = this.props.data
        this.setState({
            tag: resultId
        })
    }


    // shouldComponentUpdate = () => {
    //     const { resultId } = this.props
    //     console.log(resultId)
    //     this.setState({
    //         tag: resultId
    //     })
    // }


    showLabel = (label) => {
        return (
            <>
                <span>{label}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
        )
    }

    handleChange = (value) => {
        this.setState({
            showFields: true,
            tag: value,
        })
    }

    renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder, label }) => {
        return (
            <>
                <DateTimePicker
                    onChange={onChange}
                    format="DD/MM/YYYY"
                    placeholder={placeholder}
                    time={false}
                    value={!value ? null : new Date(value)}
                />
            </>
        )
    }

    renderTagDropdown = (field) => {
        console.log(field)
        return (<>
            <Form.Select
                name={field.input.name}
                placeholder={field.placeholder}
                onChange={(e, { value }) => field.input.onChange(value)}
                value={field.input.value}
                clearable
                options={this.props.tagOptions}
                error={field.error}
            />
            {((field.error && <span>{field.error}</span>) ||
                (field.warning && <span>{field.warning}</span>))}
        </>
        )
    }

    renderActionDropdown = () => {
        return (
            <Grid.Column width={16}>
                {this.showLabel("Action Type")}
                <Field
                    name='resultId'
                    placeholder='Result Tag'
                    onChange={this.handleChange}
                    component={this.renderTagDropdown}
                // validate={[this.required]}
                />
            </Grid.Column>
        )
    }
    required = value => {
        if (!value) {
            return 'Entere Teh value'
        }
        return undefined
    }

    renderActionFields = () => {
        let value = this.state.tag === null || this.state.tag === undefined ? this.props.data.resultId : this.state.tag
        switch (value) {
            case 2:
                return (
                    <><Grid.Row centered>
                        <Grid.Column width={8}>
                            {this.showLabel("Promise Amount")}
                            <Field
                                name="promise.amount"
                                placeholder={"Amount"}
                                fluid
                                type='number'
                                step='0.01'
                                component={CommonInput}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {this.showLabel("Promise Date")}
                            <Field
                                name="promise.date"
                                fluid
                                placeholder={"Date"}
                                component={this.renderDateTimePicker}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    </>
                )
            case 1:
                return (
                    <> <Grid.Row centered>
                        <Grid.Column width={8}>
                            {this.showLabel("Payment Amount")}
                            <Field
                                name="payment.amount"
                                placeholder={"Amount"}
                                type='number'
                                step='0.01'
                                fluid
                                component={CommonInput}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {this.showLabel("Payment Date")}
                            <Field
                                name="payment.date"
                                placeholder={"Date"}
                                type='text'
                                fluid
                                component={this.renderDateTimePicker}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    </>
                )
            case 3:
                return (
                    <><Grid.Row centered>
                        <Grid.Column width={8}>
                            {this.showLabel("Reminder Date")}
                            <Field
                                name="reminder.date"
                                placeholder={"Date"}
                                type='text'
                                fluid
                                component={this.renderDateTimePicker}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    </>
                )
            default:
                return (
                    <>
                        {null}
                    </>
                );
        }
    }

    render = () => {
        console.log(this.props)
        return (
            <>
                <Modal
                    size={this.props.size || 'small'}
                    open={this.props.open}
                    onClose={this.props.onCloseHandle}
                >
                    <Header>
                        <Icon name='edit' />
                        {"Action Outcome Edit"}
                    </Header>
                    <Modal.Content>
                        <Form
                            id='outcomeEditTag'
                            onSubmit={this.props.handleSubmit}
                            size={this.props.size}
                        >
                            <Grid>
                                {this.renderActionDropdown()}
                                {this.renderActionFields()}
                            </Grid>
                        </Form>
                    </Modal.Content>

                    <Modal.Actions>
                        {this.props.actionsComponent}
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

const valueCheck = (value, name) => {
    if (!value) {
        return `You must choose ${name}`
    }
}

const validate = (formValues) => {
    const errors = {};
    errors.resultId = valueCheck(formValues.resultId, 'resultId');
    return errors;
}

OutcomeEditForm = reduxForm({
    form: 'editTag',
    validate,
    enableReinitialize: true,
})(OutcomeEditForm)

export default OutcomeEditForm;