import React, { Component } from 'react';
import {
    Table,
    Loader,
    Dimmer,
    Segment,
    Icon
} from "semantic-ui-react";


class SelectTable extends Component {
    state = { selectedPage: 1, pageSize: 1, pageCount: 1, tempPageNum: null };

    renderTableHeader = () => {
        return (
            <Table.Header>
                <Table.Row>
                    {this.props.actions !== undefined && this.props.actionsFirst ? (
                        <Table.HeaderCell>
                            {this.props.noActionHeading ? null : "Actions"}
                        </Table.HeaderCell>
                    ) : null}
                    {this.props.header.map(head => {
                        return (
                            <Table.HeaderCell width={head.width} key={head.name}>
                                {head.name}
                            </Table.HeaderCell>
                        );
                    })}
                    {this.props.actions !== undefined && !this.props.actionsFirst ? (
                        <Table.HeaderCell>
                            {this.props.noActionHeading ? null : "Actions"}
                        </Table.HeaderCell>
                    ) : null}
                </Table.Row>

            </Table.Header>
        );
    };

    renderData = () => {
        return this.props.data.length !== 0 ? (
            <Table.Body>
                {this.props.data.map((x, ukeys) => {
                    // console.log(x.hasViewed)
                    return (
                        <Table.Row
                            key={"u" + ukeys}
                            onClick={() => this.props.clickRow(x.name)}
                            active={this.props.activeRow(x.name)}
                        >
                            {this.props.actionsFirst && this.props.actions !== undefined ? (
                                <Table.Cell style={{ width: "1%", whiteSpace: "nowrap" }}>
                                    {this.props.actions(x)}
                                </Table.Cell>
                            ) : null}
                            {this.props.header.map(
                                ({ accessor, separator, component, collapsing }, lkeys) => {
                                    var joinIt = true;
                                    if (accessor !== undefined) {
                                        const data = accessor.split(" ").map(z => {
                                            if (Array.isArray(x[z])) {
                                                joinIt = false;
                                                if (component !== undefined) {
                                                    if (x[z].length !== 0) {
                                                        return x[z].map(y =>
                                                            component(y, "u" + ukeys + "l" + lkeys)
                                                        );
                                                    } else return "null";
                                                } else {
                                                    return null;
                                                }
                                            } else if (x[z] === null) {
                                                return "null";
                                            } else if (typeof x[z] === 'object' && component !== undefined) {
                                                joinIt = false;
                                                return component(x[z], "u", ukeys + "l" + lkeys)
                                            }
                                            return x[z];
                                        });

                                        return (
                                            <Table.Cell key={"u" + ukeys + "l" + lkeys} collapsing={collapsing !== undefined && collapsing === true ? true : false} >
                                                {joinIt
                                                    ? data.join(separator !== undefined ? separator : " ")
                                                    : data}
                                            </Table.Cell>
                                        );
                                    }
                                    return null;
                                }
                            )}
                            {this.props.actionsFirst ? null : this.props.actions !==
                                undefined ? (
                                    <Table.Cell style={{ width: "1%", whiteSpace: "nowrap" }} >
                                        {this.props.actions(x)}
                                    </Table.Cell>
                                ) : null}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        ) : null;
    };

    renderFinalOut() {
        return (
            <>
                <div
                    style={{
                        paddingBottom: "10px",
                        position: "relative"
                    }}
                >
                    <Dimmer active={this.props.loading} inverted>
                        <Loader size="massive" active={this.props.loading}>
                            Loading Data
                        </Loader>
                    </Dimmer>
                    <div style={{ overflowX: this.props.overflowX ? "auto" : null }}>
                        <Table
                            selectable
                            celled
                            unstackable
                            style={{ width: "100%" }}
                            size={this.props.tableSize}
                            compact={this.props.compact}
                            collapsing={this.props.collapsing}
                        >
                            {this.renderTableHeader()}
                            {this.renderData()}
                        </Table>
                        {this.props.data.length === 0 ? (
                            <Segment style={{ textAlign: "center", fontSize: "1.3rem" }}>
                                <Icon name="exclamation triangle" />
                                {this.props.noDataInfo === undefined
                                    ? "No data found"
                                    : this.props.noDataInfo}
                            </Segment>
                        ) : null}
                    </div>
                </div>
                {/* {this.renderPaginationPart()} */}
            </>
        );
    }

    render() {
        return this.props.show !== false ? this.renderFinalOut() : null;
    }
}

export default SelectTable;

