import axios from 'axios';


import {
    LOAD_PERMISSIONS,
    QUEUE_PERMISSION,
    RESET_QUEUE_PERMISSION,
    TOGGLE_PERMISSION_PROCESSING,
    TOGGLE_PERMISSIONS
} from './types';


const apiURL = require('../config.json').apiURL;

export const LoadPermissions = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'rolepermission/getall',
                withCredentials: true,
            }).then(({ data }) => {
                console.log(data);
                // let groupList = data.permissionList.map(x => {
                //     return {x.groupId};
                // })
                dispatch({
                    type: LOAD_PERMISSIONS,
                    payload: data
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('failed')
                });
        })
    }
}


export const ToPermissionQueue = (roleId, permissionId) => {
    return ({
        type: QUEUE_PERMISSION,
        payload: {
            roleId,
            permissionId
        }
    })
}

export const ResetPermissionQueue = () => {
    return ({
        type: RESET_QUEUE_PERMISSION
    })
}

export const ConfirmPermissionChange = (permissionQueue, presentPermissions) => {
    const additions = [];
    const deletions = [];
    return dispatch => {
        if (permissionQueue.length === 0) return null;
        dispatch({
            type: TOGGLE_PERMISSION_PROCESSING
        });
        permissionQueue.map(x => {
            if (presentPermissions.filter(y => y.roleId === x.roleId && y.permissionId === x.permissionId).length !== 0) {
                deletions.push(x)
            } else additions.push(x)
            return null;
        })

        var form = new FormData();
        form.append('additions', JSON.stringify(additions))
        form.append('deletions', JSON.stringify(deletions))
        axios({
            method: 'post',
            url: apiURL + 'rolepermission/update',
            data: form
        }).then(({ data }) => {
            dispatch({
                type: RESET_QUEUE_PERMISSION
            })
            dispatch({
                type: TOGGLE_PERMISSIONS,
                payload: permissionQueue
            })
            dispatch({
                type: TOGGLE_PERMISSION_PROCESSING
            });
        })
            .catch((error) => {
                // display load error
                dispatch({
                    type: TOGGLE_PERMISSION_PROCESSING
                });
            });

    }
}
