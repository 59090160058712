import React, { Component} from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { CommonInput, CommonSelect } from '../components/forms/commonInput'

class formConstructor extends Component {

    showLabel = (label) => {
        return (
            <>
                <span>{label}</span>
                <div style={{marginBottom:'3px'}}/>
            </>
        )
    } 

    constructFields = () => {
        return this.props.formInfo.formFields.map(x => {
            if (x.type === 'dropdown'){
                return (
                    <React.Fragment key={x.id}>
                        {this.showLabel(x.caption)}
                        <CommonSelect

                            fluid
                            placeholder={"Select the "+x.caption}
                            label={x.caption}
                            displayOnly
                            options={
                                x.fieldValues.map(y => 
                                    {
                                        return ({
                                            id:`${x.id}-${y.id}`,
                                            value: y.value,
                                            text: y.value
                                        })
                                    }
                                )}
                        />
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment key={x.id}>
                        {this.showLabel(x.caption)}
                        <CommonInput
                            placeholder={"Type in the "+x.caption}
                            fluid
                            type={x.datatype}
                        />
                    </React.Fragment>
                )
            }
            /*
            for each I have
            caption
            datatype
            input type
            min length
            max length
        */

            return x.caption;
        })
        return null;    
    }

    renderFormModal = () => {
        return (
            this.props.formInfo !== null?
                <Modal
                    open={this.props.formInfo !== null}
                    onClose={this.props.onClose}
                >
                    <Modal.Header>
                        [Displaying Form] - {this.props.formInfo.name}
                    </Modal.Header>
                    <Modal.Content>
                        {this.constructFields()}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            onClick={this.props.onClose}
                        >
                            Close Form Display
                        </Button>
                    </Modal.Actions>

                </Modal>
            :
                null
        )
    } 


    render() {
        return (
            this.renderFormModal()
        )
    }
}



export default formConstructor;