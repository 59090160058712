import {
    LOAD_ROLES,
    ADD_ROLE_LOAD_TOGGLE,
    DELETE_ROLE_LOAD,
    UPDATE_ROLE_SEARCH_TERM,
    LOAD_EDIT_ROLE,
    EDIT_ROLE_ERROR,
    ROLE_EDIT_LOADING_TOGGLE
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    rowCount: 0,
    addLoading: false,
    deleteLoading: [],
    roleBeingEdited: null,
    searchTerm: '',
    roleEditError: null,
    roleEditLoading: false,
    pageInfo: {
        pageSize: 0,
        pageNumber: 0
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_ROLES:
            return { ...state, data: action.payload };
        case ADD_ROLE_LOAD_TOGGLE:
            return { ...state, addLoading: !state.addLoading }
        case UPDATE_ROLE_SEARCH_TERM:
            return { ...state, searchTerm: action.payload }
        case DELETE_ROLE_LOAD:
            if (!state.deleteLoading.includes(action.payload)) {
                return { ...state, deleteLoading: [...state.deleteLoading, action.payload] }
            } else {
                return { ...state, deleteLoading: state.deleteLoading.filter(x => x !== action.payload) }
            }
        case LOAD_EDIT_ROLE:
            return { ...state, roleBeingEdited: action.payload }
        case EDIT_ROLE_ERROR:
            return { ...state, roleEditError: action.payload }
        case ROLE_EDIT_LOADING_TOGGLE:
            return { ...state, roleEditLoading: !state.roleEditLoading }
        default:
            return state;
    }
}