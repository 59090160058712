import axios from 'axios';

import { logOut } from '../actions'

import {
    LOAD_LOGS
} from './types'

const apiURL = require('../config.json').apiURL;


export const LoadLogs = (pagesize, pagenum, searchParameters) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'auditlog/getfiltered/search',
                params: {
                    pagesize,
                    pagenum,
                    parameters: JSON.stringify(searchParameters)
                }
            }).then(({ data }) => {
                console.log(data.log)
                // let newData = data.log.map(x=>{
                //     x.time = x.timestamp.getTime();
                //     x.date = x.timestamp.getDate();
                // })
                // console.log(newData)

                dispatch({
                    type: LOAD_LOGS,
                    payload: { data: data.log, total: data.total }
                })
                resolve('done')
            })
                .catch((error) => {
                    if (error.response.status === 403) {
                        reject('failed')
                    } else {
                        dispatch(logOut())
                    }
                });
        })
    }
}