import React, { Component } from "react";
import {
  Container,
  Grid,
  Card,
  Divider,
  Label,
  Button,
  Input,
  Icon,
  Dimmer,
  Loader
} from "semantic-ui-react";
import {
  LoadProfile,
  ChangeUsername,
  ChangingUsernameStatus,
  ChangeProfilePassword,
  RemoveAllProfileMessages
} from "../actions/profileActions";
import { connect } from "react-redux";

import PasswordChangeForm from "../components/forms/passwordChangeForm";

class Profile extends Component {
  state = { newUserName: "" };

  componentDidMount = () => {
    this.props.LoadProfile();
  };

  componentWillUnmount = () => {
    this.props.RemoveAllProfileMessages();
  };

  renderCard = (header, data) => {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{header}</Card.Header>
        </Card.Content>
        <Card.Content>
          {data.map((d, x) => {
            return (
              <div key={d.id}>
                {d.label !== undefined ? (
                  <Label basic horizontal>
                    {d.label}
                  </Label>
                ) : null}
                {d.value !== undefined ? (
                  <span>
                    <b>{d.value}</b>
                  </span>
                ) : null}
                {d.component !== undefined ? d.component : null}

                {x < data.length - 1 ? <Divider /> : null}
              </div>
            );
          })}
        </Card.Content>
      </Card>
    );
  };

  handlePasswordChange = ({ newPassword, oldPassword }) => {
    this.props.ChangeProfilePassword(oldPassword, newPassword);
  };

  changeUsernameStart = () => {
    this.props.ChangingUsernameStatus("edit");
    this.setState({ newUserName: this.props.profileData.username });
  };

  renderPasswordBlock = () => {
    return (
      <Card fluid>
        <Card.Content>
          <PasswordChangeForm onSubmit={this.handlePasswordChange} />
        </Card.Content>
      </Card>
    );
  };

  renderUsernameDisplay = username => {
    return (
      <>
        <Label basic horizontal>
          Username
        </Label>
        <span>
          <b>{username}</b>
          <span style={{ display: "block", float: "right" }}>
            <Button
              size="mini"
              style={{
                backgroundColor: "Transparent",
                textDecoration: "underline",
                borderBottomWidth: "4px"
              }}
              onClick={this.changeUsernameStart}
            >
              change username
            </Button>
          </span>
        </span>
      </>
    );
  };

  renderUsernameEdit = username => {
    return (
      <span>
        <Grid>
          <Grid.Column width={4}>
            <Label basic horizontal>
              Username
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Input size="mini" fluid type="text" placeholder="Search..." action>
              <input
                value={this.state.newUserName}
                onChange={e => {
                  this.setState({ newUserName: e.target.value });
                }}
              />
              <Button
                color="green"
                type="submit"
                icon
                disabled={this.props.usernameChangeStatus === "loading"}
                loading={this.props.usernameChangeStatus === "loading"}
                onClick={() => {
                  if (
                    this.props.profileData.username !== this.state.newUserName
                  ) {
                    this.props.ChangeUsername(this.state.newUserName);
                  }
                  // display notification!!!!!
                }}
              >
                <Icon name="check" />
              </Button>
              <Button
                color="red"
                icon
                disabled={this.props.usernameChangeStatus === "loading"}
                onClick={() => this.props.ChangingUsernameStatus("display")}
              >
                <Icon name="x" />
              </Button>
            </Input>
          </Grid.Column>
        </Grid>
      </span>
    );
  };

  renderUsername = username => {
    switch (this.props.usernameChangeStatus) {
      case "display":
        return this.renderUsernameDisplay(username);
      case "edit":
        return this.renderUsernameEdit(username);
      case "loading":
        return this.renderUsernameEdit(username);
      default:
        return null;
    }
  };

  renderProfileData = () => {
    console.log(this.props.profileData);
    const {
      firstname,
      lastname,
      cnic,
      contactNum,
      email,
      isActive,
      reportToId,
      reportToName,
      roles,
      username,
      companies,
      regions
    } = this.props.profileData;
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              {/* <Grid.Row> */}
                <Grid.Column width={8}>
                  {this.renderCard("My Info", [
                    {
                      id: "profile_firstname",
                      label: "First Name",
                      value: firstname
                    },
                    {
                      id: "profile_lastname",
                      label: "Last Name",
                      value: lastname
                    },
                    {
                      id: "profile_cnic",
                      label: "CNIC",
                      value: cnic
                    },
                    {
                      id: "profile_contactnumber",
                      label: "Contact Number",
                      value: contactNum
                    }
                  ])}
                </Grid.Column>
                <br />
                <Grid.Column width={8}>
                  {this.renderCard("Report To", [
                    {
                      id: "profile_reporttousername",
                      label: "User Id",
                      value: reportToId === 0 ? "[NONE]" : reportToId
                    },
                    {
                      id: "profile_reporttoname",
                      label: "Full Name",
                      value: reportToName === null ? "[NONE]" : reportToName
                    }
                  ])}
                </Grid.Column>
                <br />
                <Grid.Column width={2}>
                  {this.renderCard("Companies", [
                    {
                      id: `profile_companies`,
                      component: companies.map(x => (
                        <Label
                          key={x.id + x.name}
                          style={{
                            borderRadius: "20px"
                          }}
                          color="black"
                        >
                          {x.name}
                        </Label>
                      ))
                    }
                  ])}
                </Grid.Column>
                <br />
                <Grid.Column width={8}>
                  {this.renderCard("Regions", [
                    {
                      id: `profile_regions`,
                      component: regions.map(x => (
                        <Label
                          key={x.id + x.name}
                          style={{
                            borderRadius: "20px"
                          }}
                        //   color="grey"
                        >
                          {x.name}
                        </Label>
                      ))
                    }
                  ])}
                </Grid.Column>
              {/* </Grid.Row> */}
            </Grid.Column>

            <Grid.Column width={8}>
              <Grid.Row>
                <Grid.Column width={8}>
                  {this.renderCard("My Account", [
                    {
                      id: "profile_username",
                      component: this.renderUsername(username)
                    },
                    {
                      id: "profile_email",
                      label: "Email Address",
                      value: email
                    },
                    {
                      id: "profile_activity",
                      label: "Activity Status",
                      component: isActive ? (
                        <Label color="green">Active</Label>
                      ) : (
                        <Label color="red">Inactive</Label>
                      )
                    },
                    {
                      id: "profile_roles",
                      label: "Roles",
                      component: roles.map(x => (
                        <Label
                          key={x.id + x.type}
                          style={{
                            borderRadius: "20px"
                          }}
                          color="teal"
                        >
                          {x.type}
                        </Label>
                      ))
                    }
                  ])}
                </Grid.Column>
                <br />
                <Grid.Column width={8}>
                  {this.renderCard("Change Password", [
                    {
                      id: "profile_passwordblock",
                      component: this.renderPasswordBlock()
                    }
                  ])}
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  };

  render() {
    return this.props.profileData.length !== 0 ? (
      this.renderProfileData()
    ) : (
      <Dimmer active>
        <Loader size="massive">Loading</Loader>
      </Dimmer>
    );
    // render loading when profile data is being gathered!!!!
    // if error then display error with a reload button (maybe)
  }
}

const mstp = state => {
  return {
    profileData: state.profile.profileData,
    usernameChangeStatus: state.profile.usernameChangeStatus
  };
};

export default connect(mstp, {
  LoadProfile,
  ChangeUsername,
  ChangingUsernameStatus,
  ChangeProfilePassword,
  RemoveAllProfileMessages
})(Profile);
