import React, { Component } from 'react';
import { Button, Grid, Icon, Segment, Input, Header } from 'semantic-ui-react';
import DataTable from '../components/dataTable';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as actions from '../actions';
import CompanyForm from '../components/forms/companyForm';
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';

class Companies extends Component {

    state = {
        AddEditBoxOpen: false,
        loading: false,
        deleteLoading: [],
        selectedCompany: null,
        inputStatus: 'add',
        access: false,
        loaded: false,
        searchName: '',
        searchPerson: '',
        searchNumber: '',
        showSearch: false
    }

    componentDidMount = () => {
        this.props.LoadCompanies()
            .then(x => this.setState({ loaded: true, access: true }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    }


    renderAddButton = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        this.setState({ AddEditBoxOpen: true, inputStatus: 'add', selectedCompany: null })
                    }}
                    color="green"
                    floated="right">
                    Add New Company
                </Button>
            </div>
        )
    }

    renderSearchButton = () => {
        return (
            <div>
                <Button
                    primary={!this.state.showSearch}
                    onClick={() => this.setState({ showSearch: !this.state.showSearch, searchName: '', searchNumber: '', searchPerson: '' })}
                >
                    {!this.state.showSearch ? 'Search Companies' : 'Hide Search Box'}
                </Button>
            </div>
        )
    }


    renderSearchBox = () => {
        return (
            this.state.showSearch ?
                <>
                    <Header attached="top">Filter Companies</Header>
                    <Segment attached>
                        <Grid>
                            <Grid.Column width={6}>
                                <span>Company Name</span>
                                <div style={{ marginBottom: '3px' }} />
                                <Input
                                    value={this.state.searchName}
                                    onChange={(e) => this.setState({ searchName: e.target.value })}
                                    placeholder="Company Name"
                                />
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <span>Contact Person</span>
                                <div style={{ marginBottom: '3px' }} />
                                <Input
                                    value={this.state.searchPerson}
                                    onChange={(e) => this.setState({ searchPerson: e.target.value })}
                                    placeholder="Contact Person"
                                />
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <span>Contact Number</span>
                                <div style={{ marginBottom: '3px' }} />
                                <Input
                                    value={this.state.searchNumber}
                                    onChange={(e) => this.setState({ searchNumber: e.target.value })}
                                    placeholder="Contact Number"
                                />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </>
                : null
        )
    }

    renderPageActions = () => {
        return (
            <div style={styles.topBox}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {this.renderSearchButton()}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {this.renderAddButton()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }

    deleteCompanyHandler = (id) => {
        this.setState({ deleteLoading: [...this.state.deleteLoading, id] })
        this.props.DeleteCompany(id)
            .then(x => {
                this.setState({ deleteLoading: this.state.deleteLoading.filter(x => x !== id) })
            }
            )
            .catch(error => {
                this.setState({ deleteLoading: this.state.deleteLoading.filter(x => x !== id) })
            }
            )
    }

    editCompanyInit = (company) => {
        const companyForEdit = {
            id: company.id,
            Name: company.name,
            Contactperson: company.contactPerson,
            Contactnum: company.contactNum
        }
        this.setState({ selectedCompany: companyForEdit, AddEditBoxOpen: true, inputStatus: 'edit' })
    }

    actionsButton = (value) => {
        const deleteProcessing = this.state.deleteLoading.includes(value.id)
        return (
            <>
                <Button size="mini" color="blue" icon disabled={deleteProcessing}
                    onClick={() => { this.editCompanyInit(value) }}>
                    <Icon name="edit" />
                </Button>
                <Button size="mini" color="red" icon
                    disabled={deleteProcessing}
                    loading={deleteProcessing}
                    onClick={() => this.deleteCompanyHandler(value.id)}>
                    <Icon name="x" />
                </Button>
            </>
        );
    }

    renderTable = () => {
        return (
            <DataTable
                header={[
                    {
                        name: 'ID',
                        accessor: 'id'
                    },
                    {
                        name: 'Company Name',
                        accessor: 'name'
                    },
                    {
                        name: 'Contact Person',
                        accessor: 'contactPerson'
                    },
                    {
                        name: 'Contact Number',
                        accessor: 'contactNum'
                    },
                    {
                        name: '# of Defaulters',
                        accessor: 'noOfDefaulters'
                    }
                ]}
                actions={(x) => this.actionsButton(x)}
                data={this.props.data.filter((x) => x.name.toLowerCase().includes(this.state.searchName.toLowerCase()) && x.contactPerson.toLowerCase().includes(this.state.searchPerson.toLowerCase()) && x.contactNum.includes(this.state.searchNumber))}
                noPagination
            />
        )
    }

    returnAddBoxActionsComponent = () => {
        return (
            <>
                <Button
                    disabled={this.state.loading}
                    basic color='red' onClick={() => this.setState({ AddEditBoxOpen: false })}>
                    <Icon name='remove' />
                    {this.state.inputStatus === 'edit' ? 'Cancel Edit' : 'Close'}
                </Button>
                <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    form="companyForm"
                    color='green' type="submit">
                    <Icon name='checkmark' />
                    {this.state.inputStatus === 'edit' ? 'Confirm Edit' : 'Add Company'}
                </Button>
            </>
        )
    }

    handleSubmit = (value) => {

        if (this.state.inputStatus === 'add' && Object.keys(value).length !== 0) {
            this.setState({ loading: true })
            this.props.AddCompany(value)
                .then(x => this.setState({ loading: false }))
                .catch(error => this.setState({ loading: false }))
        } else if (this.state.selectedCompany !== null) {
            const companyToEdit = { ...value, Id: this.state.selectedCompany.id }
            delete companyToEdit.id
            this.setState({ loading: true })
            this.props.EditCompany(companyToEdit)
                .then(x => this.setState({ loading: false }))
                .catch(error => this.setState({ loading: false }))
        } else {

        }
    }

    renderAddEditBox = () => {
        const cid = this.state.selectedCompany !== null ? this.state.selectedCompany.id : null;
        return (
            <CompanyForm
                size="tiny"
                open={this.state.AddEditBoxOpen}
                onCloseHandle={() => {
                    this.setState({ AddEditBoxOpen: false, selectedCompany: null })
                }}
                header={
                    this.state.inputStatus === 'add' ?
                        { icon: "add", text: "Add New Company" } :
                        { icon: "edit", text: "Edit Company with ID: " + cid }
                }
                actionsComponent={this.returnAddBoxActionsComponent()}
                label={this.state.inputStatus === 'edit'}
                onSubmit={this.handleSubmit}
                loading={this.state.loading}
                Message={null}
                initialValues={this.state.selectedCompany}
            />
        )
    }

    renderCompaniesBox = () => {
        return (
            <div style={styles.mainDiv}>
                {this.renderPageActions()}
                {this.renderSearchBox()}
                {this.renderAddEditBox()}
                {this.renderTable()}
            </div>
        )
    }

    render() {
        return (
            !this.state.loaded ? <LoaderView text="Company Page is loading" /> :
                this.state.access ? this.renderCompaniesBox() : <UnauthorizedView pageName="Company Page" />
        )
    }

}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

const mstp = (state) => {
    return ({
        data: state.company.data
    })
}

export default connect(mstp, actions)(Companies);