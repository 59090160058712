import axios from 'axios';

import {
    LOAD_FORMS,
    REMOVE_FORM,
    LOAD_FORM_BY_ID,
    LOAD_FORMS_WITH_ID
} from './types'

const apiURL = require('../config.json').apiURL;

export const editForm = (oldForm, changes, newFields) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            formNameChange(oldForm.id, changes.formName)
                .catch(x => reject())
                .then(x => {
                    deleteFields(changes.fieldsToDelete)
                        .catch(x => reject())
                        .then(x => {
                            addFields(oldForm.id, changes.fieldsToAdd)
                                .catch(x => reject())
                                .then(x => {
                                    editFields(oldForm.id, changes.fieldsToEdit, newFields, oldForm)
                                        .catch(x => reject())
                                        .then(x => {
                                            editFieldValues(oldForm, changes.fieldsToEdit, newFields)
                                                .catch(x => reject())
                                                .then(x => resolve())
                                        })
                                })
                        })
                })


        })
    }
}

const editFieldValues = ({ formFields }, changes, newForm) => {
    return new Promise((resolve, reject) => {
        const oldForm = formFields;
        const deletions = [];
        const additions = [];
        if (changes.length === 0) {
            resolve()
        } else {
            for (let i in changes) {
                const oldField = oldForm.filter(x => x.caption === changes[i].caption)[0]
                const oldValues = oldForm.filter(x => x.caption === changes[i].caption)[0].fieldValues
                const newValues = newForm.filter(x => x.caption === changes[i].caption)[0].fieldvalues.split(',')
                oldValues.forEach(x => {
                    if (!newValues.includes(x.value)) {
                        deletions.push({ Id: x.id })
                    }

                })
                newValues.forEach(x => {
                    if (!oldValues.map(y => y.value).includes(x)) {
                        additions.push({ formFieldId: oldField.id, value: x })
                    }
                })
            }
            const form = new FormData();
            form.append('Additions', JSON.stringify(additions));
            form.append('Deletions', JSON.stringify(deletions));
            axios({
                method: 'post',
                url: apiURL + 'fieldvalue/update',
                data: form
            }).then(x => resolve())
                .catch(x => {
                    reject()
                })
        }
    })
}

const editFields = (formID, fieldsID, fields) => {
    return new Promise((resolve, reject) => {
        if (fieldsID.length === 0) {
            resolve()
        } else {
            for (let x in fieldsID) {
                const field = fields.filter(y => y.caption === fieldsID[x].caption)[0]
                delete field['id'];
                field.Id = fieldsID[x].id;
                field.Formid = formID;
                let form = new FormData();
                form.append('id', field.Id);
                form.append('formid', field.Formid);
                form.append('caption', field.caption);
                form.append('type', field.type);
                form.append('sequenceNumber', field.sequenceNumber)
                if (field.maxlength !== undefined && field.maxlength !== null) {
                    form.append('maxlength', field.maxlength);
                } //else form.append('maxlength', null);
                if (field.minlength !== undefined && field.minlength !== null) {
                    form.append('minlength', field.minlength);
                } //else form.append('minlength', null);
                if (field.datatype !== undefined) {
                    form.append('datatype', field.datatype);
                } else form.append('datatype', null);
                // for (var pair of form.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }
                axios({
                    method: 'put',
                    url: apiURL + 'formfield/put',
                    data: form
                }).then(x => {
                    resolve()
                }).catch(error => {
                    console.log(error)
                    reject()
                })
            }
        }
        // console.log(formID)
        // console.log(fields)
    })
}

const addFields = (formID, fields) => {
    return new Promise((resolve, reject) => {
        if (fields.length === 0) {
            resolve()
        } else {
            const fieldsArray = []
            for (let x in fields) {
                let field;
                if (fields[x].fieldvalues !== undefined) {
                    const fieldvalues = fields[x].fieldvalues.split(',')
                    const formattedfieldvalues = fieldvalues.map(x => {
                        return {
                            value: x
                        }
                    })
                    field = { ...fields[x], formid: formID, fieldvalues: formattedfieldvalues }
                } else {
                    field = { ...fields[x], formid: formID }
                }
                const valueCheck = ['maxlength', 'minlength'];
                valueCheck.forEach(x => {
                    if (field[x] === undefined) {
                        field[x] = null;
                    }
                })
                if (field['id'] !== undefined) {
                    delete field['id'];
                }
                if (field['datatype'] === undefined) {
                    field['datatype'] = null;
                }
                fieldsArray.push(field)
            }
            const form = new FormData();
            form.append("Fields", JSON.stringify(fieldsArray));
            console.log(JSON.stringify(fieldsArray))
            axios({
                method: 'post',
                url: apiURL + 'formfield/post',
                data: form
            }).then(x => {
                resolve()
            })
                .catch(x => {
                    reject()
                })
        }
    })

}

export const LoadAllFormsWithID = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'form/getall'
            }).then(({ data }) => {
                const newData = data.map(x => {
                    return {
                        value: x.id,
                        text: x.name
                    }
                })
                dispatch({
                    type: LOAD_FORMS_WITH_ID,
                    payload: newData
                })
                resolve('done');
            }).catch(x => {
                reject('failed');
            })
        })
    }
}

const deleteFields = (fields) => {
    return new Promise((resolve, reject) => {
        if (fields.length === 0) {
            resolve()
        } else {
            let check = true;
            for (let x in fields) {
                const form = new FormData();
                form.append("Id", fields[x].id);
                axios({
                    method: 'delete',
                    url: apiURL + 'formfield/delete',
                    data: form
                }).then(x => { })
                    .catch(x => { check = false; reject() })
            }
            if (check === true) {
                resolve()
            } else {
                reject()
            }
        }
    })
}

const formNameChange = (id, name) => {
    return new Promise((resolve, reject) => {
        if (name === false) {
            resolve();
        } else {
            const form = new FormData();
            form.append("Id", id);
            form.append("name", name);
            axios({
                method: 'put',
                url: apiURL + 'form/put',
                data: form
            }).then(x => resolve())
                .catch(x => reject())
        }
    })
}

export const createForm = (formName, fields) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            var form = new FormData();
            form.append('formname', formName);
            form.append('fields', JSON.stringify(fields));
            axios({
                method: 'post',
                url: apiURL + 'form/post',
                data: form,
            }).then(x => {
                resolve('done')
            }).catch(x => {
                reject('failed')
            })
        })
    }
}

export const LoadFormByID = (id) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'form/get/' + id,
            }).then(({ data }) => {
                console.log(data)
                dispatch({
                    type: LOAD_FORM_BY_ID,
                    payload: data
                })
                resolve('done')
            }).catch((error) => {
                reject('failed')
            });
        })
    }
}

export const UnloadForm = () => {
    return ({
        type: LOAD_FORM_BY_ID,
        payload: null
    })
}

export const LoadForms = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'form/getall',
            }).then(({ data }) => {
                dispatch({
                    type: LOAD_FORMS,
                    payload: data
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('failed')
                });
        })
    }
}

export const deleteForm = (id) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('id', id);
            axios({
                method: 'delete',
                url: apiURL + 'form/delete',
                data: form
            }).then(({ data }) => {
                resolve('done')
                dispatch({
                    type: REMOVE_FORM,
                    payload: id
                })
            }).catch(({ data }) => {
                reject('failed')
            })
        })
    }
}

export const copyForm = (object) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            Object.keys(object).map((key, index) => {
                form.append(key.toLowerCase(), object[key])
            });
            axios({
                method: 'post',
                url: apiURL + 'form/copy',
                data: form
            }).then((response) => {
                console.log(response);
                resolve('done')
            }).catch((error) => {
                console.log(error);
                reject('failed')
            })
        })
    }
}