import React, { useState } from "react";
import {
  Button,
  Grid,
  Icon,
  Label,
  Popup,
  Header,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import DataTable from "../components/dataTable";
import LoaderView from "../components/loaderView";
import FillForm from "../components/forms/fillForm";
import * as actions from "../actions";
import ActionOutcomeForm from "../components/forms/actionoutcomeForm";
import PhoneForm from "../components/forms/phoneForm";

class CallerWindow extends React.Component {
  state = {
    displayOutcome: false,
    loaded: false,
    hasNextCallee: false,
    hasPreviousCalle: false,
    loading: false,
    addPhoneMessage: null,
    addPhoneLoading: false,
    // phoneNumbers: this.props.isRandom
    //   ? this.props.callee.phoneNumbers.map((num) => ({
    //       ...num,
    //       text: num.number,
    //       value: num.number,
    //     }))
    //   : [this.props.phoneNumbers],
  };
  static getDerivedStateFromProps(props, state) {
    let { callee, isRandom } = props;
    console.log(callee);
    if (isRandom && callee.phoneNumbers !== undefined) {
      return {
        phoneNumbers: callee.phoneNumbers.map((num) => ({
          ...num,
          text: num.number,
          value: num.number,
        })),
      };
    }
    if (callee.phoneNumbers !== undefined) {
      return {
        phoneNumbers: callee.phoneNumbers.map((num) => ({
          ...num,
          text: num.number,
          value: num.number,
        })),
      };
    }
    // if (props.phoneNumbers !== state.phoneNumbers) {
    //   return {
    //     phoneNumbers: props.phoneNumbers,
    //   };
    // }
    return null;
  }
  componentDidMount = () => {
    let { defaulterId } = this.props.callee;
    if (defaulterId) this.props.LoadPhoneNumbers(defaulterId);
  };
  AddressTable = () => {
    let { defaulterAddresses } = this.props.callee;
    if (defaulterAddresses === undefined || defaulterAddresses === null) {
      return <></>;
    }
    return (
      <Grid.Column width={16}>
        <DataTable
          header={[
            {
              name: "Address",
              accessor: "address",
            },
            // {
            //     name: 'Region',
            //     accessor: 'region'
            // },
            // {
            //     name: 'City',
            //     accessor: 'city'
            // }
          ]}
          actions={(x) => this.LoadAddressActions(x)}
          show={true}
          data={defaulterAddresses}
          noPagination
          // tableSize="tiny"
          compact={true}
          // collapsing
        />
      </Grid.Column>
    );
  };

  renderOutcome = (value) => {
    this.props
      .GetActionOutcome(value)
      .then((x) => {
        this.setState({ displayOutcome: true });
      })
      .catch((x) => {
        this.setState({ loaded: true });
      });
  };

  LoadAddressActions = (address) => {
    return (
      <>
        <Button
          data-tooltip="Open Location in Google Maps"
          data-position="top right"
          circular
          color="blue"
          size="tiny"
          disabled={false}
          icon="paper plane"
          onClick={() => this.renderLocation(address)}
        />
      </>
    );
  };
  renderLocation = (Address) => {
    console.log(Address);
    let { longitude, latitude, address } = Address;
    let link;
    if (longitude === null || latitude === null) {
      link = `http://maps.google.com?q=${address}`;
    } else {
      link = `http://maps.google.com?q=${latitude},${longitude}`;
    }
    window.open(link);
  };

  ActionHistory = () => {
    let { actions } = this.props.callee;
    if (actions === undefined || actions === null) return <></>;
    actions.map((action) => {
      let { user, outcome } = action;

      if (user && typeof user === "object" && user !== null) {
        let { firstname, lastname } = user;
        action.username = `${firstname} ${lastname}`;
      }

      if (outcome && typeof outcome === "object" && outcome !== null) {
        let { result } = outcome;
        if (result && typeof result === "object" && result !== null) {
          let { tag } = result;
          action.resultTag = tag;
        }
      }
    });
    return (
      <Grid.Column width={16}>
        <DataTable
          header={[
            {
              name: "Tag",
              accessor: "tag",
            },
            {
              name: "Date",
              accessor: "date",
            },
            {
              name: "Medium",
              accessor: "medium",
            },
            {
              name: "Username",
              accessor: "username",
            },
            {
              name: "Rresult Tag",
              accessor: "resultTag",
            },
          ]}
          actions={(x) => this.actionDetailsHistoryButton(x)}
          show={true}
          data={actions}
          noPagination
          // tableSize="tiny"
          compact={true}
          overflowX
        />
      </Grid.Column>
    );
  };

  renderOutcomeBox = () => {
    return (
      <>
        <ActionOutcomeForm
          size="large"
          open={this.state.displayOutcome}
          onCloseHandle={() => {
            this.setState({ displayOutcome: false });
          }}
          header={{ icon: "user", text: "Outcome" }}
          actionsComponent={this.renderOutcomeBoxActions()}
          loading={this.props.loading}
          OutCome={this.props.outcome}
        />
      </>
    );
  };

  renderOutcomeBoxActions = () => {
    return (
      <>
        <Button
          disabled={this.state.loading}
          basic
          color="red"
          onClick={() => this.setState({ displayOutcome: false })}
        >
          <Icon name="remove" />
          Close
        </Button>
      </>
    );
  };

  actionDetailsHistoryButton = (value) => {
    return (
      <Popup
        content={`View Action Details`}
        position="top right"
        on="hover"
        trigger={
          <Button
            size="mini"
            color="black"
            icon="eye"
            disabled={false}
            onClick={() => {
              this.renderOutcome(value.id);
            }}
          />
        }
      />
    );
  };

  contactAcionButton = (value) => {
    let { id, isInvalid } = value;
    return (
      <>
        <Popup
          content={`Mark this number as ${isInvalid ? "valid" : "invalid"}`}
          position="top right"
          on="hover"
          trigger={
            <Button
              size="mini"
              color={isInvalid ? "red" : "green"}
              icon={isInvalid ? "circle outline" : "circle"}
              disabled={false}
              onClick={() => {
                this.props
                  .toggleContactValidity(id, !isInvalid)
                  .then(() => {
                    value.isInvalid = !isInvalid;
                    this.setState({});
                  })
                  .catch(() => {});
              }}
            />
          }
        />
      </>
    );
  };

  addPhoneModal = () => {
    return (
      <PhoneForm
        size="mini"
        label
        open={this.state.openAddPhoneModal}
        onSubmit={this.submitAddPhone}
        loading={this.state.addPhoneLoading}
        onCloseHandle={() => {
          this.setState({
            openAddPhoneModal: false,
            addPhoneMessage: null,
          });
        }}
        header={{ icon: "add", text: "Add Phone" }}
        actionsComponent={this.addPhoneActions()}
        message={this.state.addPhoneMessage}
      />
    );
  };

  addPhoneActions = () => {
    return (
      <>
        <Button
          floated="left"
          onClick={() => {
            this.setState({ openAddPhoneModal: false, addPhoneMessage: null });
          }}
        >
          Close
        </Button>
        <Button
          loading={this.state.addPhoneLoading}
          color="green"
          type="submit"
          form="phoneForm"
        >
          Submit
        </Button>
      </>
    );
  };

  submitAddPhone = (value) => {
    console.log(value);
    this.setState({ addPhoneLoading: true });
    let { defaulterId } = this.props.callee;
    let { number } = value;
    this.props
      .addPhoneNumber(defaulterId, number)
      .then(() => {
        this.setState({
          addPhoneLoading: false,
          addPhoneMessage: {
            type: "success",
            header: "Success",
            content: "Phone Number Added Successfully",
          },
        });
        this.props.LoadPhoneNumbers(defaulterId).then(() => {});
      })
      .catch(() => {
        this.setState({
          addPhoneLoading: false,
          addPhoneMessage: {
            type: "error",
            header: "Failed",
            content: "Phone Number could not be added.",
          },
        });
      });
  };

  ContactTable = () => {
    // let { phoneNumbers } = this.props.callee;
    // let phoneNumbers = [];
    // console.log(phoneNumbers);
    let phoneNumbers = this.props.phoneNumbers;
    let actions = this.props.callee.actions;

    let lastCalledNumAction = actions.find(x => x.outcome.calledPhoneNumber !== null && 
        x.outcome.calledPhoneNumber !== undefined && x.outcome.calledPhoneNumber !== "");

    let lastCalledNum;
    if(lastCalledNumAction !== undefined && lastCalledNumAction !== null) {
      lastCalledNum = lastCalledNumAction.outcome.calledPhoneNumber;
    }

    if (phoneNumbers === undefined || phoneNumbers === null) return <></>;

    return (
      <>
        <Grid.Column width={16}>
          <Header size="medium">
            Contact Details:
            <Button
              icon="add"
              content="Add"
              onClick={() => {
                this.setState({
                  openAddPhoneModal: true,
                });
              }}
              size="tiny"
              floated="right"
            />
          </Header>
          <DataTable
            header={[
              {
                name: "Number",
                accessor: "number",
                component: (x, parentKey) => {
                  return (
                    <>
                      {x.number}
                      {x.number === lastCalledNum ? (
                        <Icon name={"phone"} color={"green"} size="big" />
                      ) : null}
                    </>
                  );
                },
              },
            ]}
            show={true}
            actions={(x) => this.contactAcionButton(x)}
            data={phoneNumbers}
            noPagination
            // tableSize="tiny"
            compact={true}
            // collapsing
          />
        </Grid.Column>
      </>
    );
  };

  GridColumnWidth = (data, size, align = "left", isBold = false) => (
    <Grid.Column width={size} textAlign={align}>
      {isBold ? <strong>{data}</strong> : data}
    </Grid.Column>
  );

  LoadNameAndDetails = () => {
    let {
      defaulterName,
      companyTrackingId,
      balance,
      companyName,
      defaulterAddresses,
      defaulterHistories
    } = this.props.callee;

    console.log(this.props.callee);

    //server returns actions in descending order by outcome datetime
    let latestConsumerNameAction = this.props.callee.actions.find(x => x.outcome !== null 
      && x.outcome !== undefined && x.outcome.formValues !== null && x.outcome.formValues !== undefined
        && x.outcome.formValues.find(y => y.caption.includes("Consumer Name")));

    let currentConsumerName = undefined;

    if (latestConsumerNameAction !== undefined) {
      //console.log(latestAction.outcome.formValues);
      let formValue = latestConsumerNameAction.outcome.formValues.find((x) =>
        x.caption.includes("Consumer Name")
      );
      currentConsumerName = formValue.value;
    }
    
    let lastPayAmt = "No data";
    let lastPayDate = "No data";
    let accountTag = "No Data";
    if(defaulterHistories !== null && defaulterHistories.length > 0)
    {
      let hist = defaulterHistories.find(x => x.isCurrent === true);
      if(hist !== undefined && hist !== null){
        lastPayAmt = hist.lastPaymentAmount ?? "No data";
        lastPayDate = hist.lastPaymentDate ?? "No data";
        accountTag = hist.tag ?? "No data";
      }
    }

    let cd = "No data";
    let area = "No data";
    if(defaulterAddresses !== null && defaulterAddresses.length > 0)
    {
      cd = defaulterAddresses[0].type;
      area = defaulterAddresses[0].region.name;
    }

    return (
      <>
        {currentConsumerName !== undefined
          ? this.GridColumnWidth("Current Consumer Name: ", 6)
          : null}
        {currentConsumerName !== undefined
          ? this.GridColumnWidth(currentConsumerName, 10, null, true)
          : null}
        {this.GridColumnWidth("Name in System: ", 4)}
        {this.GridColumnWidth(defaulterName, 4, "left", true)}
        {this.GridColumnWidth("Tracking Id: ", 4)}
        {this.GridColumnWidth(companyTrackingId, 4, "left", true)}
        {this.GridColumnWidth("Balance: ", 4)}
        {this.GridColumnWidth(balance, 4, "left", true)}
        {this.GridColumnWidth("Company: ", 4)}
        {this.GridColumnWidth(companyName, 4, null, true)}
        {this.GridColumnWidth("Cycle Day: ", 4)}
        {this.GridColumnWidth(cd, 4, null, true)}
        {this.GridColumnWidth("Area: ", 4)}
        {this.GridColumnWidth(area, 4, null, true)}
        {this.GridColumnWidth("Account Tag ", 3)}
        {this.GridColumnWidth(accountTag, 3, null, true)}
        {this.GridColumnWidth("Last Payment Amount: ", 5)}
        {this.GridColumnWidth(lastPayAmt, 4, null, true)}
        {this.GridColumnWidth("Last Payment Date: ", 5)}
        {this.GridColumnWidth(lastPayDate, 4, null, true)}
        {this.GridColumnWidth("", 7)}
      </>
    );
  };

  showOtherDetails = () => {
    const { otherDetails } = this.props.callee;
    if (otherDetails === undefined || otherDetails === null) return <></>;
    return (
      <>
        {otherDetails.map((item) => {
          return (
            <>
              {this.GridColumnWidth(`${item.key} :`, 4)}
              {this.GridColumnWidth(`${item.value}`, 4, null, true)}
            </>
          );
        })}
      </>
    );
  };

  prevButton = (onClickHandler, disable = false, loading = false) => (
    <Button
      fluid={false}
      compact
      animated
      disabled={disable}
      floated={"left"}
      // primary
      loading={loading}
      onClick={onClickHandler}
    >
      <Button.Content visible>Dial Previous</Button.Content>
      <Button.Content hidden>
        <Icon name="arrow left" />
      </Button.Content>
    </Button>
  );

  nextButton = (isRandom, onClickHandler, disable = false, loading = false) => (
    <Button
      fluid={!isRandom}
      compact
      animated
      disabled={disable}
      floated={"right"}
      loading={loading}
      primary
      onClick={onClickHandler}
    >
      <Button.Content visible>Dial Next</Button.Content>
      <Button.Content hidden>
        <Icon name="arrow right" />
      </Button.Content>
    </Button>
  );
  render() {
    //console.log(this.props);
    //console.log(this.state);
    let { assignedByUserName } = this.props.callee;
    let {
      nextCallee,
      isRandom,
      loadingNext,
      prevCallee,
      loadingPrev,
    } = this.props;
    return (
      <>
        {this.addPhoneModal()}
        <Grid>
          <Grid.Row columns={2} width={16}>
            {/* <Segment size="mini"> */}
            <Grid.Column width={8}>
              {!isRandom
                ? // <Button onClick={this.props.onBack}>Back</Button>
                  this.prevButton(
                    this.props.onClickPrev,
                    prevCallee === null,
                    loadingPrev
                  )
                : null}
              Assigned By: <strong>{assignedByUserName}</strong>
            </Grid.Column>
            {isRandom ? (
              <Grid.Column width={8} floated={"right"} textAlign={"right"}>
                {this.nextButton(isRandom, this.props.onClickNext)}
              </Grid.Column>
            ) : (
              <>
                <Grid.Column width={6}>
                  Next:{" "}
                  <strong>
                    {nextCallee ? nextCallee.defaulterName : "None"}
                  </strong>
                  <br />
                  Phone:{" "}
                  <strong>{nextCallee ? nextCallee.contact ? nextCallee.contact[0] : "None" : "None"}</strong>
                </Grid.Column>
                <Grid.Column width={2}>
                  {this.nextButton(
                    false,
                    this.props.onClickNext,
                    !nextCallee,
                    loadingNext
                  )}
                </Grid.Column>
              </>
            )}
            {/* </Segment> */}
          </Grid.Row>
          <Grid.Row columns={2}>
            {Object.keys(this.props.callee).length === 0 ? (
              <Grid.Column width={16}>
                <Segment style={{ textAlign: "center", fontSize: "1.3rem" }}>
                  <Icon name="exclamation triangle" />
                  {this.props.noDataInfo === undefined
                    ? "No data found"
                    : this.props.noDataInfo}
                </Segment>
              </Grid.Column>
            ) : loadingPrev ? (
              <Grid.Column width={16} style={{ height: "500px" }}>
                <LoaderView
                  text={`Previous${
                    isRandom ? "Random" : " "
                  } Callee is being Loaded`}
                />
              </Grid.Column>
            ) : loadingNext ? (
              <Grid.Column width={16} style={{ height: "500px" }}>
                <LoaderView
                  text={`Next${
                    isRandom ? "Random" : " "
                  } Callee is being Loaded`}
                />
              </Grid.Column>
            ) : (
              <>
                <Grid.Column>
                  <Segment
                    style={{
                      maxHeight: 600,
                      overflowY: "scroll",
                      overflowX: "none",
                    }}
                  >
                    <div>
                      <Grid columns={"equal"}>
                        {this.LoadNameAndDetails()}
                        {this.showOtherDetails()}
                        {this.ContactTable()}
                        {this.AddressTable()}
                        {this.ActionHistory()}
                        {this.renderOutcomeBox()}
                      </Grid>
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment loading={this.props.formLoading}>
                    <FillForm
                      noSupporting
                      tagOptions={this.props.tagsList.filter(x => x.isActive)}
                      onSubmit={this.props.onSubmitForm}
                      submitLoading={this.props.submitLoading}
                      formInfo={
                        this.props.loadedForm === null ||
                        this.props.loadedForm === undefined
                          ? []
                          : this.props.loadedForm
                      }
                      onClose={() => this.props.UnloadForm()}
                      phoneNumbers={this.state.phoneNumbers}
                    />
                  </Segment>
                </Grid.Column>
              </>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button onClick={this.props.onBack} color="red">Close</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

const mstp = (state) => {
  return {
    randomCallee: state.teleCaller.randomCallee,
    userbyID: state.user.userbyID,
    loadedForm: state.myForms.loadedForm,
    tagsList: state.tags.data,
    outcome: state.actions.outcome,
    phoneNumbers: state.teleCaller.phoneNumbers,
  };
};

export default connect(mstp, actions)(CallerWindow);
