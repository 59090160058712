import React from 'react';

import { Field, reduxForm } from 'redux-form'

import { Form, Message, Modal, Header, Icon } from 'semantic-ui-react'

import { CommonInput } from './commonInput';



let CompanyForm = (props) => {
    console.log(props)
    return (
        <div>
            <Modal
                size={props.size}
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header>
                    <Icon name={props.header.icon} />
                    {props.header.text}
                </Header>
                <Modal.Content>
                    <Form
                        id="companyForm"
                        error={props.Message !== null ? props.Message.type === 'error' : false}
                        success={props.Message !== null ? props.Message.type === 'success' : false}
                        onSubmit={props.handleSubmit}
                        size='large'>
                        {props.label ? <><span>Company Name</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={props.loading}
                            fluid
                            name="Name"
                            component={CommonInput}
                            placeholder="Company Name"
                        />
                        {props.label ? <><span>Contact Person</span><div style={{ marginBottom: '3px' }} /></> : null}
                        <Field
                            disabled={props.loading}
                            fluid
                            name="Contactperson"
                            component={CommonInput}
                            placeholder="Contact Person"
                        />
                        {props.label ? <><span>Contact Number</span><div style={{ marginBottom: '3px' }} /></> : null}

                        <Field
                            disabled={props.loading}
                            fluid
                            name="Contactnum"
                            component={CommonInput}
                            placeholder="Contact Number"
                            type="number"
                        />

                        {props.Message !== null ?
                            <Message
                                error={props.Message !== null ? props.Message.type === 'error' : false}
                                success={props.Message !== null ? props.Message.type === 'success' : false}
                                header={props.Message.header}
                                content={props.Message.content}
                            />
                            :
                            null
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {props.actionsComponent}
                </Modal.Actions>
            </Modal>


        </div>
    )
}


const valueCheck = (value, name, minLength = null) => {
    if (!value) {
        return `You must enter new ${name}`
    } else {
        if (minLength) {
            if (value.length < minLength) {
                return `${name} must be at least ${minLength} characters`
            }
        }
    }
}



const validate = (formValues) => {
    const errors = {};
    errors.Name = valueCheck(formValues.Name, 'company name', 3)
    errors.Contactperson = valueCheck(formValues.Contactperson, 'contact person', 3)
    errors.Contactnum = valueCheck(formValues.Contactnum, 'contact number', 10)
    return errors;
}


CompanyForm = reduxForm({
    form: 'company',
    validate,
    enableReinitialize: true
})(CompanyForm)


export default CompanyForm;