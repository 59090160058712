import {
    LOAD_FORMS,
    REMOVE_FORM,
    LOAD_FORM_BY_ID,
    LOAD_FORMS_WITH_ID
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    loadedForm: null,
    formsWithIds: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_FORMS:
            return { ...state, data: action.payload };
        case LOAD_FORM_BY_ID:
            return { ...state, loadedForm: action.payload }
        case REMOVE_FORM:
            return { ...state, data: state.data.filter(x => x.id !== action.payload) }
        case LOAD_FORMS_WITH_ID:
            return { ...state, formsWithIds: action.payload }
        default:
            return state;
    }
}