import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { reset, Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input, Checkbox, Button } from 'semantic-ui-react'
import { CommonSelect } from './commonInput';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)


let SIZE = 'tiny';
let TYPES = [
    { value: 'Survey', text: 'Survey' },
    { value: 'Follow Up', text: 'Follow Up' }
]
let TAGS = [
    { value: 'Promise', text: 'Promise' },
    { value: 'Reminder', text: 'Reminder' },
    { value: 'Not Paying', text: 'Not Paying' },
    { value: 'First Contact', text: 'First Contact' },
    { value: 'BD Activity', text: 'BD Activity' }
]

let MEDIUM = [
    { value: 'Sms', text: 'Sms' },
    { value: 'Email', text: 'Email' },
    { value: 'Call', text: 'Call' },
    { value: 'Visit', text: 'Visit' }
]

let TRUEFALSE = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' }
]

// const renderDateTimePicker = ({ input: { onChange, value } }) => {
//     // let newDate = new Date(value)
//     // newDate = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear()
//     return (
//         <div style={{ marginBottom: '15px' }}>
//             <DateTimePicker
//                 dropUp
//                 onChange={onChange}
//                 format="DD/MM/YYYY"
//                 placeholder="Date"
//                 time={false}
//                 value={!value ? null : new Date(value)}
//             />
//         </div>
//     )
// }

class ActionForm extends React.Component {
    state = {
        checkedStatus: null,
    }

    renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) => {
        console.error(value)
        // let newDate = new Date(value)
        // newDate = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear()
        return (
            <div style={{ marginBottom: '15px' }}>
                <DateTimePicker
                    dropUp
                    onChange={onChange}
                    format="DD/MM/YYYY"
                    placeholder="Date"
                    time={showTime}
                    value={!value ? null : new Date(value)}
                />
            </div>
        )
    }

    renderDateField = (name, placeholder, optional, disabled = false) => {
        return (
            <>
                <span>
                    {optional ? placeholder + '    (OPTIONAL)' : placeholder}
                </span>
                <div style={{ marginBottom: '3px' }} />
                <Field
                    disabled={disabled}
                    name={name}
                    component={this.renderDateTimePicker}
                    placeholder={placeholder}
                    showTime={false}
                />
                <div style={{ marginBottom: '15px' }} />
            </>
        )
    }

    renderField = (name, component, placeholder, optional, disabled = false) => {
        return (
            <>
                <span>
                    {optional ? placeholder + '    (OPTIONAL)' : placeholder}
                </span>
                <div style={{ marginBottom: '3px' }} />
                <Field
                    disabled={disabled}
                    name={name}
                    component={component}
                    placeholder={placeholder}
                />
                <div style={{ marginBottom: '15px' }} />
            </>
        )
    }

    renderSelectionField = (name, options, component, placeholder, optional, disable = false) => {
        return (
            <>
                <span>
                    {optional ? placeholder + '    (OPTIONAL)' : placeholder}
                </span>
                <div style={{ marginBottom: '3px' }} />
                <Field
                    disabled={disable}
                    fluid
                    name={name}
                    options={options}
                    component={component}
                    placeholder={placeholder}
                    clearable
                />
            </>
        )
    }

    renderEditForm() {
        // console.log(this.props.Message)
        return (
            <Form
                id="actionForm"
                error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                onSubmit={this.props.handleSubmit}
                size={SIZE}
            >
                {/* {this.props.total ? <><span>Selected : {this.props.total}</span><div style={{ marginBottom: '3px' }} /></> : null} */}
                {/* {this.props.label ? <><span>Tag</span><div style={{ marginBottom: '3px' }} /></> : null} */}
                {this.renderField("id", 'input', "Id", false, true)}
                {this.renderSelectionField("tag", TAGS, CommonSelect, "Tag")}
                {this.renderSelectionField("type", TYPES, CommonSelect, "Type")}
                {this.renderField("source", 'input', "Source", false, true)}
                {this.renderDateField("date", "Date", true)}
                {this.renderSelectionField("medium", MEDIUM, CommonSelect, "Medium")}
                {this.renderSelectionField("isRedone", TRUEFALSE, CommonSelect, "Is Redone?", false, true)}
                {this.renderSelectionField("requiresApproval", TRUEFALSE, CommonSelect, "Requires Approval")}
                {this.renderSelectionField("sortingParameter", this.props.sortingParams, CommonSelect, "Sorting Parameters", false, true)}
                {this.renderSelectionField("isAscending", TRUEFALSE, CommonSelect, "Order", false, true)}
                {this.renderSelectionField("userId", this.props.assignTo, CommonSelect, "Assign To", true)}
                {this.renderField("defaulterId", 'input', "Defaulter Id", false, true)}
                {this.renderSelectionField("formId", this.props.myForms, CommonSelect, "Form", true)}
                {/* {console.log(this.props.Message)} */}
                {this.props.showMessage !== false ?
                    <Message
                        error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                        success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                        header={this.props.Message.header}
                        content={this.props.Message.content}
                    />
                    :
                    null
                }
            </Form>
        )
    }


    renderEditModal() {
        return (
            <Modal
                size={SIZE}
                open={this.props.open}
                onClose={this.props.onCloseHandle}
            >
                <Header>
                    <Icon name={this.props.header.icon} />
                    {this.props.header.text}
                </Header>
                <Modal.Content>
                    {this.renderEditForm()}
                </Modal.Content>
                <Modal.Actions>
                    {this.props.actionsComponent}
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        return (
            <>
                {this.renderEditModal()}
            </>
        )
    }




}

const valueCheck = (value, name, minLength = null) => {
    // console.log(`Value: ${value}, Name: ${name} `)
    // console.log('entered in value check')
    if (!value) {
        //       console.log('Naot Value')
        return `You must select ${name}`
    }
    if (value == null) {
        //      console.log('entered if NULL')
        return 'Please'
    }
    // } else {
    //     if (minLength) {
    //         if (value.length < minLength) {
    //             return `${name} must be at least ${minLength} characters`
    //         }
    //     }
    // }
}

const validate = (formValues) => {
    const errors = {};
    errors.Tag = valueCheck(formValues.Tag, 'tag')
    errors.Type = valueCheck(formValues.Type, 'type')
    errors.Medium = valueCheck(formValues.Medium, 'medium')
    //errors.RequiresApproval = valueCheck(formValues.RequiresApproval, 'requires approal')
    //errors.Userid = valueCheck(formValues.Userid, 'assign to')
    //errors.Formid = valueCheck(formValues.Formid, 'form')
    return errors;
}





ActionForm = reduxForm({
    form: 'actions',
    validate,
    enableReinitialize: true
})(ActionForm)

export default ActionForm;