import axios from 'axios';

import { 
    LOAD_SELF_ATTENDANCES,
    LOAD_DUTY_STATUS,
    IN_DUTY_REASON,
    LOAD_USER_ATTENDANCES,
    LOAD_OFFICE_TIMING,
    LOAD_APP_TIMING
} from './types'

const apiURL = require('../config.json').apiURL;

export const LoadUserAttendances = (userid,start,end) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL+'attendance/get/datetime',
                params: {
                    id: userid,
                    start: start,
                    end: end
                }
            }).then(({data}) => {            
                dispatch({
                    type: LOAD_USER_ATTENDANCES,
                    payload: data
                })
                resolve('done')
            })
            .catch((error) => {
                reject('error')
            });
        })
    }
}

export const LoadSelfAttendances = (start,end) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL+'attendance/get/datetime',
                params: {
                    start: start,
                    end: end
                }
            }).then(({data}) => {            
                dispatch({
                    type: LOAD_SELF_ATTENDANCES,
                    payload: data
                })
                resolve('done')
            })
            .catch((error) => {
                reject('error')
            });
        })
    }
}

export const DutyCheck = () => {
    return dispatch => {
        return new Promise ((resolve, reject)=> {
            axios({
                method: 'get',
                url: apiURL+'attendance/ismydutyon'
            }).then(({data}) => {
                dispatch({
                    type: LOAD_DUTY_STATUS,
                    payload: data
                })
                resolve('done')
            })
            .catch((error) => {
                reject('error')
            });
        })
    }
}

export const DismissReason = () => {
    return ({
        type: IN_DUTY_REASON,
        payload: null
    })
}

export const SubmitReason = (Attendanceid,reason) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('reason',reason)
            form.append('Attendanceid',Attendanceid)
            axios({
                method: 'post',
                url: apiURL+'attendance/postreason',
                data: form
            }).then(({data}) => {
                dispatch({
                    type: IN_DUTY_REASON,
                    payload: null
                })
                resolve('done')
            })
            .catch((error) => {
                reject('error')
            });
        })
    }   
}

export const AddAttendance = (value) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('onduty',value)
            axios({
                method: 'post',
                url: apiURL+'attendance/post',
                data: form
            }).then(({data}) => {
                console.log(data)            
                if (data.message !== ""){
                    dispatch({
                        type: IN_DUTY_REASON,
                        payload: data
                    })
                }
                dispatch({
                    type: LOAD_DUTY_STATUS,
                    payload: value
                })
                resolve('done')
            })
            .catch((error) => {
                reject('error')
            });
        })
    }
}


export const LoadOfficeTiming = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'attendance/getofficetiming'
            }).then(({ data }) => {
                dispatch({
                    type: LOAD_OFFICE_TIMING,
                    payload: data
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('error')
                })
        })
    }
}

export const SetOfficeTiming = (startTime, endTime) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('Start', startTime);
            form.append('End', endTime);
            axios({
                method: 'post',
                url: apiURL + 'attendance/setofficetiming',
                data: form
            })
                .then(({ data }) => {
                    console.log(data)
                    dispatch(LoadOfficeTiming())
                    resolve('done')
                })
                .catch((error) => {
                    reject('error')
                });
        })
    }
}

export const LoadAppTiming = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'apptime/getapptime'
            }).then(({ data }) => {
                console.log(data)
                dispatch({
                    type: LOAD_APP_TIMING,
                    payload: data
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('error')
                })
        })
    }
}

export const SetAppTiming = (startTime, endTime) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('Start', startTime);
            form.append('End', endTime);
            axios({
                method: 'post',
                url: apiURL + 'apptime/setapptime',
                data: form
            })
                .then(({ data }) => {
                    console.log(data)
                    dispatch(LoadAppTiming())
                    resolve('done')
                })
                .catch((error) => {
                    reject('error')
                });
        })
    }
}

export const LoadImage = (path) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // var data = path.getBytes("UTF-8");
            // var base64 = Base64.encodeToString(data, Base64.DEFAULT);
            var base64 = btoa(path)
            console.log(base64)
            axios({
                method: 'get',
                url: apiURL + `file/getimagestring/${base64}`
            }).then((response) => {
                var image = new Image();
                image.src = "data:image/png;base64," + response.data.image
                // console.log(image)
                // image.url = response.data
                var w = window.open("");
                w.document.write(image.outerHTML)
                console.log(response)
                base64 = ''
                resolve('done')
            }).catch((error) => {
                //   if (error.response.status === 403) {
                reject('failed')
                // } else {
                //     // dispatch(logOut())
                // }
            });
        })
    }
}