import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import DataTable from '../components/dataTable';
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';
import { Segment, Header, Button } from 'semantic-ui-react';
import LogFilterForm from '../components/forms/logFilterForm';

class Logs extends Component {

    state = {
        access: false,
        loaded: false,
        loadTable: false,
        searchParameter: {},
        triggerUpdate: 0,
        filterShow: false
    }



    tablePaging = (pageSize, currentPage) => {
        this.setState({ loadTable: true })
        this.props.LoadLogs(pageSize, currentPage, this.state.searchParameter)
            .then(x => this.setState({ loaded: true, access: true, loadTable: false }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    }


    renderLogBox() {
        return (
            <div style={styles.mainDiv}>
                {this.state.access && this.state.loaded ? this.renderFilterBox() : null}
                <DataTable
                    header={[
                        { name: 'Log ID', accessor: 'id', width: 1 },
                        { name: 'Event', accessor: 'event', width: 1 },
                        { name: 'TimeStamp', accessor: 'timestamp', width: 2 },
                        // { name: 'Date', accessor: 'date', width: 2 },
                        { name: 'Table Name', accessor: 'tablename', width: 3 },
                        { name: 'User ID', accessor: 'userId', width: 1 },
                        { name: 'Platform', accessor: 'platform', width: 1 },
                        { name: 'IP Address', accessor: 'ipAddress', width: 1 },
                        { name: 'Details', accessor: 'details', width: 5 },
                    ]}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    rowCount={this.props.total}
                    data={this.props.data}
                    pageSizes={[10, 20, 50, 100]}
                    trigger={this.state.triggerUpdate}
                    onPageChange={(x, y) => this.tablePaging(x, y)}
                />
            </div>
        )
    }

    renderFilterBox = () => {
        return (
            <div style={{ marginBottom: 10 }}>
                <Header as='h3' attached='top'>
                    Filter Logs
                    <Button
                        primary
                        floated="right" size="mini"
                        onClick={() => {
                            this.setState({ filterShow: !this.state.filterShow })
                            if (this.state.filterShow == true && JSON.stringify(this.state.searchParameter) !== "{}") {
                                this.setState({ searchParameter: {} }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))
                            }
                        }}
                    >
                        {this.state.filterShow ? 'Hide' : 'Show'}
                    </Button>
                </Header>
                {this.state.filterShow ?
                    <Segment attached>
                        <LogFilterForm
                            onSubmit={(x) => {
                                // console.log(x)
                                // if (x.from !== undefined && x.from !== null && typeof x.from === 'object') {
                                //     x.from = x.from.toLocaleTimeString()
                                // }
                                // if (x.to !== undefined && x.to !== null && typeof x.to === 'object') {
                                //     x.to = x.to.toLocaleTimeString()
                                // }
                                this.setState({ searchParameter: x }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))
                            }
                            }
                        />
                    </Segment>
                    : null
                }
            </div>
        )
    }


    render() {
        return (
            <>
                {!this.state.loaded ? <LoaderView text="Log Page is loading" /> :
                    this.state.access ? null : <UnauthorizedView pageName="Log Page" />}
                {this.renderLogBox()}
            </>
        )
    }
}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

const mstp = (state) => {
    const newData = state.log.data.map(x => {
        const newTimeDate = new Date(x.datetime)
        return { ...x, time: newTimeDate.toLocaleTimeString(), date: newTimeDate.toDateString() }
    })
    return ({
        data: newData,
        total: state.log.total
    })
}

export default connect(mstp, actions)(Logs);