import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Tab,
  Label,
  Input,
  Segment,
  Grid,
  Header,
} from "semantic-ui-react";
import LoaderView from "../components/loaderView";
import UnauthorizedView from "../components/unauthorizedView";
import DatePicker from "react-datepicker";
import * as actions from "../actions";
import DataTable from "../components/dataTable";
import ConfirmBox from "../components/confirmBox";

import "react-datepicker/dist/react-datepicker.css";
import EditTag from "../components/forms/EditTagForm";
import { reset } from "redux-form";

class Settings extends Component {
  state = {
    access: false,
    loaded: false,
    startTime: null,
    endTime: null,
    currentTiming: null,
    changeTimiming: false,
    maxOnTime: null,
    minOffTime: null,
    appStartTime: null,
    appEndTime: null,
    startAppTime: null,
    endAppTime: null,
    addLoading: false,
    newTagName: "",
    tagOnDelete: null,
    showDeleteConfirmation: false,
    triggerUpdate: 1,
    tagForEdit: null,
    editLoading: false,
    selectedTab: localStorage.getItem("selectedTagsTab") ?? "Visit",
    selectedTabIndex: localStorage.getItem("selectedTagsTabIndex") ?? 0,
  };

  componentWillMount() {
    this.props
      .LoadOfficeTiming()
      .then((x) => {
        this.setState({
          maxOnTime: this.props.officeTiming.maxOnTime,
          minOffTime: this.props.officeTiming.minOffTime,
          loaded: true,
          access: true,
        });
      })
      .catch((x) => {
        this.setState({ loaded: true, access: false });
      });

    this.props
      .LoadAppTiming()
      .then((x) => {
        this.setState({
          appStartTime: this.props.appTiming.startTime,
          appEndTime: this.props.appTiming.endTime,
          loaded: true,
          access: true,
        });
      })
      .catch(() => this.setState({ loaded: true, access: false }));

    this.renderCurrentTiming();
  }
  componentDidMount() {
    this.render();
    this.setState({ loadTable: true });
    console.log();
    this.props.LoadTags(this.state.selectedTab).then(() => {
      this.setState({ loadTable: false });
    });
  }
  onChangeStartTime = (time) => {
    this.setState({ startTime: time });
  };

  onChangeEndTime = (time) => {
    this.setState({ endTime: time });
  };

  onChangeAppStartTime = (time) => {
    this.setState({ startAppTime: time });
  };

  onChangeAppEndTime = (time) => {
    this.setState({ endAppTime: time });
  };

  renderAppCurrentTiming = () => {
    return (
      <>
        <Segment>
          <Grid>
            <Grid.Column width={6}>
              <Header size="huge">Current App Timings</Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>Start Time:</Header>
              <span>{this.state.appStartTime}</span>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>End Time:</Header>
              <span>{this.state.appEndTime}</span>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  };

  renderCurrentTiming = () => {
    return (
      <>
        <Segment>
          <Grid>
            <Grid.Column width={6}>
              <Header size="huge">Current Office Timings</Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>Start Time:</Header>
              <span>{this.state.maxOnTime}</span>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>End Time:</Header>
              <span>{this.state.minOffTime}</span>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  };

  renderNewTiming = () => {
    return (
      <Segment>
        <Grid>
          <Grid.Column width={16}>
            <Header size="huge">Set New Timings</Header>
          </Grid.Column>
          {this.renderSetStartTime("Start Time")}
          {this.renderSetEndTime("End Time")}
          {this.renderSetTimeButton()}
        </Grid>
      </Segment>
    );
  };

  setTiming = () => {
    const a = this.state.startTime.toLocaleTimeString();
    const b = this.state.endTime.toLocaleTimeString();
    const c =
      a.length === 11
        ? a.substring(0, 5) + a.substring(8, 11)
        : a.substring(0, 4) + a.substring(7, 10);
    const d =
      b.length === 11
        ? b.substring(0, 5) + b.substring(8, 11)
        : b.substring(0, 4) + b.substring(7, 10);

    this.props
      .SetOfficeTiming(c, d)
      .then((x) => {
        this.componentWillMount();
      })
      .catch((x) => {});
  };

  setAppTiming = () => {
    const a = this.state.startAppTime.toLocaleTimeString();
    const b = this.state.endAppTime.toLocaleTimeString();
    const c =
      a.length === 11
        ? a.substring(0, 5) + a.substring(8, 11)
        : a.substring(0, 4) + a.substring(7, 10);
    const d =
      b.length === 11
        ? b.substring(0, 5) + b.substring(8, 11)
        : b.substring(0, 4) + b.substring(7, 10);

    this.props
      .SetAppTiming(c, d)
      .then((x) => {
        this.componentWillMount();
      })
      .catch((x) => {});
  };

  renderSetTimeButton = () => {
    return (
      <>
        <Grid.Column width={3} floated="right">
          <Header size="huge"></Header>
          <Button
            floated="left"
            size="large"
            disabled={
              this.state.startTime === null || this.state.endTime === null
            }
            color="blue"
            inverted
            onClick={() => this.setTiming()}
          >
            Set Time
          </Button>
        </Grid.Column>
      </>
    );
  };

  renderSetStartTime = (label) => {
    return (
      <Grid.Column width={6}>
        <Segment>
          <Header>{label}</Header>
          <DatePicker
            selected={this.state.startTime}
            onChange={(time) => {
              this.onChangeStartTime(time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            timeFormat="hh:mm aa"
          />
        </Segment>
      </Grid.Column>
    );
  };

  renderSetEndTime = (label) => {
    return (
      <Grid.Column width={6}>
        <Segment>
          <Header>{label}</Header>
          <DatePicker
            selected={this.state.endTime}
            onChange={(time) => this.onChangeEndTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            timeFormat="hh:mm aa"
          />
        </Segment>
      </Grid.Column>
    );
  };

  renderAppSetStartTime = (label) => {
    return (
      <Grid.Column width={6}>
        <Segment>
          <Header>{label}</Header>
          <DatePicker
            selected={this.state.startAppTime}
            onChange={(time) => {
              this.onChangeAppStartTime(time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            timeFormat="hh:mm aa"
          />
        </Segment>
      </Grid.Column>
    );
  };

  renderAppSetEndTime = (label) => {
    return (
      <Grid.Column width={6}>
        <Segment>
          <Header>{label}</Header>
          <DatePicker
            selected={this.state.endAppTime}
            onChange={(time) => this.onChangeAppEndTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            timeFormat="hh:mm aa"
          />
        </Segment>
      </Grid.Column>
    );
  };

  renderAppSetTimeButton = () => {
    return (
      <>
        <Grid.Column width={3} floated="right">
          <Header size="huge"></Header>
          <Button
            floated="left"
            size="large"
            disabled={
              this.state.startAppTime === null || this.state.endAppTime === null
            }
            color="blue"
            inverted
            onClick={() => this.setAppTiming()}
          >
            Set Time
          </Button>
        </Grid.Column>
      </>
    );
  };

  renderActionButtons = () => {
    return (
      <>
        <Button
          name="close"
          icon="x"
          color="red"
          disabled={false}
          loading={this.state.editLoading}
          onClick={() => {
            // this.props.reset("tagEdit");
            this.setState({ tagForEdit: null });
          }}
          content="Close"
        />
        <Button
          //disabled={this.state.loading}
          loading={this.state.editLoading}
          form="tagEdit"
          color="green"
          type="submit"
          icon="checkmark"
          content="Confirm"
        />
      </>
    );
  };

  handleSubmit = (value) => {
    this.setState({ editLoading: true });
    console.log(value);
    this.props
      .UpdateTag(value.id, value.tag, this.state.selectedTab)
      .then(() => {
        this.setState({ editLoading: false });
      })
      .catch(() => {
        this.setState({ editLoading: false });
        console.log("error");
      });
  };

  renderTagEdit = () => {
    console.log(this.state.tagForEdit);
    return (
      <>
        {
          <EditTag
            open={this.state.tagForEdit !== null ? true : false}
            size="tiny"
            onCloseHandle={() => {
              console.log("Close");
              this.setState({
                tagForEdit: null,
              });
            }}
            actionsComponent={this.renderActionButtons()}
            onSubmit={this.handleSubmit}
            loading={this.state.loading}
            initialValues={this.state.tagForEdit}
            data={this.state.tagForEdit !== null ? this.state.tagForEdit : null}
          />
        }
      </>
    );
  };

  renderNewAppTiming = () => {
    return (
      <Segment>
        <Grid>
          <Grid.Column width={16}>
            <Header size="huge">Set New Timings</Header>
          </Grid.Column>
          {this.renderAppSetStartTime("Start Time")}
          {this.renderAppSetEndTime("End Time")}
          {this.renderAppSetTimeButton()}
        </Grid>
      </Segment>
    );
  };

  renderAddTagBar = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <Input fluid type="text" placeholder="Type new Tag" action>
          <input
            disabled={this.state.addLoading}
            value={this.state.newTagName}
            onChange={(e) => this.setState({ newTagName: e.target.value })}
          />
          <Button
            // type="submit"
            primary
            data-tooltip={"Click to Add Tag"}
            data-position="top right"
            loading={this.state.addLoading}
            disabled={this.state.newTagName.length === 0}
            onClick={() => {
              this.setState({ addLoading: true });
              if (this.state.newTagName.length !== 0) {
                this.props
                  .AddTag(this.state.newTagName, this.state.selectedTab)
                  .then((x) =>
                    this.setState({ newTagName: "", addLoading: false })
                  )
                  .catch(() => {
                    this.setState({ newTagName: "", addLoading: false });
                  });
              } else {
                this.setState({ addLoading: false });
              }
            }}
          >
            Add New Tag
          </Button>
        </Input>
      </form>
    );
  };

  renderTagsTable = () => {
    return (
      <DataTable
        header={[
          {
            name: "Tag ID",
            accessor: "id",
          },
          {
            name: "Tag",
            accessor: "tag",
          },
        ]}
        data={this.props.tags}
        noPagination
        loading={this.state.loadTable}
        actions={this.actionsButton}
        trigger={this.state.triggerUpdate}
      />
    );
  };

  renderDeleteConfirmationBox = () => {
    return this.state.tagOnDelete !== null ? (
      <ConfirmBox
        loading={this.state.deleteLoading}
        open={this.state.showDeleteConfirmation}
        header="Confirm Tag deletion"
        content={`You are deleting Tag with id: ${this.state.tagOnDelete.id} and Tag: ${this.state.tagOnDelete.tag}.\nDeletion cannot be undone.`}
        cancelButton="Cancel"
        confirmButton="Delete"
        size="mini"
        onCancel={() =>
          this.setState({ showDeleteConfirmation: false, tagOnDelete: null })
        }
        onConfirm={() => this.handleTagDeletion()}
      />
    ) : null;
  };

  handleTagDeletion = () => {
    this.setState({ deleteLoading: true });
    this.props
      .DeleteTag(this.state.tagOnDelete.id, this.state.selectedTab)
      .then(() => {
        this.setState(
          {
            showDeleteConfirmation: false,
            deleteLoading: false,
            tagOnDelete: null,
          },
          () => {
            this.setState({ triggerUpdate: this.state.triggerUpdate + 1 });
          }
        );
      })
      .catch(() =>
        this.setState({
          showDeleteConfirmation: false,
          deleteLoading: false,
          tagOnDelete: null,
        })
      );
  };

  actionsButton = (value) => {
    return (
      <>
        <Button
          icon={value.isActive ? "circle" : "circle outline"}
          color={value.isActive ? "green" : "grey"}
          size="tiny"
          // loading={this.state.activityLoading}
          disabled={false}
          onClick={() => {
            console.log("Enter");
            // this.setState({ activityLoading: true })
            this.props
              .ToggleActive(
                value.id,
                value.isActive ? false : true,
                this.state.selectedTab
              )
              .then(() => {
                console.log("DOne");
                // this.setState({activityLoading: false})
              })
              .catch(() => {
                console.log("Error");
              });
          }}
          data-tooltip={
            value.isActive ? "Click to deactivate Tag" : "Click to Active Tag"
          }
          data-position="top right"
        />
        <Button
          data-tooltip="Edit Tag"
          data-position="top right"
          color="blue"
          size="tiny"
          disabled={false}
          icon="edit"
          onClick={() => {
            console.log(value);
            this.props.reset("tagEdit");
            this.setState({ tagForEdit: value });
          }}
        />
        <Button
          data-tooltip="Delete Tag"
          data-position="top right"
          color="red"
          size="tiny"
          disabled={false}
          icon="x"
          onClick={() => {
            this.setState({
              tagOnDelete: value,
              showDeleteConfirmation: true,
            });
          }}
        />
      </>
    );
  };

  renderTagTabs() {
    let panes = [
      {
        menuItem: "Visit",
        render: () => <Tab.Pane attached>{this.renderTags()}</Tab.Pane>,
      },
      {
        menuItem: "Call",
        render: () => <Tab.Pane attached>{this.renderTags()}</Tab.Pane>,
      },
    ];
    return (
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={this.state.selectedTabIndex}
        onTabChange={(event, data) => this.onTabChange(event, data)}
      />
    );
  }

  onTabChange(event, data) {
    let index = data.activeIndex;
    console.log(data);
    console.log(this.state.selectedTabIndex);
    console.log(this.state.selectedTab);
    localStorage.setItem("selectedTagsTabIndex", index);
    localStorage.setItem(
      "selectedTagsTab",
      data.panes[data.activeIndex].menuItem
    );
    this.setState({
      loadTable: true,
      selectedTab: data.panes[data.activeIndex].menuItem,
      selectedTabIndex: index,
      triggerUpdate: this.state.triggerUpdate + 1,
    });
    this.props.LoadTags(data.panes[data.activeIndex].menuItem).then(() => {
      this.setState({ loadTable: false });
    });
  }

  renderTags = () => {
    return (
      <Segment>
        <div style={styles.topBox}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                {/* {this.renderSearchBar()} */}
              </Grid.Column>
              <Grid.Column width={8}>{this.renderAddTagBar()}</Grid.Column>
            </Grid.Row>
            <Grid.Column width={16}>{this.renderTagsTable()}</Grid.Column>
          </Grid>
        </div>
      </Segment>
    );
  };

  renderSettingsBox = () => (
    <div style={styles.mainDiv}>
      {this.renderDeleteConfirmationBox()}
      {this.renderCurrentTiming()}
      {this.renderNewTiming()}
      {this.renderAppCurrentTiming()}
      {this.renderNewAppTiming()}
      {this.renderTagTabs()}
      {this.renderTagEdit()}
    </div>
  );

  render() {
    // console.log(this.props)
    return !this.state.loaded ? (
      <LoaderView text="Settings Page is loading" />
    ) : this.state.access ? (
      this.renderSettingsBox()
    ) : (
      <UnauthorizedView pageName="Settings Page" />
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

const mstp = (state) => {
  // console.log(state)
  return {
    officeTiming: state.attendance.officeTiming,
    appTiming: state.attendance.appTiming,
    tags: state.tags.data,
  };
};

export default connect(mstp, { ...actions, reset })(Settings);
