// clear newRoleType after adding new, show notification in case of error!
// Use promises to do so

import React, { Component } from 'react';
import { Button, Grid, Input, Icon, Modal, Header } from 'semantic-ui-react';
import DataTable from '../components/dataTable';
import { connect } from 'react-redux';
import { CommonInput } from '../components/forms/commonInput';
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';


import {
    LoadRoles,
    AddRole,
    DeleteRole,
    UpdateSearchTerm,
    LoadRoleForEdit,
    EditRole,
    removeEditError
} from '../actions';

class Roles extends Component {

    state = {
        access: false,
        loaded: false,
        newRoleName: ''
    }

    componentDidMount() {
        this.props.LoadRoles()
            .then(x => this.setState({ loaded: true, access: true }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    } 

    actionsButton = (value) => {
        const deleteProcessing = this.props.deleteLoading.includes(value.id)
        return (
            <>
                <Button size="mini" color="blue" icon disabled={deleteProcessing}
                    onClick={() => {
                        this.props.LoadRoleForEdit(value)
                        this.setState({ editedRoleName: value.type })
                    }}>
                    <Icon name="edit" />
                </Button>
                <Button size="mini" color="red" icon disabled={deleteProcessing} loading={deleteProcessing} onClick={() => this.props.DeleteRole(value.id)}>
                    <Icon name="x" />
                </Button>
            </>
        );
    }

    confirmRoleEdit = () => {
        this.props.EditRole(this.props.roleBeingEdited.id, this.state.editedRoleName, this.props.roleBeingEdited.type, this.props.pageInfo)
    }

    renderAddBar = () => {
        return (
            <form onSubmit={(e) => e.preventDefault()}>
                <Input fluid type='text' placeholder='Input type for new role' action>
                    <input disabled={this.props.addLoading}
                        value={this.state.newRoleName}
                        onChange={(e) => this.setState({ newRoleName: e.target.value })}
                    />
                    <Button
                        type="submit"
                        primary
                        disabled={this.props.addLoading}
                        loading={this.props.addLoading}
                        onClick={() => { if (this.state.newRoleName.length !== 0) this.props.AddRole(this.state.newRoleName).then(x => this.setState({ newRoleName: '' })) }}
                    >
                        Add New Role
                    </Button>
                </Input>
            </form>
        )
    }

    renderSearchBar = () => {
        return (
            <form onSubmit={(e) => e.preventDefault()}>
                <Input fluid type='text' placeholder='Search by type' action>
                    <input
                        value={this.props.searchTerm}
                        onChange={(e) => this.props.UpdateSearchTerm(e.target.value)}
                    />
                    <Button
                        icon
                        type="submit"
                        primary
                        onClick={() => {
                            this.props.LoadRoles(this.props.pageInfo.pageSize, this.props.pageInfo.pageNumber, this.props.searchTerm)
                        }}
                    >
                        <Icon name="search"></Icon>
                    </Button>
                </Input>
            </form>
        )
    }

    renderPageActions = () => {
        return (
            <div style={styles.topBox}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {this.renderSearchBar()}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {this.renderAddBar()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }

    renderTable = () => {
        return (
            <DataTable
                header={[
                    {
                        name: 'Role ID',
                        accessor: 'id'
                    },
                    {
                        name: 'Role Type',
                        accessor: 'type'
                    },
                    {
                        name: 'Number Of Users',
                        accessor: 'noOfUsers'
                    }
                ]}
                data={this.props.data.filter(x => x.type.toUpperCase().includes(this.props.searchTerm.toUpperCase()))}
                noPagination
                actions={this.actionsButton}
            />
        )
    }

    renderEditBox = () => {
        return (
            this.props.roleBeingEdited !== null ?
                <Modal size="tiny"
                    open={this.props.roleBeingEdited !== null}
                    onClose={() => this.props.LoadRoleForEdit(null, this.props.roleEditLoading)}
                >
                    <Header>
                        <Icon name="edit" />
                        Editing Role [Role ID - {this.props.roleBeingEdited.id}]
                </Header>
                    <Modal.Content>
                        <div style={{ marginBottom: '5px' }}>New Role Type</div>
                        <CommonInput
                            placeholder="New Role Type"
                            fluid
                            value={this.state.editedRoleName}
                            onChange={(e) => this.setState({ editedRoleName: e.target.value })}
                            errorMessage={this.props.roleEditError}
                            onFocus={this.props.removeEditError}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            disabled={this.props.roleEditLoading}
                            basic color='red' onClick={() => this.props.LoadRoleForEdit(null, this.props.roleEditLoading)}>
                            <Icon name='remove' /> Cancel
                    </Button>
                        <Button
                            disabled={this.props.roleEditLoading}
                            loading={this.props.roleEditLoading}
                            color='green' onClick={this.confirmRoleEdit}>
                            <Icon name='checkmark' /> Confirm Change
                    </Button>
                    </Modal.Actions>
                </Modal>
                : null
        )
    }

    renderRolesBox = () => {
        return (
            <div style={styles.mainDiv}>
                {this.renderPageActions()}
                {this.renderEditBox()}
                {this.renderTable()}
            </div>
        )
    }

    render() {
        return (
            !this.state.loaded ? <LoaderView text="Roles Page is loading" /> :
                this.state.access ? this.renderRolesBox() : <UnauthorizedView pageName="Roles Page" />
        )
    }
}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

const mstp = (state) => {
    return ({
        data: state.role.data,
        rowCount: state.role.rowCount,
        addLoading: state.role.addLoading,
        deleteLoading: state.role.deleteLoading,
        searchTerm: state.role.searchTerm,
        roleBeingEdited: state.role.roleBeingEdited,
        roleEditError: state.role.roleEditError,
        roleEditLoading: state.role.roleEditLoading
    })
}

export default connect(mstp, {
    EditRole,
    LoadRoles,
    AddRole,
    DeleteRole,
    UpdateSearchTerm,
    LoadRoleForEdit,
    removeEditError
})(Roles);