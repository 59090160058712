import axios from 'axios';

import {
    LOAD_ROLES,
    ADD_ROLE_LOAD_TOGGLE,
    DELETE_ROLE_LOAD,
    UPDATE_ROLE_SEARCH_TERM,
    LOAD_EDIT_ROLE,
    EDIT_ROLE_ERROR,
    ROLE_EDIT_LOADING_TOGGLE,
} from './types'


const apiURL = require('../config.json').apiURL;


export const LoadRoles = () => {
    return (dispatch) => {  
        return new Promise ((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL+'role/getall'   
            }).then(({data}) => {
                dispatch({
                    type: LOAD_ROLES,
                    payload: data.roles
                })
                resolve('done')
            })
            .catch((error) => {
                reject('failed')
            });
        })
    }
}


export const AddRole = (type) => {
    const form = new FormData();
    form.append('type',type);
    return (dispatch) => { 
        return new Promise((resolve, reject) => {
        dispatch({
            type: ADD_ROLE_LOAD_TOGGLE
        })
        axios({
            method: 'post',
            url: apiURL+'role/post',
            data: form
             
        }).then(({data}) => {
            dispatch(LoadRoles())
            dispatch({
                type: ADD_ROLE_LOAD_TOGGLE
            })
            resolve("done")
        })
        .catch((error) => {
            dispatch({
                type: ADD_ROLE_LOAD_TOGGLE
            })
            reject("error")
        });
          
    })
    }
}

export const DeleteRole = (id) => {
    return (dispatch) => {  
        const form = new FormData();
        form.append('Id', id)
        dispatch({
            type: DELETE_ROLE_LOAD,
            payload: id
        })
        axios({
            method: 'delete',
            url: apiURL+'role/delete',
            
            data: form
        }).then(({data}) => {
            dispatch(LoadRoles())
            dispatch({
                type: DELETE_ROLE_LOAD,
                payload: id
            })
        })
        .catch((error) => {
            dispatch({
                type: DELETE_ROLE_LOAD,
                payload: id
            })
        });
    }
}



export const UpdateSearchTerm = (term) => {
    return ({
        type: UPDATE_ROLE_SEARCH_TERM,
        payload: term
    })
}

export const LoadRoleForEdit = (role, loading) => {
    return dispatch => {
        if (!(role === null && loading === true)){
            dispatch({
                type: LOAD_EDIT_ROLE,
                payload: role
            })
            dispatch(removeEditError())
        }
    }
}

export const EditRole = (id, newRoleType, oldRoleType, pageInfo) => {
    return dispatch => {
        if (newRoleType !== oldRoleType){
            const form = new FormData();
            form.append('Id', id);
            form.append('type', newRoleType);
            dispatch({
                type: ROLE_EDIT_LOADING_TOGGLE
            })
            
            axios({
                method: 'put',
                url: apiURL+'role/put',
                
                data: form
            }).then(({data}) => {
                dispatch({
                    type: ROLE_EDIT_LOADING_TOGGLE
                })
                dispatch(LoadRoleForEdit(null, false))
                dispatch(LoadRoles())
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_ROLE_ERROR,
                    payload: 'There was an error updating the role type'
                })          
            });
        } else {
            dispatch({
                type: EDIT_ROLE_ERROR,
                payload: 'New role type cannot be same be current'
            })
        }
    }
}

export const removeEditError = () => {
    return ({
        type: EDIT_ROLE_ERROR,
        payload: null
    })
}