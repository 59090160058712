import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import { Field, reduxForm } from 'redux-form';
import { Grid, Form, Modal, Header, Icon, Input, Button } from 'semantic-ui-react';
import { CommonInput } from './commonInput';

class EditFormValue extends React.Component {

    state = {

    }

    myField = (name, label, type = "text") => {
        return (
            <>
                <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '3px' }} />
                </>
                <Field
                    disabled={false}
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder={label}
                    type={type}
                />
            </>
        )
    }

    renderEditModal = () => {
        // console.log(this.props)
        const { data } = this.props
        return (
            <Modal
                size={this.props.size}
                open={this.props.open}
                onClose={this.props.onCloseHandle}
                // style={{ width: '90%' }}
            >
                <Header>
                    <Icon name='edit' />
                    {this.props.data !== null ? `Edit Form Value with ID : ${this.props.data.id}` : null}
                </Header>
                <Modal.Content>
                    <>
                        <Form
                            id="formValueEdit"
                            onSubmit={this.props.handleSubmit}
                            size="large"
                        >
                            <Grid>
                                <Grid.Column width={16}>
                                    {this.myField('val', this.props.data.caption)}
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.actionsComponent}
                </Modal.Actions>
            </Modal>
        )
    }

    render = () => {
        return (
            <>
                {this.renderEditModal()}
            </>
        )
    }
}

EditFormValue = reduxForm({
    form: 'valueEdit',
    enableReinitialize: true
})(EditFormValue)

export default EditFormValue;