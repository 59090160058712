import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Grid,
    Header
  } from 'semantic-ui-react'

import LoginForm from '../components/forms/loginForm';
import RegisterForm from '../components/forms/registerForm';
import * as actions from '../actions';

class Entry extends Component {

    handleLogin = values => {
        this.props.logIn(values)
    }
 
    handleRegister = values => {
        this.props.register(values)
    }

    renderForm = () => {
        if (this.props.form === 'register'){
            return (
                <RegisterForm onSubmit={this.handleRegister} toggle={() => this.props.toggleEntryForm('login')} />
            )
        } else if (this.props.form === 'login') {
            return (
                <LoginForm onSubmit={this.handleLogin} toggle={() => this.props.toggleEntryForm('register')} />
            )
        }
    }

    render() {
        return (
            <Grid textAlign='center' style={{ height: '80vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: this.props.form === 'login'? 450: 600 }}>
                    <Header as='h2' color='blue' textAlign='center'>
                        {this.props.form === 'login'? 'Log-in to your account':'Register a new account'}
                    </Header>
                    {this.renderForm()}
                </Grid.Column>
            </Grid>
        )
    }
}

const mstp = (state) => {
    return ({
        form: state.auth.form
    })
}

export default connect(mstp,actions)(Entry);