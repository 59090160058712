import axios from "axios";
import {
  LOAD_VIEWALLACTIONS,
  LOAD_ACTION_OUTCOME,
  LOAD_IMAGE_FILE,
  LOAD_SORTING_PARAMS,
  LOAD_DROPDOWNS_ACTIONS,
  ACTION_CREATION_SUCCESS,
  LOAD_COMMENTS,
  ACTION_CREATION_FAIL,
} from "./types";
import { logOut } from ".";
import { reset } from "redux-form";
import { saveAs } from "file-saver";

const apiURL = require("../config.json").apiURL;

export const LoadAllViews = (
  pagesize,
  pagenum,
  searchParameters,
  value,
  status,
  medium
) => {
  var sortedby = {
    param: value,
    isdescending: status,
  };
  searchParameters.Medium = medium;
  console.log(medium);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "action/getfiltered/search",
        params: {
          pagesize,
          pagenum,
          parameters: JSON.stringify(searchParameters),
          sortby: sortedby,
        },
      })
        .then(({ data }) => {
          // console.log(data)
          // console.log(data.canOverrideApproval)
          dispatch({
            type: LOAD_VIEWALLACTIONS,
            payload: {
              data: data.actions,
              total: data.total,
              override: data.canOverrideApproval,
            },
          });
          resolve("done");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            reject("failed");
          } else {
            dispatch(logOut());
          }
        });
    });
  };
};

export const ActionFill = (action) => {
  const form = new FormData();
  console.log(JSON.stringify(action));
  Object.keys(action).map((key) => {
    //  form.append(key.toLowerCase(), JSON.stringify(action[key]))
    if (Array.isArray(action[key])) {
      //    console.log(action[key])
      for (var i = 0; i < action[key].length; i++) {
        var myItemArray = action[key][i];
        console.log(myItemArray);
        form.append(`${key}`, myItemArray, myItemArray.name);
        // for (var props in myItemArray) {
        //     form.append(`${key}[${i}]`, myItemArray, myItemArray['name'])
        // }
      }
      // form.append(`${key}[${i}][${props}]`, myItemArray[props])
    } else {
      form.append(key.toLowerCase(), JSON.stringify(action[key]));
    }
    return null;
  });
  console.log(form);
  for (var pair of form.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "actionoutcome/postbrowser",
        data: form,
      })
        .then(({ data }) => {
          dispatch(reset("fillForm"));
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const ActionRedo = (action) => {
  const form = new FormData();
  Object.keys(action).map((key, index) => {
    form.append(key.toLowerCase(), action[key]);
    return null;
  });
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "action/redo",
        data: form,
      })
        .then(({ data }) => {
          dispatch(reset("Redo"));
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const ActionRedoMultiple = (action) => {
  const form = new FormData();
  console.log(action);
  Object.keys(action).map((key, index) => {
    form.append(key.toLowerCase(), action[key]);
  });
  // console.log(form)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "action/redo",
        data: form,
      })
        .then(({ data }) => {
          dispatch(reset("Redo"));
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const DropdownFilter = (field) => {
  // const form = new FormData();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + `action/getfilterdropdown/${field}`,
        // data: form
      })
        .then(({ data }) => {
          // console.log(data)
          var list = {};
          list.field = field;
          list.data = data.dropdown.map((x) => {
            return {
              value: x,
              text: x,
            };
          });
          // console.log(list)
          dispatch({
            type: LOAD_DROPDOWNS_ACTIONS,
            payload: list,
          });
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  }; 
};

export const MarkUnread = (action) => {
  var form = new FormData();
  Object.keys(action).map((key, index) => {
    form.append(key.toLowerCase(), action[key]);
  });
  // form.append('actionId', id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: apiURL + "action/markunread",
        data: form,
      })
        .then(({ data }) => {
          console.log(data);
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const ApproveAction = (action) => {
  var form = new FormData();
  form.append("Actionid", action.id);
  form.append("Decision", action.decision);
  form.append("Reason", action.reason);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: apiURL + "actionoutcome/approve",
        data: form,
      })
        .then(({ data }) => {
          // dispatch()
          // console.log(data);
          if (action.decision === "a") {
            alert("Approved!");
          } else {
            alert("Reject!");
          }
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          alert("Error!");
          reject("failed");
        });
    });
  };
};

export const CreateAction = (action) => {
  const form = new FormData();
  Object.keys(action).map((key, index) => {
    form.append(key.toLowerCase(), action[key]);
    return null;
  });
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "action/post",
        data: form,
      })
        .then(({ data }) => {
          dispatch(reset("action"));
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const CreateMultipleAction = (actions) => {
  const form = new FormData();
  // form.append('ids', actions.ids)
  if (Array.isArray(actions.ids)) {
    actions.ids.map((x) => {
      form.append("ids", JSON.stringify(x));
    });
  }
  form.append("action", JSON.stringify(actions.action));
  form.append("filter", JSON.stringify(actions.filter));
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: apiURL + "action/postlist",
        data: form,
      })
        .then(({ data }) => {
          dispatch({
            type: ACTION_CREATION_SUCCESS,
            payload: {
              type: "success",
              header: "Successful",
              text: "Actions has been Created Successfully!",
            },
          });
          dispatch(reset("action"));
          resolve("done");
        })
        .catch((error) => {
          dispatch({
            type: ACTION_CREATION_SUCCESS,
            payload: {
              type: "error",
              header: "Unsuccessful",
              text: "Actions could not been Created!",
            },
          });
          reject("failed");
        });
    });
  };
};

export const EditAction = (action) => {
  const form = new FormData();
  Object.keys(action).map((key, index) => {
    form.append(key.toLowerCase(), action[key]);
  });
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: apiURL + "action/put",
        data: form,
      })
        .then(({ data }) => {
          dispatch(reset("action"));
          resolve("done");
        })
        .catch((error) => {
          console.error(error);
          reject("failed");
        });
    });
  };
};

export const GetActionOutcome = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "actionoutcome/get/" + id,
      })
        .then(({ data }) => {
          console.log(data);
          dispatch({
            type: LOAD_ACTION_OUTCOME,
            payload: data,
          });
          resolve("done");
        })
        .catch((error) => {
          console.log(error);
          //   if (error.response.status === 403) {
          reject("failed");
          //  } else {
          dispatch(logOut());
          //   }
        });
    });
  };
};

export const DeleteAction = (action) => {
  console.log(action);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      Object.keys(action).map((key, index) => {
        data.append(key.toLowerCase(), action[key]);
      });
      axios({
        method: "delete",
        url: apiURL + "action/delete",
        data: data,
      })
        .then((x) => {
          resolve("done");
        })
        .catch((x) => {
          console.log(x);
          reject("failed");
        });
    });
  };
};

export const LoadSupportingImage = (path) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // var data = path.getBytes("UTF-8");
      // var base64 = Base64.encodeToString(data, Base64.DEFAULT);
      let base64 = btoa(path);
      let w = window.open("", "_blank");
      axios({
        method: "get",
        url: apiURL + `file/getimagestring/${base64}`,
      })
        .then((response) => {
          let image = new Image();
          image.src = "data:image/png;base64," + response.data.image;
          w.document.write(image.outerHTML);
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    });
  };
};

export const LoadImage = (add) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const Path = new FormData();
      Path.append("path", add);
      let w = window.open("", "_blank");
      axios({
        method: "post",
        url: apiURL + "payment/getimage",
        data: Path,
      })
        .then((response) => {
          let image = new Image();
          image.src = "data:image/png;base64," + response.data.image;
          w.document.write(image.outerHTML);
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    });
  };
};

// export const LoadImageFile = (add) => {
//     return (dispatch) => {
//         return new Promise((resolve, reject) => {
//             const Path = new FormData();
//             Path.append('path', add)
//             axios({
//                 method: 'post',
//                 url: apiURL + 'payment/getimagefile',
//                 headers: {
//                     'Content-Type': 'image/png'
//                 },
//                 data: Path
//             }).then((response) => {
//                 console.log(response)
//              //   console.log(response.json())
//               console.log(typeof response.data)
//               //  console.log(response.data.image)
//                 dispatch({
//                     type: LOAD_IMAGE_FILE,
//                     payload: response.data.image
//                 })
//                 resolve('done')
//             }).catch((error) => {
//                 if (error.response.status === 403) {
//                     reject('failed')
//                 } else {
//                    // dispatch(logOut())
//                 }
//             });
//         })
//     }
// }

export const LoadSortingParams = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "action/getSortingParameters/sortby?defaulterid=" + id,
      })
        .then(({ data }) => {
          const newData = data.keys.map((x) => {
            return {
              value: x,
              text: x,
            };
          });
          dispatch({
            type: LOAD_SORTING_PARAMS,
            payload: newData,
          });
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    });
  };
};

export const UpdateFormValue = (array) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(array);
      form.append("Formvalues", JSON.stringify(array));
      axios({
        method: "put",
        url: apiURL + "actionoutcome/updateformvalues",
        data: form,
      })
        .then(({ data }) => {
          console.log("HELLLLLL");
          dispatch(reset("valueEdit"));
          resolve("done");
        })
        .catch((error) => {
          dispatch(reset("valueEdit"));
          reject("error");
        });
    });
  };
};

export const UpdateReminder = (value) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(value);
      Object.keys(value).map((key) => {
        form.append(`${key}`, value[key]);
      });
      // form.append('Id', value.id)
      // form.append('Date', value.date)
      axios({
        method: "put",
        url: apiURL + "actionoutcome/updateReminder",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const UpdatePromise = (value) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(value);
      Object.keys(value).map((key) => {
        form.append(`${key}`, value[key]);
      });
      // form.append('Id', value.id)
      // form.append('Amount', value.amount)
      // form.append('Date', value.date)
      axios({
        method: "put",
        url: apiURL + "actionoutcome/updatePromise",
        data: form,
      })
        .then(({ data }) => {
          // console.log('HELLLLLL')
          // dispatch(reset('valueEdit'))
          resolve("done");
        })
        .catch((error) => {
          // dispatch(reset('valueEdit'))
          reject("error");
        });
    });
  };
};

export const UpdateOutcomeTag = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(JSON.stringify(obj));
      form.append("Outcome", JSON.stringify(obj));
      axios({
        method: "put",
        url: apiURL + "actionoutcome/updateoutcome",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const UpdatePayment = (value) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(value);
      Object.keys(value).map((key) => {
        form.append(`${key}`, value[key]);
      });
      // form.append('Id', value.id)
      // form.append('Amount', value.amount)
      // form.append('Date', value.date)
      axios({
        method: "put",
        url: apiURL + "payment/editpayment",
        data: form,
      })
        .then(({ data }) => {
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const AddPaymentImage = (files) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(files);
      form.append("PaymentId", files.id);
      Object.keys(files.images).map((key) => {
        let image = files.images[key];
        form.append("PaymentImages", image, image.name);
      });
      axios({
        method: "post",
        url: apiURL + "payment/addimages",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const DeletePaymentImage = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("imageid", id);
      axios({
        method: "delete",
        url: apiURL + "payment/deleteimage",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const AddSupportingImages = (files) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      console.log(files);
      form.append("Outcomeid", files.id);
      Object.keys(files.images).map((key) => {
        console.log(files.images[key]);
        let image = files.images[key];
        form.append("supportingImages", image, image.name);
      });
      axios({
        method: "post",
        url: apiURL + "supportingimage/post",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const DeleteSupportingImage = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("imageid", id);
      axios({
        method: "delete",
        url: apiURL + "supportingimage/delete",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const AddComment = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("comment", obj.comment);
      form.append("outcomeid", obj.id);
      axios({
        method: "post",
        url: apiURL + "actioncomment/post",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const GetComments = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + "actioncomment/getbyaction/" + id,
      })
        .then(({ data }) => {
          console.log(data);
          dispatch({
            type: LOAD_COMMENTS,
            payload: data.comments,
          });
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const EditComment = (obj) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("comment", obj.comment);
      form.append("id", obj.id);
      axios({
        method: "put",
        url: apiURL + "actioncomment/put",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const DeleteComment = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("commentid", id);
      axios({
        method: "delete",
        url: apiURL + "actioncomment/delete",
        data: form,
      })
        .then((response) => {
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};

export const downloadPaymentImagesZip = (ids) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let array = ids.map((item) => {
        return btoa(item);
      });
      axios({
        method: "get",
        url: apiURL + "file/getMultipleInZip/" + JSON.stringify(array),
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PaymentImages.zip");
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    });
  };
};

export const downloadSupportingImagesZip = (ids) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let array = ids.map((item) => {
        return btoa(item);
      });
      axios({
        method: "get",
        url: apiURL + "file/getMultipleInZip/" + JSON.stringify(array),
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "SupportingImages.zip");
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve("done");
        })
        .catch((response) => {
          reject("failed");
        });
    });
  };
};
