import React from 'react';
import { Form, Grid, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { CommonInput, CommonSelect, CommonMultiSelect } from './commonInput';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';


import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
momentLocaliser(moment)

const myField = (name, label, disabled, type = "text") => {
    return (
        <>
            <>
                <span>{label}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
            <Field
                disabled={disabled}
                fluid
                name={name}
                component={CommonInput}
                placeholder={label}
                type={type}
            />
        </>
    )
}

const renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) =>
    <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY"
        placeholder={placeholder}
        time={showTime}
        value={!value ? null : new Date(value)}
    />


let DashboardFilterForm = (props) => {
    let l = 5;
    let m = 6;
    return (
        <Form
            id="reportFilter"
            onSubmit={props.handleSubmit}
            size="small"
        >
            <Grid>
                <Grid.Column width={3} >
                    <Field
                        fluid
                        name="medium"
                        options={[
                            { value: '', text: '' },
                            { value: 'Sms', text: 'Sms' },
                            { value: 'Email', text: 'Email' },
                            { value: 'Call', text: 'Call' },
                            { value: 'Visit', text: 'Visit' }
                        ]}
                        clearable
                        component={CommonSelect}
                        placeholder="Medium"
                    />
                </Grid.Column>
                <Grid.Column width={l}>
                    <Field
                        name="datefrom"
                        showTime={false}
                        component={renderDateTimePicker}
                        disabled={props.loading}
                        placeholder="Date From"
                    />
                </Grid.Column>
                <Grid.Column width={l}>
                    <Field
                        name="dateto"
                        showTime={false}
                        component={renderDateTimePicker}
                        disabled={props.loading}
                        placeholder="Date To"
                    />
                </Grid.Column>
                {/* <Grid.Column width={3} >
                    <Field
                        fluid
                        name="username"
                        options={props.users}
                        component={CommonMultiSelect}
                        placeholder="Username"
                    />
                </Grid.Column> */}
                <Grid.Column textAlign='left' width={3} >
                    <Button primary fluid type="submit" size="tiny" >Filter View</Button>
                </Grid.Column>

            </Grid>
        </Form>
    )
}

const validate = (formValues) => {
    console.log(formValues);
}


DashboardFilterForm = reduxForm({
    form: 'dashBoardFilter',
    enableReinitialize: true,
    initialValues: {
        medium: '',
        datefrom: '',
        dateto: ''
    }
})(DashboardFilterForm)

export default DashboardFilterForm;

