import axios from 'axios';
import { reset } from 'redux-form';
import {
    LOAD_COMPANIES,
    LOAD_COMPANIES_WITH_IDS
} from './types'

const apiURL = require('../config.json').apiURL;

export const LoadCompanies = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'company/getall'
            }).then(({ data }) => {
                dispatch({
                    type: LOAD_COMPANIES,
                    payload: data
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('failed')
                });
        })
    }
}


export const AddCompany = (company) => {
    const form = new FormData();
    Object.keys(company).map((key, index) => {
        form.append(key.toLowerCase(), company[key])
        return null;
    });
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: apiURL + 'company/post',
                data: form
            }).then(({ data }) => {
                dispatch(LoadCompanies());
                dispatch(reset('company'))
                resolve("done")
            })
                .catch((error) => {

                });
        })
    }
}

export const LoadCompaniesWithIds = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'company/getall'
            }).then(({ data }) => {
                const newData = data.map(x => {
                    return {
                        value: x.id,
                        text: x.name
                    }
                })
                // newData.unshift({ value: 0, text: '[No Company]' })
                dispatch({
                    type: LOAD_COMPANIES_WITH_IDS,
                    payload: newData
                })
                resolve('done')
            })
                .catch((error) => {
                    reject('failed')
                });
        })
    }
}

export const DeleteCompany = (id) => {
    const form = new FormData();
    form.append('Id', id)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: apiURL + 'company/delete',
                data: form
            }).then(({ data }) => {
                dispatch(LoadCompanies());
                resolve("done")
            })
                .catch((error) => {
                    reject("error")
                });
        })
    }
}

export const EditCompany = (company) => {
    const form = new FormData();
    Object.keys(company).map((key, index) => {
        form.append(key.toLowerCase(), company[key])
        return null;
    });
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: apiURL + 'company/put',
                data: form
            }).then(({ data }) => {
                dispatch(LoadCompanies());
                resolve("done")
            })
                .catch((error) => {
                    reject("error")
                });
        })
    }
}