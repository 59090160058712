import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { reset, Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input, Checkbox, Button } from 'semantic-ui-react'
import { CommonSelect } from './commonInput';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)


const renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) => {
    return (
        <div style={{ marginBottom: '15px' }}>
            <DateTimePicker
                dropUp
                onChange={onChange}
                format="DD-MM-YYYY"
                placeholder={placeholder}
                time={showTime}
                value={!value ? null : new Date(value)}
            />
        </div>
    )
}

class ActionRedo extends React.Component {

    state = {};

    renderDropDown = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ [field.input.name]: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={field.options}
                />
            </div>
        )
    }

    render() {
        return (
            <>
                <Modal
                    size={this.props.size}
                    open={this.props.open}
                    onClose={this.props.onCloseHandle}
                >
                    <Header >
                        <Icon name={this.props.header.icon} />
                        {this.props.header.text}
                    </Header>
                    <Modal.Content>
                        <Form
                            id="redoForm"
                            error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                            success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                            onSubmit={this.props.handleSubmit}
                            size='small'
                        >
                            <Field
                                name="date"
                                showTime={false}
                                component={renderDateTimePicker}
                                disabled={this.props.loading}
                                placeholder="Date (Initial Value will be copied"
                            />
                            {this.props.label ? <><span>Assign To</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                searchable
                                name="userid"
                                options={this.props.assignTo}
                                component={this.renderDropDown}
                                placeholder="Assign To (Initial Value will be copied)"
                                clearable
                            />
                            {this.props.Message !== null ?
                                <Message
                                    error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                                    success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                                    header={this.props.Message.header}
                                    content={this.props.Message.content}
                                />
                                :
                                null
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <button form="action" type="button" onClick={reset}>
                            Clear Values
                        </button> */}
                        {this.props.actionsComponent}
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

const validate = (formValues) => {
    // console.log(formValues)
}

ActionRedo = reduxForm({
    form: 'Redo',
    validate,
    enableReinitialize: true
})(ActionRedo)

export default ActionRedo;