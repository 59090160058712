import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Header, Popup, Icon, Label, Grid, Image, Accordion, Container, Segment } from 'semantic-ui-react';
import { LoadPermissions, ToPermissionQueue, ResetPermissionQueue, ConfirmPermissionChange } from '../actions'
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';

class Permissions extends Component {

    state = { access: false, loaded: false }

    componentDidMount() {
        this.props.LoadPermissions()
            .then(x => this.setState({ loaded: true, access: true }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    }

    renderPermissionGroup = (groupId) => {
        return this.props.permissions.map(permission => {
            if (permission.groupId === groupId)
                return (<>
                    <Grid.Row key={`group-permission-${groupId}-${permission.id}`} columns={this.props.roles.length + 1} >
                        <Popup
                            content={permission.description === null ? 'No Description.' : permission.description}
                            inverted
                            size='large'
                            on='hover'
                            trigger={
                                <Grid.Column >
                                    {permission.name}
                                </Grid.Column>
                            }
                        />
                        {this.renderPermissionSwitch(permission.id)}
                    </Grid.Row>
                </>
                )
        })
    }

    renderPermissionCells = () => {
        return this.props.permissions.map(x => {
            return (
                <Table.Row key={x.id} style={{ textAlign: 'center' }}>
                    <Table.Cell>{x.description}</Table.Cell>
                    {this.renderPermissionSwitch(x.id)}
                </Table.Row>
            )
        })
    }

    renderRoleList = () => {
        return (
            <Grid celled>
                <Grid.Row columns={this.props.roles.length + 1}>
                    <Grid.Column></Grid.Column>
                    {this.props.roles.map(x =>
                        <Grid.Column key={`header-${x.id}`} textAlign='center'
                        >{x.type}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        )
    }

    renderButton = (permissionId, roleId) => {
        const permissionRole = this.props.rolePermission.find(y => y.roleId === roleId && y.permissionId === permissionId)
        const permissionInQueue = this.props.permissionQueue.find(y => y.roleId === roleId && y.permissionId === permissionId)
        if (!permissionInQueue) {
            return permissionRole ? ['check circle', 'green'] : ['circle outline', 'grey']
        } else {
            return permissionRole ? ['circle outline', '#03d3fc'] : ['check circle', '#03d3fc']
        }
    }

    renderPermissionSwitch = (permissionId) => {
        return this.props.roles.map(x => {
            const value = this.renderButton(permissionId, x.id)

            return (
                <Grid.Column key={`group-role-permission${permissionId}-${x.id}`} textAlign='center'>
                    <Button size="mini" onClick={() => this.props.ToPermissionQueue(x.id, permissionId)} disabled={this.props.loading} icon style={{ backgroundColor: value[1], color: 'white' }}>
                        <Icon name={value[0]} />
                    </Button>
                </Grid.Column>
            )
        })
    }

    renderResetButton = () => {
        return this.props.permissionQueue.length > 0 ?
            <Button
                inverted color='red'
                floated='left'
                disabled={this.props.loading}
                onClick={this.props.ResetPermissionQueue}>
                Reset
            </Button>
            :
            null
    }

    renderApplyButton = () => {
        const length = this.props.permissionQueue.length;
        return length === 0 ?
            <Button
                primary
                style={{ marginBottom: '10px' }}
                floated='right'
                disabled={true}
                onClick={() => this.props.ConfirmPermissionChange(this.props.permissionQueue, this.props.rolePermission)}>
                Apply Changes
                </Button>
            :
            <Button
                disabled={this.props.loading}
                as='div'
                labelPosition='left'
                floated='right'
                style={{ marginBottom: '10px' }}>
                <Label as='a' basic>
                    {length} changes
                    </Label>
                <Button
                    primary
                    loading={this.props.loading}
                    onClick={() => this.props.ConfirmPermissionChange(this.props.permissionQueue, this.props.rolePermission)}
                >
                    Apply Changes
                    </Button>
            </Button>

    }

    renderPermissionBox = () => {
        return (
            <div style={styles.mainDiv}>
                <div style={{ marginBottom: '20px', height: '50px' }}>
                    {this.renderApplyButton()}
                    {this.renderResetButton()}
                </div>
                {this.renderGrids()}
                <div style={{ marginBottom: '20px' }} />

            </div >
        )
    }


    renderGrids = () => {
        let panels = this.props.groups.map(group => {
            let panel = {};
            panel.key = `group-${group.id}`;
            panel.title = {
                content: group.groupName
            };
            panel.content = [<>
                <Grid celled>
                    {this.renderPermissionGroup(group.id)}
                </Grid>
            </>];
            return panel;
        });

        return (
            <>
                {this.renderRoleList()}
                <Accordion
                    fluid
                    panels={panels}
                    exclusive={false}
                    styled
                />
            </>
        )
    }



    render() {
        return (
            !this.state.loaded ? <LoaderView text="Permission Page is loading" /> :
                this.state.access ? this.renderPermissionBox() : <UnauthorizedView pageName="Permission Page" />
        )
    }
}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    }
}

const mstp = (state) => {
    // console.log(state)
    return ({
        groups: state.permission.groups === undefined ? [] : state.permission.groups,
        roles: state.permission.roles === undefined ? [] : state.permission.roles,
        permissions: state.permission.permissions === undefined ? [] : state.permission.permissions,
        rolePermission: state.permission.rolePermission === undefined ? [] : state.permission.rolePermission,
        permissionQueue: state.permission.permissionQueue,
        loading: state.permission.loading
    })
}


export default connect(mstp, { LoadPermissions, ToPermissionQueue, ResetPermissionQueue, ConfirmPermissionChange })(Permissions);