import {
    SIDE_BAR_VISIBLE
} from '../actions/types';

const INITIAL_STATE = {visible: false}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case SIDE_BAR_VISIBLE:
            return {...state, visible: !state.visible};
        default:
            return state;
    }
}