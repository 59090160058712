import {
    LOAD_COMPANIES,
    LOAD_COMPANIES_WITH_IDS
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    companyWithIds: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_COMPANIES:
            return { ...state, data: action.payload };
        case LOAD_COMPANIES_WITH_IDS:
            return {
                ...state,
                companyWithIds: action.payload
            }
        default:
            return state;
    }
}