import React, { Component } from "react";
import {
  Menu,
  Label,
  Tab,
} from "semantic-ui-react";
import { connect } from "react-redux";
import * as actions from "../actions";
import LoaderView from "../components/loaderView";
import UnauthorizedView from "../components/unauthorizedView";
import Assigned from "./assigned";
import Performed from "./performed";
import Bucket from "./bucket";

class Perform extends Component {
  state = { access: false, loaded: false };

  componentDidMount = () => {
    this.setState({ access: true, loaded: true });
    this.props.LoadTags("Call", true);
  };

  renderPerformed = () => {
    return <Performed />;
  };

  renderAssigned = () => {
    return <Assigned />;
  };

  renderBucket = () => {
    return <Bucket />;
  };

  renderTabLabel = (label, count, color) => {
    return (
      <Menu.Item key={`tab_${label}`}>
        {label}
        <Label color={color}>{count ? count : "?"}</Label>
      </Menu.Item>
    );
  };

  renderTabs = () => {
    let panes = [
      {
        menuItem: this.renderTabLabel(
          "Assigned",
          this.props.assignedTotal,
          "blue"
        ),
        render: () => (
          <Tab.Pane attached={false}>{this.renderAssigned()}</Tab.Pane>
        ),
      },
      {
        menuItem: "Bucket",
        render: () => (
          <Tab.Pane attached={false}>{this.renderBucket()}</Tab.Pane>
        ),
      },
      {
        menuItem: this.renderTabLabel(
          "Performed",
          this.props.performedTotal,
          "green"
        ),
        render: () => (
          <Tab.Pane attached={false}>{this.renderPerformed()}</Tab.Pane>
        ),
      },
    ];

    return (
      <div style={styles.mainDiv}>
        <Tab
          renderActiveOnly={true}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {!this.state.loaded ? (
          <LoaderView text="Assigned page is Loading!" />
        ) : this.state.access ? (
          this.renderTabs()
        ) : (
          <UnauthorizedView pageName="Assigned Page" />
        )}
      </>
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

const mstp = (state) => {
  return {
    data: [],
    assignedTotal: state.teleCaller.assignedTotal,
    performedTotal: state.teleCaller.performedTotal,
  };
};

export default connect(mstp, actions)(Perform);
