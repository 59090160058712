import React from "react";

import { Field, reduxForm } from "redux-form";

import { Form, Message, Modal, Header, Icon } from "semantic-ui-react";

import { CommonInput, CommonSelect } from "./commonInput";

let PhoneForm = (props) => {
  console.log(props);
  let { type, isPrimary, message, label } = props;
  return (
    <div>
      <Modal size={props.size} open={props.open} onClose={props.onCloseHandle}>
        <Header>
          <Icon name={props.header.icon} />
          {props.header.text}
        </Header>
        <Modal.Content>
          <Form
            id="phoneForm"
            error={message ? message.type === "error" : false}
            success={message ? message.type === "success" : false}
            onSubmit={props.handleSubmit}
            size="large"
          >
            {label ? (
              <>
                <span>Phone Number</span>
                <div style={{ marginBottom: "3px" }} />
              </>
            ) : null}
            <Field
              disabled={props.loading}
              fluid
              name="number"
              component={CommonInput}
              placeholder="Phone Number"
              type="number"
            />
            {type ? (
              <>
                {label ? (
                  <>
                    <span>Contact Type</span>
                    <div style={{ marginBottom: "3px" }} />
                  </>
                ) : null}
                <Field
                  disabled={props.loading}
                  fluid
                  name="type"
                  component={CommonInput}
                  placeholder="Contact Type"
                />
              </>
            ) : null}
            {isPrimary ? (
              <>
                {label ? (
                  <>
                    <span>Is Primary</span>
                    <div style={{ marginBottom: "3px" }} />
                  </>
                ) : null}

                <Field
                  disabled={props.loading}
                  fluid
                  name="isPrimary"
                  component={CommonSelect}
                  placeholder="isPrimary"
                  options={[
                    { key: "z-2", value: true, text: "Yes" },
                    { key: "x02", value: false, text: "No" },
                  ]}
                  clearable
                />
              </>
            ) : null}
            {message !== null ? (
              <Message
                error={message !== null ? message.type === "error" : false}
                success={message !== null ? message.type === "success" : false}
                header={message.header}
                content={message.content}
              />
            ) : null}
          </Form>
        </Modal.Content>
        <Modal.Actions>{props.actionsComponent}</Modal.Actions>
      </Modal>
    </div>
  );
};

const valueCheck = (value, name, minLength = null) => {
  if (!value) {
    return `You must enter new ${name}`;
  } else {
    if (minLength) {
      if (value.length < minLength) {
        return `${name} must be at least ${minLength} characters`;
      }
    }
  }
};

const validate = (formValues) => {
  const errors = {};
  errors.Name = valueCheck(formValues.Name, "company name", 3);
  errors.Contactperson = valueCheck(
    formValues.Contactperson,
    "contact person",
    3
  );
  errors.Contactnum = valueCheck(formValues.Contactnum, "contact number", 10);
  return errors;
};

PhoneForm = reduxForm({
  form: "phone",
  //   validate,
  enableReinitialize: true,
})(PhoneForm);

export default PhoneForm;
