import React, { Component } from "react";
import {
  Button,
  Grid,
  Icon,
  Label,
  Popup,
  Header,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import LoaderView from "../components/loaderView";
import UnauthorizedView from "../components/unauthorizedView";
import DataTable from "../components/dataTable";
import CallerWindow from "./callerWindow";
import * as actions from "../actions";

class Bucket extends Component {
  state = {
    loadingNext: false,
    formLoading: false,
    submitLoading: false,
    currentCallee: null,
  };

  componentDidMount = () => {
    this.setState({ loadingNext: true });
    this.props
      .getRandomCalleeDetails()
      .then(() => {
        this.setState({
          loadingNext: false,
          formLoading: true,
          currentCallee: this.props.randomCallee,
        });
        this.props
          .LoadFormByID(this.props.randomCallee.formId)
          .then(() => {
            this.setState({ formLoading: false });
          })
          .catch(() => {
            this.setState({ formLoading: false });
          });
        this.props.LoadPhoneNumbers(this.props.randomCallee.defaulterId);
      })
      .catch(() => {
        this.setState({ loadingNext: false });
      });
    this.props.LoadActiveTags();
  };

  submitFillForm = (values) => {
    console.log(values);
    this.setState({ submitLoading: true });
    var data = {};
    data.actionid = this.state.currentCallee.id;
    var arr = [];
    var obj = {};
    Object.keys(values).forEach((key) => {
      var newData = {};
      if (key.includes("FORM")) {
        var k = key.substring(5);
        newData.caption = k;
        newData.value = values[key];
        arr.push(newData);
      }
      if (key.includes(values.resultId + "_")) {
        let rId = values.resultId + "";
        var k = key.substring(rId.length + 1);
        if (typeof values[key] === "object")
          obj[k] = values[key].toLocaleDateString();
        else obj[k] = values[key];
        console.log(k);
      }
    });
    data.formValues = arr;
    let tag = "";
    this.props.tagsList.forEach((x) => {
      if (x.value === values.resultId) {
        console.log("entered inside");
        tag = x.text;
      }
    });
    data[tag] = obj;
    data.resultId = values.resultId;
    console.log(data);
    var dataSend = {};
    dataSend.outcome = data;
    if (values.paymentimages !== undefined && values.paymentimages !== null) {
      dataSend.paymentimages = Object.values(values.paymentimages);
    }
    if (
      values.supportingImages !== undefined &&
      values.supportingImages !== null
    ) {
      dataSend.supportingImages = Object.values(values.supportingImages);
    }
    console.log(dataSend);
    this.props
      .ActionFill(dataSend)
      .then((x) => {
        console.log("DONE");
        this.setState({ submitLoading: false });
        this.getNextRandom();
        alert("Form Submitted");
      })
      .catch((x) => {
        this.setState({ submitLoading: false });
        alert("Failed");
      });
  };

  getNextRandom = () => {
    this.setState({ loadingNext: true });
    this.props
      .getRandomCalleeDetails()
      .then(() => {
        this.setState({
          loadingNext: false,
          formLoading: true,
          currentCallee: this.props.randomCallee,
        });
        this.props
          .LoadFormByID(this.props.randomCallee.formId)
          .then(() => {
            this.setState({ formLoading: false });
          })
          .catch(() => {
            this.setState({ formLoading: false });
          });
      })
      .catch(() => {
        this.setState({ loadingNext: false });
      });
  };

  render() {
    return (
      <CallerWindow
        callee={this.props.randomCallee}
        isRandom={true}
        loadingNext={this.state.loadingNext}
        onClickNext={() => this.getNextRandom()}
        loadedForm={this.props.loadedForm}
        userbyID={this.props.userbyID}
        submitLoading={this.state.submitLoading}
        onSubmitForm={this.submitFillForm}
        tagsList={this.props.tagsList}
      />
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

const mstp = (state) => {
  console.log(state);
  return {
    randomCallee: state.teleCaller.randomCallee,
    userbyID: state.user.userbyID,
    loadedForm: state.myForms.loadedForm,
    tagsList: state.tags.activeTags,
  };
};

export default connect(mstp, actions)(Bucket);
