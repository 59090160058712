import {
    LOAD_LOGS
} from '../actions/types';

const INITIAL_STATE = {
    data: [], total: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case LOAD_LOGS:
            return {...state, data: action.payload.data, total: action.payload.total};
        default:
            return state;
    }
}