import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { Field, reduxForm } from 'redux-form'
import { Form, Button, Message, Modal, Header, Icon, Input } from 'semantic-ui-react'
import { CommonInput } from './commonInput';
import { reset } from 'redux-form';
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'


momentLocaliser(moment)

const renderDateTimePicker = ({ input: { onChange, value }, showTime, label }) =>
    <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY"
        time={showTime}
        value={!value ? null : new Date(value)}
        placeholder={label}
    />

// class PasswordPrompt extends React.Component {


let PasswordPrompt = (props) => {
    // render() {
    // let props = this.props;
    return (
        <div>
            <Modal
                size="tiny"
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header
                    icon="file"
                    content={props.header}
                />
                <Modal.Content>
                    <Form
                        id="passwordChange2"
                        onSubmit={props.handleSubmit}
                        size="large"
                    >
                        <Field
                            name="From"
                            showTime={false}
                            component={renderDateTimePicker}
                            disabled={props.loading}
                            label="From"
                        />
                        <div style={{ marginBottom: '3px' }} />
                        <Field
                            name="To"
                            showTime={false}
                            component={renderDateTimePicker}
                            disabled={props.loading}
                            label="To"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary form='passwordChange2' type='submit'>Submit Form</Button>
                    {/* {props.actionsComponent} */}
                </Modal.Actions>
            </Modal>
            {/* <Modal
                size="tiny"
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header
                    icon="file"
                    content={props.header}
                />
                <Modal.Content>
                    <Form
                        id="passwordChange"
                        onSubmit={props.handleSubmit}
                        size="small"
                    >
                        <Field
                            // disabled={props.loading}
                            fluid
                            iconLeft
                            icon="user"
                            placeholder="Username"
                            name="Username"
                            component='input'
                        // component={CommonInput}
                        // normalize={noSpace}
                        />
                        <Field
                            // disabled={props.loading}
                            fluid
                            name="Password"
                            component='input'
                            // component={CommonInput}
                            // iconLeft
                            // icon="lock"
                            placeholder="Password"
                            type="text"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                <Button primary form='passwordChange' type='submit'>Submit Form</Button>
                    {props.actionsComponent}
                </Modal.Actions>
            </Modal> */}
        </div>
    )
}
// }

PasswordPrompt = reduxForm({
    form: 'passPrompt',
    enableReinitialize: true
    // validate
})(PasswordPrompt)


export default PasswordPrompt; 
