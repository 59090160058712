import axios from 'axios';

import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_PROCESSING_START, USER_PROCESSING_END, TOGGLE_ENTRY_FORM, LOGIN_ERROR, REGISTER_MESSAGE, LOAD_PROFILE } from './types';

const apiURL = require('../config.json').apiURL;

export const checkSession = () => {
    return (
        dispatch => {
            return new Promise((resolve, reject) => {

                axios({
                    method: 'get',
                    url: apiURL + 'userlogin/getmyprofile'
                }).then(({ data }) => {
                    dispatch({
                        type: LOAD_PROFILE,
                        payload: data.user
                    })
                    dispatch({
                        type: USER_LOGGED_IN
                    })
                    resolve('1')

                }).catch((error) => {
                    dispatch({
                        type: USER_LOGGED_OUT
                    })
                    resolve('1')

                })
            })
        }
    )
}

export const logIn = (values) => {
    return (dispatch) => {
        dispatch({
            type: USER_PROCESSING_START
        })
        dispatch({
            type: LOGIN_ERROR,
            payload: null
        })
        var form = new FormData();
        var rememberCheck = false;
        Object.keys(values).map((key, index) => {
            if (key === 'Remember') rememberCheck = true;
            form.append(key, values[key])
            return null
        });
        if (rememberCheck === false) {
            form.append('Remember', false)
        }
        axios({
            method: 'post',
            url: apiURL + 'userlogin/login',
            data: form
        }).then(tokens => {
            // console.log(tokens)
            var name = ''
            if (tokens.data !== null) {
                console.log('Not Null in header')
                name = tokens.data.user.firstname + ' ' + tokens.data.user.lastname
                window.localStorage.setItem('userHeader', name);
            }

            // console.log(name)
            dispatch({
                type: USER_PROCESSING_END
            })
            dispatch({
                type: USER_LOGGED_IN,
                payload: name
            })
            dispatch({
                type: LOGIN_ERROR,
                payload: null
            })
        })
            .catch((error) => {
                dispatch({
                    type: USER_PROCESSING_END
                })
                if (error.response === undefined) {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            header: 'Unprecedented Login Error',
                            content: 'Please try again. If this error persists then contact the IT department'
                        }
                    })
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            header: error.response.data,
                            // content: 'Please try again. If this error persists then contact the IT department'
                        }
                    })
                }
            });
    }
}

export const logOut = () => {
    return (dispatch) => {
        axios({
            method: 'post',
            url: apiURL + 'userlogin/logout',
        }).then(data => {
            dispatch({
                type: USER_LOGGED_OUT
            })
        })
            .catch((error) => {
                // return authentication error
            });
    }
}

export const toggleEntryForm = (form) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_ERROR,
            payload: null
        })
        dispatch({
            type: REGISTER_MESSAGE,
            payload: null
        })
        dispatch({
            type: TOGGLE_ENTRY_FORM,
            payload: form
        })
    }
}

export const register = (values) => {
    return (dispatch) => {
        dispatch({
            type: USER_PROCESSING_START
        })
        dispatch({
            type: REGISTER_MESSAGE,
            payload: null
        })
        var form = new FormData();
        // eslint-disable-next-line
        Object.keys(values).map((key, index) => {
            if (key !== 'confirmPassword') {
                form.append(key.toLowerCase(), values[key])
            }
        });

        axios({
            method: 'post',
            url: apiURL + 'userlogin/register',
            data: form,
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                dispatch({
                    type: REGISTER_MESSAGE,
                    payload: {
                        type: 'success',
                        header: 'Registration Successful',
                        content: 'You can log into your new account now!'
                    }
                })
                dispatch({
                    type: USER_PROCESSING_END
                })
            })
            .catch((error) => {
                dispatch({
                    type: REGISTER_MESSAGE,
                    payload: {
                        type: 'error',
                        header: 'Registration Failed',
                        content: 'You account was not able to be created, please try again later.'
                    }
                })
                dispatch({
                    type: USER_PROCESSING_END
                })

            });

    }
}
