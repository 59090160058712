import React from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form'

import { Button, Form, Segment, Message } from 'semantic-ui-react'

import { CommonInput, CommonCheckBox } from './commonInput';

import { noSpace } from './normalizers';


let LoginForm = (props) => {
    return (
        <div>
           <Form error={props.loginError !== null} onSubmit={props.handleSubmit} size='large'>
                <Segment>
                    <Field
                        disabled={props.loading}
                        fluid  
                        iconLeft
                        icon="user"
                        placeholder="Username"
                        name="Username"
                        component={CommonInput}
                        normalize={noSpace}         
                    />        
                    <Field
                        disabled={props.loading}
                        fluid
                        name="Password"
                        component={CommonInput}
                        iconLeft 
                        icon="lock"
                        placeholder="Password"
                        type="password"
                    />
                    <Field 
                        name="Remember"
                        label="Remember me"
                        component={CommonCheckBox}
                    />

                    {props.loginError !== null?
                        <Message
                            error
                            header = {props.loginError.header}
                            content = {props.loginError.content}
                        />
                        :
                        null
                    }
                    <Button type="submit" disabled={props.loading} loading={props.loading} color='blue' fluid size='large'>
                        Login
                    </Button>
                </Segment>
            </Form>
            <br/>
            <Button onClick={props.toggle} disabled={props.loading} inverted color='blue' size='small'>
                Register a new account
            </Button>
        </div>
    )
}

const valueCheck = (value,name) => {
    if (!value) {
        return `You must enter your ${name}`
    }
}

const validate = (formValues) => {
    const errors = {};
    errors.username = valueCheck(formValues.username,'username');
    errors.password = valueCheck(formValues.password,'password')
    return errors;
}
 

LoginForm = reduxForm({
    form: 'login',
    validate
})(LoginForm)

const mtsp = (state) => {
    return {
        loading: state.auth.loading,
        loginError: state.auth.loginError
    }
}

export default connect(mtsp,null)(LoginForm);