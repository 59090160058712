import {
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    USER_PROCESSING_START,
    USER_PROCESSING_END,
    TOGGLE_ENTRY_FORM,
    LOGIN_ERROR,
    REGISTER_MESSAGE,
    USERNAME_HEADER
} from '../actions/types';

const INITIAL_STATE = {
    form: 'login',
    logged: true,
    loading: false,
    loginError: null,
    registerMessage: null,
    userBar: null,
    userName: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_ENTRY_FORM:
            return { ...state, form: action.payload };
        case USER_LOGGED_IN:
            return { ...state, userBar: action.payload, logged: true };
        case USER_LOGGED_OUT:
            return { ...state, logged: false };
        case USER_PROCESSING_START:
            return { ...state, loading: true };
        case USER_PROCESSING_END:
            return { ...state, loading: false };
        case LOGIN_ERROR:
            return { ...state, loginError: action.payload }
        case REGISTER_MESSAGE:
            return { ...state, registerMessage: action.payload }
        case USERNAME_HEADER:
            return { ...state, userName: action.payload }
        default:
            return state;
    }
}