import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux';
import { Form, Message, Modal, Header, Icon, Input, Grid } from 'semantic-ui-react'
import { CommonSelect, CommonInput } from './commonInput';
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
import { reset } from 'redux-form';

momentLocaliser(moment)

const Months = [
    { value: 1, text: 'January' },
    { value: 2, text: 'February' },
    { value: 3, text: 'March' },
    { value: 4, text: 'April' },
    { value: 5, text: 'May' },
    { value: 6, text: 'June' },
    { value: 7, text: 'July' },
    { value: 8, text: 'August' },
    { value: 9, text: 'Septemper' },
    { value: 10, text: 'October' },
    { value: 11, text: 'Novermber' },
    { value: 12, text: 'December' }
]

const YEAR = 2018

const Years = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = YEAR;
    while ( startYear <= currentYear ) {
        years.push({value: startYear, text: startYear});
        startYear++;
    }   
    return years;
}

const selector = formValueSelector('dmyForm');

const renderDateTimePicker = ({ input: { onChange, value }, showTime, label, disabled }) =>
    <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY"
        time={showTime}
        value={!value ? null : new Date(value)}
        placeholder={label}
        disabled={disabled}
    />

let DateMonthYearForm = (props) => {
    const { date, year, month } = props
    // console.log(date)
    // console.log(year)
    // console.log(month)
    return (
        <div>
            <Modal
                size="tiny"
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header
                    icon="file"
                    content={props.header}
                />
                <Modal.Content>
                    <Form
                        id="dateMonthYearForm"
                        onSubmit={props.handleSubmit}
                        size="tiny"
                    >
                        <Grid centered>
                            {/* <Grid.Column width={16}> */}

                            <Grid.Row>
                                <Grid.Column width={13}>
                                    <Field
                                        name="Date"
                                        showTime={false}
                                        component={renderDateTimePicker}
                                        disabled={(month !== null && month !== undefined) || (year !== null && year !== undefined)}
                                        label="Date"
                                    />
                                    {/* <div style={{ marginBottom: '15px' }} /> */}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Field
                                        name="Month"
                                        showTime={false}
                                        component={CommonSelect}
                                        disabled={date !== null && date !== undefined}
                                        label="Month"
                                        placeholder="Month"
                                        options={Months}
                                        clearable
                                        // making it searchable makes it unusable
                                        // searchable
                                    />
                                </Grid.Column>

                                <Grid.Column width={6}>
                                    <Field
                                        name="Year"
                                        showTime={false}
                                        component={CommonSelect}
                                        disabled={date !== null && date !== undefined}
                                        clearable
                                        label="Year"
                                        placeholder="Year"
                                        options={Years()}
                                        // searchable
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            {props.Message !== null ?
                                <Message
                                    error={props.Message !== null ? props.Message.type === 'error' : false}
                                    success={props.Message !== null ? props.Message.type === 'success' : false}
                                    header={props.Message.header}
                                    content={props.Message.content}
                                />
                                :
                                null
                            }
                            {/* </Grid.Column> */}
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {props.actionsComponent}
                </Modal.Actions>
            </Modal>
        </div>
    )
}


const valueCheck = (value, name) => {
    if (!value) {
        return `You must Select a ${name}`
    } else {

    }
}


const validate = (formValues) => {
    const errors = {};
    if (formValues.Date === undefined || formValues.Date === null) {
        errors.Month = valueCheck(formValues.Month, 'Month');
        errors.Year = valueCheck(formValues.Year, 'Year');
    } else {
        errors.Date = valueCheck(formValues.Date, 'Date')
    }
    return errors;
}


DateMonthYearForm = connect(
    state => ({
        date: selector(state, 'Date'),
        year: selector(state, 'Year'),
        month: selector(state, 'Month')
        //   secondValue: selector(state, 'second')
    })
)(DateMonthYearForm)

DateMonthYearForm = reduxForm({
    form: 'dmyForm',
    validate,
    enableReinitialize: true
})(DateMonthYearForm)

export default DateMonthYearForm;