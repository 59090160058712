import React, { Component } from "react";
import {
  Modal,
  Select,
  Button,
  Grid,
  Form,
  Dropdown,
  Checkbox as CBUI,
} from "semantic-ui-react";
import {
  CommonInput,
  CommonSelect,
  CommonMultiSelect,
} from "../forms/commonInput";
import { Field, reduxForm, FieldArray, unregisterField } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../actions";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";

import moment from "moment";
import momentLocaliser from "react-widgets-moment";
momentLocaliser(moment);

class CaseFilterForm extends Component {
  state = {
    tags: null,
    companyTrackingId: null,
    priority: [
      { value: 1, text: 1 },
      { value: 2, text: 2 },
      { value: 3, text: 3 },
      { value: 4, text: 4 },
      { value: 5, text: 5 },
    ],
    datefrom: new Date(),
    dateto: new Date(),
    cycleDay: [],
    region: []
  };
  static getDerivedStateFromProps(props, state) {
    if (props.resultTags !== state.ResultTag) {
      return { ResultTag: props.resultTags };
    }
    return null;
  }
  componentDidMount() {
    this.props.LoadCompaniesWithIds();
    this.props.LoadAllCodes().then(() => {
      this.setState({ DefaulterCodes: this.props.allCodes });
    });
    this.props
      .DropdownFilter("Addresstype")
      .then(() => {
        this.getDropDownFields();
      })
      .catch(() => {});
    this.props
      .DropdownFilter("region")
      .then(() => {
        this.getDropDownFields();
      })
      .catch(() => {});
  }

  renderDateTimePicker = ({
    input: { onChange, value },
    showTime,
    placeholder,
    label,
    setDefaultToToday,
  }) => {
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <DateTimePicker
          onChange={onChange}
          format="DD-MM-YYYY"
          placeholder={placeholder}
          time={showTime}
          value={!value ? null : new Date(value)}
        />
      </>
    );
  };

  myField = (name, label, disabled, type = "text") => {
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <Field
          disabled={disabled}
          fluid
          name={name}
          component={CommonInput}
          placeholder={label}
          type={type}
        />
      </>
    );
  };

  myFieldFloat = (name, label, disabled, type = "number") => {
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <Field
          disabled={disabled}
          fluid
          name={name}
          component={CommonInput}
          placeholder={label}
          type={type}
          slep={0.01}
        />
      </>
    );
  };

  renderDropDown = (field) => {
    // console.log(field)
    // if (field === null || field === undefined){
    //     console.log("NULL or UD")
    //     console.log(field)
    // }
    return (
      <div style={{ marginBottom: "10px" }}>
        <Form.Select
          fluid
          // selection
          name={field.input.name}
          placeholder={field.placeholder}
          onChange={(e, { value }) => field.input.onChange(value)}
          value={field.input.value}
          clearable
          options={this.props.companybyID}
        />
      </div>
    );
  };

  renderCompanyDropdown = () => {
    return (
      <>
        <Grid.Column width={3}>
          {this.showLabel("Company")}
          <Field
            name="company"
            onChange={this.onCompanyDropdownChange}
            placeholder="Company"
            component={this.renderDropDown}
          />
        </Grid.Column>
      </>
    );
  };

  showLabel = (label) => {
    return (
      <>
        <span>{label}</span>
        <div style={{ marginBottom: "3px" }} />
      </>
    );
  };

  renderCommonSelect = (name, label) => {
    // console.log(this.state.name)
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <Field
          fluid
          name={name}
          component={CommonMultiSelect}
          clearable
          selection
          // onChange={(x) => console.log(x)}
          placeholder={label}
          options={this.state[name]}
        />
      </>
    );
  };

  renderCommonInput = (name, label) => {
    // console.log(this.state.name)
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <Field
          fluid
          name={name}
          component={CommonInput}
          clearable
          // onChange={(x) => console.log(x)}
          placeholder={label}
        />
      </>
    );
  };

  getTagsList = () => {
    this.setState({
      ResultTag: this.props.resultTags,
    });
  };

  getDropDownFields = () => {
    // console.log(this.props.dropdowns)
    if (this.props.dropdowns !== undefined && this.props.dropdowns !== null) {
      // console.log(this.props.dropdowns.field)
      let { field } = this.props.dropdowns;

      this.setState({
        [field]: this.props.dropdowns.data,
      });
    }
  };

  loadOtherDetails = (keyVal) => {
    // console.log(keyVal)
    keyVal.map((x) => {
      this.props
        .DropdownCustomerFilter(x.text.replace(/\s+/g, ""))
        .then(() => {
          this.getDropDownFields();
        })
        .catch(() => {});
    });
  };
  renderOtherDetailsFields = () => {
    //console.log(this.props.tagKeys)
    return this.props.tagKeys === null || this.props.tagKeys === undefined
      ? null
      : this.props.tagKeys.map((x) => {
          return (
            <Grid.Column width={2}>
              {this.rendeOtherCommonSelect(
                `${this.state.company}_${x.text}`,
                x.text,
                x.text.replace(/\s+/g, "")
              )}
              {/* {this.showLabel(x.text)}
                            <Field
                                name={`otherDetails_${x.text}`}
                                placeholder={"Type in the " + x.text}
                                fluid
                                component='input'
                            /> */}
            </Grid.Column>
          );
        });
  };

  rendeOtherCommonSelect = (name, label, access) => {
    // console.log(this.state.name)
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "1px" }} />
        </>
        <Field
          //fluid
          name={name}
          type="text"
          component={CommonInput}
          //clearable
          //selection
          // onChange={(x) => console.log(x)}
          placeholder={label}
          //options={this.state[access]}
        />
      </>
    );
  };

  unregister = (formVals) => {
    formVals.map((x) => unregisterField("customerFilter"));
  };

  onCompanyDropdownChange = (value) => {
    this.props.LoadTagKeys(value).then((x) => {
      //this.loadOtherDetails(this.props.tagKeys)
      this.setState({
        tags: this.props.tagKeys,
      });
    });
  };

  renderHasPaidDD = () => {
    return (
      <>
        {this.showLabel("Has Paid?")}
        <Field
          name="HasPaid"
          placeholder="Has Paid"
          fluid
          component={CommonSelect}
          options={[
            { key: "yes", value: true, text: "Yes" },
            { key: "no", value: false, text: "No" },
          ]}
          clearable
        />
      </>
    );
  };
  callerFilterDD = () => {
    return (
      <>
        {this.showLabel("Random/Assigned")}
        <Field
          name="isRandom"
          placeholder="Random/Assigned"
          fluid
          component={CommonSelect}
          options={[
            { key: "Random", value: true, text: "Random" },
            { key: "Assigned", value: false, text: "Assigned" },
          ]}
          clearable
        />
      </>
    );
  };
  renderActionCompletedThisMonth = () => {
    return (
      <>
        {this.showLabel("Action Completed This Month")}
        <Field
          name="ActionCompletedThisMonth"
          placeholder="ActionCompletedThisMonth"
          fluid
          component={CommonSelect}
          options={[
            { key: "z", value: true, text: "Yes" },
            { key: "x", value: false, text: "No" },
          ]}
          clearable
        />
      </>
    );
  };

  renderFlagDD = () => {
    return (
      <>
        {this.showLabel("Flag")}
        <Field
          name="Flag"
          placeholder="Flag"
          fluid
          component={CommonSelect}
          options={[
            { key: "n", value: "n", text: "Normal" },
            { key: "f", value: "f", text: "Marked" },
          ]}
          clearable
        />
      </>
    );
  };

  renderReadUnread = () => {
    return (
      <>
        {this.showLabel("Have Read")}
        <Field
          name="HaveRead"
          placeholder="Have Read"
          fluid
          component={CommonSelect}
          options={[
            { key: "n", value: true, text: "Yes" },
            { key: "f", value: false, text: "No" },
          ]}
          clearable
        />
      </>
    );
  };

  renderActionAssigned = () => {
    return (
      <>
        {this.showLabel("Action Assigned")}
        <Field
          name="ActionAssigned"
          placeholder="ActionAssigned"
          fluid
          component={CommonSelect}
          options={[
            { key: "z", value: true, text: "Yes" },
            { key: "x", value: false, text: "No" },
          ]}
          clearable
        />
      </>
    );
  };

  renderisCompleted = () => {
    return (
      <>
        {this.showLabel("Is Completed")}
        <Field
          name="isCompleted"
          placeholder="is Completed"
          fluid
          component={CommonSelect}
          options={[
            { key: "z-2", value: true, text: "Yes" },
            { key: "x02", value: false, text: "No" },
          ]}
          clearable
        />
      </>
    );
  };

  renderForm = () => {
    // console.log(this.state)
    let l = 2;
    let m = 3;
    let z = 4;
    return (
      <Form onSubmit={this.props.handleSubmit} size="tiny">
        <Grid>
          <Grid.Column width={z}>
            {this.renderCommonSelect("priority", "Priority", false)}
            {/* {this.myField('username', 'User Name', false)} */}
          </Grid.Column>
          <Grid.Column width={z}>
            {this.renderCommonSelect("region", "Area", false)}
            {/* {this.myField('username', 'User Name', false)} */}
          </Grid.Column>
          <Grid.Column width={z}>
            {this.renderCommonSelect("Addresstype", "Cycle Day", false)}
            {/* {this.myField('username', 'User Name', false)} */}
          </Grid.Column>
          <Grid.Column width={z}>
            {this.renderCommonInput("companyTrackingId", "Tracking ID", false)}
            {/* {this.myField('username', 'User Name', false)} */}
          </Grid.Column>
          <Grid.Column width={l + 1}>
            {/* {myField('datefrom', 'Date From', false)} */}
            <Field
              name="datefrom"
              showTime={false}
              component={this.renderDateTimePicker}
              placeholder="Date From"
              label="Date From"
            />
          </Grid.Column>
          <Grid.Column width={l + 1}>
            {/* {myField('dateto', 'Date To', false)} */}
            <Field
              name="dateto"
              showTime={false}
              component={this.renderDateTimePicker}
              placeholder="Date To"
              label="Date To"
            />
          </Grid.Column>
        </Grid>
        <div style={{ height: "15px" }} />
        <Button primary type="submit">
          Search with Filters
        </Button>
        <Button color="red" onClick={this.props.clearFilters}>
          Clear Filters
        </Button>
      </Form>
    );
  };

  renderCheckBoxUI = () => {
    return (
      <Field
        name="isRevoked"
        label="Revoked"
        fluid
        component={this.renderCheckbox}
      />
    );
  };

  renderCheckbox = ({ input, label }) => {
    return (
      <>
        <Form.Field>
          <CBUI
            label={label}
            checked={input.value ? true : false}
            onChange={(e, { checked }) => input.onChange(checked)}
          />
        </Form.Field>
      </>
    );
  };

  render() {
    return <>{this.renderForm()}</>;
  }
}

const mstp = (state) => {
  console.log(state);
  return {
    companybyID: state.company.companyWithIds,
    tagKeys: state.customer.tagKeys,
    dropdowns: state.actions.Dropdowns,
    allCodes: state.customer.allCodes,
    resultTags: state.tags.data,
  };
};

CaseFilterForm = reduxForm({
  form: "caseFilter",
  enableReinitialize: true,
})(CaseFilterForm);

export default connect(mstp, actions)(CaseFilterForm);
