import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Modal, Header, Input, Message } from 'semantic-ui-react'
import Datatable from '../components/dataTable'
import ConfirmBox from '../components/confirmBox';
import FormCreationForm from '../components/forms/formCreationForm';
import FormEditForm from '../components/forms/formEditForm';
import FormConstructor from '../components/formConstructor';
import * as actions from '../actions'
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';

class Forms extends Component {


    state = {
        showDeleteConfirmation: false,
        formOnDelete: null,
        deleteLoading: false,
        showCreationForm: false,
        trigger: 1,
        formMessage: null,
        showEditForm: false,
        access: false,
        loaded: false,
        //Copy Form
        showFormCopyModal: false,
        copyFormName: undefined,
        copyFromLoading: false,
        copyFormId: null,
        showCopyFormMessage: false,
        copyFormError: false,
        copyFormSuccess: false
    }

    componentDidMount = () => {
        this.props.LoadForms()
            .then(x => this.setState({ loaded: true, access: true }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    }


    renderPageActions = () => {
        return (
            <div style={styles.topBox}>
                <div>
                    <Button
                        onClick={() => {
                            this.setState({ showCreationForm: true })
                        }}
                        color="green"
                    >
                        Add New Form
                    </Button>
                </div>
            </div>
        )
    }


    renderDisplayFormModal = () => {

        return (
            this.state.showEditForm === false ?
                <FormConstructor
                    formInfo={this.props.loadedForm}
                    onClose={() => this.props.UnloadForm()}
                />
                : null
        )
    }

    renderEditFormBox = () => {
        return (
            <FormEditForm
                data={this.props.loadedForm}
                Message={this.state.formMessage}
                size="large"
                updateForm={() => this.constructForm(this.props.loadedForm.id)}
                open={this.state.showEditForm && this.props.loadedForm !== null}
                onClose={() => { this.setState({ showEditForm: false }); this.props.UnloadForm() }}
            />
        )
    }


    constructForm = (id) => {
        return new Promise((resolve, reject) => {
            this.props.LoadFormByID(id).then(x => resolve(), console.log(this.props.loadedForm)).catch(x => reject())
        })
    }

    renderFormCopyModal = () => {
        return (
            <Modal size={'tiny'}
                open={this.state.showFormCopyModal}
                onClose={() => {
                    this.setState({
                        copyFormId: null,
                        copyFormName: undefined,
                        showFormCopyModal: false,
                        showCopyFormMessage: false,
                        copyFormError: false,
                        copyFormSuccess: false
                    })
                }}
            >
                <Header>
                    <Icon name='copy' />
                    {"Copy Form"}
                </Header>
                <Modal.Content>
                    <Input
                        disabled={this.state.copyFromLoading}
                        placeholder='New Name'
                        fluid
                        value={this.state.copyFormName}
                        onChange={(e) => {
                            console.log(this.state.copyFormName)
                            this.setState({ copyFormName: e.target.value })
                        }}
                    />
                    {console.log(this.state)}
                    {this.state.showCopyFormMessage ?
                        <Message
                            onDismiss={() => {
                                this.setState({
                                    showCopyFormMessage: false,
                                    copyFormSuccess: false,
                                    copyFormError: false
                                })
                            }}
                            header={this.state.copyFormSuccess ? "Success!" : "Failed!"}
                            success={this.state.copyFormSuccess}
                            error={this.state.copyFormError}
                            content={this.copyFormSuccess ? 'Form is successfully copied/' : "Form could not be created."}

                        /> : null}
                </Modal.Content>
                <Modal.Actions>
                    {this.copyModalActions()}
                </Modal.Actions>
            </Modal>
        )
    }

    copyModalActions = () => {
        return (
            <>
                <Button
                    loading={false}
                    color='red'
                    icon='x'
                    inverted
                    floated={'left'}
                    content='Cancel'
                    onClick={() => {
                        this.setState({
                            copyFormId: null,
                            copyFormName: undefined,
                            showFormCopyModal: false,
                            showCopyFormMessage: false,
                            copyFormError: null,
                            copyFormSuccess: null
                        })
                    }}
                />
                <Button
                    loading={this.state.copyFromLoading}
                    color='green'
                    inverted
                    icon={'save'}
                    content={'Save'}
                    onClick={() => {
                        this.setState({ copyFromLoading: true })
                        let object = {};
                        object.id = this.state.copyFormId;
                        if (this.state.copyFormName !== undefined) {
                            object.name = this.state.copyFormName;
                        }
                        this.props.copyForm(object)
                            .then(() => {
                                console.log('teh')
                                this.setState({
                                    copyFromLoading: false,
                                    showCopyFormMessage: true,
                                    copyFormSuccess: true,
                                    trigger: this.state.trigger + 1
                                })
                            })
                            .catch(() => {
                                console.log('ctach')
                                this.setState({
                                    copyFromLoading: false,
                                    showCopyFormMessage: true,
                                    copyFormError: true,
                                    // trigger: this.state.trigger + 1
                                })
                            })
                    }}
                />
            </>
        )
    }

    actionsButton = (value) => {
        return (
            <>
                <Button size="mini" color="green" icon='search'
                    onClick={() => this.constructForm(value.id)}
                />
                <Button size="mini" color="grey" icon='copy'
                    onClick={() => {
                        this.setState({ copyFormId: value.id, showFormCopyModal: true })
                    }}
                />
                <Button size="mini" color="blue" icon='edit'
                    // disabled={deleteProcessing}
                    onClick={() => { this.setState({ showEditForm: true }); this.constructForm(value.id) }}
                />
                <Button size="mini" color="red" icon='x'
                    // loading={deleteProcessing}
                    onClick={() => this.setState({ showDeleteConfirmation: true, formOnDelete: value })}
                />
            </>
        );
    }


    renderFormList = () => {
        return (
            <Datatable
                header={[
                    {
                        name: 'ID',
                        accessor: 'id'
                    },
                    {
                        name: 'Form Name',
                        accessor: 'name'
                    }
                ]}
                actions={(x) => this.actionsButton(x)}
                data={this.props.data}
                triggerUpdate={this.state.trigger}
                noPagination
            />
        )
    }

    renderFormAddBox = () => {
        return (
            <FormCreationForm
                Message={this.state.formMessage}
                size="large"
                open={this.state.showCreationForm}
                onClose={() => this.setState({ showCreationForm: false })}
            />
        )
    }

    renderDeleteConfirmationBox = () => {
        return (
            this.state.formOnDelete !== null ?
                <ConfirmBox
                    loading={this.state.deleteLoading}
                    open={this.state.showDeleteConfirmation}
                    header='Confirm form deletion'
                    content={`You are deleting form with id: ${this.state.formOnDelete.id} and name: ${this.state.formOnDelete.name}. Deletion cannot be undone.`}
                    cancelButton='Cancel'
                    confirmButton="Delete"
                    size="mini"
                    onCancel={() => this.setState({ showDeleteConfirmation: false })}
                    onConfirm={() => this.handleFormDeletion()}

                />
                : null
        )
    }

    handleFormDeletion = () => {
        this.setState({ deleteLoading: true })
        this.props.deleteForm(this.state.formOnDelete.id)
            .then(x => this.setState({ deleteLoading: false, showDeleteConfirmation: false }))
            .catch(x => this.setState({ deleteLoading: false }))
    }

    renderFormsBox = () => {
        return (
            <div style={styles.mainDiv}>
                {this.renderPageActions()}
                {this.renderEditFormBox()}
                {this.renderDisplayFormModal()}
                {this.renderDeleteConfirmationBox()}
                {this.renderFormAddBox()}
                {this.renderFormList()}
                {this.renderFormCopyModal()}
            </div>
        )
    }

    render() {
        return (
            !this.state.loaded ? <LoaderView text="Forms Page is loading" /> :
                this.state.access ? this.renderFormsBox() : <UnauthorizedView pageName="Forms Page" />


        )
    }
}


const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}


const mstp = (state) => {
    return ({
        data: state.myForms.data,
        loadedForm: state.myForms.loadedForm
    })
}

export default connect(mstp, actions)(Forms);