import React from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import { Field, reduxForm } from "redux-form";
import {
  Grid,
  Form,
  Modal,
  Header,
  Icon,
  Input,
  Button,
} from "semantic-ui-react";
import { CommonInput } from "./commonInput";
import moment from "moment";
import momentLocaliser from "react-widgets-moment";
import { reset } from "redux-form";

class EditTag extends React.Component {
  state = {};

  myField = (name, label, type = "text") => {
    return (
      <>
        <>
          <span>{label}</span>
          <div style={{ marginBottom: "3px" }} />
        </>
        <Field
          disabled={false}
          fluid
          name={name}
          component={CommonInput}
          placeholder={label}
          type={type}
        />
      </>
    );
  };

  renderEditModal = () => {
    const { data } = this.props;
    return (
      <Modal
        size={this.props.size}
        open={this.props.open}
        onClose={() => this.props.CloseHandle}
        // style={{ width: '90%' }}
      >
        <Header>
          <Icon name="edit" />
          {this.props.data !== null
            ? `Edit Tag with ID:${this.props.data.id}`
            : null}
        </Header>
        <Modal.Content>
          <>
            <Form id="tagEdit" onSubmit={this.props.handleSubmit} size="small">
              <Grid>
                <Grid.Column width={16}>
                  {this.props.data
                    ? this.myField("tag", this.props.data.tag)
                    : null}
                </Grid.Column>
              </Grid>
            </Form>
          </>
        </Modal.Content>
        <Modal.Actions>{this.props.actionsComponent}</Modal.Actions>
      </Modal>
    );
  };

  render = () => {
    return <>{this.renderEditModal()}</>;
  };
}

EditTag = reduxForm({
  form: "tagEdit",
  enableReinitialize: true,
})(EditTag);

export default EditTag;
