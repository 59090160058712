import {
    LOAD_PERMISSIONS,
    QUEUE_PERMISSION,
    RESET_QUEUE_PERMISSION,
    TOGGLE_PERMISSION_PROCESSING,
    TOGGLE_PERMISSIONS
} from '../actions/types';
 

const INITIAL_STATE = {roles: [], permissions: [], rolePermission: [], permissionQueue: [], loading: false}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case LOAD_PERMISSIONS:
            return {
                ...state,
                roles: action.payload.roleList,
                permissions: action.payload.permissionList,
                rolePermission: action.payload.rolePermissionList,
                groups: action.payload.groupList
            };
        case QUEUE_PERMISSION:
            const { roleId, permissionId } = action.payload;
            const alreadyInPermissionQueue = state.permissionQueue.find(y => y.roleId === roleId && y.permissionId === permissionId)? false: true;
            if (alreadyInPermissionQueue){
                return {
                    ...state,
                    permissionQueue: [...state.permissionQueue,action.payload]
                }
            } else {
                 return {
                    ...state,
                    permissionQueue: state.permissionQueue.filter(y => (`r${y.roleId}p${y.permissionId}` !== `r${roleId}p${permissionId}`))
                }
            }
        case RESET_QUEUE_PERMISSION:
            return {
                ...state,
                permissionQueue: []
            }
        case TOGGLE_PERMISSION_PROCESSING:
            return {
                ...state,
                loading: !state.loading
            }
        case TOGGLE_PERMISSIONS:
            var newPermission = [...state.rolePermission]
            action.payload.map(x => {
                if(state.rolePermission.filter(y => (`r${y.roleId}p${y.permissionId}` === `r${x.roleId}p${x.permissionId}`)).length === 1){
                    newPermission = newPermission.filter(y => (`r${y.roleId}p${y.permissionId}` !== `r${x.roleId}p${x.permissionId}`))
                } else {
                    newPermission.push({roleId:x.roleId, permissionId: x.permissionId})
                }

                return null;
            })
            return {
                ...state,
                rolePermission: newPermission
            }
        default:
            return state;
    }
}