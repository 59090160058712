export const USER_LOGGED_IN = 1;
export const USER_LOGGED_OUT = 2;
export const USER_PROCESSING_START = 3;
export const USER_PROCESSING_END = 4;
export const TOGGLE_ENTRY_FORM = 5;
export const LOGIN_ERROR = 6;
export const REGISTER_MESSAGE = 7;
export const SIDE_BAR_VISIBLE = 8;
export const LOAD_PERMISSIONS = 9;
export const ADD_PERMISSION = 10;
export const DELETE_PERMISSION = 11;
export const QUEUE_PERMISSION = 12;
export const RESET_QUEUE_PERMISSION = 13;
export const TOGGLE_PERMISSION_PROCESSING = 14;
export const TOGGLE_PERMISSIONS = 15;
export const LOAD_ROLES = 16;
export const ADD_ROLE_LOAD_TOGGLE = 17;
export const DELETE_ROLE_LOAD = 18;
export const UPDATE_ROLE_SEARCH_TERM = 19;
export const PROFILE_PASSWORD_CHANGE_LOADING_TOGGLE = 20;
export const PROFILE_PASSWORD_CHANGE_MESSAGE = 21;
export const LOAD_PROFILE = 22;
export const CHANGE_USERNAME = 23;
export const USERNAME_CHANGE_STATUS = 24;
export const LOAD_EDIT_ROLE = 25;
export const EDIT_ROLE_ERROR = 26;
export const ROLE_EDIT_LOADING_TOGGLE = 27;
export const LOAD_COMPANIES = 28;
export const LOAD_USERS = 29;
export const TOGGLE_USER_ACTIVITY = 30;
export const LOAD_FORMS = 31;
export const REMOVE_FORM = 32;
export const LOAD_FORM_BY_ID = 33;
export const LOAD_SELF_ATTENDANCES = 34;
export const LOAD_LOGS = 35;
export const LOAD_DUTY_STATUS = 36;
export const IN_DUTY_REASON = 37;
export const LOAD_USER_ATTENDANCES = 38;
export const LOAD_FORMS_WITH_ID = 39;
export const LOAD_ALL_USER_WITH_ID = 40;
export const LOAD_CUSTOMERS = 41;
export const LOAD_DEFAULTER = 42;
export const LOAD_VIEWALLACTIONS = 43;
export const LOAD_OFFICE_TIMING = 44;
export const SET_OFFICE_TIMINGS = 45;
export const LOAD_ACTION_OUTCOME = 46;
export const LOAD_IMAGE = 47;
export const LOAD_IMAGE_FILE = 48;
export const LOAD_ACTION_REPORTS = 49;
export const LOAD_SORTING_PARAMS = 50;
export const LOAD_COMPANIES_WITH_IDS = 51;
export const LOAD_REGIONS_WITH_IDS = 52;
export const LOAD_TAG_KEYS = 53;
export const TOGGLE_DEFAULTER_FLAG = 54;
export const USERNAME_PASSWORD_RESET = 55;
export const LOAD_DROPDOWNS = 56;
export const LOAD_DROPDOWNS_ACTIONS = 57;
export const LOAD_APP_TIMING = 58;
export const ACTION_CREATION_SUCCESS = 59;
export const ACTION_CREATION_FAIL = 60;
export const USERNAME_HEADER = 61;
export const LOAD_ATTENDANCE_SUMMARY = 62;
export const LOAD_REGION_REPORTS = 63;
export const LOAD_ALL_TAGS = 64;
export const LOAD_ACTIVE_TAGS = 65;
export const TOGGLE_TAG_ACTIVITY = 66;
export const LOAD_USER_DEFAULTER_CODE = 67;
export const LOAD_ALL_CODES = 68;
export const LOAD_COMMENTS = 69;
export const LOAD_SELECTED_DEFAULTERS = 70;
export const LOAD_ACTION_HISTORY = 71;
export const LOAD_CALLER_ACTIONS_ASSIGNED = 72;
export const LOAD_CALLER_ACTIONS_PERFORMED = 73;
export const LOAD_CALLER_ACTION_DETAIL = 74;
export const LOAD_CALLER_ACTION_DETAIL_RANDOM = 75;
export const LOAD_PHONE_NUMBERS = 76;
