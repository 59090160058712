import React from 'react';
import { Modal, Button, Confirm, ModalDescription } from 'semantic-ui-react'

const ConfirmBox = (props) => {
    return (
        <Modal
            size={props.size}
            open={props.open}
            onClose={props.loading?()=>{}:props.onCancel}
        >
            <Modal.Header>
                {props.header}
            </Modal.Header>
            <Modal.Content>
                {props.content}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={props.onCancel}
                    disabled={props.loading}
                >
                    {props.cancelButtonText}
                </Button>
                <Button
                    primary
                    loading={props.loading}
                    disabled={props.loading}
                    onClick={props.onConfirm}
                >
                    {props.confirmButtonText}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

ConfirmBox.defaultProps = {
    size: 'small',
    header: 'Are you sure?',
    content: 'proceed only if you are sure',
    open: true,
    cancelButtonText: 'cancel',
    confirmButtonText: 'confirm',
    loading: false,
    onCancel: ()=>{}
}

export default ConfirmBox;