import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { reset, Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input, Checkbox, Button, GridColumn, Grid } from 'semantic-ui-react'
import { CommonSelect, CommonSearchableDropdown } from './commonInput';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)

class ActionFormCall extends React.Component {

    state = {
        isRandom: false
    }

    renderCheckbox = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Grid>
                    <GridColumn width={2}>
                        <span>Random</span>
                    </GridColumn>
                    <GridColumn width={2}>
                        <Checkbox
                            toggle  
                            onChange = {(e, data) => {
                                //field.input.value = data.checked + "";
                                //console.log(field.input.value);
                                field.input.onChange(data.checked)
                                this.setState({isRandom: data.checked});
                            }}
                        />
                    </GridColumn>
                </Grid>
            </div>
        )
    }

    renderDropDown = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    disabled={field.disabled}
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ [field.input.name]: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={field.options}
                />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Modal
                    size={this.props.size}
                    open={this.props.open}
                    onClose={this.props.onCloseHandle}
                >
                    <Header >
                        <Icon name={this.props.header.icon} />
                        {this.props.header.text}
                    </Header>
                    <Modal.Content>
                        <Form
                            id="actionsForm"
                            error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                            success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                            onSubmit={this.props.handleSubmit}
                            size='large'>
                            {this.props.total ? <><span>Selected : {this.props.total}</span><div style={{ marginBottom: '3px' }} /></> : null}
                            {this.props.label ? <><span>Tag</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="tag"
                                options={[
                                    { value: 'Promise', text: 'Promise' },
                                    { value: 'Reminder', text: 'Reminder' },
                                    { value: 'Not Paying', text: 'Not Paying' },
                                    { value: 'First Contact', text: 'First Contact' },
                                    { value: 'BD Activity', text: 'BD Activity' }
                                ]}
                                component={CommonSelect}
                                placeholder="Tag"
                                clearable
                            />

                            {this.props.label ? <><span>Type</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="type"
                                options={[
                                    { value: 'Survey', text: 'Survey' },
                                    { value: 'Follow Up', text: 'Follow Up' },
                                    { value: 'Call', text: 'Call' }
                                ]}
                                component={CommonSelect}
                                placeholder="Type"
                                clearable
                            />

                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="priority"
                                options={[
                                    { value: 1, text: 'P1' },
                                    { value: 2, text: 'P2' },
                                    { value: 3, text: 'P3' },
                                    { value: 4, text: 'P4' },
                                    { value: 5, text: 'P5 (Default if none selected)' }
                                ]}
                                component={CommonSelect}
                                placeholder="Priority (P1 is highest priority)"
                                clearable
                            />

                            {this.props.label ? <><span>Requires Approval</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="requiresApproval"
                                options={[
                                    { value: true, text: 'Yes' },
                                    { value: false, text: 'No' }
                                ]}
                                component={CommonSelect}
                                placeholder="Requires Approval"
                                clearable
                            />
                            {this.props.label ? <><span>Random</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                type="checkbox"
                                name="isRandom"
                                component={this.renderCheckbox}
                                clearable
                                value={this.state.isRandom}
                            />
                            {this.props.label ? <><span>Assign To</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading || this.state.isRandom}
                                fluid
                                searchable
                                name="userid"
                                options={this.props.assignTo}
                                component={this.renderDropDown}
                                placeholder="Assign To"
                            // clearable
                            />
                            {this.props.label ? <><span>Form</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="formid"
                                searchable
                                component={this.renderDropDown}
                                options={this.props.myForms}
                                clearable
                                placeholder="Form (Optional)"
                            />

                            {this.props.label ? <><span>Date</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                name="date"
                                showTime={false}
                                placeholder="Date (Optional)"
                                component={renderDateTimePicker}
                                disabled={this.props.loading}
                            />
                            {this.props.showMessage !== false ?
                                <Message
                                    error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                                    success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                                    header={this.props.Message.header}
                                    content={this.props.Message.text}
                                />
                                :
                                null
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        {this.props.actionsComponent}
                    </Modal.Actions>
                </Modal>


            </div>
        )
    }

}

const renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) => {
    return (
        <div style={{ marginBottom: '15px' }}>
            <DateTimePicker
                dropUp
                onChange={onChange}
                format="DD-MM-YYYY"
                placeholder={placeholder || "Date"}
                time={showTime}
                value={!value ? null : new Date(value)}
            />
        </div>
    )
}

const valueCheck = (value, name, minLength = null) => {
    if (name === 'Require Approval' || name === 'Is Ascending?') {
        if (value === undefined)
            return `You must select ${name}`;
        else
            return
    }
    if (!value) {
        return `You must select ${name}`;
    }
    if (value == null) {
        return 'Please Enter/Select Value';
    }

}
const validate = (formValues) => {
    const errors = {};
    errors.tag = valueCheck(formValues.tag, 'Tag')
    errors.type = valueCheck(formValues.type, 'Type')
    errors.requiresApproval = valueCheck(formValues.requiresApproval, 'Require Approval')
    return errors;
}

ActionFormCall = reduxForm({
    form: 'action',
    validate,
    enableReinitialize: true
})(ActionFormCall)

export default ActionFormCall;