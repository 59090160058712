import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Checkbox } from 'semantic-ui-react'
import { CommonSelect } from './commonInput';

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)

class ActionFormSMS extends React.Component {

    state = {
        checkedStatus: null
    }

    renderCheckbox = ({ label }) => {
        console.log(label)
        return (
            <Checkbox
                label={label}
                onChange={toggle}
            />
        )
    }
    toggle = () => {
        checkedStatus = !checkedStatus
        console.log(checkedStatus)
    }

    renderDropDown = (field) => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <Form.Select
                    fluid
                    // selection
                    search
                    name={field.input.name}
                    placeholder={field.placeholder}
                    onChange={(e, { value }) => {
                        field.input.onChange(value)
                        this.setState({ [field.input.name]: value })
                    }}
                    value={field.input.value}
                    clearable
                    options={field.options}
                />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Modal
                    size={this.props.size}
                    open={this.props.open}
                    onClose={this.props.onCloseHandle}
                >
                    <Header >
                        <Icon name={this.props.header.icon} />
                        {this.props.header.text}
                    </Header>
                    <Modal.Content>
                        <Form
                            id="actionsForm"
                            error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                            success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                            onSubmit={this.props.handleSubmit}
                            size='large'>
                            {this.props.total ? <><span>Selected : {this.props.total}</span><div style={{ marginBottom: '3px' }} /></> : null}
                            {this.props.label ? <><span>Tag</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                name="tag"
                                options={[
                                    { value: 'Promise', text: 'Promise' },
                                    { value: 'Reminder', text: 'Reminder' },
                                    { value: 'Not Paying', text: 'Not Paying' },
                                    { value: 'First Contact', text: 'First Contact' },
                                    { value: 'BD Activity', text: 'BD Activity' }
                                ]}
                                component={CommonSelect}
                                placeholder="Tag"
                                clearable
                            />

                            {this.props.label ? <><span>Assign To</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                disabled={this.props.loading}
                                fluid
                                searchable
                                name="userid"
                                options={this.props.assignTo}
                                component={this.renderDropDown}
                                placeholder="Assign To"
                            // clearable
                            />

                            {this.props.label ? <><span>Date</span><div style={{ marginBottom: '3px' }} /></> : null}
                            <Field
                                name="date"
                                showTime={false}
                                placeholder="Date (Optional)"
                                component={renderDateTimePicker}
                                disabled={this.props.loading}
                            />
                            {this.props.showMessage !== false ?
                                <Message
                                    error={this.props.Message !== null ? this.props.Message.type === 'error' : false}
                                    success={this.props.Message !== null ? this.props.Message.type === 'success' : false}
                                    header={this.props.Message.header}
                                    content={this.props.Message.text}
                                />
                                :
                                null
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        {this.props.actionsComponent}
                    </Modal.Actions>
                </Modal>


            </div>
        )
    }

}

var checkedStatus = false;

const renderDateTimePicker = ({ input: { onChange, value }, showTime, placeholder }) => {
    return (
        <div style={{ marginBottom: '15px' }}>
            <DateTimePicker
                dropUp
                onChange={onChange}
                format="DD-MM-YYYY"
                placeholder={placeholder || "Date"}
                time={showTime}
                value={!value ? null : new Date(value)}
            />
        </div>
    )
}

const toggle = () => {
    checkedStatus = !checkedStatus
    console.log(checkedStatus)
}

const valueCheck = (value, name, minLength = null) => {
    if (name === 'Require Approval' || name === 'Is Ascending?') {
        if (value === undefined)
            return `You must select ${name}`;
        else
            return
    }
    if (!value) {
        return `You must select ${name}`;
    }
    if (value == null) {
        return 'Please Enter/Select Value';
    }

}
const validate = (formValues) => {
    const errors = {};
    errors.tag = valueCheck(formValues.tag, 'Tag')
    return errors;
}

ActionFormSMS = reduxForm({
    form: 'action',
    validate,
    enableReinitialize: true
})(ActionFormSMS)

export default ActionFormSMS;