import React from "react";
import { reduxForm } from "redux-form";
import DataTable from "../dataTable";
import { Modal, Header, Icon, Grid, Button, Popup } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import * as actions from "../../actions";

class ActionOutcomeForm extends React.Component {
  state = {
    supportingImageLoading: false,
    paymentImageLoading: false,
  };

  renderPromiseTable = (ref) => {
    return ref.promise === null ? null : (
      <Grid.Column width={8}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Promises</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Amount",
              accessor: "amount",
            },
            {
              name: "Paid Amount",
              accessor: "paidAmount",
            },
            {
              name: "Date",
              accessor: "date",
            },
          ]}
          loading={false}
          data={new Array(ref.promise)}
          show={true}
          noPagination
          trigger={() => {}}
        />
      </Grid.Column>
    );
  };

  renderOutcomeTable = (ref) => {
    if (ref === null) {
      return null;
    } else {
      if (ref.approval !== null) {
        ref.reason = ref.approval.reason;
      }
      if (ref.result !== null) {
        ref.resultTag = ref.result.tag;
      }
    }

    let dat = new Array(ref);
    // console.log(dat);

    return (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Details</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Tag",
              accessor: "resultTag",
            },
            {
              name: "Time Stamp",
              accessor: "timestamp",
            },
            {
              name: "Latitude",
              accessor: "latitude",
            },
            {
              name: "Longitude",
              accessor: "longitude",
            },
            {
              name: "Approved Status",
              accessor: "approved",
            },
            {
              name: "Reason",
              accessor: "reason",
            },
          ]}
          actions={(x) => this.renderLocationButton(x)}
          loading={this.state.loadTable}
          data={dat}
          show={this.state.access && this.state.loaded}
          noPagination
          trigger={this.state.triggerUpdate}
          compact
        />
      </Grid.Column>
    );
  };

  renderLocationButton = (value) => {
    return (
      <>
        {/* <Button
                    icon='edit'
                    color='green'
                    size="tiny"
                    disabled={false}
                    onClick={() => this.setState({ actionOutcome: value, outcomeEditShow: true })}
                    data-tooltip="Edit Tag"
                    data-position="top right"
                /> */}
        <Button
          data-tooltip="Open Location in Google Maps"
          data-position="top right"
          circular
          color="blue"
          size="tiny"
          disabled={false}
          icon="paper plane"
          onClick={() => this.renderLocation(value)}
        />
        {/* {this.LoadAddressActions(x)} */}
      </>
    );
  };

  // renderOutcomeTable = (ref) => {
  //     let dat = new Array(ref);
  //     console.log(dat);

  //     return (
  //         <Grid.Column width={8}>
  //             <Grid.Column style={{ marginBottom: '10px' }}>
  //                 <Header size="medium">
  //                     Details
  //                 </Header>
  //             </Grid.Column>
  //             <DataTable
  //                 header={[
  //                     {
  //                         name: 'Id',
  //                         accessor: 'id'
  //                     },
  //                     {
  //                         name: 'Tag',
  //                         accessor: 'resultTag'
  //                     },
  //                     {
  //                         name: 'Time Stamp',
  //                         accessor: 'timestamp'
  //                     },
  //                     {
  //                         name: 'Approved Status',
  //                         accessor: 'approved'
  //                     }
  //                 ]}
  //                 loading={false}
  //                 data={dat}
  //                 show={true}
  //                 noPagination
  //             // trigger={() => { }}
  //             />
  //         </Grid.Column>
  //     )
  // }

  renderLocationButtonand = (outcome) => {
    var array = outcome.formValues.filter(
      (element) =>
        element.caption === "Longitude" || element.caption === "Latitude"
    );

    if (array.length === 0) {
      console.log("It is null");
      return null;
    }

    array = array.map((x) => {
      return x.caption === "Longitude"
        ? { Longitude: x.value }
        : x.caption === "Latitude"
        ? { Latitude: x.value }
        : null;
    });
    array = [{ ...array[0], ...array[1] }];
    return (
      <>
        <Grid.Column width={16}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="medium">
              Address Details{" "}
              {/*<Button floated="right" size="tiny" >Add</Button> */}
            </Header>
          </Grid.Column>
          <DataTable
            header={[
              {
                name: "Longitute",
                accessor: "Longitude",
              },
              {
                name: "Latitude",
                accessor: "Latitude",
              },
            ]}
            actions={(x) => this.LoadAddressActions(x)}
            loading={this.state.loadTable}
            show={this.state.access && this.state.loaded}
            data={array}
            noPagination
            overflowX
          />
        </Grid.Column>
      </>
    );
  };

  LoadAddressActions = (address) => {
    return (
      <>
        <Button
          data-tooltip="Open Location in Google Maps"
          data-position="top right"
          circular
          color="blue"
          size="tiny"
          disabled={false}
          icon="paper plane"
          onClick={(x) => this.renderLocation(address)}
        />
      </>
    );
  };

  renderLocation = (address) => {
    console.log(address);
    var link = `http://maps.google.com?q=${address.latitude},${address.longitude}`;
    window.open(link);
  };

  renderFlagButton = (value) => {
    let color, iconName, text, flag;

    if (!value.isInvalid) {
      color = "green";
      iconName = "circle";
      text = "Valid";
      flag = true;
    } else {
      color = "grey";
      iconName = "circle outline";
      text = "In valid";
      flag = false;
    }
    return (
      <Popup
        content={`${value.number} is ${text} Number. Click to change it.`}
        position="top right"
        on="hover"
        trigger={
          <Button
            // loading={activityProcessing}
            // disabled={activityProcessing}
            size="mini"
            color={color}
            icon={iconName}
            onClick={() => this.changeValid(value.id, flag)}
          />
        }
      />
    );
  };

  changeValid = (id, status) => {
    this.props
      .changeValidPhone(id, status)
      .then(() => console.log("done"))
      .catch(() => console.log("failed"));
  };

  renderPhoneNumbers = (phoneNumbers) => {
    console.log("nmbers", phoneNumbers);
    // if (phoneNumbers === null || phoneNumbers.length === 0) return;
    return (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Phone Numbers</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Type",
              accessor: "type",
            },
            {
              name: "Number",
              accessor: "number",
            },
            // {
            //     name: 'Type',
            //     accessor: 'type'
            // },
            // {
            //   name: "Value",
            //   accessor: "value",
            // },
          ]}
          actions={(x) => this.phoneActions(x)}
          loading={false}
          data={phoneNumbers}
          show={true}
          noPagination
          // trigger={() => { }}
        />
      </Grid.Column>
    );
  };

  phoneActions = (value) => {
    return (
      <>
        {this.renderFlagButton(value)}
        <Popup
          content={`Delete Data of ${value.name}.`}
          position="top right"
          // on='hover'
          // inverted
          trigger={
            <Button
              size="mini"
              color="red"
              icon="remove"
              //   disabled={activityProcessing}
              onClick={() => {
                this.props
                  .deletePhoneNumber(value.id)
                  .then(() => console.log("deleted"))
                  .catch(() => console.log("failed"));
              }}
            />
          }
        />
      </>
    );
  };

  renderFormValuesTable = (ref) => {
    return ref.formValues !== undefined &&
      ref.formValues.length === 0 ? null : (
      <Grid.Column width={16}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Form Values</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Caption",
              accessor: "caption",
            },
            // {
            //     name: 'Type',
            //     accessor: 'type'
            // },
            {
              name: "Value",
              accessor: "value",
            },
          ]}
          loading={false}
          data={ref.formValues}
          show={true}
          noPagination
          // trigger={() => { }}
        />
      </Grid.Column>
    );
  };

  renderReminderTable = (ref) => {
    return ref.reminder === null ? null : (
      <Grid.Column width={8}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Reminder</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Id",
              accessor: "id",
            },
            {
              name: "Date",
              accessor: "date",
            },
          ]}
          loading={false}
          data={new Array(ref.reminder)}
          show={true}
          noPagination
          // trigger={() => { }}
        />
      </Grid.Column>
    );
  };

  supportingActions = (value) => {
    return (
      <Button
        size="mini"
        color="green"
        icon="image"
        loading={this.state.supportingImageLoading}
        //  disabled={value.completedOn === null}
        onClick={() => {
          this.setState({ supportingImageLoading: true });
          this.props
            .LoadSupportingImage(value.path)
            .then(() => this.setState({ supportingImageLoading: false }))
            .catch(() => this.setState({ supportingImageLoading: false }));
        }}
      />
    );
  };

  renderSupportingImages = (ref) => {
    console.log(ref);
    var row = 0;
    var arr = ref.supportingImages.map((x) => {
      row++;
      return { id: row, path: x.path };
    });
    console.log(arr);
    return (
      <Grid.Column width={4}>
        <Grid.Column style={{ marginBottom: "10px" }}>
          <Header size="medium">Supporting Images</Header>
        </Grid.Column>
        <DataTable
          header={[
            {
              name: "Sr. No.",
              accessor: "id",
            },
          ]}
          actions={(x) => this.supportingActions(x)}
          loading={false}
          data={arr}
          show={true}
          noPagination
          overflowX
          // trigger={() => { }}
        />
      </Grid.Column>
    );
  };

  renderPaymentTable = (ref) => {
    return ref.result.tag === "Payment" ? (
      // ref.payment === null ?
      <>
        <Grid.Column width={8}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="medium">
              Payments
              {/* <Button floated="right" size="tiny" icon='add' content='Add' onClick={() => { this.setState({ uploadPaymentImage: true }); console.log(this.state.selectedOutcome) }} /> */}
            </Header>
          </Grid.Column>
          <DataTable
            header={[
              {
                name: "Id",
                accessor: "id",
              },
              {
                name: "Amount",
                accessor: "amount",
              },
              {
                name: "Date",
                accessor: "date",
              },
            ]}
            // ActionHeading='View Image'
            // actions={(x) => this.showImage(x.paymentImages)}
            loading={this.state.loadTable}
            data={ref.payment !== null ? new Array(ref.payment) : []}
            show={this.state.access && this.state.loaded}
            noPagination
            trigger={this.state.triggerUpdate}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          {this.renderPaymentImages(ref.payment)}
        </Grid.Column>
      </>
    ) : null;
  };

  renderPaymentImages = (ref) => {
    //  console.log(ref)
    var row = 0;
    var arr = ref.paymentImages.map((x) => {
      row++;
      return { id: row, imageId: x.id, path: x.path };
    });
    // console.log(arr)
    return (
      <>
        <Grid.Column width={8}>
          <Grid.Column style={{ marginBottom: "10px" }}>
            <Header size="medium">
              Payment Images
              {/* <Button floated="right" size="tiny" icon='add' content='Add' onClick={() => this.setState({ uploadPaymentImage: true })} /> */}
            </Header>
          </Grid.Column>
          <DataTable
            header={[
              {
                name: "Sr. No.",
                accessor: "id",
              },
            ]}
            actions={(x) => this.paymentActions(x)}
            loading={this.state.loadTable}
            data={arr}
            show={this.state.access && this.state.loaded}
            noPagination
            compact
            trigger={this.state.triggerUpdate}
          />
        </Grid.Column>
      </>
    );
  };

  paymentActions = (element) => {
    return (
      <>
        <Button
          size="mini"
          disabled={false}
          icon="image"
          color="green"
          loading={this.state.paymentImageLoading}
          onClick={(x) => {
            this.setState({ paymentImageLoading: false });
            this.props
              .LoadImage(element.path)
              .then((x) => {
                this.setState({ paymentImageLoading: false });
              })
              .catch((x) => {
                this.setState({ paymentImageLoading: false });
              });
          }}
        />
      </>
    );
  };

  // renderPaymentTable = (ref) => {
  //     return (
  //         ref.payment === null ? null :
  //             <Grid.Column width={8}>
  //                 <Grid.Column style={{ marginBottom: '10px' }}>
  //                     <Header size="medium">
  //                         Payments
  //                     </Header>
  //                 </Grid.Column>
  //                 <DataTable
  //                     header={[
  //                         {
  //                             name: 'Id',
  //                             accessor: 'id'
  //                         },
  //                         {
  //                             name: 'Amount',
  //                             accessor: 'amount'
  //                         },
  //                         {
  //                             name: 'Date',
  //                             accessor: 'date'
  //                         }
  //                     ]}
  //                     //ActionHeading='View Image'
  //                     //actions={() => { }}
  //                     ActionHeading='View Image'
  //                     actions={(x) => this.showImage(x.paymentImages)}
  //                     loading={false}
  //                     data={new Array(ref.payment)}
  //                     show={true}
  //                     noPagination
  //                     overflowX
  //                 // trigger={() => { }}
  //                 />
  //             </Grid.Column>
  //     )
  // }

  showImage = (imageRef) => {
    var arr = [];
    imageRef.forEach((element) => {
      arr.push(
        <Button
          disabled={false}
          loading={this.state.paymentImageLoading}
          icon="image"
          compact
          color="green"
          onClick={(x) => {
            this.setState({ paymentImageLoading: true });
            this.props
              .LoadImage(element.path)
              .then(() => this.setState({ paymentImageLoading: false }))
              .catch(() => this.setState({ paymentImageLoading: false }));
          }}
        />
      );
      // this.props.LoadImage(element.path)
      //     .then(
      //         image = this.props.image,
      //         arr.push(<a href={"data:image/png;base64," + image + ""} target="_blank" >Image <br /></a>)
      //     )
    });
    return <> {arr} </>;
  };

  render() {
    return (
      <>
        {this.props.OutCome === null ||
        this.props.OutCome === "" ||
        this.props.OutCome === undefined ? (
          <div>
            <Modal
              size="tiny"
              open={this.props.open}
              onCloseHandle={this.props.onCloseHandle}
            >
              <Header className="center">
                <Icon name={this.props.header.icon} />
                {this.props.header.text}
              </Header>
              <Modal.Content>
                <Grid>
                  <Grid.Column>
                    <h3>No Outcome to Show</h3>
                  </Grid.Column>
                </Grid>
              </Modal.Content>
              <Modal.Actions>{this.props.actionsComponent}</Modal.Actions>
            </Modal>
          </div>
        ) : (
          <div>
            <Modal
              size={this.props.size}
              open={this.props.open}
              onClose={this.props.onCloseHandle}
            >
              <Header className="center">
                <Icon name={this.props.header.icon} />
                {this.props.header.text}
              </Header>
              <Modal.Content>
                <Grid>
                  {this.renderOutcomeTable(this.props.OutCome)}
                  {this.renderPromiseTable(this.props.OutCome)}
                  {this.renderPaymentTable(this.props.OutCome)}
                  {this.renderReminderTable(this.props.OutCome)}
                  {this.renderSupportingImages(this.props.OutCome)}
                  {this.renderFormValuesTable(this.props.OutCome)}
                  {this.renderPhoneNumbers(this.props.phoneNumbers)}
                  {/* {this.renderLocationButton(this.props.OutCome)} */}
                </Grid>
              </Modal.Content>
              <Modal.Actions>{this.props.actionsComponent}</Modal.Actions>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

ActionOutcomeForm = reduxForm({
  form: "outcome",
  enableReinitialize: true,
})(ActionOutcomeForm);

export default connect(null, actions)(ActionOutcomeForm);
