import {
    LOAD_REGIONS_WITH_IDS
} from '../actions/types';

const INITIAL_STATE = {
    regionWithIds: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_REGIONS_WITH_IDS:
            return {
                ...state,
                regionWithIds: action.payload
            }
        default:
            return state;
    }
}