import React from 'react';
import {connect} from 'react-redux';
import { Field, reset, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Grid, Dropdown, Button } from 'semantic-ui-react'
import { CommonInput, CommonSelect, CommonMultiSelect } from './commonInput';
import { timeout } from 'q';
let myField = (name, label, disabled, type="text", placeholder=null, dataEditing = false) => {
    return (
        <>
            <>
                <span style={{fontSize: '1rem'}}>{label}</span>
                <div style={{marginBottom:'3px'}}/>
            </>
            <Field
                disabled={disabled || dataEditing}
                fluid
                name={name}
                component={CommonInput}
                placeholder={placeholder === null?label: placeholder}
                type={type}
            />
        </>
    )
}   

let myGrid = (loading,dropdown, disabled, input, dataEditing, cancelEditHandler) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    {myField('caption','Caption', loading,'text',null, dataEditing)}
                </Grid.Column>
                <Grid.Column width={8}>
                    <>
                        <span style={{fontSize: '1rem'}}>Input Type</span>
                        <div style={{marginBottom:'3px'}}/>
                    </>
                    <Field
                        name='type'
                        disabled={loading}
                        fluid
                        placeholder="Select Input Type"
                        component={CommonSelect}
                        options={
                            [
                                {
                                    value:"dropdown",
                                    text: 'Dropdown'
                                },{
                                    value:"textField",
                                    text: 'TextField'
                                }
                            ]
                        }
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    {
                        input?
                            myField('minlength','Minimum Length', loading, 'number')
                        : null    
                    }                
                </Grid.Column>
                <Grid.Column width={8}>
                    {
                        input?
                            myField('maxlength','Maximum Length', loading, 'number')
                        : null
                    }
                </Grid.Column>
                <Grid.Column width={16}>
                    {input? 
                        <>
                            <>
                                <span style={{fontSize: '1rem'}}>Data Type</span>
                                <div style={{marginBottom:'3px'}}/>
                            </>
                            <Field
                                name='datatype'
                                disabled={loading}
                                fluid
                                placeholder="Select Data Type"
                                component={CommonSelect}
                                options={
                                    [
                                        {
                                            value:'number',
                                            text: 'Number'
                                        },{
                                            value:'text',
                                            text: 'Text'
                                        },{
                                            value:'float',
                                            text: 'Float'
                                        }
                                    ]
                                }
                            />
                        </>
                    : null
                    }
                    
                </Grid.Column>
                
                
                <Grid.Column width={16}>
                    {
                        dropdown?
                            myField('fieldvalues','Dropdown options', loading, 'text', 'Input comma-seperated options')
                           :
                            null
                    }
                </Grid.Column>
                <Grid.Column width={8}>
                    {dataEditing !== false?
                    <Button
                        fluid
                        color="red"
                        type="button"
                        onClick={cancelEditHandler}
                    >
                        Cancel Field Edit
                    </Button>
                    :null}
                </Grid.Column>
                <Grid.Column width={8}>
                    {dataEditing === false?
                        <Button
                        disabled={disabled}
                        fluid
                        color="blue"
                        type="submit"
                        >
                            Add Field
                        </Button>
                    :
                        <Button
                        disabled={disabled}
                        fluid
                        color="blue"
                        type="submit"
                        >
                            Confirm Edit
                        </Button>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

let newFormFieldForm = (props) => {
    return (
        <Form
            id="newFormFieldForm"
            error={props.Message !== null? props.Message.type === 'error': false}
            success={props.Message !== null? props.Message.type === 'success': false}
            onSubmit={props.handleSubmit}
            size='large'>
            {myGrid(props.loading, props.dropdown, props.disabled, props.input,
                props.initialValues !== null && props.initialValues !== undefined,props.cancelEditHandler)}
            {props.Message !== null?
                <Message
                    error={props.Message !== null? props.Message.type === 'error': false}
                    success={props.Message !== null? props.Message.type === 'success': false}
                    header = {props.Message.header}
                    content = {props.Message.content}
                />
                :
                null
            }
        </Form>
    )
}


const valueCheck = (value,name,minLength = null) => {
    if (!value) {
        return `You must enter ${name}`
    } else {
        if (minLength) {
            if (value.length < minLength) {
                return `${name} must be at least ${minLength} characters`
            }
        }
    }
}

const dropdownOptionsCheck = (string) => {
    if (string === undefined || string === null){
        return 'please provide comma-seperate options for the dropdown'
    } else {
    
        const dropdownOptions = string.split(',').filter(x=> /\S/.test(x))
        // if (dropdownOptions.filter(x=> /\S/.test(x)).length ===1){
        if (dropdownOptions.length <= 1){
            return 'please provide more than 1 option'
        }
           
    }
}



const validate = (formValues) => {
    const errors = {};
    errors.caption = valueCheck(formValues.caption,'caption',3)
    errors.datatype = valueCheck(formValues.datatype,'data type')
    errors.type = valueCheck(formValues.type,'input type')
    if (formValues.type === "dropdown"){
        errors.fieldvalues = dropdownOptionsCheck(formValues.fieldvalues)
    }
    return errors;
}

const afterSubmit = (result, dispatch, props) =>{
    dispatch(reset('newFormField'));
}

newFormFieldForm = reduxForm({
    form: 'newFormField',
    validate,
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit
})(newFormFieldForm)

const mstp = (state, ownProps) => {
    return({
        dropdown: state.form.newFormField !== undefined && state.form.newFormField !== null ? state.form.newFormField.values !== undefined &&  state.form.newFormField.values !== null? state.form.newFormField.values.type === 'dropdown': false: false,
        input: state.form.newFormField !== undefined ? state.form.newFormField.values !== undefined && state.form.newFormField.values !== null? state.form.newFormField.values.type === 'textField': false: false,
        initialValues: ownProps.initialValues
    })
}


export default connect(mstp, null)(newFormFieldForm);