import React from 'react';
import {Form, Grid, Button} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { CommonInput } from './commonInput';

const myField = (name, label, disabled, type="text") => {
    return (
        <>
            <>
                <span>{label}</span>
                <div style={{marginBottom:'3px'}}/>
            </>
            <Field
                disabled={disabled}
                fluid
                name={name}
                component={CommonInput}
                placeholder={label}
                type={type}
            />
        </>
    )
}



export default reduxForm({
        form: 'userFilter',
    })((props) => {
    return (
        <Form onSubmit={props.handleSubmit} size='large'>
            <Grid>
                    <Grid.Column width={6}>
                        {myField('id','User ID',false)}    
                    </Grid.Column>
                    {/* <Grid.Column width={4}>
                        {myField('username','User Name',false)}    
                    </Grid.Column> */}
                    <Grid.Column width={5}>
                        {myField('firstname','First Name',false)}    
                    </Grid.Column>
                    <Grid.Column width={5}>
                    {myField('lastname','Last Name',false)}    
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {myField('email','Email Address',false)}
                    </Grid.Column>
                
                    <Grid.Column width={5}>
                        {myField('cnic','CNIC',false)}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {myField('contactnum','Contact Number',false)}
                    </Grid.Column>
            </Grid>
            <Button primary type="submit">Search with filters</Button>

        </Form>
           
    )
})