import axios from 'axios';
// import { reset } from 'redux-form';
import {
    LOAD_ACTION_REPORTS,
    LOAD_ATTENDANCE_SUMMARY,
    LOAD_REGION_REPORTS
} from './types';
// import { saveAs } from 'file-saver';
import { logOut } from '../actions';
//var fileDownload = require('js-file-download');

const apiURL = require('../config.json').apiURL;

export const LoadDashboardReports = (searchParameters) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'dashboard/getUserActionSummary/search',
                params: {
                    parameters: JSON.stringify(searchParameters)
                    //     //  parameters: JSON.stringify("{datefrom:2019-10-24, dateto:2019-10-24}")
                }
            }).then(({ data }) => {
                dispatch({
                    type: LOAD_ACTION_REPORTS,
                    payload: {
                        countActions: data.countActions,
                        countCompleted: data.countCompleted
                    }
                })
                resolve('done')
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        reject('failed')
                    }
                }
                else {
                    console.log(error);
                    // dispatch(logOut())
                    // reject('failed')
                }
            });
        })
    }
}


export const LoadAttendenceSummary = (searchParameters) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'dashboard/getuseractionattendancesummary',
                params: {
                    parameters: JSON.stringify(searchParameters)
                    //     //  parameters: JSON.stringify("{datefrom:2019-10-24, dateto:2019-10-24}")
                }
            }).then(({ data }) => {
                console.log(data)
                dispatch({
                    type: LOAD_ATTENDANCE_SUMMARY,
                    payload: {
                        userSummary: data.userSummaries
                    }
                })
                resolve('done')
            }).catch((error) => {
                if (error.response.status === 403) {
                    reject('failed')
                } else {
                    // dispatch(logOut())
                }
            });
        })
    }
}

export const LoadAttendanceImage = (path) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            console.log(path)
            // const Path = new FormData();
            // Path.append('path', add)
            var w = window.open("", "_blank");
            axios({
                method: 'get',
                url: apiURL + 'file/getimagestring/' + path
            }).then((response) => {
                var image = new Image();
                image.src = "data:image/png;base64," + response.data.image
                w.document.write(image.outerHTML)
                resolve('done')
            }).catch((error) => {
                //   if (error.response.status === 403) {
                reject('failed')
                // } else {
                //     // dispatch(logOut())
                // }
            });
        })
    }
}

export const LoadRegionReports = (searchParameters) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            console.log('here');
            console.log(searchParameters);
            axios({
                method: 'get',
                url: apiURL + 'dashboard/getregionactionsummary/search',
                params: {
                    parameters: JSON.stringify(searchParameters)
                    //     //  parameters: JSON.stringify("{datefrom:2019-10-24, dateto:2019-10-24}")
                }
            }).then(({ data }) => {
                console.log(data)
                let performed = []
                let assigned = []
                let regions = []

                data.regionActions.forEach(x => {
                    performed.push(x.actionsCompleted)
                    assigned.push(x.actionsAssigned)
                    regions.push(x.region)
                })
                let Summary = {
                    performed,
                    assigned,
                    regions
                }
                // console.log(Summary)
                dispatch({
                    type: LOAD_REGION_REPORTS,
                    payload: {
                        regionWiseSummary: Summary
                    }
                })
                resolve('done')
            }).catch((error) => {
                if (error.response.status === 403) {
                     reject('failed')
                } else {
                     dispatch(logOut())
                }
            });
        })
    }
}

// export const DownloadReport = (UserId, From, To) => {
//     window.open(apiURL + 'reports/downloadreport/search?parameters={}')

/* return (dispatch) => {
     return new Promise((resolve, reject) => {
         const Path = new FormData();
         Path.append('Userid', UserId)
         Path.append('From', From)
         Path.append('To', To)
         axios({
             method: 'post',
             url: apiURL + 'reports/downloadreport',
             responseType: 'blob',
             headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
             data: Path
         }).then((response) => {
             var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

             saveAs(blob);
             dispatch();
             // var link = document.createElement('a');
             // link.href = window.URL.createObjectURL(blob);
             // link.download = 'demo.xls';

             // document.body.appendChild(link);

             // link.click();
             dispatch(reset('dates'))

             //  document.body.removeChild(link);
             //    fileDownload(response.data, 'filename.xlsx');
         })
     })
 }
 */
//}