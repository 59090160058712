import {
    LOAD_USERS,
    TOGGLE_USER_ACTIVITY,
    LOAD_ALL_USER_WITH_ID,
    USERNAME_PASSWORD_RESET
} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    total: 0,
    addLoading: false,
    deleteLoading: [],
    UserBeingEdited: null,
    userbyID: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_USERS:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total
            }
        case LOAD_ALL_USER_WITH_ID:
            return {
                ...state,
                userbyID: action.payload
            }
        case TOGGLE_USER_ACTIVITY:
            return {
                ...state,
                data: state.data.map(x => {
                    if (x.id === action.payload) {
                        x.isActive = !x.isActive
                    }
                    return x;
                })
            }
        case USERNAME_PASSWORD_RESET:
            return {
                ...state,
                credentials: action.payload
            }
        default:
            return state;
    }
}