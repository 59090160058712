import {
    LOAD_VIEWALLACTIONS,
    LOAD_ACTION_OUTCOME,
    LOAD_IMAGE,
    LOAD_IMAGE_FILE,
    LOAD_SORTING_PARAMS,
    LOAD_DROPDOWNS_ACTIONS,
    ACTION_CREATION_SUCCESS,
    ACTION_CREATION_FAIL,
    LOAD_COMMENTS

} from '../actions/types';

const INITIAL_STATE = {
    data: [],
    total: 0,
    override: false,
    addLoading: false,
    deleteLoading: [],
    outcome: null,
    isChecked: [],
    sortingParams: null,
    message: {},
    commentsList: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_VIEWALLACTIONS:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                override: action.payload.override
            }
        case LOAD_ACTION_OUTCOME:
            return {
                ...state,
                outcome: action.payload
            }
        case LOAD_IMAGE:
            return {
                ...state,
                image: action.payload
            }
        case LOAD_IMAGE_FILE:
            return {
                ...state,
                imageFile: action.payload
            }
        case LOAD_SORTING_PARAMS:
            return {
                ...state,
                sortingParams: action.payload
            }
        case LOAD_DROPDOWNS_ACTIONS:
            return {
                ...state,
                Dropdowns: action.payload
            }
        case ACTION_CREATION_SUCCESS:
            return {
                ...state,
                message: action.payload
            }
        case ACTION_CREATION_FAIL:
            return {
                ...state,
                message: action.payload
            }
        case LOAD_COMMENTS:
            return {
                ...state,
                commentsList: action.payload
            }
        default:
            return state;
    }

}