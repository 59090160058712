import {
  LOAD_CALLER_ACTIONS_ASSIGNED,
  LOAD_CALLER_ACTIONS_PERFORMED,
  LOAD_CALLER_ACTION_DETAIL,
  LOAD_CALLER_ACTION_DETAIL_RANDOM,
  LOAD_PHONE_NUMBERS
} from "../actions/types";

const INITIAL_STATE = {
  data: [],
  selectedCallee: {},
  randomCallee: {},
  total: 0,
  assignedTotal: null,
  performedTotal: null,
  phoneNumbers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CALLER_ACTIONS_ASSIGNED:
      return {
        ...state,
        data: action.payload.data,
        assignedTotal: action.payload.total,
      };

    case LOAD_CALLER_ACTIONS_PERFORMED:
      return {
        ...state,
        data: action.payload.data,
        performedTotal: action.payload.total,
      };

    case LOAD_CALLER_ACTION_DETAIL:
      return {
        ...state,
        selectedCallee: action.payload,
      };

    case LOAD_CALLER_ACTION_DETAIL_RANDOM:
      return {
        ...state,
        randomCallee: action.payload,
      };

    case LOAD_PHONE_NUMBERS:
      return {
        ...state,
        phoneNumbers: action.payload,
      };

    default:
      return state;
  }
};
