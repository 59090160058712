import axios from "axios";

import {
  LOAD_ALL_TAGS,
  LOAD_ACTIVE_TAGS,
  TOGGLE_TAG_ACTIVITY,
  LOAD_TAGS,
} from "./types";

const apiURL = require("../config.json").apiURL;

export const LoadTags = (medium, includeGeneral) => {
  if(includeGeneral === null || includeGeneral === undefined)
    includeGeneral = false;
  
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        //changed by Nikhil. No need to show general tags like Promise, Payment and Reminder
        //in settings page. Someone might accidently delete or edit etc.
        url:
          apiURL +
          `outcomeResult/getall/true?medium=${medium}&includeGeneral=${includeGeneral}`,
      })
        .then(({ data }) => {
          console.log(data);
          let { resultTags } = data;
          resultTags = resultTags.map((tag) => {
            return { ...tag, text: tag.tag, value: tag.id };
          });
          dispatch({
            type: LOAD_ALL_TAGS,
            payload: resultTags,
          });
          resolve("done");
        })
        .catch((error) => {
          reject("failed");
        });
    });
  };
};

export const LoadActiveTags = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: apiURL + `outcomeResult/getall/false`,
      })
        .then(({ data }) => {
          console.log(data);
          const activeTags = data.resultTags.map((x) => {
            return {
              value: x.id,
              text: x.tag,
            };
          });
          data.activeTags = activeTags;
          dispatch({
            type: LOAD_ACTIVE_TAGS,
            payload: data,
          });
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const AddTag = (tag, medium) => {
  const form = new FormData();
  form.append("Tag", tag);
  form.append("Medium", medium);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // dispatch({
      //     type: ADD_ROLE_LOAD_TOGGLE
      // })
      axios({
        method: "post",
        url: apiURL + "outcomeResult/post",
        data: form,
      })
        .then(({ data }) => {
          dispatch(LoadTags(medium));
          resolve("done");
        })
        .catch((error) => {
          //   dispatch({
          //     type: ADD_ROLE_LOAD_TOGGLE,
          //   });
          console.log(error);
          reject("error");
        });
    });
  };
};

export const DeleteTag = (id, medium) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      const form = new FormData();
      form.append("Id", id);
      axios({
        method: "delete",
        url: apiURL + "outcomeResult/delete",
        data: form,
      })
        .then(({ data }) => {
          dispatch(LoadTags(medium));
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const UpdateTag = (id, tag, medium) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append("Id", id);
      form.append("Tag", tag);
      form.append("Medium", medium);
      axios({
        method: "put",
        url: apiURL + "outcomeResult/put",
        data: form,
      })
        .then(({ data }) => {
          dispatch(LoadTags(medium));
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const ToggleActive = (id, isActive, medium) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      console.log(isActive);
      const form = new FormData();
      form.append("Id", id);
      form.append("isActive", isActive);
      axios({
        method: "put",
        url: apiURL + "outcomeResult/togglestatus",
        data: form,
      })
        .then(({ data }) => {
          dispatch(LoadTags(medium));
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};
