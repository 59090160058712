import React, { Component } from 'react';
import { Button, Grid, Header, Segment, Popup, Icon, Progress, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import ReactSpeedometer from "react-d3-speedometer";
import LoaderView from '../components/loaderView';
// eslint-disable-next-line no-unused-vars
import DataTable from '../components/dataTable';
import UnauthorizedView from '../components/unauthorizedView';
import DashboardFilterForm from '../components/forms/dashBoardFilterForm';
import ReactApexCharts from 'react-apexcharts';
import SelectTable from '../components/selectRowsTable';

class Dashboard extends Component {

    state = {
        activeRows: [],
        access: false,
        loaded: false,
        searchParameter: { datefrom: new Date().toLocaleDateString(), dateto: new Date().toLocaleDateString() },
        filterShow: false,
        minVal: 0,
        maxVal: 0,
        current: 0,
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 200
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                style: {
                    fontSize: '10px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['#fff']
            },
            xaxis: {
                // categories: ["asdfjh","dfa","dsf","ad","adf","ad",'asfd','dfs','adf','adf'],
            },
        },
    }


    static getDerivedStateFromProps(props, state) {
        // console.log(props)
        // console.log(state)
        if (props.countCompleted !== state.current && props.countActions !== state.maxVal && (props.countCompleted !== undefined || props.countActions !== undefined)) {

            // if (props.regionSummary !== undefined) {
            //     console.log("not undefeinfe")
            //     console.log(props.regionSummary)
            // }
            return {
                ...state,
                //maxVal: props.countActions,
                //current: props.countCompleted
            }
        }


        return null;
    }


    componentDidMount() {
        this.props.LoadAttendenceSummary(this.state.searchParameter)
            .then(() => {
                this.setState({
                    current: this.props.userSummary.reduce((a, b) => a + (b["actionsPerformed"] || 0), 0),
                    maxVal: this.props.userSummary.reduce((a, b) => a + (b["actionsAssigned"] || 0), 0),
                    loaded: true,
                    access: true
                });
            })
            .catch(() => { })
        /*this.props.LoadDashboardReports(this.state.searchParameter)
            .then(x => this.setState({
                loaded: true,
                access: true,
                current: this.props.countCompleted,
                maxVal: this.props.countActions
            }))
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))*/
        this.props.LoadRegionReports(this.state.searchParameter)
            .then(x => {
                // console.log("RegionReport")
                let options = this.state.options
                options.xaxis.categories = this.props.regionSummary.regions
                // console.log(options.xaxis.categories)
                this.setState({
                    loaded: true,
                    access: true,
                    //current: this.props.countCompleted,
                    //maxVal: this.props.countActions,
                    series: [
                        {
                            name: 'Assigned',
                            data: this.props.regionSummary.assigned
                        },
                        {
                            name: 'Perfomed',
                            data: this.props.regionSummary.performed
                        }
                    ],
                    options: options
                })
            })
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))
        this.props.DropdownFilter('username')
            .then(x => {
                // console.log('Got it')
            })
            .catch(x => {

            })
    }

    getDropDownFields = () => {
        // console.log(this.props.dropdowns)
        if (this.props.dropdowns !== undefined && this.props.dropdowns !== null) {
            // console.log(this.props.dropdowns.field)
            this.setState({
                [this.props.dropdowns.field]: this.props.dropdowns.data
            })
        }
    }

    /*handleSubmit = () => {
        this.props.LoadDashboardReports(this.state.searchParameter)
            .then(x => {
                this.setState({
                    loaded: true,
                    access: true,
                    current: this.props.countCompleted,
                    maxVal: this.props.countActions
                })
            })
            .catch(x => this.setState({
                loaded: true,
                access: false
            }));
    }

    LoadData = () => {
        this.props.LoadDashboardReports(this.state.searchParameter)
            .then(x => this.setState({
                loaded: true,
                access: true,
                current: this.props.countCompleted,
                maxVal: this.props.countActions
            }))
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))
        this.props.LoadRegionReports(this.state.searchParameter)
            .then(x => this.setState({
                loaded: true,
                access: true,
                current: this.props.countCompleted,
                maxVal: this.props.countActions
            }))
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))
    }*/


    actionButton = (value) => {
        return (
            <>
                <Popup
                    content={`Duty on Image.`}
                    position='top right'
                    // inverted
                    // on='hover'
                    trigger={
                        <Button
                            key={value.id}
                            size='mini'
                            color='green'
                            icon='image'
                            // loading={this.state.supportingImageLoading}
                            disabled={value.dutyOnImageLink === null}
                            onClick={() => {
                                // this.setState({ supportingImageLoading: false })
                                this.props.LoadAttendanceImage(value.dutyOnImageLink)
                                    .then(() => {
                                    })
                                    .catch(() => console.error('Error'))
                            }}
                        />
                    }
                />
                <Popup
                    content={`Duty Off Image.`}
                    position='top right'
                    // inverted
                    // on='hover'
                    trigger={
                        <Button
                            key={value.id}
                            size='mini'
                            color='blue'
                            icon='image'
                            // loading={this.state.supportingImageLoading}
                            disabled={value.dutyOffImageLink === null}
                            onClick={() => {
                                // this.setState({ supportingImageLoading: false })
                                this.props.LoadAttendanceImage(value.dutyOffImageLink)
                                    .then(() => {
                                    })
                                    .catch(() => console.error('Error'))
                            }}
                        />
                    }
                />
            </>
        )
    }


    clickRow = (e) => {
        console.log(e)
        let array = this.state.activeRows
        if (array.includes(e)) {
            array.splice(array.indexOf(e), 1);
        } else {
            array.push(e)
        }

        let searchParam = this.state.searchParameter
        if (array.length === 0) {
            delete searchParam.username
        } else {
            searchParam.username = array;
        }

        this.setState({ activeRows: array, searchParameter: searchParam });
        // console.log(searchParam)
        this.LoadApiCalls(searchParam)
    }

    activeRow = (e) => {
        return this.state.activeRows.includes(e)
    }


    renderSummaryTable = () => {
        let data = this.props.userSummary !== null && this.props.userSummary !== undefined ? this.props.userSummary : []

        if (data.length !== 0) {
            data.map(obj => {
                if ((obj.name.charAt(0) >= '0' && obj.name.charAt(0) <= '9') === false)
                    obj.name = obj.userId + ". " + obj.name;
                let progress = {}
                progress['actionsAssigned'] = obj['actionsAssigned']
                progress['actionsPerformed'] = obj['actionsPerformed']
                obj.progress = progress;
                if (obj.dutyOnTime !== null && obj.dutyOnTime !== undefined) {
                    let str = obj.dutyOnTime;
                    str = str.substr(11);
                    obj.onTime = str;
                }
                if (obj.dutyOffTime !== null && obj.dutyOffTime !== undefined) {
                    let str = obj.dutyOffTime;
                    str = str.substr(11);
                    obj.offTime = str;
                }
            })
        }

        console.log(this.state.current + "/" + this.state.maxVal);
        return (
            // <div style={{ height: '500px' }}>

            <SelectTable
                header={[
                    {
                        name: 'User',
                        accessor: 'name',
                        collapsing: true
                    },
                    {
                        name: 'Progress',
                        accessor: 'progress',
                        component: (x, parentKey) => {
                            let ratio = x.actionsPerformed === 0 && x.actionsAssigned === 0 ? 0 : (x.actionsPerformed / x.actionsAssigned)
                            return (
                                <Progress
                                    key={parentKey}
                                    style={{ marginBottom: "2px" }}
                                    size='medium'
                                    value={x.actionsPerformed}
                                    total={x.actionsAssigned}
                                    progress='ratio'
                                    color={
                                        ratio === 0 ? 'grey' : ratio <= 0.25 ? 'red' : ratio <= 0.5 ? 'yellow' : 'green'
                                    }
                                // autoSuccess
                                />
                            )
                        }
                    },
                    {
                        name: 'Duty On',
                        accessor: 'onTime',
                        collapsing: true
                    },
                    {
                        name: 'Duty Off',
                        accessor: 'offTime',
                        collapsing: true
                    }
                ]}
                actions={(x) => this.actionButton(x)}
                clickRow={this.clickRow}
                activeRow={this.activeRow}
                data={data}
                show={this.state.access && this.state.loaded}
                trigger={this.state.triggerUpdate}
                compact
                tableSize="small"
                noPagination
                overflowX
                collapsing
            />
        )
    }

    renderSpeedoMeter = () => {
        let ht = window.innerHeight * 0.8
        return (
            <Grid>
                <Grid.Row centered columns={2} >
                    <Grid.Column width={7} >
                        {/* <div style={{ height: '25rem' }}> */}
                        <Segment style={{ height: ht, overflowX: 'auto' }}>
                            {this.renderSummaryTable()}
                        </Segment>
                        {/* </div> */}
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Segment>
                            <div style={{ height: '25rem' }}>
                                <ReactSpeedometer
                                    forceRender={true}
                                    fluidWidth={true}
                                    maxValue={this.state.maxVal}
                                    value={this.state.current}
                                    needleColor="red"
                                    startColor="grey"
                                    endColor="black"
                                    // eslint-disable-next-line no-template-curly-in-string
                                    currentValueText='Completed: ${value}'
                                />
                            </div>
                        </Segment>

                        <Segment>
                            {this.state.options.xaxis.categories !== undefined ? typeof this.state.options.xaxis.categories[0] === "string" ? <ReactApexCharts options={this.state.options} series={this.state.series} type="bar" height={300} /> : null : null}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    renderFilterBox = () => {
        return (
            <>
                <Header as='h3' attached='top'>
                    Filters
                    <Button
                        primary
                        floated="right"
                        size="mini"
                        onClick={() => {
                            this.setState({
                                filterShow: !this.state.filterShow,
                            })
                        }}
                    >
                        {this.state.filterShow ? 'Hide' : 'Show'}
                    </Button>
                </Header>
                {this.state.filterShow ?
                    <Segment attached>
                        <DashboardFilterForm
                            // users={this.props.users.data}
                            initialValues={this.state.searchParameter}
                            onSubmit={(x) => {
                                console.log(x)
                                if (x.datefrom === null) {
                                    x.datefrom = ""
                                }
                                if (x.dateto === null) {
                                    x.dateto = ""
                                }
                                if (x.medium === null) {
                                    delete x.medium
                                }

                                if (x.medium === "") {
                                    delete x.medium
                                }
                                if (typeof x.datefrom === 'object') {
                                    if (x.datefrom !== null) {
                                        x.datefrom = x.datefrom.toLocaleDateString()
                                    }
                                }
                                if (typeof x.dateto === 'object') {
                                    if (x.completedontp !== null) {
                                        x.dateto = x.dateto.toLocaleDateString();
                                    }
                                }
                                if (x.username !== undefined && x.username.length === 0) {
                                    delete x.username
                                }
                                console.log(x)
                                this.setState({ searchParameter: x })
                                this.LoadApiCalls(x)
                            }}
                        />
                    </Segment>
                    : null
                }
            </>
        )
    }

    LoadApiCalls(x) {
        this.props.LoadAttendenceSummary(this.state.searchParameter)
            .then(z => {
                this.setState({
                    current: this.props.userSummary.reduce((a, b) => a + (b["actionsPerformed"] || 0), 0),
                    maxVal: this.props.userSummary.reduce((a, b) => a + (b["actionsAssigned"] || 0), 0),
                    loaded: true,
                    access: true
                });
            })
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))
        /*this.props.LoadDashboardReports(x)
            .then(z => {
                this.setState({
                    access: true,
                    loaded: true,
                    maxVal: this.props.countActions,
                    current: this.props.countCompleted
                })
            })
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))*/
        this.props.LoadRegionReports(x)
            .then(x => {
                console.log("RegionReport")
                let options = this.state.options
                options.xaxis.categories = this.props.regionSummary.regions
                // console.log(options.xaxis.categories)
                this.setState({
                    loaded: true,
                    access: true,
                    //current: this.props.countCompleted,
                    //maxVal: this.props.countActions,
                    series: [
                        {
                            name: 'Assigned',
                            // data: [2, 2, 3, 5, 3, 2, 2, 1, 3, 3, 4]
                            data: this.props.regionSummary.assigned
                        },
                        {
                            name: 'Perfomed',
                            data: this.props.regionSummary.performed
                            // data: [2, 2, 3, 5, 3, 2, 2, 1, 3, 3, 4]

                        }
                    ],
                    options: options
                })
            })
            .catch(x => this.setState({
                loaded: true,
                access: false
            }))
    }


    renderTextShow = () => {
        //  console.log(this.state.searchParameter)
        var textShow = ""
        if (this.state.searchParameter.datefrom !== undefined && this.state.searchParameter.datefrom !== null && this.state.searchParameter.dateto !== undefined && this.state.searchParameter.dateto !== null) {
            if (this.state.searchParameter.datefrom === "" && this.state.searchParameter.dateto === "" && this.state.searchParameter.medium
                === "") {
                textShow = 'Overall Stats'
            } else if (this.state.searchParameter.datefrom === this.state.searchParameter.dateto && this.state.searchParameter.datefrom === (new Date()).toLocaleDateString()) {
                textShow = `Stats of Today`
            } else if (this.state.searchParameter.datefrom === this.state.searchParameter.dateto && this.state.searchParameter.datefrom !== "") {
                textShow = `Statistics of ${this.state.searchParameter.datefrom}`
            } else if (this.state.searchParameter.datefrom !== this.state.searchParameter.dateto && this.state.searchParameter.medium === "") {
                textShow = `Stats from \n${this.state.searchParameter.datefrom} to ${this.state.searchParameter.dateto}`
            } else if (this.state.searchParameter.medium !== "" && this.state.searchParameter.datefrom !== "" && this.state.searchParameter.dateto !== "") {
                textShow = `Stats of ${this.state.searchParameter.medium} From ${this.state.searchParameter.datefrom} To ${this.state.searchParameter.dateto}`
            } else if (this.state.searchParameter.medium !== "" && this.state.searchParameter.datefrom === "" && this.state.searchParameter.dateto === "") {
                textShow = `Stats of ${this.state.searchParameter.medium}`
            } else if (this.state.searchParameter.medium !== "" && this.state.searchParameter.datefrom !== "" && this.state.searchParameter.dateto === "") {
                textShow = `Stats of ${this.state.searchParameter.medium} from ${this.state.searchParameter.datefrom}`
            } else if (this.state.searchParameter.medium !== "" && this.state.searchParameter.datefrom === "" && this.state.searchParameter.dateto !== "") {
                textShow = `Stats of ${this.state.searchParameter.medium} to ${this.state.searchParameter.dateto}`
            }
        }
        return (
            <>
                <h2>{textShow}</h2>
            </>
        )
    }

    renderDashboard() {
        return (
            <div style={styles.mainDiv}>
                {this.state.access && this.state.loaded ? this.renderFilterBox() : null}
                <div style={{ marginBottom: '15px' }} />
                {this.state.access && this.state.loaded ? this.renderSpeedoMeter() : null}
            </div>
        )
    }

    render() {
        // console.log(this.state)
        return (
            <>
                {!this.state.loaded ? <LoaderView text="Dashboard is loading" /> :
                    this.state.access ? null : <UnauthorizedView pageName="Dashboard" />}
                {this.state.access && this.state.loaded ? this.renderDashboard() : null}
            </>
        )
    }
}


const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

const mstp = (state) => {
    // console.log(state)
    return ({
        countActions: state.dashboard.countActions,
        countCompleted: state.dashboard.countCompleted,
        users: state.actions.Dropdowns,
        userSummary: state.dashboard.userSummary,
        regionSummary: state.dashboard.regionWiseSummary
    })
}

export default connect(mstp, actions)(Dashboard);