import React from 'react';
import {Segment, Icon} from 'semantic-ui-react'

export default (props) => {
    return(
        <div
            style={
                {
                    height: '80%',
                    marginLeft:'10px',
                    marginRight:'10px',
                }
            }
        >
            <Segment
                style={
                    {
                        height: '100%',
                        border: 'dashed grey 3px',
                        textAlign: 'center',
                        position: 'relative'
                    }
                }
            >
                <span style={{ color: 'grey', fontSize: '2rem', position: 'absolute', top: '50%', left: '50%', height: '40%', width: '50%', margin: '-15% 0 0 -25%', verticalAlign: 'middle' }}>
                    <div style={{ marginBottom: '8rem' }} />
                    <Icon name="ban" size="huge" />
                    <br />
                    <div style={{ marginBottom: '3rem' }} />
                    You are <b>NOT</b> authorised to access the <i>{props.pageName}</i>
                </span>
            </Segment>
        </div>
    )
}