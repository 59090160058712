import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'
import { Field, reduxForm } from 'redux-form'
import { Form, Message, Modal, Header, Icon, Input } from 'semantic-ui-react'
import { CommonSelect } from './commonInput';
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'
import { reset } from 'redux-form';

momentLocaliser(moment)

const renderDateTimePicker = ({ input: { onChange, value }, showTime, label }) =>
    <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY"
        time={showTime}
        value={!value ? null : new Date(value)}
        placeholder={label}
    />

const acti = [
    { value: 'All', text: 'All' },
    { value: 'Action Performed', text: 'Action Performed' }
]

const order = [
    { value: true, text: 'Ascending' },
    { value: false, text: 'Descending' }
]

const medium = [
    { value: 'All', text: 'All' },
    { value: 'Visits', text: 'Visits' },
    { value: 'Calls', text: 'Calls' },
    { value: 'SMS', text: 'SMS' }
]

let renderSelectionField = (name, options, component, placeholder, optional, disable = false) => {
    return (
        <>
            <span>
                {optional ? placeholder + '    (OPTIONAL)' : placeholder}
            </span>
            <div style={{ marginBottom: '3px' }} />
            <Field
                disabled={disable}
                fluid
                name={name}
                options={options}
                component={component}
                placeholder={placeholder}
                clearable
            />
        </>
    )
}


let DateInputForm = (props) => {
    // console.log(props)
    return (
        <div>
            <Modal
                size="tiny"
                open={props.open}
                onClose={props.onCloseHandle}
            >
                <Header
                    icon="file"
                    content={props.header}
                />
                <Modal.Content>
                    <Form
                        id="dateInputForm"
                        onSubmit={props.handleSubmit}
                        size="large"
                    >
                        {props.segregation ?
                            <Field
                                name="segregation_val"
                                showTime={false}
                                component={CommonSelect}
                                disabled={props.loading}
                                options={acti}
                                fluid
                                label="Segregation"
                            />
                            : null}

                        {props.myForm !== null || props.myForm !== undefined ?
                            <Field
                                name="formId"
                                showTime={false}
                                component={CommonSelect}
                                disabled={props.loading}
                                options={props.myForms}
                                fluid
                                label="Forms"
                            />

                            : null}
                        {/* <Field
                            name='Segregation'
                            // defaultValue={'Action Performed'}
                            options={acti}
                            compponent={CommonSelect}
                            placeholder={'All'}
                        /> */}
                        <Field
                            name="order"
                            showTime={false}
                            component={CommonSelect}
                            disabled={props.loading}
                            options={order}
                            fluid
                            label="Order by date"
                        />
                        <Field
                            name="medium"
                            showTime={false}
                            component={CommonSelect}
                            disabled={props.loading}
                            options={medium}
                            fluid
                            label="Medium"
                        />
                        <Field
                            name="From"
                            showTime={false}
                            component={renderDateTimePicker}
                            disabled={props.loading}
                            label="From"
                        />
                        <div style={{ marginBottom: '3px' }} />
                        <Field
                            name="To"
                            showTime={false}
                            component={renderDateTimePicker}
                            disabled={props.loading}
                            label="To"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {props.actionsComponent}
                </Modal.Actions>
            </Modal>
        </div>
    )
}

DateInputForm = reduxForm({
    form: 'dates',
    enableReinitialize: true
})(DateInputForm)

export default DateInputForm;