import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

export default (props) => {
    return (
        <div
            style={
                {
                    height: '80%',
                    marginLeft: '10px',
                    marginRight: '10px',
                }
            }
        >
            <Segment
                style={{ height: '100%' }}
            >
                <Dimmer active>
                    <Loader size="massive" inverted>{props.text}</Loader>
                </Dimmer>
            </Segment>
        </div>
    )
}