import React from 'react';
import { Form, Grid, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { CommonInput } from './commonInput';
import { normalizeDate } from '../forms/normalizers'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'

import moment from 'moment'
import momentLocaliser from 'react-widgets-moment'

momentLocaliser(moment)



const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
    <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY"
        placeholder="Date"
        time={showTime}
        value={!value ? null : new Date(value)}
    />

const myField = (name, label, disabled, type = "text") => {
    return (
        <>
            <>
                <span>{label}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
            <Field
                disabled={disabled}
                fluid
                name={name}
                component={CommonInput}
                placeholder={label}
                type={type}
            />
        </>
    )
}

const myDatePicker = (name, label) => {
    return (
        <>
            <>
                <span>{label}</span>
                <div style={{ marginBottom: '3px' }} />
            </>
            <>
                <Field
                    fluid
                    name={name}
                    component={CommonInput}
                    placeholder="MM-DD-YYYY"
                    type="text"
                    normalize={normalizeDate}
                />
            </>
        </>
    )
}

export default reduxForm({
    form: 'logFilter',
})((props) => {
    return (
        <Form onSubmit={props.handleSubmit} size='large'>
            <Grid>
                <Grid.Column width={3}>
                    {myField('event', 'Event', false)}
                </Grid.Column>
                <Grid.Column width={4}>
                    {myField('tablename', 'Table Name', false)}
                </Grid.Column>
                <Grid.Column width={3}>
                    {myField('userId', 'User ID', false)}
                </Grid.Column>
                <Grid.Column width={3}>
                    {/* {myDatePicker('from', 'From')} */}
                    <>
                        <span>{'From'}</span>
                        <div style={{ marginBottom: '3px' }} />
                    </>
                    <Field
                        name="from"
                        showTime={false}
                        component={renderDateTimePicker}
                        disabled={props.loading}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    {/* {myDatePicker('to', 'To')} */}
                    <>
                        <span>{'To'}</span>
                        <div style={{ marginBottom: '3px' }} />
                    </>
                    <Field
                        name="to"
                        showTime={false}
                        component={renderDateTimePicker}
                        disabled={props.loading}
                    />
                </Grid.Column>
            </Grid>
            <Button primary type="submit">Search with filters</Button>

        </Form>

    )
})