import React from 'react';

import { Input, Dropdown, Checkbox, Form } from 'semantic-ui-react'

const renderError = (error) => {
    if (error !== null) {
        return (
            <div style={{ textAlign: "left", marginTop: '3px', color: '#FE4242' }}>
                {error}
            </div>
        )
    }
}

const renderWarning = (warning) => {
    if (warning !== null) {
        return (
            <div style={{ textAlign: "left", marginTop: '3px', color: '#b3b300 ' }}>
                {warning}
            </div>
        )
    }
}

export const CommonInput = (props) => {
    return (
        <div style={{ marginBottom: props.marginBottom || '15px' }}>
            <Input style={{ ...props.styles }}
                onBlur={props.onBlur}
                size={props.size}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
                disabled={props.disabled}
                error={props.errorMessage !== undefined && props.errorMessage !== null ? true : false}
                fluid={props.fluid ? true : false}
                icon={props.icon || null}
                iconPosition={props.iconLeft ? 'left' : null}
                placeholder={props.placeholder || ''}
                type={props.type || 'text'}
                autoComplete={props.autoComplete ? 'on' : 'off'}
                {...props.input}
            />
            {props.errorMessage !== null ? renderError(props.errorMessage) : null}
            {props.meta !== undefined ? renderError(props.meta.error && props.meta.touched ? props.meta.error : null) : null}
        </div>
    )
}

export const CommonCheckBox = (props) => {
    // const inputProps = {...props.input};
    delete props.input['value']
    return (
        <div style={{ marginBottom: props.marginBottom || '15px' }}>
            <Checkbox
                // style={{...props.styles}}
                {...props}
                {...props.input}
            />
        </div>
    )
}

export const CommonSearchableDropdown = (props) => {
    return (
        <div style={{ marginBottom: props.marginBottom || '15px' }}>
            <Form.Select
                search={props.searchable}
                name={props.name}
                placeholder={props.placeholder}
                {...props.input}
                placeholder={props.placeholder}
                onChange={(e, { value }) => props.displayOnly ? () => { } : props.input.onChange(value)}
                disabled={props.disabled}
                error={props.errorMessage !== undefined ? true : false}
                fluid={props.fluid ? true : false}
                options={props.options}
                clearable={props.clearable}
            />
            {props.errorMessage !== null ? renderError(props.errorMessage) : null}
            {props.meta !== undefined ? renderError(props.meta.error && props.meta.touched ? props.meta.error : null) : null}
        </div>
    )
}

export const CommonSelect = (props) => {
    return (
        <div style={{ marginBottom: props.marginBottom || '15px' }}>
            <Dropdown style={{ ...props.styles }}
                search={props.searchable}
                selection
                name={props.name}
                placeholder={props.placeholder}
                {...props.input}
                onChange={(e, { value }) => props.displayOnly ? () => { } : props.input.onChange(value)}
                disabled={props.disabled}
                error={props.errorMessage !== undefined ? true : false}
                fluid={props.fluid ? true : false}
                options={props.options}
                clearable={props.clearable}
            // warning={props.warningMessage !== undefined ? true : false}
            />
            {props.errorMessage !== null ? renderError(props.errorMessage) : null}
            {props.meta !== undefined ? renderError(props.meta.error && props.meta.touched ? props.meta.error : null) : null}
            {/* {props.meta !== undefined ? renderWarning(props.meta.warning && props.meta.touched ? props.meta.warning : null) : null} */}
        </div>
    )
}

export const CommonMultiSelect = (props) => {
    return (
        <div style={{ marginBottom: props.marginBottom || '15px' }}>
            <Dropdown style={{ ...props.styles }}
                options={props.options}
                selection={props.selection}
                multiple
                search
                value={props.input.value || []}
                onChange={(e, { value }) => {
                    props.input.onChange(value)
                }}
                disabled={props.disabled}
                error={props.errorMessage !== undefined ? true : false}
                fluid={props.fluid !== undefined? "true" : "false"}
                placeholder={props.placeholder}
            />
            {props.errorMessage !== null ? renderError(props.errorMessage) : null}
            {props.meta !== undefined ? renderError(props.meta.error && props.meta.touched ? props.meta.error : null) : null}
        </div>
    )
}