import React, { Component } from 'react';
import { Segment, Grid, Header, Label, Icon, Button, Input, Popup, GridRow } from 'semantic-ui-react';
import Calender from '../components/calender';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Datatable from '../components/dataTable';


class Attendance extends Component {

    state = {
        loadingDuty: false,
        reason: '',
        reasonLoading: false,
        reasonError: null,
        dataLoading: false,
        dateData: [],
        tableHeight: 0,
        userAttendances: null,
        supportingImageLoading: false
    }


    componentWillUnmount() {
        this.setState({ reasonError: null })
    }

    componentWillMount() {
        this.setState({ loadingDuty: true })
        this.props.DutyCheck()
            .then((x) => this.setState({ loadingDuty: false }))
            .catch((x) => this.setState({ loadingDuty: false }))
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({ tableHeight: document.getElementById('attendanceTableUserView-x420').clientHeight })
        }, 100)
    }


    renderAttendanceMarkerBox = () => {
        var today = new Date();
        return (
            <Segment>
                {this.props.userid === undefined ?
                    <Grid>
                        <Grid.Column width={8}>
                            <Header size='huge'>
                                My Attendances
                        </Header>

                            <Label>Today</Label> {today.toLocaleDateString('en-PK', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Segment floated="right">
                                <span style={{ marginRight: '10px' }}>
                                    {this.props.duty ? 'End' : 'Start'} Duty for today
                            </span>
                                <Button
                                    disabled={this.state.loadingDuty}
                                    loading={this.state.loadingDuty}
                                    size="small" icon
                                    color={!this.state.loadingDuty ? this.props.duty ? 'green' : 'red' : null}
                                    onClick={() => {
                                        this.setState({ loadingDuty: true })
                                        this.props.AddAttendance(!this.props.duty)
                                            .catch((x) => this.setState({ loadingDuty: false }))
                                            .then((x) => this.setState({ loadingDuty: false }))
                                    }}
                                >
                                    <Icon
                                        name={this.props.duty ? 'circle outline' : 'circle'}
                                        size="large"
                                    />
                                </Button>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                    :
                    <Header size='huge'>
                        Attendances Info
                    </Header>
                }
                {this.props.needForReason !== null || this.props.userid === null ?
                    <>
                        <Header attached="top">
                            {this.props.needForReason.message === "late" ?
                                "You are late on duty today, do you want to provide a reason?"
                                : null
                            }
                            {this.props.needForReason.message === "early" ?
                                "You are leaving early from duty today, do you want to provide a reason?"
                                : null
                            }
                            <Button floated="right" size="mini"
                                color="red"
                                onClick={() => this.props.DismissReason()}
                            >
                                No
                        </Button>
                        </Header>
                        <Segment attached>
                            <Input
                                disabled={this.state.reasonLoading}
                                placeholder='Provide reason here'
                                fluid
                                error={this.state.reasonError !== null}
                                value={this.state.reason}
                                onChange={(e) => this.setState({ reason: e.target.value })}
                            >
                                <input />
                                <Button
                                    onClick={() => this.reasonHandler()}
                                    disabled={this.state.reasonLoading || this.state.reason.length === 0}
                                    loading={this.state.reasonLoading}
                                    primary
                                >
                                    Submit Reason</Button>
                            </Input>
                            <span style={{ color: 'red' }}>
                                {this.state.reasonError}
                            </span>
                        </Segment>
                    </>
                    : null
                }
            </Segment>
        )
    }

    reasonHandler = () => {
        this.setState({ reasonLoading: true, reasonError: null })
        this.props.SubmitReason(this.props.needForReason.attendanceId, this.state.reason)
            .then((x) => this.setState({ reasonLoading: false }))
            .catch((x) => this.setState({ reasonLoading: false, reasonError: 'There was an issue submitting the reason, please try again.' }))
    }

    LoadUserAttendances = (lastdate, month, year) => {
        this.setState({ dataLoading: true })
        const start = `${year}-${month + 1}-1`;
        const end = `${year}-${month + 1}-${lastdate}`
        if (this.props.userid === undefined) {
            this.props.LoadSelfAttendances(start, end)
                .then(() => this.setState({ dataLoading: false }))
                .catch(() => this.setState({ dataLoading: false }))
        } else {
            this.props.LoadUserAttendances(this.props.userid, start, end)
                .then(() => this.setState({ dataLoading: false }))
                .catch(() => this.setState({ dataLoading: false }))
        }
    }

    makeCalenderStyles = () => {
        const calenderStyles = {}


        const labelFinder = this.props.attendance.map(x => {
            let date = new Date(x.timestamp)
            // let parts = date.toLocaleDateString().split('/')
            // console.log(parts)
            // console.log(x)
            // console.log(date)
            // date = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`)
            // console.log(date)
            let label = null;
            if (x.isUnPunctual === true && x.onDuty === true) {
                label = 'LS'
            }
            if (x.isUnPunctual === true && x.onDuty === false) {
                label = 'EL'
            }
            return {
                date: date.getDate(),
                label: label
            };
        })
        const labels = [];
        labelFinder.map(x => {
            if (x.label !== null) {
                labels[x.date] = [...labels[x.date] !== undefined ? labels[x.date] : [], x.label]
            }
        })

        labels.map((x, index) => {
            labels[index] = [...new Set(labels[index])];
        })



        this.props.attendance.map(x => {
            let date = new Date(x.timestamp)
            if (labels[date.getDate()] === undefined || labels[date.getDate()].length === 0) {
                calenderStyles[date.getDate()] = {
                    styles: { backgroundColor: '#09B35A', color: 'white' },
                    label: ['P']
                }
            } else {
                calenderStyles[date.getDate()] = {
                    styles: { backgroundColor: '#B31200', border: 'solid white 1px', color: 'white' },
                    label: labels[date.getDate()]
                }
            }
            return null;
        })

        return calenderStyles;
    }

    calenderDateClickHandler = (date) => {
        const v = this.props.attendance.filter(x => {
            const calDate = new Date(x.timestamp);
            if (calDate.getDate() === date) {
                return x
            }
        })
        const dataForTable = v.map(row => {
            const newTime = new Date(row.timestamp)
            console.log(row)
            newTime.toLocaleTimeString()
            console.log(newTime)
            //newTime.setMinutes(newTime.getMinutes()+300);
            let remark = '';
            if (row.isUnPunctual === true && row.onDuty === true) {
                remark = 'Late Start'
            }
            if (row.isUnPunctual === true && row.onDuty === false) {
                remark = 'Early Leave'
            }
            return ({
                ...row,
                timestamp: newTime.toLocaleTimeString(),
                for: row.onDuty === true ? 'Start' : 'Leave',
                remark: remark,
                reason: row.attendanceReason === null ? '' : row.attendanceReason.reason,
            })
        })
        this.setState({ dateData: dataForTable })

    }

    renderAttendanceCalender = () => {
        return (
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div style={{ flex: 2 }}>
                    <Calender
                        loading={this.state.dataLoading}
                        onMonthChange={(lastdate, month, year) => {
                            this.LoadUserAttendances(lastdate, month, year);
                            this.setState({ dateData: [] })
                        }}
                        DayStyles={this.makeCalenderStyles()}
                        onClick={(x) => this.calenderDateClickHandler(x)}
                    />
                </div>
                <div id='attendanceTableUserView-x420' style={{ flex: 2 }}>
                    <Segment style={{ height: this.state.tableHeight, overflowY: 'auto', marginLeft: '15px' }}>
                        <Datatable
                            header={[
                                { name: 'Time', accessor: 'timestamp' },
                                { name: 'For', accessor: 'for' },
                                { name: 'Remarks', accessor: 'remark' },
                                { name: 'Reason for remark', accessor: 'reason' },
                            ]}
                            actions={(x) => this.renderAction(x)}
                            noDataInfo='No date selected'
                            data={this.state.dateData}
                            noPagination
                        />
                    </Segment>
                </div>
            </div>


        )
    }

    renderAction = (x) => {
        let address = {}
        address.latitude = x.latitude
        address.longitude = x.longitude
        let images = x.attendanceImages ? x.attendanceImages : []
        console.log(x)
        return (
            <>
                <Button
                    data-tooltip="Open Location in Google Maps"
                    data-position="top right"
                    circular
                    color="blue"
                    size="tiny"
                    disabled={false}
                    icon="paper plane"
                    onClick={(x) => this.renderLocation(address)}
                />
                {images.map(x => {
                    return (
                        <Button
                            key={`image_${x.id}`}
                            size='mini'
                            color='green'
                            icon='image'
                            loading={this.state.supportingImageLoading}
                            //  disabled={value.completedOn === null}
                            onClick={() => {
                                this.setState({ supportingImageLoading: true })
                                this.props.LoadImage(x.path)
                                    .then(() => {
                                        this.setState({ supportingImageLoading: false })
                                    })
                                    .catch(() => console.error('Error'))
                            }}
                        />)
                })}
            </>
        )
    }

    renderLocation = (address) => {
        // console.log(address)
        var link = `http://maps.google.com?q=${address.latitude},${address.longitude}`
        window.open(link);
    }

    render() {
        return (
            <div style={styles.mainDiv}>
                {this.renderAttendanceMarkerBox()}
                {this.renderAttendanceCalender()}
            </div>
        )
    }

}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

Attendance.defaultProps = {
    attendance: []
}

const mstp = (state, ownProps) => {
    return ({
        attendance: ownProps.userid === undefined ? state.attendance.selfAttendance : state.attendance.userAttendance,
        duty: state.attendance.dutyStatus,
        needForReason: state.attendance.needForReason
    })
}

export default connect(mstp, actions)(Attendance);