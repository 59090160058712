export const noSpace = value => {
  if (!value) {
    return value
  }
  return value.replace(/\s/, '');
}

export const normalizeCNIC  = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}-${onlyNums.slice(12, 13)}`;
};

export const normalizeContact  = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 12)}`;
};

export const normalizeDate = (value, prevValue) => {
  if (!value) return value;

  const valueOnlyNumbers = value.replace(/[^\d]/g, '');
  const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

  // Enable backspacing:
  // if the user is backspacing and they backspace a forward slash, the date's
  // numbers won't be affected so just return the value.
  if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

  const month = valueOnlyNumbers.slice(0, 2);
  const day = valueOnlyNumbers.slice(2, 4);
  const year = valueOnlyNumbers.slice(4, 8);

  if (valueOnlyNumbers.length < 2) return `${month}`;
  if (valueOnlyNumbers.length == 2) return `${month}-`;
  if (valueOnlyNumbers.length < 4) return `${month}-${day}`;
  if (valueOnlyNumbers.length == 4) return `${month}-${day}-`;
  if (valueOnlyNumbers.length > 4) return `${month}-${day}-${year}`;
}

