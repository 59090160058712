import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';

import authReducer from './authReducer';
import navReducer from './navReducer';
import permissionReducer from './permissionReducer';
import roleReducer from './roleReducer';
import profileReducer from './profileReducer';
import companyReducer from './companyReducer';
import userReducer from './userReducer';
import myFormsReducer from './formReducer';
import attendanceReducer from './attendanceReducer';
import logReducer from './logReducer';
import customerReducer from './customerReducer';
import viewAllActionsReducer from './viewAllActionReducer';
import dashboardReports from './reportActionReducer';
import regionReducer from './regionReducer';
import tagReducers from './tagReducer';
import teleCallerReducers from './teleCallerReducer';

export default combineReducers({
    form: formReducer,
    auth: authReducer,
    nav: navReducer,
    permission: permissionReducer,
    role: roleReducer,
    profile: profileReducer,
    company: companyReducer,
    user: userReducer,
    myForms: myFormsReducer,
    attendance: attendanceReducer,
    log: logReducer,
    customer: customerReducer,
    actions: viewAllActionsReducer,
    dashboard: dashboardReports,
    region: regionReducer,
    tags: tagReducers,
    teleCaller: teleCallerReducers
})