import React, { Component } from 'react';
import {
    Button, Grid, Icon, Label, Popup, Header, Segment,
    Checkbox, Modal, Message, Input, Dropdown
} from 'semantic-ui-react';
import DataTable from '../components/dataTable';
import SelectTable from '../components/selectableTable';
import DataTableCustomer from '../components/dataTableCutomers';

import { connect } from 'react-redux';
import { reset } from 'redux-form';
import DateInputForm from '../components/forms/datesInputForm';
// import { CommonInput } from '../components/forms/commonInput';
import * as actions from '../actions';
//import UserForm from '../components/forms/userForm';
import ConfirmBox from '../components/confirmBox';
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';
//import CustomerFilterForm from '../components/forms/searchFilterCustomer';
import CustomerFilterForm from '../components/forms/searchFilterCustomer1';
import UploadFilesModal from '../components/forms/uploadFilesModal';
//import CustomerFilterForm from '../components/forms/customerFilterForm';
import ActionFormVisit from '../components/forms/actionFormVisit';
import ActionOutcomeForm from '../components/forms/actionoutcomeForm';
import ActionFormSMS from '../components/forms/actionFormSMS';
import ActionFormCall from '../components/forms/actionFormCall';

const apiURL = require('../config.json').apiURL;


class Customers extends Component {

    state = {
        loading: false,
        activityLoading: [],
        selectedDefaulter: [],
        displayCustomer: false,
        access: false,
        loaded: false,
        loadTable: false,
        searchParameter: {},
        triggerUpdate: 0,
        filterShow: false,
        addActionShow: false,
        showDeleteConfirmation: false,
        customerOnDelete: null,
        deleteLoading: false,
        displayOutcome: false,
        selectedIds: [],
        selectAll: false,
        checked: false,
        resetCB: false,
        uploadShow: false,
        uploadRevokedShow: false,
        selectedFile: null,
        selectedRevokedFile: null,
        showMessage: false,
        messageText: "",
        messageError: false,
        messageSuccess: false,
        keysLoading: false,
        showKeys: false,
        enableTagValues: false,
        Otherdetailkey: null,
        Otherdetailvalue: null,
        downloadShow: false,
        selectAllDefaulters: false,
        filtered: null,
        sortby: 'id',
        isdescending: true,
        currentPage: 1,
        showActionMessage: false,
        selectedSupportingFile: null,
        uploadSupportingFileShow: false,
        loadingSupportingDocumentZip: false,
        selectedSupportingDocumentsPaths: [],
        checkedDocument: false,
        selectAllDocuments: false,
        checkedSupportingDocs: false,
        selectAllSupportingDocs: false,
        filterLoading: false,
        clearLoading: false,
        downloadReportLoading: false,
        //Revoked File
        revokedFileShowMessage: false,
        revokedFileSuccess: false,
        revokedFileWarning: false,
        revokedFileError: false,
        revokedFileTextMessage: null,
        //Select Customers by uploading a file
        selectedSelectCustomersFiles: null,
        uploadSelectCutomersFileShow: false,
        selectCustomerFileWarning: false,
        selectCustomerFileSuccess: false,
        selectCustomerFileError: false,
        selectCustomerShowMessage: false,
        selectCustomerFileTextMessage: null,
        selectedCustomersFromFile: false,
        //Unrevoked File
        selectedUnrevokedFiles: null,
        uploadUnrevokedFileShow: false,
        unrevokedFileShowMessage: false,
        unrevokedFileSuccess: false,
        unrevokedFileError: false,
        unrevokedFileWarning: false,
        unrevokedFileTextMessage: null,
        
        showVisitForm: false,
        showCallForm: false,
        showSMSForm: false,

        PhoneOptionsClicked: false
    }

    componentDidMount() {
        this.props.LoadRoles();
        this.props.LoadAllUsersWithID(true);
        this.props.LoadAllFormsWithID();
        this.props.LoadCompaniesWithIds();
    }

    tablePaging = (pageSize, currentPage) => {
        console.log(this.state.loaded)
        this.setState({ loadTable: true, currentPage: currentPage })
        this.props.LoadCustomers(pageSize, currentPage, this.state.searchParameter, this.state.sortby, this.state.isdescending)
            .then(x => {
                this.handlechecked();
                this.state.filterLoading === true ? this.setState({ loaded: true, access: true, loadTable: false, displayCustomer: false, filterLoading: false }) :
                    this.state.clearLoading === true ? this.setState({ loaded: true, access: true, loadTable: false, displayCustomer: false, clearLoading: false })
                        : this.setState({ loaded: true, access: true, loadTable: false, displayCustomer: false })
            })
            .catch(x => this.setState({ loaded: true, access: false }))
        //this.removeFromActionIds()
    }

    componentWillUnmount() {
        this.setState({ loaded: false, access: true })
    }

    handleCustomerDeletion = () => {
        this.setState({ deleteLoading: true })
        this.props.DeleteDefaulter(this.state.customerOnDelete.id)
            .then(x => this.setState({ deleteLoading: false, customerOnDelete: null, showDeleteConfirmation: false, triggerUpdate: this.state.triggerUpdate + 1 }))
            .catch(x => this.setState({ deleteLoading: false, showDeleteConfirmation: false, customerOnDelete: null }))
    }


    renderDeleteConfirmationBox = () => {
        return (
            this.state.customerOnDelete !== null ?
                <ConfirmBox
                    loading={this.state.deleteLoading}
                    open={this.state.showDeleteConfirmation}
                    header='Confirm Customer deletion'
                    content={`You are deleting customer with id: ${this.state.customerOnDelete.id} and name: ${this.state.customerOnDelete.name}. Deletion cannot be undone.`}
                    cancelButton='Cancel'
                    confirmButton="Delete"
                    size="mini"
                    onCancel={() => this.setState({ showDeleteConfirmation: false, customerOnDelete: null })}
                    onConfirm={() => this.handleCustomerDeletion()}

                />
                : null
        )
    }


    selectAllCB = () => {
        var data;
        var arr = this.state.selectedIds;
        if (!this.state.selectAll) {
            data = this.props.data.map(x => x.id)
            arr = arr.concat(data);
            var set = new Set(arr);
            arr = Array.from(set);
            this.setState({
                selectedIds: arr,
                selectAll: true,
                checked: true
            })
        } else if (this.state.selectAll) {
            data = this.props.data.map(x => x.id)

            arr = arr.filter(item => !data.includes(item))
            this.setState({
                selectedIds: arr,
                selectAll: false,
                checked: false
            })
        }
    }

    handleCheckbox = (value) => {
        let arr = this.state.selectedIds;
        let ch = this.state.selectedIds.includes(value.id);
        if (ch) {
            var arr1 = arr.filter(n => n !== value.id)
            this.setState({
                selectedIds: arr1,
                // resetCB: false
            })
        } else {
            arr.push(value.id)
            this.setState({
                selectedIds: arr,
                // resetCB: false
            })
        }
        this.handlechecked();
    }

    renderChekbox = (value) => {
        var check = this.state.selectedIds.includes(value.id)
        return (
            <Checkbox
                checked={check || this.state.selectAllDefaulters}
                onClick={() => this.handleCheckbox(value)}
            />
        )
    }

    handlechecked = () => {
        var data = this.props.data.map(x => x.id);
        var global = this.state.selectedIds;
        var check = data.every(element => global.includes(element));
        this.setState({
            checked: check,
            selectAll: check
        })

    }

    // checkBoxHandler = (val) => {
    //     console.log(val)
    //     let arr = this.state.selectedIds;
    //     let ch = this.state.selectedIds.includes(val.id);
    //     if (ch) {
    //         var arr1 = arr.filter(n => n !== val.id)
    //         this.setState({
    //             selectedIds: arr1,
    //             resetCB: false
    //         })
    //     } else {
    //         arr.push(val.id)
    //         this.setState({
    //             selectedIds: arr,
    //             resetCB: false
    //         })
    //     }
    // }
    // addtoActionIds = () => {
    //     var arr = this.props.data
    //     var arr1 = arr.map(x => x.id)
    //     this.setState({ selectedIds: arr1, selectAll: false })
    // }

    // removeFromActionIds = () => {
    //     var arr = []
    //     this.setState({ selectedIds: arr })
    // }

    // selectAllCheckBox = (val) => {
    //     return (<> <Checkbox defaultChecked onClick={() => this.checkBoxHandler(val)} /></>)
    // }

    // selectCheckBox = (val) => {
    //     return (<><Checkbox onClick={() => this.checkBoxHandler(val)} /></>)
    // }


    actionButton = (value) => {
        const activityProcessing = this.state.activityLoading.includes(value.id)

        return (
            <>
                {/*this.renderFlagButton(value)*/}
                <Popup
                    content={`View Details of ${value.name}.`}
                    position='top right'
                    // inverted
                    // on='hover'
                    trigger={
                        <Button size="mini" color='blue' icon='eye'
                            disabled={activityProcessing}
                            onClick={() => { this.renderDefaulter(value.id) }}
                        />
                    }
                />
                <Popup
                    content={`Delete Data of ${value.name}.`}
                    position='top right'
                    // on='hover'
                    // inverted
                    trigger={
                        <Button size="mini" color="red" icon='remove'
                            disabled={activityProcessing}
                            onClick={() => { this.setState({ showDeleteConfirmation: true, customerOnDelete: value }) }}
                        />
                    }
                />
            </>
        );
    }


    // selectAll = () => {
    //     if (this.state.resetCB) {
    //         this.setState({
    //             resetCB: false
    //         })
    //     }
    //     if (!this.state.selectAll) { this.addtoActionIds() }
    //     else { this.removeFromActionIds() }
    //     this.setState({
    //         selectAll: !this.state.selectAll
    //     })
    // }

    clearSelected = () => {
        console.log('CLear selected Called')
        this.setState({ searchParameter: {}, clearLoading: true }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))
    }

    renderClearButton = () => {
        return (
            <Button
                loading={this.state.filterLoading}
                // ?\\loading={this.state.clearLoading}
                size='tiny'
                color={Object.keys(this.state.searchParameter).length === 0 ? null : 'blue'}
                onClick={() => {
                    this.clearSelected()
                }}
                content='Clear'
            />
        )
    }
    renderAllButton = () => {
        return (
            <Button
                size='tiny'
                fluid
                primary={this.state.selectAllDefaulters || this.state.selectedCustomersFromFile}
                onClick={() => {
                    if (this.state.selectedCustomersFromFile) {
                        this.setState({
                            selectedCustomersFromFile: false,
                            filtered: this.state.searchParameter,
                            checked: false,
                            selectedIds: []
                        })
                    } else {
                        this.setState({
                            selectAllDefaulters: !this.state.selectAllDefaulters,
                            filtered: this.state.searchParameter,
                            checked: !this.state.checked,
                            selectedIds: []
                        })
                    }
                }}
                content='Select All'
            />
        )
    }

    renderTable = () => {
        const dat = this.displayCustomer ? [] : this.props.data;
        return (
            <DataTableCustomer
                header={[
                    {
                        name: 'ID',
                        accessor: 'id'
                    },
                    {
                        name: 'AC',
                        accessor: 'actionCodes',
                        // component: "<Icon name='checkmark' />"
                    },
                    {
                        name: 'Full Name',
                        accessor: 'name'
                    },
                    {
                        name: 'Company Tracking ID',
                        accessor: 'companyTrackingId'
                    },
                    {
                        name: 'Company Name',
                        accessor: 'companyName'
                    },
                    // {
                    //     name: "Defaulter's Details",
                    //     accessor: "otherDetails"
                    // },
                    /*{
                        name: 'Score',
                        accessor: 'score'
                    },*/
                    {
                        name: 'MRU',
                        accessor: 'mru'
                    },
                    {
                        name: 'Balance',
                        accessor: 'balance'
                    },
                    {
                        name: 'Address',
                        accessor: 'address'
                    },
                    {
                        name: "Cycle Day",
                        accessor: "addressType"
                    },
                    {
                        name: 'Area',
                        accessor: 'region'
                    },
                    {
                        name: 'City',
                        accessor: 'city'
                    },
                    {
                        name: 'Phone',
                        accessor: 'number'
                    },
                    {
                        name: "Account Tag",
                        accessor: "accountTag"
                    },
                    /*{
                        name: `Code`,
                        accessor: "defaulterCode"
                    },*/
                    {
                        name: "Last Payment Amount",
                        accessor: "lastPaymentAmount"
                    },
                    {
                        name: "Last Payment Date",
                        accessor: "lastPaymentDate"
                    }
                ]}
                // checkbox={(x) => this.state.selectAll ? this.selectAllCheckBox(x) : this.state.resetCB ? this.resetCheckBox(x) : this.selectCheckBox(x)}
                checkbox={(x) => this.renderChekbox(x)}
                selectAll={() => this.selectAllCB()}
                allselected={this.state.checked}
                WholeSelected={this.state.selectAllDefaulters}
                actions={(x) => this.actionButton(x)}
                // handleCheckBox={this.handleCheckbox}
                loading={this.state.loadTable}
                show={this.state.access && this.state.loaded && !this.state.displayCustomer}
                rowCount={this.props.total}
                data={Array.isArray(dat) ? dat : []}
                tableSize={'small'}
                compact
                collapsing
                pageSizes={[10, 20, 50, 100, 250]}
                trigger={this.state.triggerUpdate}
                onPageChange={(x, y) => this.tablePaging(x, y)}
                sorted
                sortby={this.state.sortby}
                isdescending={this.state.isdescending}
                handleSortby={this.handleSortbyChange}
                overflowX
                // customerPage
                currentPage={this.state.currentPage}
                localstorage={'customerPage'}
            />
        )
    }

    handleSortbyChange = (value) => {
        console.log(value)
        this.setState({
            sortby: value,
            isdescending: !this.state.isdescending,
            triggerUpdate: this.state.triggerUpdate + 1
        })
    }

    renderAddActionBox = () => {
        if(this.state.showVisitForm){
            return (
            <>
                <ActionFormVisit
                    size="tiny"
                    open={this.state.showVisitForm}
                    onCloseHandle={() => {
                        this.setState({ showVisitForm: false })
                    }}
                    header={
                        { icon: "add", text: "Create Visit Action" }
                    }
                    // label
                    actionsComponent={this.returnAddBoxActionsComponent()}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    assignTo={this.props.userbyID}
                    myForms={this.props.formsWithIds}
                    sortingParams={this.props.sortingParams}
                    showMessage={this.state.showActionMessage}
                    Message={this.props.message}
                    total={this.state.selectedIds.length}
                />
            </>
            )
        } else if (this.state.showCallForm){
            return (
                <>
                    <ActionFormCall
                        size="tiny"
                        open={this.state.showCallForm}
                        onCloseHandle={() => {
                            this.setState({ showCallForm: false })
                        }}
                        header={
                            { icon: "add", text: "Create Calling Action" }
                        }
                        // label
                        actionsComponent={this.returnAddBoxActionsComponent()}
                        onSubmit={this.handleSubmit}
                        loading={this.state.loading}
                        assignTo={this.props.userbyID}
                        myForms={this.props.formsWithIds}
                        sortingParams={this.props.sortingParams}
                        showMessage={this.state.showActionMessage}
                        Message={this.props.message}
                        total={this.state.selectedIds.length}
                    />
                </>
                )
        } else if (this.state.showSMSForm) {
            return (
                <>
                    <ActionFormSMS
                        size="tiny"
                        open={this.state.showSMSForm}
                        onCloseHandle={() => {
                            this.setState({ showSMSForm: false })
                        }}
                        header={
                            { icon: "add", text: "Add SMS Attempts" }
                        }
                        // label
                        actionsComponent={this.returnAddBoxActionsComponent()}
                        onSubmit={this.handleSubmit}
                        loading={this.state.loading}
                        assignTo={this.props.userbyID}
                        showMessage={this.state.showActionMessage}
                        Message={this.props.message}
                        total={this.state.selectedIds.length}
                    />
                </>
                )
        }
    }

    handleSubmit = (value) => {
        var data = {}

        if (value !== undefined && value !== null) {
            if (value.date !== undefined && value.date !== null && typeof value.date === 'object') {
                value.date = value.date.toLocaleDateString()
            }
            if (value.userid !== undefined && value.userid !== null && value.userid === 0) {
                delete value.userid
            }
            if (this.state.showVisitForm)
                value.medium = 'Visit';
            else if (this.state.showCallForm){
                value.medium = 'Call';
                if(value.isRandom)
                    value.userid = null;
            }
            else if (this.state.showSMSForm){
                value.type = 'Follow Up';
                value.requiresApproval = false;
                value.medium = 'SMS';
            }
            data.action = value
            data.ids = this.state.selectedIds
            // console.log
            data.filter = this.state.filtered

        }

        //console.log(data)

        this.setState({ loading: true })
        this.props.CreateMultipleAction(data)
            .then(x => {
                // alert("Actions Created")
                //console.log(this.state.searchParameter)
                this.setState({ loading: false, selectedIds: [], showActionMessage: true }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))

            })
            .catch(error => {
                // alert('No Action Created')
                //console.log(this.state.searchParameter)
                this.setState({ loading: false, showActionMessage: true, })
                // this.setState({ loading: false, addActionShow: false, resetCB: false })
            })
        this.setState({ selectAll: false })
        // this.clearSelected()

    }

    renderAddActionDropdown = () => {
        return (
            <>
                <Dropdown
                    disabled={this.state.selectedIds.length === 0 && !this.state.selectAllDefaulters}
                    value={null}
                    text='Action'
                    button
                    className='icon green'
                    floating
                    labeled
                    compact
                    icon='plus'
                    onChange={(e, { value }) => this.onChangeActionDropdown(value)}
                    options={[
                        { key: `action1`, text: 'Visit', value: 'Visit'},
                        { key: `action2`, text: 'Call', value: 'Call' },
                        { key: `action3`, text: 'SMS', value: 'SMS' },
                    ]}
                />
            </>
        )
    }

    onChangeActionDropdown = (value) => {
        console.log(value);
        if (value === 'Visit') {
            this.props.LoadSortingParams(this.state.selectedIds[0]).then((x) => {
                this.setState({
                    showVisitForm: true,
                    sortingParams: this.props.sortingParams
                })
            })
            this.setState({ showVisitForm: true });
        } else if (value === 'Call') {
            this.props.LoadSortingParams(this.state.selectedIds[0]).then((x) => {
                this.setState({
                    showCallForm: true,
                    sortingParams: this.props.sortingParams
                })
            })
            this.setState({ showCallForm: true });
        } else if (value === 'SMS') {
            this.props.LoadSortingParams(this.state.selectedIds[0]).then((x) => {
                this.setState({
                    showSMSForm: true,
                    sortingParams: this.props.sortingParams
                })
            })
            this.setState({ showSMSForm: true });
        }
        value = null;
    }

    returnAddBoxActionsComponent = () => {
        return (
            <>
                <Button
                    disabled={this.state.loading}
                    basic color='red' onClick={() => {
                        this.setState({
                            //addActionShow: false,
                            showVisitForm: false,
                            showCallForm: false,
                            showSMSForm: false,
                            showActionMessage: false
                        })
                    }}
                    icon='remove'
                    content='Close'
                />
                <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    form="actionsForm"
                    color='green' type="submit"
                    icon='checkmark'
                    content='Creat Action'
                />
            </>
        )
    }

    /*renderAddActionButton = () => {
        return (
            <Button
                size='tiny'
                disabled={this.state.selectedIds.length === 0 && !this.state.selectAllDefaulters}
                onClick={() => {
                    this.props.LoadSortingParams(this.state.selectedIds[0]).then((x) => {
                        this.setState({
                            addActionShow: true,
                            sortingParams: this.props.sortingParams
                        })
                    })
                    this.setState({ addActionShow: true })
                }}
                color="green"
                // fluid
                content="Action"
            />
        )
    }*/

    renderUploadActionComponents = () => {
        return (<>
            <Button
                disabled={this.state.loading}
                floated="left"
                size='tiny'
                color='red'
                inverted
                onClick={() => {
                    this.setState({ uploadShow: false })
                }}
                icon='remove'
                content='Close'
            />
            <Button
                disabled={this.state.loading}
                loading={this.state.loading}
                onClick={this.onClickHandler}
                size='tiny'
                icon='upload'
                content='Upload'
            />
        </>
        )
    }

    renderFilterButton = () => {
        return (
            <>
                <Button
                    size='tiny'
                    primary={this.state.filterShow}
                    onClick={() => {
                        this.setState({ filterShow: !this.state.filterShow })
                        // if (!this.state.filterShow === false && JSON.stringify(this.state.searchParameter) !== '{}') {
                        //     this.setState({ searchParameter: {} }, () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 }))
                        // }
                        //   dispatch(reset('customerFilter'))
                    }}
                    //floated='right'
                    fluid
                >
                    {!this.state.filterShow ? 'Filters' : 'Hide Filter'}
                </Button>
            </>
        )
    }

    handleFilterSubmit = (x) => {
        console.log(x)
        if ('company' in x) {
            // eslint-disable-next-line array-callback-return
            x.companyname = this.props.companybyID.find(function (y) { if (y.value === x.company) return y })
            console.log(x.companyname)
            console.log(x.company)
            x.companyname = x.companyname.text
            // eslint-disable-next-line array-callback-return
            var arr = Object.entries(x).map(([key, value]) => {
                console.log(key)
                if (key.substring(0, x.company.toString().length + 1) === `${x.company}_`) {
                    return { "key": key.substring(x.company.toString().length + 1), "value": value }
                }
            })
            x.otherdetails = arr.filter(function (el) { return el; })
        } else {
            delete x.company
            delete x.companyname
            delete x.otherdetails
        }
        if (x.minlastPaymentDate !== undefined && x.minlastPaymentDate !== null && typeof x.minlastPaymentDate === 'object') {
            // x.minlastPaymentDate = x.minlastPaymentDate.toLocaleDateString()
            // console.log(typeof minlastPaymentDate)
        } else {
            delete x.minlastPaymentDate
        }
        if (x.maxlastPaymentDate !== undefined && x.maxlastPaymentDate !== null && typeof x.maxlastPaymentDate === 'object') {
            // x.maxlastPaymentDate = x.maxlastPaymentDate.toLocaleDateString()
        } else {
            // delete x.maxlastPaymentDate
        }
        console.log(x);
        Object.keys(x).forEach(key => {
            if (Array.isArray(x[key])) {
                if (x[key].length === 0) {
                    delete x[key];
                }
            } else if (x[key] === "" || x[key] === null || x[key] === undefined) {
                delete x[key];
            }
        })
        this.setState({ searchParameter: x, filtered: x, filterLoading: true },
            () => this.setState({ triggerUpdate: this.state.triggerUpdate + 1 })
        )
    }

    renderFilterBox = () => {
        return (
            this.state.filterShow ?
                <div style={{ marginBottom: '10px' }}>
                    <Header attached="top">Filter Customer </Header>
                    <Segment attached>
                        <CustomerFilterForm
                            onSubmit={(x) => this.handleFilterSubmit(x)}
                            actionsComponent={this.renderFilterActionsComponent}
                            initialValues={this.state.searchParameter}
                        />
                    </Segment>
                </div>
                : null
        )
    }

    renderFilterActionsComponent = () => {
        return (
            <>
                <Button
                    loading={this.state.filterLoading}
                    primary
                    type='submit'
                    icon='filter'
                    content="Filter"
                />
                <Button
                    color={Object.keys(this.state.searchParameter).length === 0 ? null : 'red'}
                    onClick={() => {
                        this.clearSelected()
                    }}
                    content='Clear Filter'
                />

            </>
        )
    }

    renderDefaulter = (id) => {
        this.props.LoadDefaulter(id)
            .then(x => {
                this.setState({
                    loaded: true,
                    selectedDefaulter: this.props.selectedCustomer,
                    access: true,
                    loadTable: false,
                    displayCustomer: true
                })
            })
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    renderCustomerDisplay = () => {
        const customer = this.state.selectedDefaulter;
        console.log(customer)
        return (
            <div style={styles.mainDiv}>
                <Segment attached="top">
                    <Header as="h2">
                        <span><Icon name="user" /></span>
                        <span>Customer Profile</span>
                        <span>
                            <Button color="red" floated="right"
                                onClick={() => { this.setState({ displayCustomer: false, selectedDefaulter: null, loaded: false }) }}
                            >
                                <Icon name="x" />
                                Close
                             </Button>
                        </span>
                    </Header>
                </Segment>

                <Segment attached="bottom">
                    <Grid>
                        {this.renderCustomerDisplayCell(3, 'Customer ID', customer.id)}
                        {this.renderCustomerDisplayCell(5, 'Name', customer.name)}
                        {this.renderCustomerDisplayCell(4, 'Balance', customer.balance)}
                        {/*this.renderCustomerDisplayCell(4, 'Score', customer.score)*/}
                        {/* {this.renderCustomerDisplayCell(8, 'Others Details', JSON.stringify(customer.otherDetails))} */}
                        {this.renderCustomerDisplayCell(4, 'Company Tracking Id', customer.companyTrackingId)}
                        {this.renderCustomerDisplayCell(4, 'Company Id', customer.companyId)}
                        {this.renderCustomerDisplayCell(5, 'Company Name', customer.company.name)}
                        {this.renderCustomerDisplayCell(5, 'Contact Person', customer.company.contactPerson)}
                        {this.renderCustomerDisplayCell(6, 'Company Number', customer.company.contactNum)}
                        {this.renderNumberTable(customer.phoneNumbers)}
                        {this.renderRefernceTable(customer.references)}
                        {this.renderHistories(customer.histories)}
                        {this.renderOtherDetails(customer)}
                        {this.renderSupportingDocuments(customer)}
                        {this.renderAddressTable(customer.defaulterAddresses)}
                        {this.renderActionsTable(customer.actions)}
                        {this.renderOutcomeBox()}
                    </Grid>
                </Segment>
            </div>
        )
    }

    renderSupportingDocsCheckbox = value => {
        var check = this.state.selectedSupportingDocumentsPaths.includes(value.path);
        return (
            <Checkbox checked={check} onClick={() => this.handleSupportingDocumentsCheckBox(value)} />
        );
    };

    handleSupportingDocumentsCheckBox = value => {
        let array = this.state.selectedSupportingDocumentsPaths
        let index = array.indexOf(value.path)
        if (index !== -1) {
            //for removing any element form the array at posiditon 'index'
            array.splice(index, 1);
            this.setState({
                selectedSupportingDocumentsPaths: array
            })
        } else {
            // adding to the list if not already in it
            array.push(value.path)
            this.setState({
                selectedSupportingDocumentsPaths: array
            })
        }
        this.handleAllSupportingDocumentsChecked()
    }

    handleAllSupportingDocumentsChecked = () => {
        let data = this.state.selectedDefaulter.supportingDocuments.map(x => x.path)
        let array = this.state.selectedSupportingDocumentsPaths
        let check = data.every(element => array.includes(element));
        this.setState({
            checkedSupportingDocs: check,
            selectAllSupportingDocs: check
        })

    }

    selectAllSupportingDocsCB = () => {
        let global = this.state.selectedDefaulter.supportingDocuments
        let arr = this.state.selectedSupportingDocumentsPaths
        let data = global.map(x => x.path)
        if (!this.state.selectAllSupportingDocs) {
            arr = arr.concat(data);
            let set = new Set(arr);
            arr = Array.from(set)
            console.log(arr)
            this.setState({
                selectedSupportingDocumentsPaths: arr,
                selectAllSupportingDocs: true,
                checkedSupportingDocs: true
            })
        } else if (this.state.selectAllSupportingDocs) {
            arr = arr.filter(item => !data.includes(item))
            console.log(arr)
            this.setState({
                selectedSupportingDocumentsPaths: arr,
                selectAllSupportingDocs: false,
                checkedSupportingDocs: false
            })
        }
        // console.log(global)
    }

    renderSupportingDocuments = (data) => {
        // console.log(this.state.selectedSupportingDocumentsPaths)
        let docs = data.supportingDocuments.length !== 0 ? data.supportingDocuments : []
        let row = 0;
        let array = docs.map(item => {
            row++;
            return { rid: row, docId: item.id, path: item.path }
        })
        return (
            <Grid.Column width={data.supportingDocuments.length === 0 ? 8 : 4}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    {/* <Segment clearing> */}
                    {/* <Header size="medium" floated='left'>
                            {'Supporting Documents'}
                        </Header> */}
                    <Header size="medium">
                        {'Supporting Documents'}
                        {/* <span position='right'> */}
                        <Popup content='Download Supporting Documents in zip file.' position='top right' trigger={
                            <Button size='mini' icon='download' content='' floated='right'
                                loading={this.state.loadingSupportingDocumentZip}
                                disabled={this.state.selectedSupportingDocumentsPaths.length === 0}
                                onClick={() => {
                                    this.setState({ loadingSupportingDocumentZip: true })
                                    this.props.DownloadSupportingDocumentsZip(this.state.selectedSupportingDocumentsPaths)
                                        .then(() => {
                                            this.setState({
                                                selectedSupportingDocumentsPaths: [],
                                                checkedSupportingDocs: false,
                                                selectAllDocuments: false,
                                                loadingSupportingDocumentZip: false
                                            })
                                        })
                                        .catch(() => {
                                            alert('Not Downloaded')
                                            this.setState({
                                                selectedSupportingDocumentsPaths: [],
                                                checkedSupportingDocs: false,
                                                selectAllDocuments: false,
                                                loadingSupportingDocumentZip: false
                                            })
                                        })
                                }
                                }
                            />
                            //     <Icon name='download' size='large' />
                            // </Button>
                        }
                        />
                        {/* </span> */}
                        {/* </span> */}
                    </Header>
                    {/* </Segment> */}

                    <SelectTable
                        header={[
                            {
                                name: 'Sr. No.',
                                accessor: 'rid'
                            }
                        ]}
                        checkbox={x => this.renderSupportingDocsCheckbox(x)}
                        selectAll={() => this.selectAllSupportingDocsCB()}
                        allselected={this.state.checkedSupportingDocs}
                        // actions={(x) => this.supportingDocsActions(x)}
                        loading={this.state.loadTable}
                        data={array}
                        show={this.state.access && this.state.loaded}
                        noPagination
                        compact
                        trigger={this.state.triggerUpdate}
                    />
                </Grid.Column>
            </Grid.Column >
        )
    }

    renderOtherDetails = (data) => {
        let details = data.otherDetails
        return (
            <Grid.Column width={data.supportingDocuments.length === 0 ? 8 : 4}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        Other Details {/* <Button floated="right" size="tiny" >Add</Button> */}
                    </Header>
                </Grid.Column>
                <DataTable
                    header={[

                        {
                            name: 'Key',
                            accessor: 'key'
                        },
                        {
                            name: 'Value',
                            accessor: 'value'
                        }
                    ]}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={details}
                    noPagination
                />
            </Grid.Column>
        )
    }

    renderRefernceTable = (ref) => {
        return (
            <Grid.Column width={8}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        References {/* <Button floated="right" size="tiny" >Add</Button> */}
                    </Header>
                </Grid.Column>
                <DataTable
                    header={[
                        {
                            name: 'ID',
                            accessor: 'id'
                        },
                        {
                            name: 'Name',
                            accessor: 'name'
                        },
                        {
                            name: 'Relation',
                            accessor: 'relation'
                        },
                        {
                            name: 'Phone No.',
                            accessor: 'phoneNumber'
                        },
                        {
                            name: 'Address',
                            accessor: 'address'
                        }
                    ]}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={ref}
                    noPagination
                />

            </Grid.Column>
        )
    }

    renderCompanyTable = () => {
        const dat = this.props.data.defaulterCompanies;
        for (var l in dat) {
            let list = Object.assign({}, dat[l]);
            for (var key in list) {
                if (typeof list[key] === 'object') {
                    const d = Object.assign({}, list[key]);
                    for (var g in d) {
                        let c = g.charAt(0).toUpperCase() + g.substring(1);
                        let n = key.concat(c);
                        list[n] = d[g]
                    }
                }
            }
            dat[l] = list;
        }

        return (
            <>
                <DataTable
                    header={[
                        {
                            name: 'ID',
                            accessor: 'id'
                        },
                        {
                            name: 'Company Name',
                            accessor: 'companyName'
                        },
                        {
                            name: 'Balance',
                            accessor: 'balance'
                        },
                        {
                            name: 'Details',
                            accessor: 'defaulterDetails'
                        }
                    ]}
                    loading={this.state.loadTable}
                    data={dat}
                    show={this.state.access && this.state.loaded}
                    noPagination
                    trigger={this.state.triggerUpdate}

                />


            </>
        )
    }

    renderHistories = (histories) => {
        //console.log(histories);
        return (
            <Grid.Column width={8} >
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        History {/* <Button floated="right" size="tiny" >Add</Button> */}
                    </Header>
                </Grid.Column>
                <DataTable
                    header={[
                        {
                            name: 'ID',
                            accessor: 'id'
                        },
                        {
                            name: 'Last Payment Amount',
                            accessor: 'lastPaymentAmount'
                        },
                        {
                            name: 'Last Payment Date',
                            accessor: 'lastPaymentDate'
                        },
                        {
                            name: 'Tag',
                            accessor: 'tag'
                        },
                        {
                            name: 'Is Current?',
                            accessor: 'isCurrent'
                        }
                    ]}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={histories}
                    noPagination
                    trigger={this.state.triggerUpdate}
                />
            </Grid.Column>
        )
    }

    renderNumberTable = (numbers) => {
        return (
            <Grid.Column width={8}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        Contact Details {/* <Button floated="right" size="tiny" >Add</Button> */}
                    </Header>
                </Grid.Column>
                <DataTable
                    header={[
                        {
                            name: 'ID',
                            accessor: 'id'
                        },
                        {
                            name: 'Number',
                            accessor: 'number'
                        },
                        {
                            name: 'Type',
                            accessor: 'type'
                        },
                        {
                            name: 'Is Primary?',
                            accessor: 'isPrimary'
                        }
                    ]}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={numbers}
                    noPagination
                    trigger={this.state.triggerUpdate}
                />

            </Grid.Column>
        )
    }


    renderAddressTable = (addresses) => {
        const dat = addresses;
        for (var l in dat) {
            let list = Object.assign({}, dat[l]);
            for (var key in list) {
                if (typeof list[key] === 'object') {
                    const d = Object.assign({}, list[key]);
                    for (var g in d) {
                        if (typeof d[g] === 'object') {
                            const e = Object.assign({}, d[g])
                            for (var f in e) {
                                let o = f.charAt(0).toUpperCase() + f.substring(1);
                                let m = g.concat(o)
                                list[m] = e[f]
                            }
                        } else {
                            let c = g.charAt(0).toUpperCase() + g.substring(1);
                            let n = key.concat(c);
                            list[n] = d[g]
                        }
                    }
                }
            }
            dat[l] = list;
        }

        return (
            <Grid.Column width={16}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        Address Details {/*<Button floated="right" size="tiny" >Add</Button> */}
                    </Header>
                </Grid.Column>
                <DataTable
                    header={[
                        {
                            name: 'ID',
                            accessor: 'id'
                        },
                        {
                            name: 'Address',
                            accessor: 'address'
                        },
                        {
                            name: 'Type',
                            accessor: 'type'
                        },
                        {
                            name: 'is Primary?',
                            accessor: 'isPrimary'
                        },
                        {
                            name: 'Longitute',
                            accessor: 'longitude'
                        },
                        {
                            name: 'Latitude',
                            accessor: 'latitude'
                        },
                        {
                            name: 'Image',
                            accessor: 'elevationImage'
                        },
                        {
                            name: 'Region',
                            accessor: 'regionName'
                        },
                        {
                            name: 'City',
                            accessor: 'cityName'
                        }
                    ]}
                    actions={(x) => this.LoadAddressActions(x)}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={dat}
                    noPagination
                    overflowX
                />
            </Grid.Column>
        )
    }

    LoadAddressActions = (address) => {
        return (
            <>

                <Button
                    data-tooltip="Open Location in Google Maps"
                    data-position="top right"
                    circular
                    color="blue"
                    size="tiny"
                    disabled={false}
                    icon="paper plane"
                    onClick={(x) => this.renderLocation(address)}
                />

                {/* <Button
                    data-tooltip="Open Image in New Tab"
                    data-position="top right"
                    circular
                    size="tiny"
                    color="green"
                    disabled={address.elevationImage === null ? true : false}
                    icon="image"
                    onClick={(x) => { this.props.LoadElevationImage(address.elevationImage) }}
                /> */}

            </>
        )
    }

    renderLocation = (address) => {
        var LonLat = address.longitude === null || address.latitude === null ? false : true
        var link = LonLat ? `http://maps.google.com?q=${address.latitude},${address.longitude}`
            : `http://maps.google.com?q=${address.address},${address.regionName},${address.cityName}`
        window.open(link);
    }

    renderCustomerDisplayCell = (size, label, data) => {
        return (
            <Grid.Column width={size}>
                <Segment>
                    <span style={
                        {
                            border: 'solid #d3d3d3 0px',
                            borderRadius: 5,
                            padding: 4,
                            marginRight: 7
                        }
                    }>
                        {label.concat(' : ')}
                    </span>
                    {data}
                </Segment>
            </Grid.Column>
        )
    }

    onChangeRevokedFileHandler = (event) => {
        this.setState({
            selectedRevokedFile: event.target.files[0]
        })
    }

    onChangeFileHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }

    modalForUploadRevokedFile = () => {
        return (
            <UploadFilesModal
                open={this.state.uploadRevokedShow}
                content={"Upload Excel File for Revoked"}
                onChangeFilesHandler={this.onChangeRevokedFileHandler}
                actionsComponent={this.renderUploadRevokedActionComponents}
                multiple={false}
                showMessage={this.state.revokedFileShowMessage}
                successMessage={this.state.revokedFileSuccess}
                warningMessage={this.state.revokedFileWarning}
                errorMessage={this.state.revokedFileError}
                messageText={this.state.revokedFileTextMessage}
            />
        )
    }

    renderUploadRevokedActionComponents = () => {
        return (<>
            <Button
                disabled={this.state.loading}
                floated="left"
                size='tiny'
                color='red'
                inverted
                onClick={() => {
                    this.setState({
                        uploadRevokedShow: false,
                        selectedRevokedFile: null,
                        revokedFileShowMessage: false,
                        revokedFileSuccess: false,
                        revokedFileWarning: false,
                        revokedFileError: false,
                        revokedFileTextMessage: null
                    })
                }}
                icon='remove'
                content='Close'
            />
            <Button
                disabled={this.state.loading}
                loading={this.state.loading}
                onClick={this.onClickRevokedHandler}
                size='tiny'
                icon='upload'
                content='Upload'
            />
        </>
        )
    }

    modalForUnrevokedFilesUpload = () => {
        return (
            <UploadFilesModal
                open={this.state.uploadUnrevokedFileShow}
                content={"Upload File for Unrevoked Customer(s)"}
                onChangeFilesHandler={this.onChangeUnrevokedFileHandler}
                actionsComponent={this.renderUploadUnrevokedActionComponents}
                multiple={false}
                showMessage={this.state.unrevokedFileShowMessage}
                successMessage={this.state.unrevokedFileSuccess}
                warningMessage={this.state.unrevokedFileWarning}
                errorMessage={this.state.unrevokedFileError}
                messageText={this.state.unrevokedFileTextMessage}
            />
        );
    }

    renderUploadUnrevokedActionComponents = () => {
        return (
            <>
                <Button
                    disabled={this.state.loading}
                    floated="left"
                    basic
                    size='tiny'
                    color='red'
                    onClick={() => {
                        this.setState({
                            uploadUnrevokedFileShow: false,
                            unrevokedFileShowMessage: false,
                            unrevokedFileSuccess: false,
                            unrevokedFileError: false,
                            unrevokedFileWarning: false,
                            unrevokedFileTextMessage: null
                        })
                    }}
                    icon='remove'
                    content='Close'
                />
                <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    onClick={this.onClickUnrevokedHandler}
                    size='tiny'
                    icon='upload'
                    content='Upload'
                />
            </>
        )
    }

    onChangeUnrevokedFileHandler = (event) => {
        this.setState({
            selectedUnrevokedFiles: event.target.files[0]
        })
    }

    onClickUnrevokedHandler = () => {
        console.log(this.state.selectedUnrevokedFiles);
        if (this.state.selectedUnrevokedFiles === null) {
            this.setState({
                unrevokedFileShowMessage: true,
                unrevokedFileWarning: true,
                unrevokedFileTextMessage: 'Please Select a File.'
            })
        } else {
            this.props.UploadUnrevokedCutomers(this.state.selectedUnrevokedFiles)
                .then(() => {
                    this.setState({
                        unrevokedFileShowMessage: true,
                        unrevokedFileSuccess: true,
                        unrevokedFileTextMessage: 'Successfully Uploaded!.',
                        triggerUpdate: this.state.triggerUpdate + 1
                    })
                })
                .catch(() => {
                    this.setState({
                        unrevokedFileShowMessage: true,
                        unrevokedFileError: true,
                        unrevokedFileTextMessage: 'Error in Uploading File. Please check File again.'
                    })
                })
        }
    }


    modalForUploadSupportingFile = () => {
        return (
            <Modal open={this.state.uploadSupportingFileShow} size="tiny">
                <Header icon="upload" content="Upload Supporting File(s) of Customer(s)" />
                <Modal.Content>
                    <div className="ui container">
                        <div className="ui row">
                            <div className="offset-md-3 col-md-6">
                                <div className="form-group files">
                                    <Input multiple type="file" onChange={this.onChangePDFFileHandler} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showMessage ?
                        <Message
                            error={this.state.messageError}
                            success={this.state.messageSuccess}
                            onDismiss={this.handleDismiss}
                            header={this.state.messageSuccess ? "Successfull!" : "Attention!"}
                            content={this.state.messageText} />
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    {this.renderUploadSupportingActionComponents()}
                </Modal.Actions>
            </Modal>
        )
    }

    renderUploadSupportingActionComponents = () => {
        return (<>
            <Button
                disabled={this.state.loading}
                floated="left"
                size='tiny'
                color='red'
                inverted
                onClick={() => {
                    this.setState({ uploadSupportingFileShow: false })
                }}
                icon='remove'
                content='Close'
            />
            <Button
                disabled={this.state.loading}
                loading={this.state.loading}
                onClick={this.onClickPDFHandler}
                size='tiny'
                icon='upload'
                content='Upload'
            />
        </>
        )
    }

    onChangePDFFileHandler = (event) => {
        this.setState({
            selectedSupportingFile: event.target.files
        })
    }

    onClickPDFHandler = () => {
        if (this.state.selectedSupportingFile === null) {
            this.setState({ messageError: true, messageText: "Please select a File(s)", showMessage: true })

        } else {
            this.setState({ loading: true })
            this.props.UploadPDFCustomer(this.state.selectedSupportingFile)
                .then((x) => {
                    this.setState({ messageSuccess: true, messageText: "Successfullly Uploaded", showMessage: true, loading: false, selectedSupportingFile: null })
                })
                .catch((y) => {
                    this.setState({ messageError: true, messageText: "Could not be Uploaded", showMessage: true, loading: false, selectedSupportingFile: null })
                })
        }
    }


    modalForSelectCustomersFile = () => {
        return (
            <UploadFilesModal
                open={this.state.uploadSelectCutomersFileShow}
                content={"Upload File for Selecting Customer(s)"}
                onChangeFilesHandler={this.onChangeSelectCustomerFileHandler}
                actionsComponent={this.renderUploadSelectCustomersActionComponents}
                multiple={false}
                showMessage={this.state.selectCustomerShowMessage}
                successMessage={this.state.selectCustomerFileSuccess}
                errorMessage={this.state.selectCustomerFileError}
                warningMessage={this.state.selectCustomerFileWarning}
                messageText={this.state.selectCustomerFileTextMessage}
            />
        )
    }

    onChangeSelectCustomerFileHandler = (event) => {
        this.setState({
            selectedSelectCustomersFiles: event.target.files[0]
        })
    }

    renderUploadSelectCustomersActionComponents = () => {
        return (
            <div>
                <Button
                    disabled={this.state.fileUploadLoading}
                    floated='left'
                    size='tiny'
                    color='red'
                    inverted
                    onClick={() => {
                        this.setState({
                            uploadSelectCutomersFileShow: false,
                            selectCustomerFileWarning: false,
                            selectCustomerFileError: false,
                            selectCustomerFileSuccess: false,
                            selectCustomerShowMessage: false,
                            selectCustomerFileTextMessage: null,
                            selectedSelectCustomersFiles: null
                        })
                    }}
                    icon='remove'
                    content='Close'
                />
                <Button
                    loading={this.state.fileUploadLoading}
                    disabled={this.state.fileUploadLoading}
                    onClick={this.onClickSelectCustomersHandler}
                    size='tiny'
                    icon='upload'
                    content='Upload'
                />
            </div>
        )
    }

    onClickSelectCustomersHandler = () => {
        console.log(this.state.selectedSelectCustomersFiles);
        if (this.state.selectedSelectCustomersFiles === null) {
            this.setState({ selectCustomerFileError: true, selectCustomerFileTextMessage: "Please select a File", selectCustomerShowMessage: true })
        } else {
            this.setState({ fileUploadLoading: true })
            this.props.UploadSelectCustomerFile(this.state.selectedSelectCustomersFiles)
                .then(() => {
                    console.log(this.props.selectedCustomers)
                    console.log(this.props.invalidTrackingIds)
                    this.props.selectedCustomers.length === 0 && this.props.invalidTrackingIds.length > 0 ?
                        this.setState({
                            selectCustomerShowMessage: true,
                            selectCustomerFileError: true,
                            selectCustomerFileTextMessage: `None of the ids is valid!`
                        })
                        : this.props.selectedCustomers.length > 0 && this.props.invalidTrackingIds.length > 0 ?
                            this.setState({
                                selectCustomerShowMessage: true,
                                selectCustomerFileWarning: true,
                                selectCustomerFileTextMessage: `Valid ids has been selected.
                                Following ids are not valid:\n${this.props.invalidTrackingIds}`,
                                selectedIds: this.props.selectedCustomers,
                                selectedCustomersFromFile: true
                            }) : this.setState({
                                selectCustomerShowMessage: true,
                                selectCustomerFileSuccess: true,
                                selectCustomerFileTextMessage: `Successfully Selected!`,
                                selectedIds: this.props.selectedCustomers,
                                selectedCustomersFromFile: true
                            })
                })
                .catch(() => {
                    // console.log('catch')
                    // this.setState({
                    //     selectCustomerFileError: true,
                    //     selectCustomerFileTextMessage: "Please select a valid File",
                    //     selectCustomerShowMessage: true
                    // })
                })
                .finally(() => {
                    this.setState({fileUploadLoading:false})
                })
        }
    }

    onCompanyDropdownChange = (e, { value }) => {
        this.setState({
            keysLoading: true
        })
        this.props.LoadTagKeys(value)
            .then(x => {
                this.setState({
                    keysLoading: false,
                    showKeys: true
                })
            })


    }

    handleTagVal = (e) => {
        this.setState({
            Otherdetailvalue: e.target.value
        })
    }

    renderTagValues = () => {
        return (
            <>
                <Input
                    fluid
                    icon='search'
                    placeholder='Search...'
                    disabled={!this.state.enableTagValues}
                    onChange={this.handleTagVal}
                    name='Otherdetailvalue'
                />
            </>
        )
    }
    handleKeyDropdown = (e, { value }) => {
        this.setState({
            enableTagValues: true,
            Otherdetailkey: value
        })
    }
    renderTagKeys = () => {
        return (
            <>
                <Dropdown
                    fluid
                    selection
                    loading={this.state.keysLoading}
                    disabled={!this.state.showKeys}
                    options={this.props.tagKeys}
                    onChange={this.handleKeyDropdown}
                    placeholder='Tag Keys'
                />
            </>
        )
    }

    renderCompanyDropdown = () => {
        return (
            <>
                <Dropdown
                    fluid
                    selection
                    options={this.props.companybyID}
                    onChange={this.onCompanyDropdownChange}
                    placeholder='Company'
                />
            </>
        )
    }

    renderSearchIconButton = () => {
        return (
            <>
                <Button
                    icon='search'
                    disabled={!(this.state.showKeys && this.state.enableTagValues)}
                    onClick={this.handleSearchTag}
                />
            </>
        )
    }

    handleSearchTag = () => {
        var ser = {}
        ser.Otherdetailkey = this.state.Otherdetailkey
        ser.Otherdetailvalue = this.state.Otherdetailvalue
        this.setState({
            searchParameter: ser,
            triggerUpdate: this.state.triggerUpdate + 1
        })

    }

    renderFlagButton = (value) => {
        let color, iconName, text, flag;

        if (value.flag === 'f') {
            color = 'green';
            iconName = 'circle';
            text = "marked"
            flag = 'n'
        } else if (value.flag === 'n') {
            color = 'grey';
            iconName = 'circle outline';
            text = "normal"
            flag = 'f'
        }
        return (
            <Popup
                content={`${value.name} is ${text} Customer. Click to change flag.`}
                position='top right'
                on='hover'
                trigger={
                    <Button
                        // loading={activityProcessing}
                        // disabled={activityProcessing}
                        size="mini"
                        color={color}
                        icon={iconName}
                        onClick={() => this.changeFlag(value.id, flag)}
                    />
                } />
        )
    }

    changeFlag = (id, flag) => {
        this.props.UpdateFlag(id, flag)
            .then(() => {
                console.log('Updated')
                this.setState({
                    triggerUpdate: this.state.triggerUpdate + 1
                })
            })
            .catch(() => {
                console.log('Error')
            })
    }

    onChangeUploadDropdown = (value) => {
        console.log(value);
        if (value === 'Customers') {
            this.setState({ uploadShow: true });
        } else if (value === 'Revoked') {
            this.setState({ uploadRevokedShow: true });
        } else if (value === 'Supporting') {
            this.setState({ uploadSupportingFileShow: true });
        } else if (value === 'Select') {
            this.setState({ uploadSelectCutomersFileShow: true });
        } else if (value === 'Unrevoked') {
            this.setState({ uploadUnrevokedFileShow: true });
        }
        value = null;
    }

    renderUploadDropdown = () => {
        return (
            <>
                <Dropdown
                    value={null}
                    text='Upload'
                    button
                    className='icon'
                    floating
                    labeled
                    compact
                    icon='upload'
                    onChange={(e, { value }) => this.onChangeUploadDropdown(value)}
                    options={[
                        { key: `file1`, text: 'Customers', value: 'Customers', icon: 'file excel' },
                        { key: `file2`, text: 'Revoked', value: 'Revoked', icon: 'file excel' },
                        { key: `file3`, text: 'Supporting', value: 'Supporting', icon: 'attach' },
                        { key: `file4`, text: 'Select Ids', value: 'Select', icon: 'file excel' },
                        { key: `file5`, text: 'Unrevoked', value: 'Unrevoked', icon: `file excel` }
                    ]}
                />
            </>
        )
    }

    renderActionsTable = (dat) => {
        dat.map(obj => {
            let name = this.props.userbyID.find(x => x.value === obj.userId)
            obj.user = name !== null && name !== undefined ? name.text : 'Null'
            let form = this.props.formsWithIds.find(x => x.value === obj.formId)
            obj.form = form !== null && form !== undefined ? form.text : 'Null'
            obj.source = obj.source === true ? 'Outbound' : 'Inbound'
        })
        return (
            <Grid.Column width={16}>
                <Grid.Column style={{ marginBottom: '10px' }}>
                    <Header size="medium">
                        Actions
                        <Label>Total: {dat.length}</Label>
                    </Header>                  
                </Grid.Column>
                <DataTable
                    header={[
                        {
                            name: 'Tag',
                            accessor: 'tag'
                        },
                        {
                            name: 'Type',
                            accessor: 'type'
                        },
                        {
                            name: 'Source',
                            accessor: 'source'
                        },
                        {
                            name: 'Requires Approval',
                            accessor: 'requiresApproval'
                        },
                        {
                            name: 'Date',
                            accessor: 'date'
                        },
                        {
                            name: 'Meduim',
                            accessor: 'medium'
                        },
                        {
                            name: 'User ID',
                            accessor: 'userId'
                        },
                        {
                            name: 'User Name',
                            accessor: 'user'
                        },
                        {
                            name: 'Defaulter ID',
                            accessor: 'defaulterId'
                        },
                        /*{
                            name: 'Form Id',
                            accessor: 'formId'
                        },*/
                        {
                            name: 'Form',
                            accessor: 'form'
                        }
                    ]}
                    ActionHeading='View Outcome'
                    actions={(x) => this.viewOutcome(x)}
                    loading={this.state.loadTable}
                    show={this.state.access && this.state.loaded}
                    data={dat}
                    noPagination
                    overflowX
                />
            </Grid.Column>
        )
    }

    viewOutcome = (x) => {
        return (
            <Button size="tiny" color="black" icon
                onClick={() => this.renderOutcome(x.id)}
            >
                <Icon name="search" />
            </Button>
        )
    }

    renderOutcomeBoxActions = () => {
        return (
            <>
                <Button
                    disabled={this.state.loading}
                    basic color='red' onClick={() => this.setState({ displayOutcome: false })}
                >
                    <Icon name="remove" />
                    Close
                </Button>
            </>
        )
    }

    modalForUploadFile = () => {
        return (
            <Modal open={this.state.uploadShow} size="tiny">
                <Header icon="upload" content="Upload Excel File" />
                <Modal.Content>
                    <div className="ui container">
                        <div className="ui row">
                            <div className="offset-md-3 col-md-6">

                                <div className="form-group files">
                                    <Input type="file" onChange={this.onChangeFileHandler} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {this.state.showMessage ?
                        <Message
                            error={this.state.messageError}
                            success={this.state.messageSuccess}
                            onDismiss={this.handleDismiss}
                            header={this.state.messageSuccess ? "Successfull!" : "Attention!"}
                            content={this.state.messageText} />
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    {this.renderUploadActionComponents()}
                </Modal.Actions>
            </Modal>
        )
    }

    handleDismiss = () => {
        this.setState({ showMessage: false, messageError: false, messageSuccess: false })
    }


    onClickHandler = () => {
        if (this.state.selectedFile === null) {
            this.setState({ messageError: true, messageText: "Please select a File", showMessage: true })

        } else {
            this.setState({ loading: true })
            this.props.UploadFile(this.state.selectedFile)
                .then((x) => {
                    this.setState({ messageSuccess: true, messageText: "Successfullly Uploaded", showMessage: true, loading: false, selectedFile: null })
                })
                .catch((y) => {
                    // eslint-disable-next-line no-dupe-keys
                    this.setState({ messageError: true, messageText: "Could not be Uploaded", showMessage: true, loading: false, selectedFile: null })
                })
        }
    }

    onClickRevokedHandler = () => {
        if (this.state.selectedRevokedFile === null) {
            this.setState({ messageError: true, messageText: "Please select a File", showMessage: true })
        } else {
            this.setState({ loading: true })
            this.props.UploadRevoked(this.state.selectedRevokedFile)
                .then((x) => {
                    this.setState({
                        revokedFileShowMessage: true,
                        revokedFileSuccess: true,
                        revokedFileWarning: false,
                        revokedFileError: false,
                        revokedFileTextMessage: 'Successfully Uploaded!',
                        selectedRevokedFile: null,
                        loading: false,
                        triggerUpdate: this.state.triggerUpdate + 1
                    })
                })
                .catch((y) => {
                    console.log("Feaild")
                    this.setState({ messageError: true, messageText: "Could not be Uploaded", showMessage: true, loading: false, selectedRevokedFile: null })
                })
        }
    }

    renderOutcomeBox = () => {
        return (
            <>
                <ActionOutcomeForm
                    size="large"
                    open={this.state.displayOutcome}
                    onCloseHandle={() => {
                        this.setState({ displayOutcome: false })
                    }}
                    header={
                        { icon: "user", text: "Outcome" }
                    }
                    actionsComponent={this.renderOutcomeBoxActions()}
                    loading={this.props.loading}
                    OutCome={this.props.outcome}
                />
            </>
        )
    }

    renderOutcome = (value) => {
        this.props.GetActionOutcome(value)
            .then(x => {
                this.setState({ displayOutcome: true })
            }).catch((x => {
                this.setState({ loaded: true })
            }
            ))
    }

    renderDownloadButton = () => {
        return (
            <Button
                loading={this.state.downloadReportLoading}
                //   primary={false}
                size='tiny'
                fluid
                disabled={(this.state.selectedIds.length === 0 && !this.state.selectAllDefaulters) || 
                    this.state.downloadReportLoading}
                icon='download'
                floated='right'
                color='teal'
                onClick={() => {
                    this.setState({
                        downloadShow: true
                    })
                }}
                content='Report'
            />

        )
    }

    modalForDownloadReports = () => {
        var value = {}
        value.segregation_val = 'Action Performed'
        // console.log(this.props.formsWithIds)
        value.formId = this.props.formsWithIds.length !== 0 ? this.props.formsWithIds[0].value : 1
        value.order = false
        value.medium = 'All'
        return (
            <DateInputForm
                size="tiny"
                header="Download"
                //  header={this.state.selectedIds.length === 1 ? `Report of 1 Defaulter` : `Report of ${this.state.selectedIds.length} Defaulters`}
                open={this.state.downloadShow}
                onCloseHandle={() => {
                    this.setState({
                        downloadShow: false,
                    })
                }}
                actionsComponent={this.renderDownloadActionsComponent()}
                onSubmit={this.handleDownloadSubmit}
                loading={this.state.loading}
                initialValues={value}
                segregation
                myForms={this.props.formsWithIds}
            />
        )
    }

    renderDownloadActionsComponent = () => {
        return (
            <>
                <Button
                    basic color='red' onClick={() => {
                        this.setState({
                            downloadShow: false,
                        })
                    }}
                    icon='x'
                    content="Cancel"
                />
                <Button
                    loading={this.state.loading}
                    form="dateInputForm"
                    color='green'
                    type='submit'
                    icon='download'
                    content="Download"
                />
            </>
        )
    }


    handleDownloadSubmit = (value, dispatch) => {
        var data = {}
        if (value.From !== undefined && value.From !== null && typeof value.From === 'object') {
            data.From = value.From.getFullYear() + "-" + (value.From.getUTCMonth() + 1) + "-" + value.From.getDate();
        }
        if (value.To !== undefined && value.To !== null && typeof value.To === 'object') {
            data.To = value.To.getFullYear() + "-" + (value.To.getUTCMonth() + 1) + "-" + value.To.getDate();
        }
        value.ids = this.state.selectedIds
        // eslint-disable-next-line no-unused-vars
        if (value.ids.length === 0) {
            console.log(value)
            window.open(apiURL + `reports/getdefaulterreport/search?parameters=${JSON.stringify(value)}&filter=${JSON.stringify(this.state.filtered)}`, '')
        }
        else {
            this.setState({downloadReportLoading: true});
            this.props.DownloadDefaulterReport(JSON.stringify(value))
                .then()
                .catch(() => {
                    alert("Something went wrong");
                })
                .finally(() => {
                    this.setState({downloadReportLoading: false});
                })
            
            // w.document.write(apiURL + `reports/getdefaulterreport/search?parameters=${JSON.stringify(data)}&filter=${JSON.stringify(this.state.filtered)}&segregation=${JSON.stringify(value.segregation_val)}`)
            //window.open(apiURL + `reports/getdefaulterreport/search?parameters=${JSON.stringify(value)}&filter={}`, '')
        }
        this.setState({ downloadShow: false })
        return dispatch(reset('dates'))
    }

    handleMultipleFlag = flag => {
        this.setState({ loading: true })
        let object = {};
        object.flag = flag;
        if (this.state.selectedIds.length !== 0) {
            object.ids = [...this.state.selectedIds];
        } else {
            object.filter = { ...this.state.searchParameter }
        }

        this.props.UpdateMultipleFlag(object)
            .then(() => { this.setState({ loading: false, triggerUpdate: this.state.triggerUpdate + 1, selectedIds: [] }) })
            .catch(() => this.setState({ loading: false, selectedIds: [] }))
    }

    handlePhoneOptions = option => {
        this.setState({PhoneOptionsClicked: true});
        switch(option) {
            case 1:
                this.props.RemoveDuplicatePhoneNums()
                    .then((response) => alert(`${response["count"]} duplicates/empty numbers found and removed`))
                    .catch(() => alert("Something went wrong"))
                    .finally(() => this.setState({PhoneOptionsClicked: false}));
                break;
            case 2:
                this.props.SeparatePhoneNums()
                    .then(() => alert("Successfully separated!"))
                    .catch(() => alert("Something went wrong"))
                    .finally(() => this.setState({PhoneOptionsClicked: false}));
                break;
        }
    }

    renderMultipleFlag = () => {
        return (
            <Popup
                content={`Flag customers`}
                position='top right'
                on='hover'
                trigger={
                    <Dropdown text="Flag" fluid button compact
                        disabled={this.state.selectedIds.length === 0 && 
                            Object.keys(this.state.searchParameter).length === 0}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { this.handleMultipleFlag('f') }}>Flagged</Dropdown.Item>
                            <Dropdown.Item onClick={() => { this.handleMultipleFlag('n') }}>Normal</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
            />
        )
    }

    renderPhoneNumberOptions = () => {
        return (

                    <Dropdown 
                        loading={this.state.PhoneOptionsClicked} 
                        text="Phone Options" 
                        fluid button compact
                        disabled={this.state.PhoneOptionsClicked}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { this.handlePhoneOptions(1) }}>Remove Duplicates</Dropdown.Item>
                            <Dropdown.Item onClick={() => { this.handlePhoneOptions(2) }}>Separate</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

        )
    }

    renderPageActions = () => {
        // eslint-disable-next-line no-unused-vars
        var half = 8;
        return (
            <div style={{ marginBottom: '10px' }}>
                <Grid >
                    <Grid.Column width={2}>
                        {this.renderAllButton()}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {this.renderFilterButton()}
                    </Grid.Column>
                    <Grid.Column width={1}>
                        {/* {this.props.total === 0 ? null : <span>{`${this.props.total} Total`}</span>} */}
                        {this.props.total === 0 ? null : <Label basic>{`${this.props.total} Total`}</Label>}
                    </Grid.Column>
                    <Grid.Column width={1}>
                        {this.state.selectAllDefaulters ? <Label basic >{`${this.props.total} Selected`}
                            </Label> : this.state.selectedIds.length === 0 ? null :
                                <Label basic >{`${this.state.selectedIds.length} Selected`}</Label>}
                        {/* {this.state.selectedIds.length !== 0 && !this.state.selectedAllDefaulters ? <Label basic >{`${this.state.selectedIds.length} Selected`}</Label> : this.state.selectAllDefaulters ? <Label basic >{`${this.props.total} Selected`}</Label> : null} */}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {/* {this.state.selectedIds.length === 0 ? null : this.renderAddActionButton()} */}
                        {this.renderAddActionDropdown()}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {/* {this.state.selectedIds.length === 0 ? null : this.renderDownloadButton()} */}
                        {this.renderDownloadButton()}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {/* {this.renderUploadRevokedButton()} */}
                        {this.renderMultipleFlag()}
                    </Grid.Column>
                    <Grid.Column width={2} >
                        {/* {this.renderUploadRevokedButton()} */}
                        {this.renderPhoneNumberOptions()}
                    </Grid.Column>
                    <Grid.Column width={2}>
                        {/* {this.renderUploadButton()} */}
                        {this.renderUploadDropdown()}
                    </Grid.Column>

                    {/* <Grid.Column width={3}>
                        {this.renderCompanyDropdown()}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {this.renderTagKeys()}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {this.renderTagValues()}
                    </Grid.Column>
                    <Grid.Column width={1}>
                        {this.renderSearchIconButton()}
                    </Grid.Column> 
                    <Grid.Column width={2}>
                        {`Selected : ${this.state.selectedIds.length}`}
                    </Grid.Column> */}
                </Grid>
            </div>
        )
    }

    renderCustomerBox = () => {
        return (
            <div style={styles.mainDiv}>
                {this.state.access && this.state.loaded ? this.renderPageActions() : null}
                {this.renderFilterBox()}
                {this.modalForDownloadReports()}
                {this.renderDeleteConfirmationBox()}
                {this.renderAddActionBox()}
                {this.state.uploadShow ? this.modalForUploadFile() : null}
                {this.state.uploadRevokedShow ? this.modalForUploadRevokedFile() : null}
                {this.state.uploadSupportingFileShow ? this.modalForUploadSupportingFile() : null}
                {this.state.uploadUnrevokedFileShow ? this.modalForUnrevokedFilesUpload() : null}
                {this.state.uploadSelectCutomersFileShow ? this.modalForSelectCustomersFile() : null}
                {/* <div style={{ height: 10 }} /> */}
                {this.renderTable()}
            </div>
        )
    }

    render() {
        console.log(this.state.loaded);
        return (
            <>
                {!this.state.loaded ?
                    <LoaderView text="Customer Page is Loading" /> :
                    this.state.access ? null :
                        <UnauthorizedView pageName="Customer Page" />}
                {this.state.displayCustomer && this.state.loaded ? this.renderCustomerDisplay() : 
                    this.renderCustomerBox()}
            </>
        )
    }

}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}

const mstp = (state) => {
    return ({
        // data: state.customer.data === [] ? [] : state.customer.data,
        data: state.customer.data,
        total: state.customer.total,
        userbyID: state.user.userbyID,
        selectedCustomer: state.customer.selectedDefaulter,
        outcome: state.actions.outcome,
        formsWithIds: state.myForms.formsWithIds,
        AddressImage: state.actions.image,
        sortingParams: state.actions.sortingParams,
        companybyID: state.company.companyWithIds,
        tagKeys: state.customer.tagKeys,
        message: state.actions.message,
        selectedCustomers: state.customer.selectedCustomers,
        invalidTrackingIds: state.customer.invalidTrackingIds
    })
}

export default connect(mstp, actions)(Customers);