import {
    LOAD_SELF_ATTENDANCES,
    LOAD_USER_ATTENDANCES,
    LOAD_DUTY_STATUS,
    IN_DUTY_REASON,
    LOAD_OFFICE_TIMING,
    LOAD_APP_TIMING
} from '../actions/types';

const INITIAL_STATE = {
    selfAttendance: [],
    userAttendance: [],
    dutyStatus: false,
    needForReason: null,
    officeTiming: null,
    appTiming: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_SELF_ATTENDANCES:
            return { ...state, selfAttendance: action.payload }
        case LOAD_USER_ATTENDANCES:
            return { ...state, userAttendance: action.payload }
        case LOAD_DUTY_STATUS:
            return { ...state, dutyStatus: action.payload }
        case IN_DUTY_REASON:
            return { ...state, needForReason: action.payload }
        case LOAD_OFFICE_TIMING:
            return { ...state, officeTiming: action.payload }
        case LOAD_APP_TIMING:
            return { ...state, appTiming: action.payload }
        default:
            return state;
    }
}