import {
  LOAD_ALL_TAGS,
  LOAD_ACTIVE_TAGS,
  TOGGLE_TAG_ACTIVITY,
  LOAD_TAGS,
} from "../actions/types";

const INITIAL_STATE = {
  data: [],
  activeTags: [],
  tags: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_TAGS:
      return {
        ...state,
        data: action.payload,
      };
    case LOAD_ACTIVE_TAGS:
      return {
        ...state,
        activeTags: action.payload.activeTags,
        tags: action.payload.resultTags,
      };
    case TOGGLE_TAG_ACTIVITY:
      return {
        ...state,
        data: state.data.map((x) => {
          if (x.id === action.payload) {
            x.isActive = !x.isActive;
          }
          return x;
        }),
      };
    default:
      return state;
  }
};
