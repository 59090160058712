import React, { Component } from 'react';
import { Button, Grid, Icon, Label, Popup, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import LoaderView from '../components/loaderView';
import UnauthorizedView from '../components/unauthorizedView';
import DataTable from '../components/dataTable';
import CallerWindow from './callerWindow';
import * as actions from '../actions';

class Performed extends Component {

    state = {
        filterShow: false,
        loading: false,
        callerWindow: false,
        selectedCustomer: null,
        pageSize: 0,
        currentPage: 1,
        loadTable: false,
        triggerUpdate: 1,
        loadTable: false,
    }

    componentDidMount = () => {
        // if (this.props.performed) {
        this.setState({ triggerUpdate: this.state.triggerUpdate + 1 });
        // }
    }

    tablePaging = (pageSize, currentPage) => {
        this.setState({ loadTable: true, currentPage: currentPage })
        this.props.LoadAllAssignedCallees(pageSize, currentPage, true)
            .then(x => this.setState({ loaded: true, access: true, loadTable: false, displayAction: false }))
            .catch(x => this.setState({ loaded: true, access: false }))
    }

    actionButtons = value => {
        return (
            <Popup
                content={`Perform Action on ${value.name}`}
                position='top right'
                on='hover'
                trigger={
                    <Button size="mini" color='blue' icon='call'
                        disabled={false}
                        onClick={() => {
                            // this.setState({ callerWindow: true, selectedCustomer: value });
                        }}
                    />}
            />
        )
    }

    renderTable = () => {
        // const data = this.props.performed ? this.props.performedData : this.props.assignedData
        const data = this.props.data;
        console.log(data);
        return (
            <DataTable
                header={[
                    {
                        name: 'Name',
                        accessor: 'defaulterName'
                    },
                    {
                        name: 'Tracking Id',
                        accessor: 'companyTrackingId'
                    },
                    {
                        name: 'Balance',
                        accessor: 'balance'
                    },
                    {
                        name: 'Company',
                        accessor: 'companyName'
                    },
                    {
                        name: 'Contact',
                        accessor: 'contact',
                        separator: ','
                    },
                    {
                        name: 'Assgined By',
                        accessor: 'assignedByUserName'
                    }
                ]}
                // actions={(x) => this.actionButtons(x)}
                loading={this.state.loadTable}
                // show={this.state.access && this.state.loaded && !this.state.displayCustomer}
                rowCount={this.props.total}
                loading={this.state.loadTable}
                show={true}
                data={data}
                pageSizes={[10, 20, 50, 100]}
                trigger={this.state.triggerUpdate}
                // noPagination
                onPageChange={(x, y) => this.tablePaging(x, y)}
                overflowX
            />
        )
    }

    onClickDial = () => {
        return (
            <CallerWindow customer={this.state.selectedCustomer} />
        )
    }

    render() {
        console.log(this.props)
        return (
            <div >
                {this.state.callerWindow ? this.onClickDial() : this.renderTable()}
            </div>
        );
    }
}

const styles = {
    mainDiv: {
        marginLeft: '3vw',
        marginRight: '3vw',
    },
    topBox: {
        marginBottom: '10px'
    }
}


const mstp = (state) => {
    console.log(state);
    return ({
        data: state.teleCaller.data,
        total: state.teleCaller.performedTotal,
    })
}

export default connect(mstp, actions)(Performed);