import React from 'react';
import { Modal, Header, Input, Message } from 'semantic-ui-react';


const UploadFilesModal = (props) => {
    return (
        <Modal open={props.open} size='tiny'>
            <Header icon='upload' content={props.content} />
            <Modal.Content>
                <div className="ui container">
                    <div className="ui row">
                        <div className="offset-md-3 col-md-6">
                            <div className="form-group files">
                                <Input multiple={props.multiple} type="file" onChange={props.onChangeFilesHandler} />
                            </div>
                        </div>
                    </div>
                </div>
                {props.showMessage ?
                    <Message
                        size='large'
                        error={props.errorMessage}
                        success={props.successMessage}
                        warning={props.warningMessage}
                        onDismiss={props.handleDismiss}
                        header={props.successMessage ? "Successfull!" : props.errorMessage ? "Error!" : props.warningMessage ? "Attention!" : ""}
                        content={props.messageText} />
                    : null}
            </Modal.Content>
            <Modal.Actions>
                {/* {props.actionsComponent} */}
                <props.actionsComponent />
            </Modal.Actions>
        </Modal>
    )
}

export default UploadFilesModal;