import React, { Component } from 'react';

class error404 extends Component {

    render() {
        return (
            <div>404 Error: Page not found</div>
        )
    }

}

export default error404;