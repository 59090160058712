import React, { Component } from "react";
import { Modal, Input, Button, Grid, Form, Dropdown } from "semantic-ui-react";
import { CommonInput, CommonSelect } from "../forms/commonInput";
import { Field, reduxForm } from "redux-form";
import LoaderView from "../loaderView";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";

import moment from "moment";
import momentLocaliser from "react-widgets-moment";
momentLocaliser(moment);

let form = null;
let fieldErrors = null;

class FillForm extends Component {
  state = {
    actionTag: null,
    showFields: false,
    tag: null,
    selectedFiles: null,
    elevationImageFile: null,
    phoneNumbers: this.props.phoneNumbers,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.phoneNumbers !== state.phoneNumbers) {
      console.log("Not Equal");
      return {
        phoneNumbers: props.phoneNumbers,
      };
    }
    return null;
  }

  renderDateTimePicker = ({
    input: { onChange, value },
    showTime,
    placeholder,
    label,
  }) => {
    return (
      <>
        {/* <>
                    <span>{label}</span>
                    <div style={{ marginBottom: '1px' }} />
                </> */}
        <DateTimePicker
          onChange={onChange}
          format="DD-MM-YYYY"
          placeholder={placeholder}
          time={false}
          clearable
          value={!value ? null : new Date(value)}
        />
      </>
    );
  };

  showLabel = (label) => {
    return (
      <>
        <span>{label}</span>
        <div style={{ marginBottom: "3px" }} />
      </>
    );
  };

  constructFields = () => {
    let { formInfo } = this.props;
    //console.log(formInfo);
    if (
      formInfo === null ||
      formInfo === undefined ||
      Object.keys(formInfo).length === 0
    ) {
      //console.log(formInfo);
      return null;
    }
    form = formInfo;
    return this.props.formInfo.formFields.map((x) => {
      if (x.type === "dropdown") {
        return (
          <Grid.Column key={x.id} width={8}>
            {this.showLabel(x.caption)}
            <Dropdown
              fluid
              selection
              name={`FORM_${x.caption}`}
              placeholder={"Select the " + x.caption}
              label={x.caption}
              options={x.fieldValues.map((y) => {
                return {
                  id: `${x.id}-${y.id}`,
                  value: y.value,
                  text: y.value,
                };
              })}
            />
          </Grid.Column>
        );
      } else if (x.datatype === "float") {
        return (
          <Grid.Column key={x.id} width={8}>
            {this.showLabel(x.caption)}
            <Field
              name={`FORM_${x.caption}`}
              placeholder={"Type in the " + x.caption}
              type="number"
              step={0.01}
              component={CommonInput}
            />
          </Grid.Column>
        );
        
      } else {
        return (
          <Grid.Column key={x.id} width={8}>
            {this.showLabel(x.caption)}
            <Field
              name={`FORM_${x.caption}`}
              placeholder={"Type in the " + x.caption}
              type={x.datatype == "number" ? "number" : x.datatype}
              //this isn't resizing the input field
              size={x.maxLength / 2}
              component={CommonInput}
            />
          </Grid.Column>
        );
      }
      /*
            for each I have
            caption
            datatype
            input type
            min length
            max length
        */

      return x.caption;
    });
    return null;
  };
  handleChange = (value) => {
    //console.log(value);
    this.setState({
      showFields: true,
      tag: value,
    });
  };

  onChangeFileHandler = (event, input) => {
    //console.log(event.target.files);
    let image = event.target.files;
    input.onChange(image);
  };

  renderInputField = ({ input, type, meta }) => {
    return (
      <Input
        name={input.name}
        type={type}
        onChange={(event) => this.onChangeFileHandler(event, input)}
        multiple
      />
    );
  };

  renderElevationImageUpload = () => {
    return (
      <Grid.Column width={8}>
        {this.showLabel("Select Supporting Image(s)")}
        <Field
          name="supportingImages"
          type="file"
          //value={this.state.elevationImageFile}
          component={this.renderInputField}
        />
        <div style={{ height: "15px" }} />
      </Grid.Column>
    );
  };

  renderInputPaymentField = ({ input, type, meta }) => {
    return (
      <Input
        name={input.name}
        type={type}
        onChange={(event) => this.onChangeFileHandler(event, input)}
        multiple
      />
    );
  };

  renderPaymentImages = () => {
    return (
      <Field
        name="paymentimages"
        type="file"
        //value={this.state.elevationImageFile}
        component={this.renderInputPaymentField}
      />
    );
  };

  renderActionFields = () => {
    var value = this.state.tag;
    switch (value) {
      case 2:
        return (
          <>
            <Grid.Column width={8}>
              {this.showLabel("Promise Amount")}
              <Field
                name={"2_amount"}
                placeholder={"Amount"}
                fluid
                type="number"
                step={"0.01"}
                component={CommonInput}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              {this.showLabel("Promise Date")}
              <Field
                name={"2_date"}
                fluid
                placeholder={"Date"}
                component={this.renderDateTimePicker}
              />
              {fieldErrors === null ? null : 
                <div style={{ textAlign: "left", marginTop: '3px', color: '#FE4242' }}>
                  {fieldErrors["2_date"]}
                </div>
              }
            </Grid.Column>
          </>
        );
      case 1:
        return (
          <>
            <Grid.Column width={5}>
              {this.showLabel("Payment Amount")}
              <Field
                name="1_amount"
                placeholder={"Amount"}
                type="number"
                step="0.01"
                fluid
                component={CommonInput}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              {this.showLabel("Payment Date")}
              <Field
                name="1_date"
                placeholder={"Date"}
                type="text"
                fluid
                component={this.renderDateTimePicker}
              />
              {fieldErrors === null ? null : 
                <div style={{ textAlign: "left", marginTop: '3px', color: '#FE4242' }}>
                  {fieldErrors["1_date"]}
                </div>
              }
            </Grid.Column>
            <Grid.Column width={6}>
              {this.showLabel("Payment Pictures (optional)")}
              {this.renderPaymentImages()}
            </Grid.Column>
          </>
        );
      case 3:
        return (
          <>
            <Grid.Column width={8}>
              {this.showLabel("Reminder Date")}
              <Field
                name="3_date"
                placeholder={"Date"}
                type="text"
                fluid
                component={this.renderDateTimePicker}
              />
              {fieldErrors === null ? null : 
                <div style={{ textAlign: "left", marginTop: '3px', color: '#FE4242' }}>
                  {fieldErrors["3_date"]}
                </div>
              }
            </Grid.Column>
          </>
        );
      default:
        return <>{null}</>;
    }
  };

  renderTagDropdown = (field) => {
    console.log(field);
    return (
      <Form.Select
        name={field.input.name}
        placeholder={field.placeholder}
        onChange={(e, { value }) => field.input.onChange(value)}
        value={field.input.value}
        clearable
        options={this.props.tagOptions}
      />
    );
  };

  renderPhoneNumberDropdown = (field) => {
    console.log(this.props.phoneNumbers);
    return (
      <Form.Select
        name={field.input.name}
        placeholder={field.placeholder}
        onChange={(e, { value }) => field.input.onChange(value)}
        value={field.input.value}
        clearable
        options={this.props.phoneNumbers}
      />
    );
  };

  renderActionDropdown = () => {
    return (
      <Grid.Column width={16}>
        {this.showLabel("Action Type")}
        <Field
          name="resultId"
          placeholder="Result Tag"
          onChange={this.handleChange}
          component={this.renderTagDropdown}
        />
      </Grid.Column>
    );
  };

  handlePhoneChange = (value) => {
    this.setState({
      calledPhoneNumber: value,
    });
  };

  renderCalledPhoneNoDropdown = () => {
    return (
      <Grid.Column width={16}>
        {this.showLabel("Called Phone No.")}
        <Field
          name="calledPhoneNumber"
          placeholder="Called Phone no."
          onChange={this.handlePhoneChange}
          component={this.renderPhoneNumberDropdown}
        />
      </Grid.Column>
    );
  };

  renderForms = () => {
    return (
      <>
        {this.props.formInfo === null || this.props.formInfo === undefined
          ? "No Form to Display"
          : this.constructFields()}
      </>
    );
  };

  hanldePicUpload = (event, value) => {
    //console.log(event);
  };

  render() {
    return (
      <>
        <div style={styles.mainDiv}>
          <Form id="Fill" onSubmit={this.props.handleSubmit}>
            <Grid>
              {this.renderForms()}
              {this.renderActionDropdown()}
              {this.renderActionFields()}
              {this.props.noSupporting ? (
                <div style={{ height: "25px" }} />
              ) : (
                this.renderElevationImageUpload()
              )}
              {this.props.phoneNumbers
                ? this.renderCalledPhoneNoDropdown()
                : null}
            </Grid>
            <div style={{ height: "20px", width: "100%" }} />
            <Button
              primary
              loading={this.props.submitLoading}
              form="Fill"
              type="submit"
            >
              Submit Form
            </Button>
          </Form>
        </div>
      </>
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

// let FillForm = (props) => {
//     console.log(props)
//     let l = 3;
//     let m = 2;
//     return (
//         <Form>
//             <Grid>
//                 <Grid.Column width={l}>
//                     {constructFields(props)}
//                 </Grid.Column>
//             </Grid>
//         </Form>
//     )
// }

const validate = (formValues) => {
  const errors = {};
  if (form !== null) {
    form.formFields.forEach((field) => {
      let value = formValues[`FORM_${field.caption}`];
      if (
        field.minLength !== null &&
        field.minLength > 0 &&
        (value === null ||
          value === undefined ||
          value.length < field.minLength)
      )
        errors[
          `FORM_${field.caption}`
        ] = `${field.caption} cannot be smaller than ${field.minLength} characters`;

      if (
        field.maxLength !== null &&
        value !== null &&
        value !== undefined &&
        value.length > field.maxLength
      )
        errors[
          `FORM_${field.caption}`
        ] = `${field.caption} cannot be greater than ${field.maxLength} characters`;
    });
  }

  let resultId = formValues["resultId"];
  if(resultId !== undefined && resultId !== null) {
    switch(resultId) {
      case 1:
        if(formValues["1_amount"] === undefined || formValues["1_amount"] === null)
          errors["1_amount"] = "Amount cannot be empty";
        if(formValues["1_date"] === undefined || formValues["1_date"] === null)
          errors["1_date"] = "Date cannot be empty";
        break;
      case 2:
        if(formValues["2_amount"] === undefined || formValues["2_amount"] === null)
          errors["2_amount"] = "Amount cannot be empty";
        if(formValues["2_date"] === undefined || formValues["2_date"] === null)
          errors["2_date"] = "Date cannot be empty";
        break;
      case 3:
        if(formValues["3_date"] === undefined || formValues["3_date"] === null)
          errors["3_date"] = "Date cannot be empty";
    }
  }

  fieldErrors = errors;
  return errors;
};

FillForm = reduxForm({
  form: "fillForm",
  validate,
})(FillForm);

export default FillForm;
